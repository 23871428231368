import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ChapterReceivedReferralSearch = () => {
  const token = sessionStorage.getItem("token");
  const chapterId = sessionStorage.getItem("chapter_id");
  const org_id = sessionStorage.getItem("org_id");

  const [filters, setFilters] = useState({
    search_text: "",
    referred_to_first_name: "",
    referred_by_first_name: "",
  });
  const navigate = useNavigate();
  const [referrls, setReferrals] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  useEffect(() => {
    // Apply filters when the filters state changes
    applyFilters();
  }, [filters]);

  const fetchReferrals = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/referrals/chapter/get-referralByMe/${org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // including the token in the headers
          },
        }
      );

      if (response.status === 502) {
        // Don't throw error for 502 status code
        console.error(
          "Bad Gateway error occurred while fetching sent referrals"
        );
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      if (data && data.results) {
        const filteredReferrals = data.results.filter(
          (referral) => referral.referred_to_memberChapterId == chapterId
        );
        console.log("------------>",filteredReferrals);
        
        setReferrals(filteredReferrals.reverse());
      }
    } catch (err) {
      console.error("Error fetching referrals:", err);
      // setError(
      //   "An error occurred while fetching referrals. Please try again later."
      // );
    }
  };

  useEffect(() => {
    fetchReferrals();
  }, []);

  const handleSearchError = (errorMessage) => {
    console.error(errorMessage);
    // Set the alert message for search error
    navigate("/dashboards/app/chapter-received-referrals", {
      state: { alertMessage: errorMessage },
    });
  };

  const applyFilters = () => {
    const { search_text, referred_to_first_name, referred_by_first_name } = filters;
     
    console.log("referrls values:---------------------> ", referrls);

    const updatedResults = referrls.filter((result) => {
      const isSearchMatch =
        search_text === "" ||
        (result.company_name &&
          result.company_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.referral_desc &&
          result.referral_desc
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.referral_name &&
          result.referral_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.referred_by_first_name &&
          result.referred_by_first_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.referred_to_first_name &&
          result.referred_to_first_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.email_id &&
          result.email_id.toLowerCase().includes(search_text.toLowerCase())) ||
        (result.ref_status &&
          result.ref_status.toLowerCase().includes(search_text.toLowerCase()));

      const isCompanyMatch =
        referred_to_first_name === "" ||
        (result.referred_to_first_name &&
          result.referred_to_first_name
            .toLowerCase()
            .includes(referred_to_first_name.toLowerCase()));

      const isMemberMatch =
        referred_by_first_name === "" ||
        (result.referred_by_first_name &&
          result.referred_by_first_name
            .toLowerCase()
            .includes(referred_by_first_name.toLowerCase()));

      return isSearchMatch && isCompanyMatch && isMemberMatch;
    });

    setFilteredResults(() => updatedResults); // Use callback function here
  };

  const handleSearch = () => {
    applyFilters();
    console.log("received referrals search results: ", filteredResults);

    if (filteredResults.length == 0) {
      // No matching results, set the alert message
      navigate("/dashboards/app/chapter-received-referrals", {
        state: { alertMessage: "No matching results found!" },
      });
    } else {
      // Results found, navigate to the MembersList page with the filtered results
      navigate("/dashboards/app/chapter-received-referrals", {
        state: { searchResults: filteredResults },
      });
    }
  };

  const handleReset = () => {
    setFilters({
      search_text: "",
      referred_to_first_name: "",
      referred_by_first_name: "",
    });
    navigate("/dashboards/app/chapter-received-referrals", {
      state: { searchResults: null },
    });
  };

  return (
    <div>
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search here..."
          name="search_text"
          value={filters.search_text}
          onChange={handleInputChange}
        />
      </div>
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Filter by Referred to"
          name="referred_to_first_name"
          value={filters.referred_to_first_name}
          onChange={handleInputChange}
        />
      </div>
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Filter by Referred by"
          name="referred_by_first_name"
          value={filters.referred_by_first_name}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-12 text-center pt-2">
        <button className="btn btn-primary" onClick={handleSearch}>
          <b>Search and Apply Filters</b>
        </button>
      </div>
      <div className="col-12 text-center pt-2">
        <button className="btn btn-danger" onClick={handleReset}>
          <b>Reset</b>
        </button>
      </div>
    </div>
  );
};

export default ChapterReceivedReferralSearch;
