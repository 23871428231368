import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AllMeetingSearch = () => {
  const token = sessionStorage.getItem("token");
  const orgId = sessionStorage.getItem("org_id");
  const [filters, setFilters] = useState({
    search_text: "",
    meeting_mode: "",
    organizer_name: "",
    meeting_type: "",
  });
  const org_id = sessionStorage.getItem("org_id");

  const navigate = useNavigate();
  const [userMeetings, setUserMeetings] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  useEffect(() => {
    // Apply filters when the filters state changes
    applyFilters();
  }, [filters]);

  const fetchUserMeetings = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `/meetings/data/total-meetings/org/${orgId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data && response.data.results) {
        setUserMeetings(response.data.results);
      }
    } catch (err) {
      console.error("Error fetching user meetings:", err);
      // handleSearchError("An error occurred while fetching user meetings. Please try again later.");
    }
  };

  useEffect(() => {
    fetchUserMeetings();
  }, []);

  const handleSearchError = (errorMessage) => {
    console.error(errorMessage);
    // Set the alert message for search error
    navigate("/dashboard/app/AllMeetingList", {
      state: { alertMessage: errorMessage },
    });
  };

  const applyFilters = () => {
    const { search_text, meeting_mode, organizer_name, meeting_type } = filters;
     

    const updatedResults = userMeetings.filter((result) => {
      const isSearchMatch =
        search_text == "" ||
        (result.organizer_name &&
          result.organizer_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_mode &&
          result.meeting_mode
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_type &&
          result.meeting_type
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_subject &&
          result.meeting_subject
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_desc &&
          result.meeting_desc
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_place &&
          result.meeting_place
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_link &&
          result.meeting_link
            .toLowerCase()
            .includes(search_text.toLowerCase()));

      const isModeMatch =
        meeting_mode === "" ||
        (result.meeting_mode && result.meeting_mode === meeting_mode);

      const isOrganizerMatch =
        organizer_name === "" ||
        (result.organizer_name &&
          result.organizer_name
            .toLowerCase()
            .includes(organizer_name.toLowerCase()));

      const isTypeMatch =
        meeting_type === "" ||
        (result.meeting_type && result.meeting_type === meeting_type);

      return isSearchMatch && isModeMatch && isOrganizerMatch && isTypeMatch;
    });

    setFilteredResults(() => updatedResults); // Use callback function here
  };

  const handleSearch = () => {
    applyFilters();
    console.log("all meeting search results: ", filteredResults);

    if (filteredResults.length == 0) {
      // No matching results, set the alert message
      navigate("/dashboard/app/AllMeetingList", {
        state: { alertMessage: "No matching results found!" },
      });
    } else {
      // Results found, navigate to the MembersList page with the filtered results
      navigate("/dashboard/app/AllMeetingList", {
        state: { searchResults: filteredResults },
      });
    }
  };

  const handleReset = () => {
    setFilters({
      search_text: "",
      meeting_mode: "",
      organizer_name: "",
      meeting_type: "",
    });
    navigate("/dashboard/app/AllMeetingList", {
      state: { searchResults: null },
    });
  };

  return (
    <div>
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search here..."
          name="search_text"
          value={filters.search_text}
          onChange={handleInputChange}
        />
      </div>
      <div className="input-group pb-2">
        <select
          className="form-select"
          placeholder="Filter by Meeting Mode"
          name="meeting_mode"
          value={filters.meeting_mode}
          onChange={handleInputChange}
        >
          <option value="">Select Meeting Mode</option>
          <option value="Virtual">Virtual</option>
          <option value="In-Person">In-Person</option>
        </select>
      </div>
      <div className="input-group pb-2">
        <select
          className="form-select"
          placeholder="Filter by Meeting Type"
          name="meeting_type"
          value={filters.meeting_type}
          onChange={handleInputChange}
        >
          <option value="">Select Meeting Type</option>
          <option value="Chapter">Chapter</option>
          <option value="One-on-One">One-on-One</option>
        </select>
      </div>

      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="Filter by organised by"
          name="organizer_name"
          value={filters.organizer_name}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-12 text-center pt-2">
        <button className="btn btn-primary" onClick={handleSearch}>
          <b>Search and Apply Filters</b>
        </button>
      </div>
      <div className="col-12 text-center pt-2">
        <button className="btn btn-danger" onClick={handleReset}>
          <b>Reset</b>
        </button>
      </div>
    </div>
  );
};

export default AllMeetingSearch;
