import React, { useState, useEffect } from "react";
import "./customcss/admintabstyle.css";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Card,
  Dropdown,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FaTrash,
  FaEdit,
  FaEye,
  FaComment,
  FaEllipsisV,
  FaTimes,
} from "react-icons/fa";
import MyReferralComment from "./myReferralComment";
import EditReferral from "./editReferral";
import DeleteReferral from "./deleteReferral";

const ReferralList = ({ searchResults, alertMessage }) => {
  const navigate = useNavigate();
  const [referrals, setReferrals] = useState([]);
  const [error, setError] = useState(null);
  const [eyeClickedRow, setEyeClickedRow] = useState(null);
  const [commentRow, setCommentRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [deleteRow, setDeleteRow] = useState(null);
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const member_id = sessionStorage.getItem("member_id");
  const token = sessionStorage.getItem("token");
  const org_id = sessionStorage.getItem("org_id");

  //fetch referrals list for user
  const fetchReferrals = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/referrals/get-referralByMe/${member_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // including the token in the headers
          },
        }
      );
      if (response.status === 502) {
        // Don't throw error for 502 status code
        console.error(
          "Bad Gateway error occurred while fetching sent referrals"
        );
        return;
      }
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data && data.results) {
        setReferrals(data.results.reverse());
      }
    } catch (err) {
      console.error("Error fetching referrals:", err);
      // setError(
      //   "An error occurred while fetching referrals. Please try again later."
      // );
    }
  };

  useEffect(() => {
    // Fetch initial data
    fetchReferrals();

    // Poll for updates whenever refreshNeeded changes
    const intervalId = setInterval(() => {
      fetchReferrals();
    }, 5000); // Polling every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [refreshNeeded]); // useEffect will be triggered whenever refreshNeeded changes

  const toggleEyeDetails = (index) => {
    setEyeClickedRow((prev) => (prev === index ? null : index));
  };

  const toggleCommentSection = (index) => {
    setCommentRow((prev) => (prev === index ? null : index));
  };

  const toggleEditSection = (index) => {
    setEditRow((prev) => (prev === index ? null : index));
  };

  const toggleDeleteSection = (index) => {
    setDeleteRow((prev) => (prev === index ? null : index));
  };

  const handleDocumentClick = (event) => {
    // Check if the click occurred outside the expanded rows
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    // Check if the click occurred inside any expanded row
    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    // Close expanded rows if the click occurred outside
    if (!clickedInsideExpandedRow) {
      setEyeClickedRow(null);
      setCommentRow(null);
      setEditRow(null);
      setDeleteRow(null);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [eyeClickedRow, commentRow, editRow, deleteRow]);

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <Table className="rounded">
                    <thead className="bg-primary text-white">
                      <tr>
                        <th>
                          <b>Referred To</b>
                        </th>
                        <th>
                          <b>Referral Name</b>
                        </th>
                        <th>
                          <b>Status</b>
                        </th>
                        <th>
                          <b></b>
                        </th>
                      </tr>
                    </thead>
                    {referrals.length > 0 ? (
                      <tbody>
                        {(searchResults && searchResults.length > 0
                          ? searchResults
                          : referrals
                        ).map((referral, index) => (
                          <React.Fragment key={referral.referral_id}>
                            <tr>
                              <td>
                                <b>{referral.referred_to_first_name}</b>
                              </td>
                              <td>
                                <b>{referral.referral_name}</b>
                              </td>
                              <td>{referral.ref_status}</td>
                              <td>
                                <div className="d-lg-none">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="link"
                                      id="dropdown-basic"
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "skyblue",
                                      }}
                                    >
                                      <FaEllipsisV className="ellipsis-icon" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => toggleEyeDetails(index)}
                                      >
                                        <FaEye className="viewicon" /> View
                                      </Dropdown.Item>
                                      {referral.ref_status !=
                                        "Business Done" && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              toggleCommentSection(index)
                                            }
                                          >
                                            <FaComment className="commenticon" />{" "}
                                            Comment
                                          </Dropdown.Item>
                                        )}
                                      {referral.ref_status !=
                                        "Business Done" && (
                                          <Dropdown.Item
                                            onClick={() => toggleEditSection(index)}
                                          >
                                            <FaEdit className="editicon" /> Edit
                                          </Dropdown.Item>
                                        )}
                                      {/* // <Dropdown.Item
                                      //   onClick={() =>
                                      //     toggleDeleteSection(index)
                                      //   }
                                      // >
                                      //   <FaTrash className="deleteicon" />{" "}
                                      //   Delete
                                      // </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                <div className="d-none d-lg-block">
                                  <FaEye
                                    className="viewicon"
                                    size={15}
                                    color="#007BFF"
                                    onClick={() => toggleEyeDetails(index)}
                                  />
                                  &nbsp;&nbsp;
                                  {referral.ref_status !=
                                    "Business Done" && (
                                      <FaComment
                                        className="commenticon"
                                        size={15}
                                        color="#007BFF"
                                        onClick={() => toggleCommentSection(index)}
                                      />)}
                                  &nbsp;&nbsp;
                                  {referral.ref_status !=
                                    "Business Done" && (
                                      <FaEdit
                                        className="editicon"
                                        size={15}
                                        color="#007BFF"
                                        onClick={() => toggleEditSection(index)}
                                      />
                                    )}
                                  &nbsp;&nbsp;
                                  {/* <FaTrash
                                    className="deleteicon"
                                    size={15}
                                    color="red"
                                    onClick={() => toggleDeleteSection(index)}
                                  /> */}
                                </div>
                              </td>
                            </tr>

                            {eyeClickedRow === index && (
                              <tr className="expanded-row">
                                <td
                                  colSpan="4"
                                  style={{ position: "relative" }}
                                >
                                  {/* Close Icon */}
                                  <div
                                    className="close-icon"
                                    onClick={() => setEyeClickedRow(null)}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "15px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    &#10006;
                                  </div>

                                  {/* Expanded Row Content */}
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <p>
                                        <b>
                                          Company Name: {referral.company_name}
                                        </b>
                                      </p>
                                      {referral.ref_status ===
                                        "Business Done" && (
                                          <p>
                                            <b>
                                              Business Done Amounts:{" "}
                                              {referral.business_done_amount}
                                            </b>
                                          </p>
                                        )}
                                      <p>
                                        <b>Email: {referral.email_id}</b>
                                      </p>
                                    </div>
                                    <div className="col-lg-6">
                                      <p>
                                        <b>Contact No: {referral.contact_no}</b>
                                      </p>
                                      <p>
                                        <b>
                                          Referral Description:{" "}
                                          {referral.referral_desc}
                                        </b>
                                      </p>
                                      <p>
                                        <b>
                                        Referral Flag: {referral.ref_flag}
                                        </b>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}

                            {commentRow === index && (
                              <tr className="expanded-row">
                                <td
                                  colSpan="4"
                                  style={{ position: "relative" }}
                                >
                                  {/* Close Icon */}
                                  <div
                                    className="close-icon"
                                    onClick={() => setCommentRow(null)}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "15px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    &#10006;
                                  </div>

                                  {/* AddComment Component */}
                                  <MyReferralComment
                                    referralId={referral.referral_id}
                                  />
                                </td>
                              </tr>
                            )}

                            {editRow === index && (
                              <tr className="expanded-row">
                                <td
                                  colSpan="4"
                                  style={{ position: "relative" }}
                                >
                                  {/* Close Icon */}
                                  <div
                                    className="close-icon"
                                    onClick={() => setEditRow(null)}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "15px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    &#10006;
                                  </div>

                                  {/* AddComment Component */}
                                  <EditReferral
                                    referralId={referral.referral_id}
                                    onClose={() => setEditRow(null)}
                                  />
                                </td>
                              </tr>
                            )}
                            {deleteRow === index && (
                              <tr className="expanded-row">
                                <td
                                  colSpan="4"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    className="close-icon"
                                    onClick={() => setDeleteRow(null)}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "20px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    &#10006;
                                  </div>
                                  <DeleteReferral
                                    referralId={referral.referral_id}
                                    onClose={() => setDeleteRow(null)}
                                  />
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    ) : (
                      <p>No referrals available.</p>
                    )}
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {error && (
        <Alert
          variant="danger"
          onClose={() => setError(null)}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={() => setError(null)}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}

      {alertMessage && (
        <Alert
          variant="danger"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={() =>
                navigate("/dashboards/app/by-me", {
                  state: { alertMessage: null },
                })
              }
            />
          </div>
          <Alert.Heading>Alert!</Alert.Heading>
          <p>{alertMessage}</p>
        </Alert>
      )}
    </>
  );
};

export default ReferralList;
