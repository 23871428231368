import {React, useEffect, useState} from 'react'
import { Row, Col, Button, Form, Container, Image, Card ,Alert} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';


//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
// import 'swiper/components/navigation/navigation.scss';

// img
import logo from '../../../assets/images/logo-full.png'
import login1 from '../../../assets/images/login/1.png'
import login2 from '../../../assets/images/login/2.png'
import login3 from '../../../assets/images/login/3.png'
import { FaTimes } from "react-icons/fa";
import { jwtDecode } from 'jwt-decode';
// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Recoverpw = () => {
  const navigate = useNavigate();

  let history = useNavigate()
   const [email_id , setEmail_id] = useState('')
   const [error, setError] = useState(null);
   const [org, setOrg] = useState(logo);
  const [orgLogo, setOrgLogo] = useState(null);
  const [orgGallery, setOrgGallery] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const navigateToSignInPage = () => {
    // Navigate to the friend profile page with memberId
    navigate("/auth/sign-in");
  };

const handleSuccess = (message) => {
  setSuccessMessages([...successMessages, message]);
};

// useEffect(() => {
//   const getQueryParam = (url, param) => {
//     const queryParams = new URL(url).searchParams;
//     return queryParams.get(param);
//   };
//   let token;
//   if (sessionStorage.getItem("login_token")) {
//     token = sessionStorage.getItem("login_token");
//   } else {
//     token = getQueryParam(window.location.href, "token");
//   }

//   if (!token) {
//     navigate("/auth/sign-in");
//     return;
//   }
//   if (token) {
//     try {
//       axios
//         .post(process.env.REACT_APP_API_URL + "/org/verify-token/", { token })
//         .then((response) => {
//           let org_info = response.data.result[0];
//           console.log("orgid==>", org_info.organization_name);
//           sessionStorage.setItem("org_name", org_info.organization_name);

//           setOrg(org_info);
//         });
//       sessionStorage.setItem("login_token", token);
//     } catch (error) {
//       if (error.response && error.response.status === 404) {
//         console.error("No org found:", error);
//       } else {
//         console.error("Error fetching org:", error);
//       }
//     }
//     console.log("here", org);
//   }

//   const decodedToken = jwtDecode(token);
//   const verifiedOrgId = decodedToken.org_id;
//   setOrgId(verifiedOrgId);
//   if (verifiedOrgId) {
//     try {
//       axios
//         .get(
//           `${process.env.REACT_APP_API_URL}/org/getOrgImages/${verifiedOrgId}`
//         )
//         .then((response) => {
//           if (response.status === 200) {
//             const { awsLogoUrls, galleryUrlsWithDesc } = response.data;
//             const L1 = awsLogoUrls.length > 0 ? awsLogoUrls[0] : null;
//             const G1 = galleryUrlsWithDesc;

//             setOrgLogo(L1);
//             setOrgGallery(G1);
//             sessionStorage.setItem("org_logo", L1);

//             // Optionally, handle the gallery images with descriptions
//             // For example, you can store them in sessionStorage or another state if needed
//             sessionStorage.setItem("org_gallery", JSON.stringify(G1));
//           } else {
//             console.error("Unexpected response status:", response.status);
//           }
//         })
//         .catch((error) => {
//           if (error.response && error.response.status === 404) {
//             console.error("No organization found:", error);
//           } else {
//             console.error("Error fetching organization images:", error);
//           }
//         });
//     } catch (error) {
//       console.error("Unexpected error occurred:", error);
//     }
//   }
//   // fetchMembers(verifiedOrgId);
// }, [navigate, sessionStorage.getItem("login_token")]);


useEffect(() => {
  const getSubdomain = () => {
    const hostname = window.location.hostname; // Example: "localorg.nellinfotech.com"
    const parts = hostname.split("."); 
    if (parts.length > 2) {
      return parts[0]; // Extract subdomain (e.g., "localorg")
    }
    return null;
  };

  let token;
  if (sessionStorage.getItem("login_token")) {
    token = sessionStorage.getItem("login_token");
  } else {
    token = getSubdomain(); // Get subdomain instead of query param
  }

  if (!token) {
    navigate("/auth/sign-in");
    return;
  }

  if (token) {
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/org/verify-orgName/", { token })
        .then((response) => {
          if (response.data.result.length > 0) {
            let org_info = response.data.result[0];
            sessionStorage.setItem("org_name", org_info.organization_name);
            sessionStorage.setItem("login_token", token);
            setOrg(org_info);

            // Set verifiedOrgId from API response (not from token)
            setOrgId(org_info.org_id);

            // Fetch organization images using the verified org_id
            axios
              .get(`${process.env.REACT_APP_API_URL}/org/getOrgImages/${org_info.org_id}`)
              .then((imgResponse) => {
                if (imgResponse.status === 200) {
                  const { awsLogoUrls, galleryUrlsWithDesc } = imgResponse.data;
                  const L1 = awsLogoUrls.length > 0 ? awsLogoUrls[0] : null;
                  const G1 = galleryUrlsWithDesc;

                  setOrgLogo(L1);
                  setOrgGallery(G1);
                  sessionStorage.setItem("org_logo", L1);
                  sessionStorage.setItem("org_gallery", JSON.stringify(G1));
                } else {
                  console.error("Unexpected response status:", imgResponse.status);
                }
              })
              .catch((error) => {
                console.error("Error fetching organization images:", error);
              });
          } else {
            console.error("No organization found for token:", token);
            navigate("/auth/sign-in");
          }
        })
        .catch((error) => {
          console.error("Error fetching org:", error);
          navigate("/auth/sign-in");
        });
    } catch (error) {
      console.error("Unexpected error occurred:", error);
      navigate("/auth/sign-in");
    }
  }
}, [navigate, sessionStorage.getItem("login_token")]);


const sendPasswordResetLink = async () => {

  setLoadingSubmit(true); // Start loading state

  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port;
  const url = `${protocol}//${hostname}:${port}`;

 
  try {
    // Sending password reset email
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/forget-password`,
      { email_id, url }
    );
    handleSuccess('Please check your email and click on the provided link to reset your password !!!');
    console.log('Email sent successfully', response);

    

  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.error("No organization found:", error);
    } else {
      console.error("Error occurred:", error);
      setError('Error sending email. Please try again later.'); // Call an error handler to show an error message
    }
  } finally {
    setLoadingSubmit(false); // Stop loading state
  }
};


  return (
    <>
      <section className="sign-in-page">
        <div id="container-inside">
          <div id="circle-small"></div>
          <div id="circle-medium"></div>
          <div id="circle-large"></div>
          <div id="circle-xlarge"></div>
          <div id="circle-xxlarge"></div>
        </div>
        <Container className="p-0">
          <Row className="no-gutters">
            <Col md="6" className="text-center pt-5">
              <div className="sign-in-detail text-white">
              <div
                  className="sign-in-logo mb-5 justify-content-center"
                  to="#"
                  style={{ display: "flex", alignItems: "center" }}
                >
                 <div class="d-flex align-items-center">
                      {!orgLogo ?

                        <svg
                          width="50"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.67733 9.50001L7.88976 20.2602C9.81426 23.5936 14.6255 23.5936 16.55 20.2602L22.7624 9.5C24.6869 6.16666 22.2813 2 18.4323 2H6.00746C2.15845 2 -0.247164 6.16668 1.67733 9.50001ZM14.818 19.2602C13.6633 21.2602 10.7765 21.2602 9.62181 19.2602L9.46165 18.9828L9.46597 18.7275C9.48329 17.7026 9.76288 16.6993 10.2781 15.8131L12.0767 12.7195L14.1092 16.2155C14.4957 16.8803 14.7508 17.6132 14.8607 18.3743L14.9544 19.0239L14.818 19.2602ZM16.4299 16.4683L19.3673 11.3806C18.7773 11.5172 18.172 11.5868 17.5629 11.5868H13.7316L15.8382 15.2102C16.0721 15.6125 16.2699 16.0335 16.4299 16.4683ZM20.9542 8.63193L21.0304 8.5C22.1851 6.5 20.7417 4 18.4323 4H17.8353L17.1846 4.56727C16.6902 4.99824 16.2698 5.50736 15.9402 6.07437L13.8981 9.58676H17.5629C18.4271 9.58676 19.281 9.40011 20.0663 9.03957L20.9542 8.63193ZM14.9554 4C14.6791 4.33499 14.4301 4.69248 14.2111 5.06912L12.0767 8.74038L10.0324 5.22419C9.77912 4.78855 9.48582 4.37881 9.15689 4H14.9554ZM6.15405 4H6.00746C3.69806 4 2.25468 6.50001 3.40938 8.50001L3.4915 8.64223L4.37838 9.04644C5.15962 9.40251 6.00817 9.58676 6.86672 9.58676H10.2553L8.30338 6.22943C7.9234 5.57587 7.42333 5.00001 6.8295 4.53215L6.15405 4ZM5.07407 11.3833L7.88909 16.2591C8.05955 15.7565 8.28025 15.2702 8.54905 14.8079L10.4218 11.5868H6.86672C6.26169 11.5868 5.66037 11.5181 5.07407 11.3833Z"
                            fill="white"
                          />
                        </svg>

                        :
                        <img
                          src={orgLogo}
                          className="img-fluid ml -3"
                          alt="logo"
                          style={{ marginRight: "5px" }}
                        />



                      }
                      {!org.organization_name ? <h1 className="text-white ml-3">
                        &nbsp;<b>CB</b>
                      </h1> : <h1 className="text-white ml-3">
                        <b>{org.organization_name}</b>
                      </h1>
                      }
                    </div>
                </div>
                <div className="sign-slider overflow-hidden">
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    className="list-inline m-0 p-0"
                  >
                    {orgGallery.length > 0 ? (
                      orgGallery.map((image, index) => (
                        <SwiperSlide key={index}>
                          <Image
                            style={{ paddingLeft: "30px" }}
                            src={image.url}
                            className="img-fluid mb-4"
                            alt={`Slide ${index + 1}`}
                          />
                          {/* Optionally, display description if available */}
                          {image.desc && (
                            <div className="image-description">
                              <p>{image.desc}</p>
                            </div>
                          )}
                        </SwiperSlide>
                      ))
                    ) : (
                      <>
                        <Swiper
                          spaceBetween={30}
                          centeredSlides={true}
                          autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                          }}
                          className="list-inline m-0 p-0 "
                        >
                          <SwiperSlide>
                            <Image
                              src={login1}
                              className="img-fluid mb-4"
                              alt="logo"
                            />
                            <h4 className="mb-1 text-white">
                              Find new friends
                            </h4>
                            <p>
                              It is a long established fact that a reader will
                              be distracted by the readable content.
                            </p>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Image
                              src={login2}
                              className="img-fluid mb-4"
                              alt="logo"
                            />
                            <h4 className="mb-1 text-white">
                              Connect with the world
                            </h4>
                            <p>
                              It is a long established fact that a reader will
                              be distracted by the readable content.
                            </p>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Image
                              src={login3}
                              className="img-fluid mb-4"
                              alt="logo"
                            />
                            <h4 className="mb-1 text-white">
                              Create new events
                            </h4>
                            <p>
                              It is a long established fact that a reader will
                              be distracted by the readable content.
                            </p>
                          </SwiperSlide>
                        </Swiper>
                      </>
                    )}
                  </Swiper>
                </div>
              </div>
            </Col>
            <Col md="6"  style={{marginTop:'25vh', marginBottom: '35vh'}}>
              <Card className="sign-in-from" style={{maxHeight:'40vh'}}>
                <Card.Header style={{maxHeight:'10vh'}}>
                  <h3 className='text-center'>Reset Password</h3>
                </Card.Header>
                <Card.Body style={{maxHeight:'20vh'}}>
                  <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
                  <Form className="mt-0">
                    <Form.Group>
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        className="mb-0"
                        id="exampleInputEmail1"
                        placeholder="Enter email"
                        value={email_id}
                        onChange={(e)=>setEmail_id(e.target.value)} // Handle email input change
                      />
                    </Form.Group>
                  </Form>
                </Card.Body>
                <Card.Footer className="d-inline-block w-100" style={{maxHeight:'10vh', marginTop:'1vh'}}>
                  <Button
                    variant="primary"
                    type="button"
                    className="float-end"
                    onClick={sendPasswordResetLink} // Call the sendPasswordResetLink function
                    disabled={loadingSubmit}
                  >
                  {loadingSubmit ? 'sumitting...' : 'submit'}

                  </Button>
                </Card.Footer>    
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

        {/* Error Alert */}
        {error && (
          <Alert
          variant="danger"
          onClose={() => setError(null)}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={() => setError(null)}
            />
          </div>
            <Alert.Heading>Error!</Alert.Heading>
            <p>{error}</p>
          </Alert>
        )}
      <Alert
            show={successMessages.length > 0}
            variant="success"
            onClose={() => setSuccessMessages([])}
            dismissible
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              border: "1px solid green",
              color: "black",
              width: "300px",
              zIndex: 1000,
            }}
         >
            <Alert.Heading>Success!</Alert.Heading>
            {successMessages.map((message, index) => (
               <p key={index}>{message}</p>
            ))}
            <div style={{ textAlign: "right" }}>
          <button
            className="btn btn-primary"
            onClick={() => navigateToSignInPage()}
          >
            OK
          </button>
        </div>
         </Alert>
    </>
  )
}

export default Recoverpw


