import React, { useState, useEffect, useCallback } from "react";
import FriendProfile from "./user-profile-edit";
import axios from "axios";
import "./customcss/admintabstyle.css";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  Form,
  Button,
  Nav,
  Modal,
  Alert,
  Table,
  Dropdown,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import user5 from "../../../assets/images/user/1.png";
import { FaTrash, FaEdit, FaEye, FaEllipsisV, FaTimes } from "react-icons/fa";
import { BsPersonSlash } from "react-icons/bs";
import useOrgNaming from "../../../hooks/getNamingHook";
const RenuewMemberList = () => {
  const [members, setMembers] = useState([]);
  const navigate = useNavigate();
  const org_id = sessionStorage.getItem("org_id");
  const token = sessionStorage.getItem("token");
  const chapter_id = sessionStorage.getItem("chapter_id");
  const userRole = sessionStorage.getItem("role");
  const role = sessionStorage.getItem("role");
  const [eyeClickedRow, setEyeClickedRow] = useState(null);
  const { getOrgNames, loading } = useOrgNaming(org_id, token);
  const handleDocumentClick = (event) => {
    // Check if the click occurred outside the expanded rows
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    // Check if the click occurred inside any expanded row
    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    // Close expanded rows if the click occurred outside
    if (!clickedInsideExpandedRow) {
      setEyeClickedRow(null);
      // setCommentRow(null);
      // setEditRow(null);
      // setDeleteRow(null);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [eyeClickedRow]);

  const toggleEyeDetails = (index) => {
    setEyeClickedRow((prev) => (prev === index ? null : index));
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  //get the member list as per the admin role
  const fetchMembers = async () => {
    try {
      const token = sessionStorage.getItem("token");
      let endpoint = `${process.env.REACT_APP_API_URL}/members/members/all/${org_id}`;
      if (userRole === "SUPER_ADMIN") {
        endpoint = `${process.env.REACT_APP_API_URL}/members/members/getAll/list`;
      }
      const response = await axios.get(
        endpoint,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        let allMembers = response.data.imageUrls || [];
        let currentDate, ninetyDaysFromNow, filteredMembers, membershipEndDate
        if (userRole === "SUPER_ADMIN") {
          // Filter plans where org_id is null
          allMembers = allMembers.filter((plan) => plan.org_id === null);
          currentDate = new Date();
          ninetyDaysFromNow = new Date();
         ninetyDaysFromNow.setDate(currentDate.getDate() + 90); // Set the date 90 days from now
 
          filteredMembers = allMembers.filter((member) => {
            membershipEndDate = new Date(member.membership_end_date);
           return (
             membershipEndDate >= currentDate &&
             membershipEndDate <= ninetyDaysFromNow
           );
         });
 
         setMembers(filteredMembers);
        } 
         currentDate = new Date();
         ninetyDaysFromNow = new Date();
        ninetyDaysFromNow.setDate(currentDate.getDate() + 90); // Set the date 90 days from now

         filteredMembers = allMembers.filter((member) => {
           membershipEndDate = new Date(member.membership_end_date);
          return (
            membershipEndDate >= currentDate &&
            membershipEndDate <= ninetyDaysFromNow
          );
        });

        setMembers(filteredMembers);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("No Members found:", error);
        // handleSuccess("No Members found");
      } else {
        console.error("Error fetching members:", error);
        // setError("Error fetching members");
      }
    }
  };

  // for view profile
  const openviewProfileForm = (memberidd, userid) => {
    navigate("/dashboard/app/fprofile", {
      state: { state: memberidd, userid: userid },
    });
    //}
  };

  return (
    <Container>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <h4>
                <b>Renew Membership List</b>
              </h4>
            </Card.Header>
            <Card.Body
              className="p-0"
              style={{ overflowY: "auto", height: "80vh" }}
            >
              <Table className="rounded">
                <thead className="bg-primary text-white">
                  <tr>
                    <th>
                      
                    </th>
                    <th>
                      <b>Name</b>
                    </th>
                    <th>
                      {" "}
                      <b> {getOrgNames?.alt_chapter || "Chapter"}</b>{" "}
                    </th>
                    <th>
                      <b>Membership End Date</b>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {members.map((member, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td className="col-lg-2">
                          {member.imageUrl ? (
                            <img
                              src={member.imageUrl}
                              alt="story-img"
                              className="rounded-circle avatar-40"
                            />
                          ) : (
                            <img
                              src={user5}
                              alt="story-img"
                              className="rounded-circle avatar-40"
                            />
                          )}
                        </td>
                        <td className="col-lg-3">
                          <b>
                            {member.first_name} {member.last_name}
                          </b>
                        </td>
                        <td className="col-lg-2">
                          <b>{member.chapter_name}</b>
                        </td>
                        <td className="col-lg-2">
                          {/* <b>{member.membership_end_date}</b> */}
                          <b>
                            {new Date(member.membership_end_date)
                              .getDate()
                              .toString()
                              .padStart(2, "0")}
                            /
                            {(
                              new Date(member.membership_end_date).getMonth() +
                              1
                            )
                              .toString()
                              .padStart(2, "0")}
                            /
                            {new Date(member.membership_end_date).getFullYear()}
                          </b>
                        </td>
                        <td className="col-lg-2">
                          <FaEye
                            className="viewicon"
                            size={15}
                            color="#007BFF"
                            onClick={() =>
                              openviewProfileForm(
                                member.member_id,
                                member.user_id
                              )
                            }
                          />
                          &nbsp;&nbsp;
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RenuewMemberList;
