import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const EventSearch = () => {
  const [filters, setFilters] = useState({
    search_text: "",
  });
  const navigate = useNavigate();
  const org_id = sessionStorage.getItem("org_id");
  const tokenn = sessionStorage.getItem("token");
  const chapterId = sessionStorage.getItem("chapter_id");
  const roleId = sessionStorage.getItem("role_id");
  const [filteredResults, setFilteredResults] = useState([]);
  const [events, setEvents] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  useEffect(() => {
    // Apply filters when the filters state changes
    applyFilters();
  }, [filters]);

  const fetchDataFromApi = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/event/events",
        {
          headers: {
            Authorization: `Bearer ${tokenn}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        // Use roleId to determine which events to set
        if (roleId == 2) {
          const orgEvents = data.processedEvents.filter(
            (event) =>
              event.org_id == org_id &&
              event.chapter_id != null &&
              event.chapter_id == chapterId
          );
          const sortedEvents = orgEvents.sort(
            (a, b) =>
              new Date(a.event_start_date) - new Date(b.event_start_date)
          );

          setEvents(sortedEvents);
        } else if (roleId == 4) {
          const orgEvents = data.processedEvents.filter(
            (event) => event.org_id == org_id
          );
          const sortedEvents = orgEvents.sort(
            (a, b) =>
              new Date(a.event_start_date) - new Date(b.event_start_date)
          );

          setEvents(sortedEvents);
        } else if (roleId == 1) {
          const orgEvents = data.processedEvents.filter(
            (event) => event.org_id === null
          );
          const sortedEvents = orgEvents.sort(
            (a, b) =>
              new Date(a.event_start_date) - new Date(b.event_start_date)
          );

          setEvents(sortedEvents);
        }
      } else {
        // Handle errors from the backend
        const errorData = await response.json();
        // setError(`Error fetching events: ${JSON.stringify(errorData)}`);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error(
        "Error fetching events. Please try again later.",
        error.message
      );
      // setError("Error fetching events. Please try again later.");
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const handleSearchError = (errorMessage) => {
    console.error(errorMessage);
    // Set the alert message for search error
    navigate("dashboard/app/organizations", {
      state: { alertMessage: errorMessage },
    });
  };

  const applyFilters = () => {
    const { search_text } = filters;
     

    const updatedResults = events.filter((result) => {
      const isSearchMatch =
        search_text == "" ||
        (result.event_topic &&
          result.event_topic
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.event_mode &&
          result.event_mode
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.event_organizer_name &&
          result.event_organizer_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.event_details &&
          result.event_details
            .toLowerCase()
            .includes(search_text.toLowerCase()));

      return isSearchMatch;
    });

    setFilteredResults(() => updatedResults); // Use callback function here
  };

  const handleSearch = () => {
    applyFilters();

    if (filteredResults.length == 0) {
      // No matching results, set the alert message
      navigate("/dashboard/createEvent", {
        state: { alertMessage: "No matching results found!" },
      });
    } else {
      // Results found, navigate to the MembersList page with the filtered results
      navigate("/dashboard/createEvent", {
        state: { searchResults: filteredResults },
      });
    }
  };

  const handleReset = () => {
    setFilters({
      search_text: "",
    });
    navigate("/dashboard/createEvent", { state: { searchResults: null } });
  };

  return (
    <div>
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          name="search_text"
          value={filters.search_text}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-12 text-center pt-2">
        <button className="btn btn-primary" onClick={handleSearch}>
          <b>Search</b>
        </button>
      </div>
      <div className="col-12 text-center pt-2">
        <button className="btn btn-danger" onClick={handleReset}>
          <b>Reset</b>
        </button>
      </div>
    </div>
  );
};

export default EventSearch;
