import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ThankYouNoteReceivedSearch = () => {
  const token = sessionStorage.getItem("token");

  const [filters, setFilters] = useState({
    search_text: "",
  });
  const navigate = useNavigate();
  const org_id = sessionStorage.getItem("org_id");
  const userRole = sessionStorage.getItem("role");
  const chapterId = sessionStorage.getItem("chapter_id");
  const [filteredResults, setFilteredResults] = useState([]);
  const [thankYouNotes, setThankYouNotes] = useState([]);
  const member_id = sessionStorage.getItem("member_id");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  useEffect(() => {
    // Apply filters when the filters state changes
    applyFilters();
  }, [filters]);

  //To fetch the thank you note data
  const fetchThankYouNotes = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/referrals/data/get-ThankyouNote`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // including the token in the headers
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch Thank You Note data");
      }

      const data = await response.json();

      // Filter the thank you notes based on user role
      const filteredThankYouNotes = data.results.filter((note) => {
        if (userRole === "ORG_ADMIN") {
          // For ORG_ADMIN: Check if the org_id matches
          return org_id == note.referred_by_org_id;
        } else if (userRole === "CHAPTER_ADMIN") {
          // For CHAPTER_ADMIN: Check if the chapterId matches
          return chapterId == note.referred_by_memberChapterId;
        } else if (userRole === "MEMBER") {
          // For MEMBER: Check if the member_id matches
          return member_id == note.referred_by;
        } else if (userRole === "SUPER_ADMIN") {
          // For SUPER_ADMIN: Include only notes where org_id is null
          return note.referred_by_org_id === null;
        }
      });
      setThankYouNotes(filteredThankYouNotes);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    // Call the function to fetch the Thank You Note data
    fetchThankYouNotes();
  }, []);

  const handleSearchError = (errorMessage) => {
    console.error(errorMessage);
    // Set the alert message for search error
    navigate("dashboard/app/organizations", {
      state: { alertMessage: errorMessage },
    });
  };

  const applyFilters = () => {
    const { search_text } = filters;
     

    const updatedResults = thankYouNotes.filter((result) => {
      const isSearchMatch =
        search_text == "" ||
        (result.referred_to_first_name &&
          result.referred_to_first_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.referred_by_first_name &&
          result.referred_by_first_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.business_done_amount &&
          result.business_done_amount
            .toLowerCase()
            .includes(search_text.toLowerCase()));

      return isSearchMatch;
    });

    setFilteredResults(() => updatedResults); // Use callback function here
  };

  const handleSearch = () => {
    applyFilters();

    if (filteredResults.length == 0) {
      // No matching results, set the alert message
      navigate("/org-thank-you-notes-recieved", {
        state: { alertMessage: "No matching results found!" },
      });
    } else {
      // Results found, navigate to the MembersList page with the filtered results
      navigate("/org-thank-you-notes-recieved", {
        state: { searchResults: filteredResults },
      });
    }
  };

  const handleReset = () => {
    setFilters({
      search_text: "",
    });
    navigate("/org-thank-you-notes-recieved", {
      state: { searchResults: null },
    });
  };

  return (
    <div>
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          name="search_text"
          value={filters.search_text}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-12 text-center pt-2">
        <button className="btn btn-primary" onClick={handleSearch}>
          <b>Search</b>
        </button>
      </div>
      <div className="col-12 text-center pt-2">
        <button className="btn btn-danger" onClick={handleReset}>
          <b>Reset</b>
        </button>
      </div>
    </div>
  );
};

export default ThankYouNoteReceivedSearch;
