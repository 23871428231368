import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaTrash, FaEye, FaEllipsisV, FaTimes } from "react-icons/fa";
import userPlaceholder from "../../../assets/images/user/1.png";
import { Alert } from "react-bootstrap";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Modal,
  Dropdown,
} from "react-bootstrap";
import axios from "axios";

const CreateOrgAdmin = () => {
  const navigate = useNavigate();
  const org_id = sessionStorage.getItem("org_id");
  const [admins, setAdmins] = useState([]);
  const [members, setMembers] = useState([]);
  const token = sessionStorage.getItem("token");
  const chapterId = sessionStorage.getItem("chapter_id");
  const userRole = sessionStorage.getItem("role");
  const [memberData1, setMemberData1] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const [addNewAdmin, setAddNewAdmin] = useState(false); // Toggle for "Add New Admin"
  const [newAdmin, setNewAdmin] = useState({ name: "", mobile: "", email: "" });
  const [adminToDelete, setAdminToDelete] = useState(null);
  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const org_name = sessionStorage.getItem("org_name");
  const memberId = sessionStorage.getItem("member_id");
  const [organizationData, setOrganizationData] = useState({
    org_name: org_name,
    owner_name: "",
    contact: "",
    email: "",
    created_by: memberId,
    url: "",
    orgId: org_id
  });
  // Fetch members and admins from API
  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/members/members/all/${org_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        const data = await response.json();

        console.log("dtrdty", data);


        let membershipReqCount;
        let filteredData;

        if (userRole === "ORG_ADMIN") {
          membershipReqCount = data.imageUrls.length;
          // setMemberDataCal(data.imageUrls);
        } else {
          filteredData = data.imageUrls.filter(
            (request) => request.chapter_id == chapterId
          );
          membershipReqCount = filteredData.length;
          // setMemberDataCal(filteredData);
        }
        setMemberData1(data.imageUrls);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchMemberData();

  }, []);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = (adminId) => {
    setAdminToDelete(adminId);
    setShowDelete(true);
  };

  const handleCreateAdmin = async (e) => {
    e.preventDefault();

    // if (!isFormValid()) {
    //   setValidated(true); // Trigger Bootstrap validation styles
    //   return;
    // }

    try {
      // setLoadingSubmit(true); // Start loading state
      // console.log("==organizationData org==>",organizationData);

      const protocol = window.location.protocol;

      const hostname = window.location.hostname;

      const port = window.location.port;

      const url = protocol + "//" + hostname + ":" + port;

      organizationData.url = url;
      const combinedData = {
        org_name: organizationData.org_name || "superOrgAdmin",                          // Set org_name to null
        owner_name: newAdmin.name || '',          // Set owner_name from newAdmin.name or an empty string
        contact: newAdmin.mobile || '',          // Set contact from newAdmin.mobile or an empty string
        email: newAdmin.email || '',             // Set email from newAdmin.email or an empty string
        created_by: organizationData.created_by,  // Use newAdmin.created_by or default to '1'
        url: url,                                 // Set url to 'http://localhost:443'
        orgId: organizationData.orgId || null,         // Use newAdmin.orgId or default to 'null'
      };

      combinedData.url = url;
      setOrganizationData(combinedData);
      console.log(organizationData);

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/org/createOrgUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(combinedData),
        }
      );

      if (response.ok) {
        // console.log("Organization created successfully");
        // showSuccess();
      } else {
        const errorData = await response.json();
        console.error("Error creating Organization:", errorData);
        setError(
          errorData.error ||
          "Error creating Organization admin. Please try again later."
        );
      }
    } catch (error) {
      console.error("Unexpected error:", error.message);
      setError("Error creating Organization. Please try again later.");
    } finally {
      // setLoadingSubmit(false); // Stop loading state
    }
  };

  const handleCreateAdmin1 = async (selectedMember) => {
    try {
      if (!selectedMember) {
        console.error("No member selected.");
        setError();
        return;
      }

      // Prepare the URL dynamically
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port = window.location.port;
      const url = `${protocol}//${hostname}:${port}`;

      // Combine the organization data and selected member data
      const combinedData = {
        org_name: organizationData.org_name || "superOrgAdmin", // Default to 'superOrgAdmin'
        owner_name: selectedMember.first_name || "",           // Use the selected member's name
        contact: selectedMember.contact_no || "",                       // Use newAdmin.mobile or default to an empty string
        email: selectedMember.email_id || "",                          // Use newAdmin.email or default to an empty string
        created_by: organizationData.created_by,                      // Use the selected member's ID
        url,                                                  // Set the constructed URL
        orgId: organizationData.orgId || null,                // Use organizationData.orgId or default to null
      };

      console.log("Combined Data:", combinedData);

      // Make API call to submit the data
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/org/createOrgUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(combinedData),
        }
      );

      // Handle the API response
      if (response.ok) {
        console.log("Admin created successfully");
        setSuccessMessages("Admin created successfully!");
      } else {
        const errorData = await response.json();
        console.error("Error creating admin:", errorData);
        setError(
          errorData.message || "Error creating admin. Please try again later."
        );
      }
    } catch (error) {
      console.error("Unexpected error:", error.message);
      setError("An unexpected error occurred. Please try again later.");
    }
  };

  const handleDeleteAdmin = async () => {
    try {
      await axios.delete(`/api/deleteAdmin/${adminToDelete}`);
      setAdmins((prev) => prev.filter((admin) => admin.id !== adminToDelete));
      setShowDelete(false);
      setSuccessMessages(["Organization Admin successfully deleted!"]);
    } catch (err) {
      console.error("Error deleting admin:", err);
      setError("Failed to delete admin.");
    }
  };

  const handleNewAdminChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin((prev) => ({ ...prev, [name]: value }));
  };

  const clearError = () => setError(null);

  const handleClose = () => navigate("/userposts");

  return (
    <div id="content-page" className="content-page">
      <Container>
        <Row className="justify-content-center">
          <Col lg={12}>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <h4 className="card-title">
                  <b>Create Organization Admin</b>
                </h4>
              </Card.Header>
              <Card.Body>
                {error && <p className="text-danger">{error}</p>}
                {successMessages.map((msg, idx) => (
                  <p key={idx} className="text-success">
                    {msg}
                  </p>
                ))}
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Check
                          type="checkbox"
                          label="Add New Admin"
                          checked={addNewAdmin}
                          onChange={() => setAddNewAdmin((prev) => !prev)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {!addNewAdmin ? (
                    <>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="admin">Members :</Form.Label>
                            <Form.Control
                              as="select"
                              id="admin"
                              className="form-select"
                              onChange={(e) => setSelectedAdmin(e.target.value)} // Update selectedAdmin with the selected ID
                              value={selectedAdmin}
                            >
                              <option value="" disabled>
                                Select From Members
                              </option>
                              {memberData1.map((member) => (
                                <option key={member.id} value={member.id}>
                                  {member.first_name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="d-flex justify-content-end p-3">
                            <Button
                              type="button"
                              className="btn btn-primary me-2"
                              onClick={() => {
                                console.log("----------->", selectedAdmin);

                                const selectedMemberObject = memberData1.find(
                                  (m) => m.first_name === selectedAdmin
                                );
                                console.log("Selected Member Object:", selectedMemberObject);
                                handleCreateAdmin1(selectedMemberObject);
                              }}                            >
                              Submit
                            </Button>
                            <Button
                              type="reset"
                              className="btn bg-danger"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={newAdmin.name}
                              onChange={handleNewAdminChange}
                              placeholder="Enter admin name"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control
                              type="text"
                              name="mobile"
                              value={newAdmin.mobile}
                              onChange={handleNewAdminChange}
                              placeholder="Enter mobile number"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              value={newAdmin.email}
                              onChange={handleNewAdminChange}
                              placeholder="Enter email address"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="d-flex justify-content-end p-3">
                            <Button
                              type="button"
                              className="btn btn-primary me-2"
                              onClick={handleCreateAdmin}
                            >
                              Submit
                            </Button>
                            <Button
                              type="reset"
                              className="btn bg-danger"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}


                </Form>
              </Card.Body>
            </Card>
          </Col>

          {/* Organization Admin List */}
          <Col lg={12}>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <h4 className="card-title">Organization Admin List</h4>
              </Card.Header>
              <Card.Body
                className="p-0"
                style={{ overflowY: "auto", height: "80vh" }}
              >
                <Table className="rounded" style={{ width: "100%" }}>
                  <thead className="bg-primary text-white">
                    <tr>
                      <th> </th>
                      <th>Name</th>
                      <th>Email</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {admins.map((admin, index) => (
                      <tr key={index}>
                        <td>
                          <img
                            src={admin.profileImage || userPlaceholder}
                            alt="Admin Avatar"
                            className="rounded-circle avatar-40"
                          />
                        </td>
                        <td>{admin.name}</td>
                        <td>{admin.email}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle variant="link">
                              <FaEllipsisV size={20} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <FaEye size={15} style={{ color: "#007BFF" }} />
                                &nbsp; View
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleShowDelete(admin.id)}
                              >
                                <FaTrash className="deleteicon" size={15} />
                                &nbsp; Remove
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          {/* Success Alert */}
          <Alert
            show={successMessages.length > 0}
            variant="success"
            onClose={() => setSuccessMessages([])}
            dismissible
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              border: "1px solid green",
              color: "black",
              width: "300px",
              zIndex: 1000,
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <FaTimes
                style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
                onClick={() => setSuccessMessages([])}
              />
            </div>
            <Alert.Heading>Success!</Alert.Heading>
            {successMessages.map((message, index) => (
              <p key={index}>{message}</p>
            ))}
          </Alert>
          {/* Error Alert */}
          {error && (
            <Alert
              variant="danger"
              onClose={clearError}
              dismissible
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                border: "1px solid red",
                color: "black",
                width: "300px",
                zIndex: 1000,
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <FaTimes
                  style={{
                    cursor: "pointer",
                    color: "black",
                    fontSize: "15px",
                  }}
                  onClick={clearError}
                />
              </div>
              <Alert.Heading>Error!</Alert.Heading>
              <p>{error}</p>
            </Alert>
          )}
        </Row>

      </Container>
    </div>
  );
};

export default CreateOrgAdmin;
