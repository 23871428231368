import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MembershipRequestSearch = () => {
  const token = sessionStorage.getItem("token");

  const [filters, setFilters] = useState({
    search_text: "",
  });
  const navigate = useNavigate();
  const org_id = sessionStorage.getItem("org_id");
  const userRole = sessionStorage.getItem("role");
  const [membershipRequests, setMembershipRequests] = useState([]);
  const [deactivatedMembers, setDeactivatedMembers] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  useEffect(() => {
    // Apply filters when the filters state changes
    applyFilters();
  }, [filters]);

  //fectch the all membership request on page
  const fetchMembershipRequests = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      // const response = await axios.get(
      //   `${process.env.REACT_APP_API_URL}/membershipRequest/membership-requests/org/${org_id}`,
      //   config
      // );

      let endpoint = `${process.env.REACT_APP_API_URL}/membershipRequest/membership-requests/org/${org_id}`;
      if (userRole === "SUPER_ADMIN") {
        endpoint = `${process.env.REACT_APP_API_URL}/membershipRequest/membership-requests/list/all`;
      }
      const response = await axios.get(endpoint, config);

      // const allRequests = response.data.requests;
      // if (userRole === "SUPER_ADMIN") {
      //   const allRequests = allRequests.filter(
      //     (request) => request.org_id == "null"
      //   );
      // }

      let allRequests = response.data.requests;

      if (userRole === "SUPER_ADMIN") {
        allRequests = allRequests.filter((request) => request.org_id === null);
      } else {
        // Code to handle `allRequests` for other roles
        console.log(allRequests); // Example of handling; replace with your logic
      }

      let pendingAndAcceptedRequests = [];
      let deactivatedMembersList = [];

      pendingAndAcceptedRequests = allRequests.filter(
        (request) => request.membership_status !== "Rejected"
      );

      // Filter out the rejected requests
      deactivatedMembersList = allRequests.filter(
        (request) => request.membership_status === "Rejected"
      );

      // Update the states accordingly
      setMembershipRequests(pendingAndAcceptedRequests);
      setDeactivatedMembers(deactivatedMembersList);
    } catch (error) {
      console.error("Error fetching membership requests: ", error);
    }
  };

  useEffect(() => {
    fetchMembershipRequests();
  }, []);

  const handleSearchError = (errorMessage) => {
    console.error(errorMessage);
    // Set the alert message for search error
    navigate("dashboard/app/organizations", {
      state: { alertMessage: errorMessage },
    });
  };

  const applyFilters = () => {
    const { search_text } = filters;
     

    const updatedResults = membershipRequests.filter((result) => {
      const isSearchMatch =
        search_text == "" ||
        (result.first_name &&
          result.first_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.last_name &&
          result.last_name.toLowerCase().includes(search_text.toLowerCase())) ||
        (result.email_id &&
          result.email_id.toLowerCase().includes(search_text.toLowerCase())) ||
        (result.membership_status &&
          result.membership_status
            .toLowerCase()
            .includes(search_text.toLowerCase()));

      return isSearchMatch;
    });

    setFilteredResults(() => updatedResults); // Use callback function here
  };

  const handleSearch = () => {
    applyFilters();

    if (filteredResults.length == 0) {
      // No matching results, set the alert message
      navigate("/dashboards/app/renew-mship", {
        state: { alertMessage: "No matching results found!" },
      });
    } else {
      // Results found, navigate to the MembersList page with the filtered results
      navigate("/dashboards/app/renew-mship", {
        state: { searchResults: filteredResults },
      });
    }
  };

  const handleReset = () => {
    setFilters({
      search_text: "",
    });
    navigate("/dashboards/app/renew-mship", { state: { searchResults: null } });
  };

  return (
    <div>
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          name="search_text"
          value={filters.search_text}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-12 text-center pt-2">
        <button className="btn btn-primary" onClick={handleSearch}>
          <b>Search</b>
        </button>
      </div>
      <div className="col-12 text-center pt-2">
        <button className="btn btn-danger" onClick={handleReset}>
          <b>Reset</b>
        </button>
      </div>
    </div>
  );
};

export default MembershipRequestSearch;
