import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Carousel,
  Alert,
} from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import "font-awesome/css/font-awesome.min.css";
import { FaTimes } from "react-icons/fa";



const timeOptions = [];
for (let h = 0; h < 24; h++) {
  for (let m = 0; m < 60; m += 15) {
    const hour12 = h % 12 || 12; // Convert 24h to 12h format
    const minute = m.toString().padStart(2, "0");
    const period = h < 12 ? "AM" : "PM";

    timeOptions.push({
      label: `${hour12}:${minute} ${period}`, // What user sees
      value: `${h.toString().padStart(2, "0")}:${minute}`, // What is stored
    });
  }
}


const CreateEventForm = ({ onCancel }) => {
  const memberId = sessionStorage.getItem("member_id");
  const chapterId = sessionStorage.getItem("chapter_id");
  const token = sessionStorage.getItem("token");

  const [formData, setFormData] = useState({
    event_topic: "",
    event_organizer_name: "",
    event_details: "",
    event_start_date: "",
    event_end_date: "",
    event_cutoff_reg_date: "",
    event_time_in: "",
    event_time_out: "",
    event_mode: "",
    event_link: "",
    event_place: "",
    event_charges: "",
    payment_link: "",
    // event_img_path: [], // New field for storing selected images
    // file_type: [],
    chapter_id: chapterId,
    created_by: memberId,
  });

  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [error, setError] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const org_id = sessionStorage.getItem("org_id");
  const userRole = sessionStorage.getItem("role");
  // const fetchMembers = async () => {
  //   setLoading(true);
  //   try {
  //     const token = sessionStorage.getItem("token");
  //     let endpoint = `${process.env.REACT_APP_API_URL}/members/members/all/${org_id}`;
  //     if (userRole === "SUPER_ADMIN") {
  //       endpoint = `${process.env.REACT_APP_API_URL}/members/members/getAll/list`;
  //     }
  //     const response = await axios.get(
  //       endpoint,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (userRole === "SUPER_ADMIN") {
  //       // Filter plans where org_id is null
  //       const filteredPlans = response.data.imageUrls.filter((plan) => plan.org_id === null);
  //       setMembers(filteredPlans);
  //     }
  //     setMembers(response.data.imageUrls);
  //   } catch (error) {
  //     // setError(error.message);
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    // fetchMembers();
    setSelectedImages([]);
  }, []);

  const updateFormData = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (
      name == "event_start_date" ||
      name == "event_end_date" ||
      name == "event_cutoff_reg_date"
    ) {
      setValidated(false);
    }
  };

  const isDateValid = (date) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    return new Date(date) >= currentDate;
  };

  // const isCutoffRegDateValid = (cutoffRegDate, eventStartDate) => {
  //   const startDate = new Date(eventStartDate);
  //   const cutoffDate = new Date(cutoffRegDate);
  //   const currentDate = new Date();

  //   // Ensure cutoff date is today or in the future and before the event start date
  //   return cutoffDate >= currentDate && cutoffDate < startDate;
  // };

  const isCutoffRegDateValid = (cutoffRegDate, eventStartDate) => {
    const startDate = new Date(eventStartDate);
    const cutoffDate = new Date(cutoffRegDate);
    const currentDate = new Date();

    // Zero out the time part for date comparison
    startDate.setHours(0, 0, 0, 0);
    cutoffDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    // Ensure cutoff date is today or in the future and before the event start date
    return cutoffDate >= currentDate && cutoffDate < startDate;
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const newImages = Array.from(files).map((file, index) => ({
        id: index, // Unique identifier
        file: file,
      }));
      setSelectedImages((prevImages) => [...prevImages, ...newImages]);
    }
  };

  const handleRemoveImage = (id) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((image) => image.id != id)
    );
  };

  const isFormValid = () => {
    const currentDate = new Date();

    // Check your form validation conditions here
    // Return true if the form is valid, false otherwise
    return (
      formData.event_topic !== "" &&
      formData.event_organizer_name !== "" &&
      formData.event_start_date !== "" &&
      isDateValid(formData.event_start_date) &&
      // (formData.event_end_date === "" ||
      //   isEndDateValid(formData.event_end_date, formData.event_start_date)) &&
      (formData.event_cutoff_reg_date === "" ||
        isCutoffRegDateValid(
          formData.event_cutoff_reg_date,
          formData.event_start_date
        )) &&
      formData.event_charges !== "" &&
      formData.event_cutoff_reg_date !== "" &&
      formData.event_time_in !== "" &&
      formData.event_time_out !== "" &&
      formData.event_mode !== "" &&
      // formData.payment_link !== "" &&
      (formData.event_mode === "Virtual"
        ? formData.event_link !== ""
        : formData.event_place !== "") &&
      formData.event_details !== "" &&
      selectedImages.length > 0
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!isFormValid()) {
      setValidated(true); // Trigger Bootstrap validation styles
      return;
    }

    try {
      setLoadingSubmit(true); // Start loading state

      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      const selectedImagesArray = Array.from(selectedImages);
      selectedImagesArray.forEach((file, index) => {
        formDataToSend.append(`event_img_path`, file.file);
      });

      // Get FCM token from local storage
      const fcmToken = localStorage.getItem("fcmToken");

      // Include FCM token in the request payload
      formDataToSend.append("fcmToken", fcmToken);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/event/create",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formDataToSend,
        }
      );

      if (response.ok) {
        console.log("Event created successfully");
        showSuccess();
      } else {
        const errorData = await response.json();
        console.error("Error creating event:", errorData);
        setError("Error creating event. Please try again later.");
      }
    } catch (error) {
      console.error("Unexpected error:", error.message);
      setError("Error creating event. Please try again later.");
    } finally {
      setLoadingSubmit(false); // Stop loading state
    }
  };

  const resetForm = () => {
    setFormData({
      event_topic: "",
      event_organizer_name: "",
      event_details: "",
      event_start_date: "",
      event_end_date: "",
      event_cutoff_reg_date: "",
      event_time_in: "",
      event_time_out: "",
      event_mode: "",
      event_link: "",
      event_place: "",
      event_charges: "",
      payment_link: "",
      chapter_id: chapterId,
      created_by: memberId,
    });

    setSelectedImages([]);
    setValidated(false);

    // Clear the file input value
    const fileInput = document.getElementById("event_img_path");
    if (fileInput) {
      fileInput.value = ""; // Reset the file input value
    }
  };

  const showSuccess = () => {
    setShowSuccessAlert(true);
    resetForm();
  };

  const closeSuccess = () => {
    setShowSuccessAlert(false);
    onCancel();
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg="12">
              <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <h3>Create an event</h3>
                  <AiOutlineClose
                    onClick={onCancel}
                    style={{ cursor: "pointer", fontSize: "20px" }}
                  />
                </Card.Header>
                <Card.Body>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col lg="6">
                        <Form.Group controlId="event_topic">
                          <Form.Label>
                            Event Topic <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter event topic"
                            name="event_topic"
                            value={formData.event_topic}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter the event topic.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg="6">
                        <Form.Group controlId="event_organizer_name">
                          <Form.Label>
                            Event Organizer Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter event organizer name"
                            name="event_organizer_name"
                            value={formData.event_organizer_name}
                            onChange={handleChange}
                            required
                          >
                            {/* <option value="">Select Organizer</option>
                            {members.map((member) => (
                              <option key={member.member_id} value={member.member_id}>
                                {`${member.first_name} ${member.last_name}`}
                              </option>
                            ))} */}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please select the event organizer.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <Form.Group controlId="event_start_date">
                          <Form.Label>
                            Event Start Date{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="event_start_date"
                            placeholder="Select event starting date"
                            value={formData.event_start_date}
                            onChange={handleChange}
                            min={
                              new Date(new Date().getTime() + 86400000)
                                .toISOString()
                                .split("T")[0]
                            } // Tomorrow's date
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please select the event start date.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg="6">
                        <Form.Group controlId="event_end_date">
                          <Form.Label>Event End Date</Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Select event end date"
                            name="event_end_date"
                            value={formData.event_end_date}
                            onChange={handleChange}
                            min={formData.event_start_date}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please select the event end date.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <Form.Group controlId="event_charges">
                          <Form.Label>
                            Event Charges{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                style={{
                                  background: "#0d6efd",
                                  color: "#fff",
                                  fontSize: "12px",
                                }}
                              >
                                ₹
                              </span>
                            </div>
                            <Form.Control
                              type="number"
                              placeholder="Enter event charges"
                              name="event_charges"
                              value={formData.event_charges}
                              onChange={handleChange}
                              required
                              className="form-control"
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Please enter a valid numeric amount for event
                            charges.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg="6">
                        <Form.Group controlId="event_cutoff_reg_date">
                          <Form.Label>
                            Event Cutoff Registration Date{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="event_cutoff_reg_date"
                            placeholder="Select cutoff date for reg..."
                            value={formData.event_cutoff_reg_date}
                            onChange={handleChange}
                            min={new Date().toISOString().split("T")[0]}
                            max={
                              formData.event_start_date
                                ? new Date(
                                  new Date(
                                    formData.event_start_date
                                  ).getTime() - 86400000
                                )
                                  .toISOString()
                                  .split("T")[0]
                                : ""
                            }
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please select the event Cutoff Registration Date.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <Form.Group controlId="event_charges">
                          <Form.Label>
                            Payment link
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </Form.Label>
                          <div className="input-group">
                            <Form.Control
                              type="text"
                              placeholder="Enter Payment link"
                              name="payment_link"
                              value={formData.payment_link}
                              onChange={handleChange}
                              // required
                              className="form-control"
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Please enter a valid payment link
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>

                      <Col lg="6">
                        <Form.Group controlId="event_time_in">
                          <Form.Label>
                            Event Time In <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <div className="custom-dropdown">
                            <Form.Select
                              name="event_time_in"
                              value={formData.event_time_in}
                              onChange={handleChange}
                              required
                            >
                              <option value="">Select Time</option>
                              {timeOptions.map((time) => (
                                <option key={time.value} value={time.value}>
                                  {time.label}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Please select the event time in.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg="6">
                        <Form.Group controlId="event_time_out">
                          <Form.Label>
                            Event Time Out <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <div className="custom-dropdown">
                            <Form.Select
                              name="event_time_out"
                              value={formData.event_time_out}
                              onChange={handleChange}
                            >
                              <option value="">Select Time</option>
                              {timeOptions.map((time) => (
                                <option key={time.value} value={time.value}>
                                  {time.label}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Please select the event time out.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>


                    <Row>
                      <Col lg="6">
                        <Form.Group controlId="event_mode">
                          <Form.Label>
                            Event Mode <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="event_mode"
                            value={formData.event_mode}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Event Mode</option>
                            <option value="In-Person">In-Person</option>
                            <option value="Virtual">Virtual</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please select the event mode.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      {formData.event_mode === "Virtual" && (
                        <Col lg="6">
                          <Form.Group controlId="event_link">
                            <Form.Label>
                              Event Link <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter event link"
                              name="event_link"
                              value={formData.event_link}
                              onChange={handleChange}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter the event link.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )}
                      {formData.event_mode === "In-Person" && (
                        <Col lg="6">
                          <Form.Group controlId="event_place">
                            <Form.Label>
                              Event Place{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter event place"
                              name="event_place"
                              value={formData.event_place}
                              onChange={handleChange}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter the event place.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )}
                    </Row>

                    <Row>
                      <Col lg="6">
                        <Form.Group controlId="event_details">
                          <Form.Label>
                            Event Details{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Enter event details"
                            name="event_details"
                            value={formData.event_details}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please select the event details.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg="6">
                        <Form.Group controlId="event_img_path">
                          <Form.Label>
                            Event Images <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <div className="custom-file-upload">
                            <Form.Control
                              type="file"
                              accept="image/*, video/*, application/pdf, .ppt, .pptx, .doc, .docx"
                              name="event_img_path"
                              onChange={handleImageChange}
                              required
                              multiple
                              isInvalid={
                                validated && selectedImages.length === 0
                              }
                              className={
                                validated && selectedImages.length === 0
                                  ? "is-invalid"
                                  : ""
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {validated &&
                                selectedImages.length === 0 &&
                                "Please, select a file."}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginTop: "10px",
                            maxWidth: "100vw",
                          }}
                        >
                          {selectedImages.map((image) => (
                            <div
                              key={image.id}
                              style={{
                                maxWidth: "100%",
                                alignItems: "center",
                                backgroundColor: "#f0f0f0",
                                padding: "5px 10px",
                                marginBottom: "10px",
                                borderRadius: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {image.file.name}
                              <span
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "15px",
                                  color: "#666",
                                  fontSize: "20px",
                                }}
                                onClick={() => handleRemoveImage(image.id)}
                              >
                                &times;
                              </span>
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-end">
                  <Button className="mx-2" variant="danger" onClick={resetForm}>
                    <b>Reset</b>
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loadingSubmit} // Disable the button while loading
                  >
                    <b>{loadingSubmit ? "Creating..." : "Create"}</b>
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Success Alert */}
        <Alert
          show={showSuccessAlert}
          variant="success"
          onClose={closeSuccess}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid green",
            color: "green",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "green", fontSize: "15px" }}
              onClick={closeSuccess}
            />
          </div>
          <Alert.Heading>Success!</Alert.Heading>
          <p>Event created successfully.</p>
        </Alert>

        {/* Error Alert */}
        {error && (
          <Alert
            variant="danger"
            onClose={clearError}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              border: "1px solid red",
              color: "red",
              width: "300px",
              zIndex: 1000, // Set zIndex to a higher value
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <FaTimes
                style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
                onClick={clearError}
              />
            </div>
            <Alert.Heading>Error!</Alert.Heading>
            <p>{error}</p>
          </Alert>
        )}
      </div>
    </>
  );
};

export default CreateEventForm;
