import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

const SentDataTable = ({ thankYouNotes, alertMessage }) => {
  return (
    <>
      <div>
        {alertMessage ? (
          <div>
            {" "}
            <p>{alertMessage}</p>
          </div>
        ) : (
          <Table className="rounded">
            <thead className="bg-primary text-white">
              <tr>
                <th>
                  <b>Sender</b>
                </th>
                <th>
                  <b> Receiver</b>
                </th>
                <th>
                  <b>Business Done Amount</b>
                </th>
                <th>
                  <b></b>
                </th>
              </tr>
            </thead>
            {thankYouNotes.length > 0 ? (
              <tbody>
                {thankYouNotes.map((thankyou, index) => (
                  <React.Fragment key={thankyou.thank_you_note_id}>
                    <tr>
                      <td>
                        <b>{thankyou.referred_to_first_name}</b>
                      </td>
                      <td>
                        <b>{thankyou.referred_by_first_name}</b>
                      </td>
                      <td>
                        <b> ₹{thankyou.business_done_amount}</b>
                      </td>
                      <td></td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            ) : (
              <p>No data available.</p>
            )}
          </Table>
        )}
      </div>
    </>
  );
};

export default SentDataTable;
