import React, { useState, useEffect } from "react";
// fullcalender
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import axios from "axios"; // Import axios
import Swal from "sweetalert2"; // Import Swal
import { useNavigate } from "react-router-dom";
// react-boostrap
import {
  Container,
  Col,
  Row,
  Dropdown,
  ProgressBar,
  Card,
} from "react-bootstrap";

// components
// import Card from '../../../components/Card'
import CustomToggle from "../../../components/dropdowns";

// Datepicker
import Datepicker from "../../../components/datepicker";

// apex-chart
import Chart from "react-apexcharts";
import useOrgNaming from "../../../hooks/getNamingHook";
const UserDashboard = () => {
  const navigate = useNavigate();
  const [memberData, setMemberData] = useState([]);
  const [totalMeetings, setTotalMeetings] = useState(0);
  const [totalChapterMeetings, setTotalChapterMeetings] = useState(0);
  const [chapterData, setChapterData] = useState([]);
  const [totalMyReferrals, setTotalMyReferrals] = useState(0);
  const [totalSentReferrals, setTotalSentReferrals] = useState(0);
  const token = sessionStorage.getItem("token");
  const roleId = sessionStorage.getItem("role_id");
  const [events, setEvents] = useState([]);
  const [tranning, settranning] = useState([]);
  const [metting, setmetting] = useState([]);
  const memberId = sessionStorage.getItem("member_id");
  const chapterId = sessionStorage.getItem("chapter_id");
  const org_id = sessionStorage.getItem("org_id");
  const { getOrgNames, loading } = useOrgNaming(org_id, token);
  const [memberCategories, setMemberCategories] = useState([]);

  //Navigate to related page
  const sendReferalsFor = () => {
    //   const dataToSend = 'YourDataHere';
    navigate("/dashboards/app/for-me");
  };
  const sendMeeting = () => {
    //   const dataToSend = 'YourDataHere';
    navigate("/dashboards/app/meeting");
  };

  const sendChapterMeeting = () => {
    //   const dataToSend = 'YourDataHere';
    navigate("/dashboards/app/chapter-meeting");
  };

  const sendReferalsBy = () => {
    //   const dataToSend = 'YourDataHere';
    navigate("/dashboards/app/by-me");
  };

  //get the data for the calander display
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/event/even/member/${memberId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const eventData = eventResponse.data.result;
        // console.log("eventData", eventResponse.data.result);
        const formattedEvents = eventData.map((event, index) => {
          // console.log("meeting_date", event.event_start_date);
          const meetingDateParts = event.event_start_date
            .split("T")[0]
            .split("-"); // Extracts only the date part and splits it into parts [YYYY, MM, DD]
          const meetingYear = parseInt(meetingDateParts[0], 10);
          const meetingMonth = parseInt(meetingDateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date objects
          const meetingDay = parseInt(meetingDateParts[2], 10);
          // Extracting hours, minutes, and seconds from meeting_start_time
          const [hours, minutes, seconds] = event.event_time_in
            .split(":")
            .map(Number);
          // console.log(" meeting.meeting_start_time", event.event_time_in);
          // console.log("hours, minutes, seconds", hours, minutes, seconds);
          const eventTimeIn = new Date(
            meetingYear,
            meetingMonth,
            meetingDay,
            hours,
            minutes,
            seconds
          );
          // console.log("meetingStartTime", eventTimeIn);
          // Formatting the time in UTC
          const formattedStartTime = eventTimeIn.toISOString();
          // console.log("formattedStartTime", formattedStartTime);
          // Extracting hours, minutes, and seconds from meeting_start_time
          const [hours1, minutes1, seconds1] = event.event_time_out
            .split(":")
            .map(Number);
          // console.log(" meeting.meeting_start_time", event.event_time_out);
          // console.log("hours, minutes, seconds", hours1, minutes1, seconds1);
          const eventTimeOut = new Date(
            meetingYear,
            meetingMonth,
            meetingDay,
            hours1,
            minutes1,
            seconds1
          );
          // console.log("meetingStartTime", eventTimeOut);
          // Formatting the time in UTC
          const formattedEndTime = eventTimeOut.toISOString();
          // console.log("formattedStartTime", formattedEndTime);
          const eventId = `event_${index}`; // Create a unique ID for each event
          // Process event data
          const title = `Event: ${event.event_topic}`;
          return {
            id: eventId,
            title: title,
            start: formattedStartTime, // Concatenate date and start time
            end: formattedEndTime, // Use the same formatted datetime for both start and end
            description: event.event_details,
            type: "Event", // Set the type property for meetings
          };
        });
        // console.log("formattedEvents", formattedEvents);

        // Append unique events to events state and update eventIds state
        setEvents(formattedEvents);
        // setTotalDataa(formattedEvents)
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    const fetchTrainingData = async () => {
      try {
        const trainingResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/training/registered/member/${memberId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const trainingData = trainingResponse.data.results;
        
        const formattedTrainings = trainingData.map((training, index) => {
          // Keep the original start date
          const startDate = training.train_start_date.split("T")[0]; // Extracts only the date part
          const endDate = training.train_end_date ? training.train_end_date.split("T")[0] : startDate;
    
          // Combine date and time directly from the database for start time
          const formattedStartTime = `${startDate}T${training.train_start_time}`;
    
          // Extract hours and minutes for the end time
          const [endHours] = training.train_end_time.split(":").map(Number);
    
          // If end time is after midnight (00:00 or beyond) but on the next day
          let adjustedEndDate = endDate;
          if (endHours === 0) {
            const tempDate = new Date(startDate);
            tempDate.setDate(tempDate.getDate() + 1); // Move to the next day
            adjustedEndDate = tempDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
          }
    
          // Combine the adjusted end date and time
          const formattedEndTime = `${adjustedEndDate}T${training.train_end_time}`;
    
          const trainingId = `training_${index}`; // Create a unique ID for each training
    
          // Process training data
          const title = `Training: ${training.training_topic}`;
          
          return {
            id: trainingId,
            title: title,
            start: formattedStartTime, // Directly use the date and start time from the database
            end: formattedEndTime,     // Use the adjusted end time
            description: training.training_details,
            type: "Training", // Set the type property for training
          };
        });
    
        // Append unique trainings to events state and update eventIds state
        settranning(formattedTrainings);
      } catch (error) {
        console.error("Error fetching training data:", error);
      }
    }; 
    
    //fetch the data of  meeting for the  member dashboard calaneder
    const fetchChapterMeetingData = async () => {
      try {
        // Fetch meetings by member_id
        const userMeetingResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/meetings/chaptermeeting/chapter/all/${chapterId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const userMeetingData = userMeetingResponse.data?.results?.resultTwo;
        /// Convert the Map values to an array
        const uniqueMeetings = Array.from(userMeetingData.values());

        // Set the total number of unique meetings
        setTotalChapterMeetings(uniqueMeetings.length);
      } catch (error) {
        console.error("Error fetching meeting data:", error);
      }
    };

    const fetchMeetingData = async () => {
      try {
        // Fetch meetings by memberId
        const userMeetingResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/meetings/oneonone-meeting/user/${memberId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const userMeetingData = userMeetingResponse.data.results;
    
        // Fetch meetings by memberId
        const memberMeetingResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/meetings/one-one-one/member/${memberId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const memberMeetingData = memberMeetingResponse.data.results;
    
        // Merge the results, filtering out duplicates
        const meetingMap = new Map();
        [...userMeetingData, ...memberMeetingData].forEach((meeting) => {
          meetingMap.set(meeting.meeting_id, meeting);
        });
    
        const allMeetingData = Array.from(meetingMap.values());
        const formattedMeetings = allMeetingData.map((meeting, index) => {
          // Extracting date from meeting_date
          const meetingDateParts = meeting.meeting_date.split("T")[0].split("-");
          const meetingYear = parseInt(meetingDateParts[0], 10);
          const meetingMonth = parseInt(meetingDateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date objects
          const meetingDay = parseInt(meetingDateParts[2], 10);
    
          // Extracting hours, minutes, and seconds from meeting_start_time
          const [startHours, startMinutes, startSeconds] = meeting.meeting_start_time.split(":").map(Number);
          const meetingStartTime = new Date(meetingYear, meetingMonth, meetingDay, startHours, startMinutes, startSeconds);
          const formattedStartTime = meetingStartTime.toISOString();
    
          // Extracting hours, minutes, and seconds from meeting_end_time
          const [endHours, endMinutes, endSeconds] = meeting.meeting_end_time.split(":").map(Number);
          
          // If the meeting end time is earlier than the start time, it means the meeting ends on the next day
          let meetingEndTime = new Date(meetingYear, meetingMonth, meetingDay, endHours, endMinutes, endSeconds);
          if (endHours < startHours || (endHours === 0 && endMinutes < startMinutes)) {
            meetingEndTime.setDate(meetingEndTime.getDate() + 1); // Move to the next day
          }
          const formattedEndTime = meetingEndTime.toISOString();
    
          // Create a unique ID for each meeting
          const meetingId = `meeting_${index}`;
          
          // Process meeting data
          const title = `Meeting: ${meeting.meeting_subject}`;
          
          return {
            id: meetingId,
            title: title,
            start: formattedStartTime,
            end: formattedEndTime,
            description: meeting.meeting_desc,
            type: "Meeting",
            first_name: meeting.attendee_first_name,
            last_name: meeting.attendee_last_name,
            organized_by: meeting.organized_by,
            creator_user_name: meeting.creator_first_name,
            creator_last_name: meeting.creator_last_name,
          };
        });
    
        // Append unique meetings to events state and update eventIds state
        setmetting(formattedMeetings);
      } catch (error) {
        console.error("Error fetching meeting data:", error);
      }
    };
    

    fetchChapterMeetingData();
    fetchEventData();
    fetchTrainingData();
    fetchMeetingData();
  }, [memberId, token]);

  const renderEventContent = (eventInfo) => {
    let symbol = "";
    let textColor = "";
    let bgColor = "";

    // Determine the symbol, text color, and background color based on the event type
    if (eventInfo.event.extendedProps.type === "Event") {
      symbol = "E"; // Symbol for meeting
      textColor = "white"; // Text color for meetings
      bgColor = "blue"; // Background color for meetings
    } else if (eventInfo.event.extendedProps.type === "Training") {
      symbol = "T"; // Symbol for training
      textColor = "white"; // Text color for trainings
      bgColor = "green"; // Background color for trainings
    } else {
      symbol = "M"; // Symbol for event
      textColor = "white"; // Text color for events
      bgColor = "gray"; // Background color for events
    }

    // Return a div with the appropriate symbol, text color, and background color
    return (
      <div
        className="event-symbol"
        style={{
          color: textColor,
          backgroundColor: bgColor,
          fontWeight: "bold",
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {symbol}
      </div>
    );
  };

  // Function to convert time from 24-hour to 12-hour format
const formatTime = (date) => {
  const timeString = date.toTimeString().split(" ")[0]; // Extract "HH:MM:SS" part
  const [hour, minute] = timeString.split(":");
  let period = "AM";
  let formattedHour = parseInt(hour, 10);

  if (formattedHour === 0) {
    formattedHour = 12;  // Midnight case (00:00 to 12:00 AM)
  } else if (formattedHour >= 12) {
    period = "PM";
    if (formattedHour > 12) {
      formattedHour -= 12;
    }
  }

  return `${formattedHour}:${minute} ${period}`;
};
  // handel the cander click
  const handleEventClick = (arg) => {
     // Handle event click
  const eventStart = new Date(arg.event.start);
  const eventEnd =  new Date(arg.event.end) ;
  console.log("event.end",arg.event.end);
  // Format start and end dates with custom time formatting
  const startDate = `${eventStart.toLocaleDateString()} ${formatTime(eventStart)}`;
  const endDate = eventEnd
    ? `${eventEnd.toLocaleDateString()} ${formatTime(eventEnd)}`
    : "";
    
console.log("endDate",endDate);
    let meetingWith = ""; // Initialize meetingWith string
    // Check if the event type is a meeting
    if (arg.event.extendedProps.type === "Meeting") {
      if (memberId == arg.event.extendedProps.organized_by) {
        const firstName = arg.event.extendedProps.first_name
          ? arg.event.extendedProps.first_name
          : "N/A";
        const lastName = arg.event.extendedProps.last_name
          ? arg.event.extendedProps.last_name
          : "N/A";
        meetingWith = `Meeting with: ${firstName} ${lastName}`;
      } else {
        const createrfirstName = arg.event.extendedProps.creator_user_name
          ? arg.event.extendedProps.creator_user_name
          : "N/A";
        const createrlastName = arg.event.extendedProps.creator_last_name
          ? arg.event.extendedProps.creator_last_name
          : "N/A";
        meetingWith = `Meeting with: ${createrfirstName} ${createrlastName}`;
      }
    }
    Swal.fire({
      title: arg.event.title,
      html: `
    <p>Start Date: ${startDate}</p>
    <p>End Date: ${endDate}</p>
    ${meetingWith} <!-- Render meetingWith only for meetings -->
   `,
    });
  };

  const fetchUserMeetings = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/meetings/oneonone/chapter/all/${org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();

      if (data && data.imageUrls) {
        // Include meetings where meeting_type is not "Chapter" or null
        // Assuming `userRole` contains the role of the current user (ORG_ADMIN or CHAPTER_ADMIN)
        const filteredMeetings = data.imageUrls.filter((meeting) => {
          if (roleId == 2 || roleId == 3) {
            // Apply the chapterId condition for CHAPTER_ADMIN role
            return (
              meeting.meeting_type === "Chapter" &&
              meeting.chapter_id == chapterId
            );
          }
          // Show all chapter meetings for ORG_ADMIN role
          return meeting.meeting_type === "Chapter";
        });
        // const filteredMeetings = data.results.filter(
        //   (meeting) =>
        //     meeting.meeting_type === "Chapter" &&
        //     meeting.chapter_id == chapterId
        // );

        // setUserMeetings(data.results);
        // setUserMeetings(filteredMeetings);
        const uniqueMeetings = Array.from(filteredMeetings.values());

        // Set the total number of unique meetings
        setTotalChapterMeetings(uniqueMeetings.length);
      }
    } catch (err) {
      console.error("Error fetching user meetings:", err);
      // setError(
      //   "An error occurred while fetching user meetings. Please try again later."
      // );
    }
  };

  useEffect(() => {
    fetchUserMeetings();

    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchUserMeetings();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchMeetingData = async (apiUrl) => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
        const data = await response.json();
        return data.results ? data.results.length : 0;
      } catch (error) {
        console.error("Error fetching meeting data:", error);
        return 0;
      }
    };

    const fetchDataFromBothAPIs = async () => {
      try {
        // Fetch meetings by memberId
        const userMeetingResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/meetings/oneonone-meeting/user/${memberId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const userMeetingData = userMeetingResponse.data.results;

        // Fetch meetings by memberId
        const memberMeetingResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/meetings/one-one-one/member/${memberId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const memberMeetingData = memberMeetingResponse.data.results;

        // Merge the results
        // Merge the results, filtering out duplicates
        const meetingMap = new Map();
        [...userMeetingData, ...memberMeetingData].forEach((meeting) => {
          meetingMap.set(meeting.meeting_id, meeting);
        });

        /// Convert the Map values to an array
        const uniqueMeetings = Array.from(meetingMap.values());

        // Set the total number of unique meetings
        setTotalMeetings(uniqueMeetings.length);
      } catch (error) {
        console.error("Error fetching data from both APIs:", error);
        // Handle error
      }
    };

    const fetchTotalSentReferrals = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            `/referrals/get-referralByMe/${memberId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
        const data = await response.json();

        console.log("Referral data:", data);

        if (data && data.results) {
          const referralCount = data.results.length;
          console.log("Sent Referral count:", referralCount);
          setTotalSentReferrals(referralCount);
        } else {
          console.error(
            "Invalid or missing referral results in the API response."
          );
        }
      } catch (err) {
        console.error("Error fetching total referrals:", err);
        // Handle error
      }
    };

    const fetchTotalMyReferrals = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL +
            `/referrals/get-referralForMe/${memberId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
        const data = await response.json();

        console.log("Referral data:", data);

        if (data && data.results) {
          const referralCount = data.results.length;
          console.log("My Referral count:", referralCount);
          setTotalMyReferrals(referralCount);
        } else {
          console.error(
            "Invalid or missing referral results in the API response."
          );
        }
      } catch (err) {
        console.error("Error fetching total referrals:", err);
        // Handle error
      }
    };

    const fetchMemberData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/members/members/all/${org_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }
        const data = await response.json();
        setMemberData(data.imageUrls);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataFromBothAPIs();
    fetchTotalSentReferrals();
    fetchTotalMyReferrals();
    fetchMemberData();
  }, [memberId]);

  useEffect(() => {
    const fetchChapterData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/chapters/chapters",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add your authorization header here
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        const data = await response.json(); // Use json() to extract data from the response
        console.log("chapter data:", data);
        setChapterData(data);
      } catch (error) {
        console.error("Error fetching chapter data:", error);
      }
    };

    fetchChapterData();
    fetchMemberCategories();
  }, []);

  const fetchMemberCategories = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `/memberCategory/get_org_memberCategory/${org_id}`,
        config
      );
  
      const data = response.data; // ✅ Use response.data for Axios
      const categories = data.filter((item) => item.org_id == org_id || item.org_id == null); // ✅ Use .filter() instead of .map()
  
      console.log("cat------", categories);
      setMemberCategories(categories);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  
  const calculatePercentage = (category) => {
    if (!memberData || memberData.length === 0) return 0; // Handle empty or undefined memberData

    const totalMembers = memberData.length; // Get the total number of members
    const categoryMembers = memberData.filter(
      (member) => member.member_category === category // Filter members by the current category
    ).length;

    const percentage = Math.round((categoryMembers / totalMembers) * 100) || 0; // Calculate percentage and avoid division by zero

    return percentage; // Return the calculated percentage
  };

 

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row as="ul" className="list-unstyled mb-0">
            <Col md="6" lg="3" as="li">
              <Card style={{ height: "15vh" }}>
                <Card.Body>
                  <div className="points">
                    <a href="#" onClick={sendMeeting}>
                      {/* <span>Last Month</span> */}
                      <div className="d-flex align-items-center">
                        <h4>
                          {" "}
                          Member {getOrgNames?.alt_meeting || "Meetings"}:{" "}
                          {totalMeetings}
                        </h4>
                        {/* <small className="text-danger ms-3">- 12</small> */}
                      </div>
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" lg="3" as="li">
              <Card style={{ height: "15vh" }}>
                <Card.Body>
                  <div className="points">
                    <a href="#" onClick={sendChapterMeeting}>
                      {/* <span>Last Month</span> */}
                      <div className="d-flex align-items-center">
                        <h4>
                          {" "}
                          {getOrgNames?.alt_chapter || "Chapter"}{" "} {getOrgNames?.alt_meeting || "Meetings"}:{" "}
                          {totalChapterMeetings}
                        </h4>
                        {/* <small className="text-danger ms-3">- 12</small> */}
                      </div>
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" lg="3" as="li">
              <Card style={{ height: "15vh" }}>
                <Card.Body>
                  <div className="points">
                    <a href="#" onClick={sendReferalsBy}>
                      {/* <span>Last Months</span> */}
                      <div className="d-flex align-items-center">
                        <h4>Referrals Sent: {totalSentReferrals}</h4>
                        {/* <small className="text-success ms-3">+ 10</small> */}
                      </div>
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" lg="3" as="li">
              <Card style={{ height: "15vh" }}>
                <Card.Body>
                  <div className="points">
                    <a href="#" onClick={sendReferalsFor}>
                      {/* <span>Last Month</span> */}
                      <div className="d-flex align-items-center">
                        <h4>Referrals Received: {totalMyReferrals}</h4>
                        {/* <small className="text-success ms-3">+ 8</small> */}
                      </div>
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md="12" lg="12">
              <Card>
                <Card.Body>
                  <FullCalendar
                    plugins={[dayGridPlugin, listPlugin]}
                    events={[...events, ...tranning, ...metting]}
                    eventClick={handleEventClick}
                    eventContent={renderEventContent} // Use the renderEventContent callback
                    // eventRender={handleEventRender} // Use the eventRender callback
                  />
                </Card.Body>
              </Card>
            </Col>
            {/* <Col lg="4" md="6">
              <Card>
                <Card.Header>
                  <div className="header-title">
                    <h4 className="card-title"> {getOrgNames?.alt_membership_category || "Member Categories"}</h4>
                  </div>
                </Card.Header>
                <Card.Body style={{ overflowX: "scroll", maxHeight: "67vh" }}>
                  {memberCategories.map((category, index) => (
                    <div className="mb-3" key={category.mcategory_id}>
                      <div className="d-flex justify-content-between mt-2 text-dark">
                        <h6>{category.member_category}</h6>
                        <small>{`${calculatePercentage(
                          category.member_category
                        )}%`}</small>
                      </div>
                      <ProgressBar
                        variant={
                          index % 2 === 0
                            ? "danger"
                            : index % 3 === 0
                            ? "primary"
                            : index % 5 === 0
                            ? "success"
                            : "warning"
                        }
                        className="mt-2"
                        now={calculatePercentage(category.member_category)}
                        style={{ height: "6px" }}
                      />
                    </div>
                  ))}
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserDashboard;
