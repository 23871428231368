import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  Table,
  Dropdown,
} from "react-bootstrap";
import {
  FaTrash,
  FaEye,
  FaComment,
  FaEllipsisV,
  FaTimes,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import MyReferralComment from "./myReferralComment";
import DeleteReferral from "./deleteReferral";
import useOrgNaming from "../../../hooks/getNamingHook";

const ChapterReceivedReferrals = () => {
  const token = sessionStorage.getItem("token");
  const chapterId = sessionStorage.getItem("chapter_id");
  const org_id = sessionStorage.getItem("org_id");
  const location = useLocation();
  const navigate = useNavigate();
  const { searchResults, alertMessage } = location.state || {};
  const [referrals, setReferrals] = useState([]);
  const [error, setError] = useState(null);
  const [eyeClickedRow, setEyeClickedRow] = useState(null);
  const [commentRow, setCommentRow] = useState(null);
  const [deleteRow, setDeleteRow] = useState(null);
  const { getOrgNames, getSettingNames } = useOrgNaming(org_id, token);

  const fetchReferrals = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/referrals/chapter/get-referralByMe/${org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // including the token in the headers
          },
        }
      );

      if (response.status === 502) {
        // Don't throw error for 502 status code
        console.error(
          "Bad Gateway error occurred while fetching sent referrals"
        );
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      if (data && data.results) {
        const filteredReferrals = data.results.filter(
          (referral) => referral.referred_to_memberChapterId == chapterId
        );
        setReferrals(filteredReferrals.reverse());
      }
    } catch (err) {
      console.error("Error fetching referrals:", err);
      // setError(
      //   "An error occurred while fetching referrals. Please try again later."
      // );
    }
  };

  useEffect(() => {
    // Fetch initial data
    fetchReferrals();

    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchReferrals();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once

  const toggleEyeDetails = (index) => {
    setEyeClickedRow((prev) => (prev === index ? null : index));
  };

  const toggleCommentSection = (index) => {
    setCommentRow((prev) => (prev === index ? null : index));
  };

  const toggleDeleteSection = (index) => {
    setDeleteRow((prev) => (prev === index ? null : index));
  };

  const handleDocumentClick = (event) => {
    // Check if the click occurred outside the expanded rows
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    // Check if the click occurred inside any expanded row
    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    // Close expanded rows if the click occurred outside
    if (!clickedInsideExpandedRow) {
      setEyeClickedRow(null);
      setCommentRow(null);
      setDeleteRow(null);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [eyeClickedRow, commentRow, deleteRow]);

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg={12}>
              <Card className="mt-3 p-1">
                <Card.Header>
                  <h4>
                    <b>Referrals Received</b>
                  </h4>
                </Card.Header>

                <Col lg={12}>
                  <Card.Body>
                    <Table className="rounded">
                      <thead className="bg-primary text-white">
                        <tr>
                          <th>
                            <b>Referred By</b>
                          </th>
                          <th>
                            <b>Referred To</b>
                          </th>
                          <th>
                            <b>Status</b>
                          </th>
                          {getSettingNames.chapter_referral_thirdparty_data ==
                            0 && (
                              <th>
                                <b></b>
                              </th>
                            )}
                        </tr>
                      </thead>
                      {referrals.length > 0 ? (
                        <tbody>
                          {(searchResults && searchResults.length > 0
                            ? searchResults
                            : referrals
                          ).map((referral, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td>
                                  <b>{referral.referred_by_first_name}</b>
                                </td>
                                <td>
                                  <b>{referral.referred_to_first_name}</b>
                                </td>
                                <td>
                                  <b>{referral.ref_status}</b>
                                </td>
                                {getSettingNames.chapter_referral_thirdparty_data ==
                                  0 && (
                                    <td>
                                      {/* Actions */}

                                      <div className="d-lg-none">
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant="link"
                                            id="dropdown-basic"
                                            style={{
                                              fontSize: "1.5rem",
                                              color: "skyblue",
                                            }}
                                          >
                                            <FaEllipsisV className="ellipsis-icon" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {/* {getSettingNames.chapter_referral_thirdparty_data ==
                                          1 && ( */}
                                            <Dropdown.Item
                                              onClick={() =>
                                                toggleEyeDetails(index)
                                              }
                                            >
                                              <FaEye className="viewicon">
                                                View
                                              </FaEye>
                                            </Dropdown.Item>
                                            {/* )} */}
                                            {/* <Dropdown.Item
                                          onClick={() =>
                                            toggleCommentSection(index)
                                          }
                                        >
                                          <FaComment className="commenticon" />{" "}
                                          Comment
                                        </Dropdown.Item> */}
                                            {/* <Dropdown.Item onClick={() => toggleDeleteSection(index)}>
                                        <FaTrash className="deleteicon" /> Delete
                                      </Dropdown.Item> */}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                      <div className="d-none d-lg-block">
                                        {getSettingNames.chapter_referral_thirdparty_data ==
                                          0 && (
                                            <FaEye
                                              className="viewicon"
                                              size={15}
                                              color="#007BFF"
                                              onClick={() =>
                                                toggleEyeDetails(index)
                                              }
                                            />
                                          )}
                                        &nbsp;&nbsp;
                                        {/* {referral.ref_status !=
                                    "Business Done" && (
                                    <FaComment
                                      className="commenticon"
                                      size={15}
                                      color="#007BFF"
                                      onClick={() =>
                                        toggleCommentSection(index)
                                      }
                                    />
                                    )} */}
                                        &nbsp;&nbsp;
                                        {/* <FaTrash
                                    className="deleteicon"
                                    size={15}
                                    color="red"
                                    onClick={() => toggleDeleteSection(index)}
                                  /> */}
                                      </div>
                                    </td>
                                  )}
                              </tr>
                              {/* Expanded rows for detailed information */}
                              {eyeClickedRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setEyeClickedRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <p>
                                          <b>
                                            Referral Flag: {referral.ref_flag}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            Referral Name:{" "}
                                            {referral.referral_name}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            Company Name:{" "}
                                            {referral.company_name}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            Business Done Amounts: ₹
                                            {referral.business_done_amount}
                                          </b>
                                        </p>
                                      </div>
                                      <div className="col-lg-6">
                                        <p>
                                          <b>Email: {referral.email_id}</b>
                                        </p>
                                        <p>
                                          <b>
                                            Contact No: {referral.contact_no}
                                          </b>
                                        </p>
                                        <p>
                                          <b>
                                            Referral Description:{" "}
                                            {referral.referral_desc}
                                          </b>
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {commentRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setCommentRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <MyReferralComment
                                      referralId={referral.referral_id}
                                    />
                                  </td>
                                </tr>
                              )}
                              {deleteRow === index && (
                                <tr className="expanded-row">
                                  <td
                                    colSpan="4"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      className="close-icon"
                                      onClick={() => setDeleteRow(null)}
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "20px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#10006;
                                    </div>
                                    <DeleteReferral
                                      referralId={referral.referral_id}
                                      onClose={() => setDeleteRow(null)}
                                    />
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      ) : (
                        <p>No referrals available.</p>
                      )}
                    </Table>
                  </Card.Body>
                </Col>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {error && (
        <Alert
          variant="danger"
          onClose={() => setError(null)}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={() => setError(null)}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}

      {alertMessage && (
        <Alert
          variant="danger"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={() =>
                navigate("/dashboards/app/chapter-received-referrals", {
                  state: { alertMessage: null },
                })
              }
            />
          </div>
          <Alert.Heading>Alert!</Alert.Heading>
          <p>{alertMessage}</p>
        </Alert>
      )}
    </>
  );
};

export default ChapterReceivedReferrals;
