import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  FaTrash,
  FaEdit,
  FaEye,
  FaEllipsisV,
  FaPowerOff,
  FaTimes,
} from "react-icons/fa";
import user5 from "../../../assets/images/user/1.png";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
  Table,
  Modal,
  Dropdown,
} from "react-bootstrap";
import useOrgNaming from "../../../hooks/getNamingHook";
const CreateAdmin = () => {
  const navigate = useNavigate();
  const [chapters, setChapters] = useState([]);
  const [members, setMembers] = useState([]);
  const [chapterMembers, setChapterMembers] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState("");
  const [selectedMember, setSelectedMember] = useState("");
  const memberId = sessionStorage.getItem("member_id");
  const org_id = sessionStorage.getItem("org_id");
  const token = sessionStorage.getItem("token"); // Fetch the token from localStorage
  const role = sessionStorage.getItem("role");
  const { getOrgNames, loading } = useOrgNaming(org_id, token);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null); // State to manage errors
  const [showdelete, setShowdelete] = useState(false);
  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = (memberId) => {
    setMemberToDelete(memberId);
    setShowdelete(true);
  };

  useEffect(() => {
    // Fetch chapters on component mount
    fetchChapters();
  }, []);

  const fetchChapters = async () => {
    let endpoint = `${process.env.REACT_APP_API_URL}/chapters/orgChapters/${org_id}`;
    if (role === "SUPER_ADMIN") {
      endpoint = `${process.env.REACT_APP_API_URL}/chapters/chapters`;
    }
    try {
      const response = await axios.get(
        endpoint,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let data = response.data;
      if (role === "SUPER_ADMIN") {
        // Filter plans where org_id is null
         data = data.filter((plan) => plan.org_id === null);
        setChapters(data);
      } else {
        // Directly set the plans for other roles
        setChapters(data);
      }
      // setChapters(response.data);
    } catch (error) {
      console.error("Error fetching chapters:", error);
    }
  };

  const fetchMembers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/members/members/chapter/${selectedChapter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Filter members with role_id equal to 2

      // Set the filtered members in state
      setMembers(response.data.results);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };
  useEffect(() => {
    // Only fetch members if selectedChapter is set
    if (selectedChapter !== "") {
      fetchMembers();
    }
  }, [selectedChapter]); // Add selectedChapter to the dependency array

  const handleChapterChange = (event) => {
    const chapterId = event.target.value;
    setSelectedChapter(chapterId);
    fetchChapterAdminMembers(chapterId);
  };

  const handleMemberChange = (event) => {
    const member_id = event.target.value;
    setSelectedMember(member_id);
  };

  const fetchChapterAdminMembers = async (chapter_id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/chapters/admin/members/${chapter_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("member data---.",response.data.length===0,chapterMembers);
      
      if(response.data.length===0)
        setMembers([]);
      else
         setChapterMembers(response.data);

      
      setSelectedMember(""); // Reset selectedMember to clear the dropdown selection
    } catch (error) {
      setChapterMembers("");
      console.error("Error fetching chapter admin members:", error);
    }
  };

  const openviewProfileForm = (memberidd, userid) => {
    // if (member && member.member_id) {
    navigate("/dashboard/app/fprofile", {
      state: { state: memberidd, userid: userid },
    }); //}
  };

  //for show the message
  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  const clearError = () => {
    setError(null);
  };

  const handleCreateAdmin = async () => {
    try {
      const protocol = window.location.protocol;

      const hostname = window.location.hostname;

      const port = window.location.port;

      const url = protocol + "//" + hostname + ":" + port;
      const org_token=token

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/createadmin/chapters/${selectedMember}`,
        { memberId, url,org_token },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Chapter admin created:", response.data);
      // Optionally, fetch the updated list of members after creating admin
      fetchChapterAdminMembers(selectedChapter);
      fetchMembers();
      setSelectedMember(""); // Reset selectedMember to clear the dropdown selection
      handleSuccess("Chapter admin created");
    } catch (error) {
      setError("Error in Chapter admin creation");

      console.error("Error creating chapter admin:", error);
    }
  };

  const handleDeleteAdmin = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/chapters/${memberToDelete}`,
        { memberId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Optionally, fetch the updated list of members after deleting admin
      setShowdelete(false);
      handleSuccess("Chapter admin deleted");
      fetchMembers();
      setSelectedMember(""); // Reset selectedMember to clear the dropdown selection
      fetchChapterAdminMembers(selectedChapter);
    } catch (error) {
      setError("Error deleting chapter admin");
    }
  };

  const handleClose = () => {
    navigate("/userposts"); // Implement your logic for closing the form here
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">
                      <b>Create Admin</b>
                    </h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="chapter">
                            {" "}
                            {getOrgNames?.alt_chapter || "Chapter"} Name:
                          </Form.Label>
                          <Form.Control
                            as="select"
                            id="chapter"
                            className="form-select"
                            onChange={handleChapterChange}
                            value={selectedChapter}
                          >
                            <option value="" disabled>
                              Select {getOrgNames?.alt_chapter || "Chapter"}
                            </option>
                            {chapters.map((chapter) => (
                              <option
                                key={chapter.id}
                                value={chapter.chapter_id}
                              >
                                {chapter.chapter_name}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="member">Members:</Form.Label>
                          <Form.Control
                            as="select"
                            id="member"
                            className="form-select"
                            onChange={handleMemberChange}
                            value={selectedMember}
                          >
                            <option value="" disabled>
                              Select Member
                            </option>
                            {members.map((member, index) => (member.role_id!==2&&
                              <option key={index} value={member.member_id}>
                                {member.first_name + " " + member.last_name}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="d-flex justify-content-end p-3">
                          <Button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={handleCreateAdmin}
                          >
                            Submit
                          </Button>
                          <Button
                          style={{borderColor : '#ff9b8a'}}
                            type="reset"
                            className="btn bg-danger"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={12}>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="iq-header-title">
                    {chapterMembers.length > 0 && (
                      <h4 className="card-title">
                        {chapterMembers[0].chapter_name} {getOrgNames?.alt_chapter || "Chapter"} Admin List
                      </h4>
                    )}
                  </div>
                </Card.Header>

                <Card.Body
                  className="p-0"
                  style={{ overflowY: "auto", height: "80vh" }}
                >
                  <Table className="rounded" style={{ width: "100%" }}>
                    <thead
                      className="bg-primary text-white"
                      style={{ width: "100%" }}
                    >
                      <tr>
                        <th>
                          <b> </b>
                        </th>
                        <th>
                          <b> Name</b>
                        </th>
                        <th>
                          <b> Email</b>
                        </th>
                        <th>
                          <b> </b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {chapterMembers.map((chaptermember, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td className="col-sm-2">
                              {/* {chaptermember.profileImage ? (
                                <img
                                  src={chaptermember.profileImage}
                                  alt="story-img"
                                  className="rounded-circle avatar-40"
                                />
                              ) : ( */}
                                <img
                                  src={user5}
                                  alt="story-img"
                                  className="rounded-circle avatar-40"
                                />
                              {/* )} */}
                            </td>
                            <td className="col-sm-3">
                              <b>
                                {" "}
                                {chaptermember.first_name}{" "}
                                {chaptermember.last_name}
                              </b>
                            </td>
                            <td className="col-sm-4">
                              <b>{chaptermember.email_id}</b>
                            </td>

                            <td className="col-lg-3">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="link"
                                  id="dropdown-basic"
                                >
                                  {/* Horizontal three-dot icon */}
                                  <FaEllipsisV size={20} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      openviewProfileForm(
                                        chaptermember.member_id,
                                        chaptermember.user_id
                                      )
                                    }
                                  >
                                    {/* Eye icon */}
                                    <FaEye
                                      size={15}
                                      style={{ color: "#007BFF" }}
                                    />
                                    &nbsp; View
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleShowdelete(chaptermember.member_id)
                                    }
                                  >
                                    {/* Trash icon */}
                                    <FaTrash className="deleteicon" size={15} />
                                    &nbsp; Remove
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              {/* /modal for confirm Delete  */}
                              <Modal
                                centered
                                show={showdelete}
                                onHide={handleClosedelete}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Remove Chapter Admin</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Are you sure you want to remove this member from chapter admin role ?
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={handleClosedelete}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="primary"
                                    onClick={handleDeleteAdmin}
                                  >
                                    Remove
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* Success Alert */}
          <Alert
            show={successMessages.length > 0}
            variant="success"
            onClose={() => setSuccessMessages([])}
            dismissible
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              border: "1px solid green",
              color: "black",
              width: "300px",
              zIndex: 1000,
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <FaTimes
                style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
                onClick={() => setSuccessMessages([])}
              />
            </div>
            <Alert.Heading>Success!</Alert.Heading>
            {successMessages.map((message, index) => (
              <p key={index}>{message}</p>
            ))}
          </Alert>

          {/* Error Alert */}
          {error && (
            <Alert
              variant="danger"
              onClose={clearError}
              dismissible
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                border: "1px solid red",
                color: "black",
                width: "300px",
                zIndex: 1000,
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <FaTimes
                  style={{
                    cursor: "pointer",
                    color: "black",
                    fontSize: "15px",
                  }}
                  onClick={clearError}
                />
              </div>
              <Alert.Heading>Error!</Alert.Heading>
              <p>{error}</p>
            </Alert>
          )}
        </Container>
      </div>
    </>
  );
};

export default CreateAdmin;
