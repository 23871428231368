import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";

// Import icons
import icon8 from "../assets/images/icon/08.png"; // Facebook
import icon9 from "../assets/images/icon/09.png"; // Twitter
import icon10 from "../assets/images/icon/10.png"; // Instagram
import icon11 from "../assets/images/icon/11.png"; // Google Plus
import icon12 from "../assets/images/icon/12.png"; // YouTube
import icon13 from "../assets/images/icon/13.png"; // LinkedIn
import icon16 from "../assets/images/icon/16.png"; // Whatsapp






const ShareOffcanvas = ({ imgLink }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const postMessage = "Check out this amazing article!";
    const postURL = imgLink; // Assuming `imgLink` is a string URL passed as a prop.

    // Function to open a new window with a specified URL
    const openShareWindow = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    // Share handlers for different platforms
    const handleFacebookShare = () => {
        const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postURL)}`;
        openShareWindow(facebookShareURL);
    };

    const handleWhatsAppShare = (postURL) => {
        const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(postURL)}`;
        window.open(whatsappURL,);
    };

    const handleInstagramShare = () => {
        const instagramURL = "https://www.instagram.com/";
        window.open(instagramURL, "_blank");
        navigator.clipboard.writeText(postURL);
        // alert("Post link copied! Paste it into your Instagram story or message.");
    };

    const handleTwitterShare = () => {
        const twitterShareURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(postURL)}&text=${encodeURIComponent(postMessage)}`;
        openShareWindow(twitterShareURL);
    };

    const handleLinkedInShare = () => {
        // Remove 'fbclid' if present
        const url = new URL(postURL);
        url.searchParams.delete('fbclid');

        // Encode the URL
        const encodedURL = encodeURIComponent(url.href);

        // Create the LinkedIn share URL
        const linkedInShareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedURL}`;

        // Open in a new window/tab
        window.open(linkedInShareURL, "_blank");
    };

    // Add more platform functions as needed (e.g., Reddit, Pinterest, etc.)

    return (
        <>
            <div className="d-flex align-items-center feather-icon mt-2 mt-md-0">
                <Link to="#" onClick={handleShow} className="d-flex align-items-center">
                    <span className="material-symbols-outlined md-18">share</span>
                    <span className="ms-1"> Share</span>
                </Link>
            </div>
            <Offcanvas show={show} onHide={handleClose} placement="bottom">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Share</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="d-flex flex-wrap align-items-center">
                        <div className="text-center me-3 mb-3 pointer" onClick={handleFacebookShare}>
                            <img src={icon8} className="img-fluid rounded mb-2" alt="Facebook" />
                            <h6>Facebook</h6>
                        </div>
                        <div className="text-center me-3 mb-3 pointer" onClick={handleTwitterShare}>
                            <img src={icon9} className="img-fluid rounded mb-2" alt="Twitter" />
                            <h6>Twitter</h6>
                        </div>
                        {/* <div className="text-center me-3 mb-3 pointer" onClick={handleLinkedInShare}>
                            <img src={icon13} className="img-fluid rounded mb-2" alt="LinkedIn" />
                            <h6>LinkedIn</h6>
                        </div> */}
                        <div className="text-center me-3 mb-3 pointer" onClick={handleInstagramShare}>
                            <img src={icon10} className="img-fluid rounded mb-2" alt="Instagram" />
                            <h6>Instagram</h6>
                        </div>
                        <div className="text-center me-3 mb-3 pointer" onClick={() => handleWhatsAppShare(postURL)}>
                            <img src={icon16} style={{ width: "32px", height: "32px", objectFit: "cover" }} className="rounded mb-2" alt="Whatsapp" />
                            <h6>Whatsapp</h6>
                        </div>
                        {/* Add more platforms here */}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default ShareOffcanvas;
