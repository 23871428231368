import React, { useState, useEffect, useCallback } from "react";
import FriendProfile from "./user-profile-edit";
import axios from "axios";
import "./customcss/admintabstyle.css";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  Form,
  Button,
  Nav,
  Modal,
  Alert,
  Table,
  Dropdown,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import user5 from "../../../assets/images/user/1.png";
import {
  FaTrash,
  FaEdit,
  FaEye,
  FaEllipsisV,
  FaPowerOff,
  FaTimes,
} from "react-icons/fa";
import { BsPersonSlash } from "react-icons/bs";

//image
// import img1 from "../../../assets/images/user/11.png";
import img1 from "../../../assets/images/user/profile-edit.png";
import useOrgNaming from "../../../hooks/getNamingHook";
const Members = () => {
  const navigate = useNavigate();
  // Assuming 'members' is the state for storing the list of members
  const [members, setMembers] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const location = useLocation();
  const org_id = sessionStorage.getItem("org_id");
  const { searchResults, alertMessage } = location.state || [];
  const chapter_id = sessionStorage.getItem("chapter_id");
  const role = sessionStorage.getItem("role");
  const token = sessionStorage.getItem("token");
  const member_id = sessionStorage.getItem("member_id");
  const { getOrgNames, getSettingNames } = useOrgNaming(org_id, token);
  // to show the deactive  Tab
  const [deactivatedMembers, setDeactivatedMembers] = useState([]);
  const [deletedMembers, setDeletedMembers] = useState([]);
  const [pendingMembers, setPendingMembers] = useState([]);
  const [activeTab, setActiveTab] = useState("first");

  // to show the profile
  const [showdeactiveForm, setshowdeactiveForm] = useState(false);
  const [showviewProfileForm, setShowviewProfileForm] = useState(false);
  const [selectedRequest, setselectedRequest] = useState(null);
  const [selectedRequestId, setselectedRequestId] = useState(null);
  // State to manage errors message and success Messages
  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null); // State to manage errors

  // set the images of the profile and logo
  const [databaseImage, setDatabaseImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [memberToDelete, setMemberToDelete] = useState(null);

  // to show the delete model form
  const [showdelete, setShowdelete] = useState(false);
  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = (memberId) => {
    setMemberToDelete(memberId);
    setShowdelete(true);
  };

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      setMembers(searchResults); // Update members with search results
    } else if (!alertMessage) {
      // Fetch the default member list only if alertMessage is not present
      fetchMembers();
    }
  }, [searchResults, alertMessage]);

  //Update the member perofle data
  const updateMemberData = () => {
    if (selectedRequest) {
      const member_id = selectedRequest.member_id;
      // Add the member_id to the selectedRequest object

      selectedRequest.user_id = member_id;
      fetch(process.env.REACT_APP_API_URL + `/members/members/${member_id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedRequest),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          return response.json();
        })
        .then((data) => {
          handleSuccess("Member data updated successfully");
          fetchMembers();
          setShowviewProfileForm(false);
          console.log("Member data updated:", data);
        })
        .catch((error) => {
          setError("There was a problem with the update request");
          fetchMembers();
          setShowviewProfileForm(false);
          console.error("There was a problem with the update request:", error);
        });
    }
  };

  const closeAlert = () => {
    // Clear the alertMessage state to revert to default member list
    navigate("/dashboards/app/members", { state: { alertMessage: null } });
  };

  // //get the member list as per the admin role
  const fetchMembers = async () => {
    try {
      const token = sessionStorage.getItem("token");
      let endpoint ;
      if (role === "SUPER_ADMIN" || role === "CHAPTER_ADMIN") {
        endpoint = `${process.env.REACT_APP_API_URL}/members/members/getAll/list`;
      }else{
        endpoint = `${process.env.REACT_APP_API_URL}/members/members/all/${org_id}`;
      }
      const response = await axios.get(
        endpoint,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        let allMembers = response.data.imageUrls || [];
        let activeMembers, deactivatedMembers, deletedMembers, pendingMembers;

       
        const formatDeactivatedDate = (dateStr) => {
          if (!dateStr) return null;
          const date = new Date(dateStr);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = String(date.getFullYear());
          return `${day}/${month}/${year}`;
        };

        const processMembers = (members) => {
          return members.map((member) => ({
            ...member,
            deactive_date: formatDeactivatedDate(member.deactive_date),
          }));
        };

        if (role === "CHAPTER_ADMIN") {
          const chapterMembers = allMembers.filter(
            (member) => member.chapter_id == chapter_id
          );
          activeMembers = chapterMembers.filter(
            (member) =>
              member.member_status == "Active" && member.member_is_delete === 0
          );
          deactivatedMembers = chapterMembers.filter(
            (member) =>
              member.member_status == "Deactive" &&
              member.member_is_delete === 0
          );

          pendingMembers = chapterMembers.filter(
            (member) =>
              (member.member_status == "Pending" &&
                member.member_is_delete === 0) ||
              (member.member_status == "ActivePending" &&
                member.member_is_delete === 0)
          );
          deletedMembers = chapterMembers.filter(
            (member) => member.member_is_delete === 1
          );
          setAllMembers(allMembers);
          setMembers(processMembers(activeMembers));
          setDeactivatedMembers(processMembers(deactivatedMembers));
          setPendingMembers(processMembers(pendingMembers));
          setDeletedMembers(processMembers(deletedMembers));
        } else if(role === "SUPER_ADMIN"){
          const allMembers1 = allMembers.filter((plan) => plan.org_id === null);
          activeMembers = allMembers1.filter(
            (member) =>
              member.member_status == "Active" && member.member_is_delete === 0
          );

          deactivatedMembers = allMembers1.filter(
            (member) =>
              member.member_status == "Deactive" &&
              member.member_is_delete === 0
          );

          pendingMembers = allMembers1.filter(
            (member) =>
              (member.member_status == "Pending" &&
                member.member_is_delete === 0) ||
              (member.member_status == "ActivePending" &&
                member.member_is_delete === 0)
          );
          deletedMembers = allMembers1.filter(
            (member) => member.member_is_delete === 1
          );
          setAllMembers(allMembers1);
          setMembers(processMembers(activeMembers));
          setDeactivatedMembers(processMembers(deactivatedMembers));
          setPendingMembers(processMembers(pendingMembers));
          setDeletedMembers(processMembers(deletedMembers));
        } else {
          activeMembers = allMembers.filter(
            (member) =>
              member.member_status == "Active" && member.member_is_delete == 0
          );
          deactivatedMembers = allMembers.filter(
            (member) =>
              member.member_status == "Deactive" &&
              member.member_is_delete === 0
          );
          deletedMembers = allMembers.filter(
            (member) => member.member_is_delete !== 0
          );
          pendingMembers = allMembers.filter(
            (member) =>
              (member.member_status == "Pending" &&
                member.member_is_delete === 0) ||
              (member.member_status == "ActivePending" &&
                member.member_is_delete === 0)
          );
          setAllMembers(allMembers);
          setPendingMembers(processMembers(pendingMembers));
          setMembers(processMembers(activeMembers));
          setDeactivatedMembers(processMembers(deactivatedMembers));
          setDeletedMembers(processMembers(deletedMembers));
        }

        setDatabaseImage(allMembers.imageUrl);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("No Members found:", error);
        // handleSuccess("No Members found");
      } else {
        console.error("Error fetching members:", error);
        // setError("Error fetching members");
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      throw new RangeError("Invalid time value");
    }
    return date.toISOString();
  };

  //close  and open the deactive Form

  const openDeactiveForm = (request) => {
    setselectedRequest(request);
    setselectedRequestId(request.member_id);
    setshowdeactiveForm(true);
  };

  const closeDeactiveForm = () => {
    setshowdeactiveForm(false);
  };

  //handel change if update data of member
  const handleMemberDataChange = useCallback(
    (key, value) => {
      // Check if the key is 'dob' (Date of Birth) or 'is_active'
      if (key === "dob") {
        // Ensure the date is in the correct format (yyyy-mm-dd)
        const formattedDate = new Date(value).toISOString().split("T")[0];
        setselectedRequest((prevData) => ({
          ...prevData,
          [key]: formattedDate,
        }));
      } else if (key === "deactive_date") {
        const formattedDate = new Date(value).toISOString().split("T")[0];
        setselectedRequest((prevData) => ({
          ...prevData,
          [key]: formattedDate,
        }));
      } else if (key === "member_is_active") {
        const updatedValue = Number(value); // Convert to number (0 or 1)
        setselectedRequest((prevData) => ({
          ...prevData,
          [key]: updatedValue,
        }));
      } else {
        // For other fields, update normally
        setselectedRequest((prevData) => ({ ...prevData, [key]: value }));
      }
    },
    [setselectedRequest]
  );

  //For profile image update fuction of member
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        console.log("selectedImage", selectedImage);
        // Call the image update API here
        updateMemberImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  // Determine which image to display
  const displayImage = selectedImage;

  const updateMemberImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("profileImage", file);
      formData.append("fieldName", "profileImage");
      console.log("selectedRequestId", selectedRequestId);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/members/upload-image/${selectedRequestId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        // Handle non-successful response
        console.error("Error updating member image:", response.statusText);
        // Show error message to the user
        // setError('There was a problem with the update request');
      } else {
        // Handle the successful response
        const responseData = await response.json();
        fetchMembers();
      }
    } catch (error) {
      // Handle errors
      console.error("Error updating member image:", error);
      // Show error message to the user
      // setError('There was a problem with the update request');
      // ... rest of the code to handle errors
    }
  };

  //For profile image update fuction of member
  const handleLogoChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Handle the selected logo
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedLogo(reader.result);
        updateMemberlogo(file);
      };
      reader.readAsDataURL(file);
    }
  };

  // Determine which logo to display
  const displayLogo = selectedLogo;

  const updateMemberlogo = async (file) => {
    try {
      const formData = new FormData();
      formData.append("logoImage", file);

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/members/upload-logo/${selectedRequestId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Handle the successful response
      fetchMembers();
    } catch (error) {
      // Handle errors
      console.error("Error updating member image:", error);
    }
  };

  // for view profile
  const openviewProfileForm = (memberidd, userid) => {
    // if (member && member.member_id) {
    navigate("/dashboard/app/fprofile", {
      state: { state: memberidd, userid: userid },
    });
    //}
  };

  // for Edit form show profle

  const openeditProfileForm = (request) => {
    setselectedRequest(request);
    setselectedRequestId(request.member_id);
    setShowviewProfileForm(true);
  };
  const closeviewProfileForm = () => {
    setShowviewProfileForm(false);
  };
  //for show the message
  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    // Fetch members when the component mounts
    fetchMembers();
  }, []);

  const handleSelect = (key) => {
    setActiveTab(key);
  };
  //for delete fuctionality
  const handleDelete = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/members/members/${memberToDelete}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Assuming you have state variables and state-setting functions
        setMembers((prevMembers) =>
          prevMembers.filter((member) => member.member_id !== member_id)
        );

        // Handle success or display a success message
        setShowdelete(false);
        fetchMembers();
        handleSuccess("Record is deleted ");
      })
      .catch((error) => setError("An error occurred while deleting the data"));
  };
  //for deactived fuctionality
  const handleDeactive = async (memberid, deactive_date) => {
    try {
      const token = sessionStorage.getItem("token");

      // Fetch the current member status
      const currentMember = allMembers.find(
        (member) => member.member_id === memberid
      );

      // Assuming members is a state variable holding the member data
      const currentStatus = currentMember ? currentMember.member_status : null;
      let newStatus;

      // Determine the new status based on role and current status
      if (role === "CHAPTER_ADMIN") {
        if (currentStatus === "Active") {
          newStatus = "Pending";
        }
      } else if (role === "ORG_ADMIN") {
        if (currentStatus === "Active" || currentStatus === "Pending") {
          newStatus = "Deactive";
        } else if (currentStatus === "Deactive") {
          newStatus = "Active";
        }
      }

      // Proceed only if there's a valid newStatus
      if (newStatus) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/members/members/status/${memberid}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              member_status: newStatus,
              deactive_date: deactive_date, // Send deactivation date if applicable
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // Update state and handle success
        setshowdeactiveForm(false);
        fetchMembers();
        handleSuccess("Member status updated successfully");
      } else {
        setError("An error occurred while updating the data");
      }
    } catch (error) {
      setError("An error occurred while updating the data");
    }
  };

  const handleActive = async (member_id) => {
    try {
      const token = sessionStorage.getItem("token");
      // Fetch the current member status
      const currentMember = allMembers.find(
        (member) => member.member_id === member_id
      );
      const currentStatus = currentMember ? currentMember.member_status : null;
      let newStatus;
      // Determine the new status based on role and current status
      if (role === "CHAPTER_ADMIN") {
        // CHAPTER_ADMIN can only change "Active" status to "Pending"
        if (currentStatus === "Deactive") {
          newStatus = "ActivePending";
        }
      } else if (role === "ORG_ADMIN") {
        // ORG_ADMIN can toggle between "Active" and "Deactive", and set "Pending" to "Deactive"
        if (currentStatus === "Deactive" || currentStatus === "ActivePending") {
          newStatus = "Active";
        } else if (currentStatus === "Deactive") {
          newStatus = "Active";
        }
      }
      console.log("newStatus", newStatus);

      // Proceed only if there's a valid newStatus
      if (newStatus) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/members/members/status/${member_id}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ member_status: newStatus }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // Update state and handle success
        fetchMembers();
        handleSuccess("Member status updated successfully");
      } else {
        setError("Invalid status change based on role");
      }
    } catch (error) {
      setError("An error occurred while updating the data");
    }
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            {/* ----multitab code---  */}
            <Col lg="12">
              <Card>
                <Card.Header>
                  <h4>
                    <b>Members List</b>
                  </h4>
                </Card.Header>
                <Card.Body className="p-2">
                  <Tab.Container activeKey={activeTab} onSelect={handleSelect}>
                    <div className="user-tabing p-3">
                      <div className="flex-wrap align-items-center justify-content-between">
                        <div className=" align-items-center text-center profile-forum-items p-0 m-0">
                          <Nav variant="pills">
                            <Col sm={3} className=" p-2">
                              <Nav.Item>
                                <Nav.Link eventKey="first" role="button">
                                  Members
                                </Nav.Link>
                              </Nav.Item>
                            </Col>

                            <Col sm={3} className=" p-2">
                              <Nav.Item>
                                <Nav.Link eventKey="second" role="button">
                                  Deactivated Members
                                </Nav.Link>
                              </Nav.Item>
                            </Col>

                            <Col sm={3} className=" p-2">
                              <Nav.Item>
                                <Nav.Link eventKey="third" role="button">
                                  Active/Deactive pending
                                </Nav.Link>
                              </Nav.Item>
                            </Col>

                            <Col sm={3} className=" p-2">
                              <Nav.Item>
                                <Nav.Link eventKey="four" role="button">
                                  Deleted Members
                                </Nav.Link>
                              </Nav.Item>
                            </Col>
                          </Nav>
                        </div>

                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            {/* -----1 all members  code---  */}
                            <Col lg={12}>
                              <Card>
                                <Card.Body
                                  className="p-0"
                                  style={{ overflowY: "auto", height: "80vh" }}
                                >
                                  {alertMessage ? (
                                    <div>
                                      {" "}
                                      <p>{alertMessage}</p>
                                    </div>
                                  ) : (
                                    <>
                                      {members.length === 0 ? (
                                        <p>No data available</p>
                                      ) : (
                                        <Table className="rounded">
                                          <thead className="bg-primary text-white">
                                            <tr>
                                              <th></th>
                                              <th>
                                                <b> Name</b>
                                              </th>
                                              <th>
                                                <b>
                                                  {getOrgNames?.alt_chapter ||
                                                    "Chapter"}
                                                </b>
                                              </th>
                                              <th>
                                                <b>
                                                  {" "}
                                                  {getOrgNames?.alt_membership_category ||
                                                    "Category"}
                                                </b>
                                              </th>
                                              <th>
                                                <b> </b>
                                              </th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {members.map((member, index) => (
                                              <React.Fragment key={index}>
                                                <tr>
                                                  <td>
                                                    {member.imageUrl ? (
                                                      <img
                                                        src={member.imageUrl}
                                                        alt="story-img"
                                                        className="rounded-circle avatar-40"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={user5}
                                                        alt="story-img"
                                                        className="rounded-circle avatar-40"
                                                      />
                                                    )}
                                                  </td>
                                                  <td>
                                                    <b>
                                                      {" "}
                                                      {member.first_name}{" "}
                                                      {member.last_name}
                                                    </b>
                                                  </td>
                                                  <td>
                                                    <b>
                                                      {" "}
                                                      {member.chapter_name}
                                                    </b>
                                                  </td>

                                                  <td>
                                                    <b>
                                                      {member.member_category}
                                                    </b>
                                                  </td>
                                                  <td>
                                                    <Dropdown>
                                                      <Dropdown.Toggle
                                                        variant="link"
                                                        id="dropdown-basic"
                                                      >
                                                        {/* Horizontal three-dot icon */}
                                                        <FaEllipsisV
                                                          size={20}
                                                        />
                                                      </Dropdown.Toggle>

                                                      <Dropdown.Menu>
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            openviewProfileForm(
                                                              member.member_id,
                                                              member.user_id
                                                            )
                                                          }
                                                        >
                                                          {/* Eye icon */}
                                                          <FaEye
                                                            size={15}
                                                            style={{
                                                              color: "#007BFF",
                                                            }}
                                                          />
                                                          &nbsp; View
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            openeditProfileForm(
                                                              member
                                                            )
                                                          }
                                                        >
                                                          {/* Edit icon */}
                                                          <FaEdit
                                                            size={15}
                                                            style={{
                                                              color: "#007BFF",
                                                            }}
                                                          />
                                                          &nbsp; Edit
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            openDeactiveForm(
                                                              member
                                                            )
                                                          }
                                                        >
                                                          {/* Trash icon */}
                                                          <BsPersonSlash
                                                            className="deleteicon"
                                                            size={17}
                                                          />
                                                          &nbsp; Deactive
                                                        </Dropdown.Item>

                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            handleShowdelete(
                                                              member.member_id
                                                            )
                                                          }
                                                        >
                                                          {/* Trash icon */}
                                                          <FaTrash
                                                            className="deleteicon"
                                                            size={15}
                                                          />
                                                          &nbsp; Delete
                                                        </Dropdown.Item>
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                    {/* /modal for confirm Delete  */}
                                                    <Modal
                                                      centered
                                                      show={showdelete}
                                                      onHide={handleClosedelete}
                                                    >
                                                      <Modal.Header closeButton>
                                                        <Modal.Title>
                                                          Delete Row
                                                        </Modal.Title>
                                                      </Modal.Header>
                                                      <Modal.Body>
                                                        Are you sure you want to
                                                        delete this record?
                                                      </Modal.Body>
                                                      <Modal.Footer>
                                                        <Button
                                                          variant="secondary"
                                                          onClick={
                                                            handleClosedelete
                                                          }
                                                        >
                                                          Close
                                                        </Button>
                                                        <Button
                                                          variant="primary"
                                                          onClick={handleDelete}
                                                        >
                                                          yes delete
                                                        </Button>
                                                      </Modal.Footer>
                                                    </Modal>
                                                  </td>
                                                </tr>
                                              </React.Fragment>
                                            ))}
                                          </tbody>
                                        </Table>
                                      )}
                                    </>
                                  )}
                                </Card.Body>
                              </Card>
                            </Col>
                          </Tab.Pane>

                          <Tab.Pane eventKey="second">
                            {/* ---2 deactived member code--  */}
                            <Col lg={12}>
                              <Card>
                                <Card.Body
                                  className="p-0"
                                  style={{ overflowY: "auto", height: "80vh" }}
                                >
                                  <Table className="rounded" eventKey="second">
                                    <thead
                                      className="bg-primary text-white"
                                      eventKey="second"
                                    >
                                      <tr>
                                        <th></th>
                                        <th>
                                          <b> Name</b>
                                        </th>
                                        <th>
                                          <b>
                                            {getOrgNames?.alt_chapter ||
                                              "Chapter"}
                                          </b>
                                        </th>
                                        <th>
                                          <b> Deactived Date</b>
                                        </th>
                                        <th>
                                          <b> </b>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {deactivatedMembers.map(
                                        (member, index) => (
                                          <React.Fragment key={index}>
                                            <tr>
                                              <td className="col-lg-2">
                                                {member.imageUrl ? (
                                                  <img
                                                    src={member.imageUrl}
                                                    alt="story-img"
                                                    className="rounded-circle avatar-40"
                                                  />
                                                ) : (
                                                  <img
                                                    src={user5}
                                                    alt="story-img"
                                                    className="rounded-circle avatar-40"
                                                  />
                                                )}
                                              </td>
                                              <td className="col-lg-3">
                                                <b>
                                                  {" "}
                                                  {member.first_name}{" "}
                                                  {member.last_name}
                                                </b>
                                              </td>
                                              <td className="col-lg-2">
                                                <b> {member.chapter_name}</b>
                                              </td>

                                              <td className="col-lg-2">
                                                <b>{member.deactive_date}</b>
                                              </td>
                                              <td className="col-lg-2">
                                                <Dropdown>
                                                  <Dropdown.Toggle
                                                    variant="link"
                                                    id="dropdown-basic"
                                                  >
                                                    {/* Horizontal three-dot icon */}
                                                    <FaEllipsisV size={20} />
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        openviewProfileForm(
                                                          member.member_id,
                                                          member.user_id
                                                        )
                                                      }
                                                    >
                                                      {/* Eye icon */}
                                                      <FaEye
                                                        size={15}
                                                        style={{
                                                          color: "#007BFF",
                                                        }}
                                                      />
                                                      &nbsp; View
                                                    </Dropdown.Item>
                                                    {role === "ORG_ADMIN" ||
                                                    (role === "CHAPTER_ADMIN" &&
                                                      getSettingNames.member_onboard_authority ===
                                                        1) ? (
                                                      <>
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            handleActive(
                                                              member.member_id
                                                            )
                                                          }
                                                        >
                                                          {/* Trash icon */}
                                                          <BsPersonSlash
                                                            className="deleteicon"
                                                            size={17}
                                                          />
                                                          &nbsp; Aactive
                                                        </Dropdown.Item>
                                                      </>
                                                    ) : null}
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        handleShowdelete(
                                                          member.member_id
                                                        )
                                                      }
                                                    >
                                                      {/* Trash icon */}
                                                      <FaTrash
                                                        className="deleteicon"
                                                        size={15}
                                                      />
                                                      &nbsp; Delete
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                                {/* /modal for confirm Delete  */}
                                                <Modal
                                                  centered
                                                  show={showdelete}
                                                  onHide={handleClosedelete}
                                                >
                                                  <Modal.Header closeButton>
                                                    <Modal.Title>
                                                      Delete Row
                                                    </Modal.Title>
                                                  </Modal.Header>
                                                  <Modal.Body>
                                                    Are you sure you want to
                                                    delete this record?
                                                  </Modal.Body>
                                                  <Modal.Footer>
                                                    <Button
                                                      variant="secondary"
                                                      onClick={
                                                        handleClosedelete
                                                      }
                                                    >
                                                      Close
                                                    </Button>
                                                    <Button
                                                      variant="primary"
                                                      onClick={handleDelete}
                                                    >
                                                      yes delete
                                                    </Button>
                                                  </Modal.Footer>
                                                </Modal>
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Tab.Pane>

                          <Tab.Pane eventKey="third">
                            {/* --------3 deactivation pending members code---  */}
                            <Col lg={12}>
                              <Card>
                                <Card.Body
                                  className="p-0"
                                  style={{ overflowY: "auto", height: "80vh" }}
                                >
                                  <Table className="rounded">
                                    <thead className="bg-primary text-white">
                                      <tr>
                                        <th></th>
                                        <th>
                                          <b> Name</b>
                                        </th>
                                        <th>
                                          <b>
                                            {getOrgNames?.alt_chapter ||
                                              "Chapter"}
                                          </b>
                                        </th>
                                        <th>
                                          <b> Deactived Date</b>
                                        </th>
                                        <th>
                                          <b> </b>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {pendingMembers.map((member, index) => (
                                        <React.Fragment key={index}>
                                          <tr>
                                            <td className="col-lg-2">
                                              {member.imageUrl ? (
                                                <img
                                                  src={member.imageUrl}
                                                  alt="story-img"
                                                  className="rounded-circle avatar-40"
                                                />
                                              ) : (
                                                <img
                                                  src={user5}
                                                  alt="story-img"
                                                  className="rounded-circle avatar-40"
                                                />
                                              )}
                                            </td>
                                            <td className="col-lg-3">
                                              <b>
                                                {" "}
                                                {member.first_name}{" "}
                                                {member.last_name}
                                              </b>
                                            </td>
                                            <td className="col-lg-2">
                                              <b>{member.chapter_name}</b>
                                            </td>

                                            <td className="col-lg-2">
                                              <b>{member.deactive_date}</b>
                                            </td>
                                            <td className="col-lg-2">
                                              <Dropdown>
                                                <Dropdown.Toggle
                                                  variant="link"
                                                  id="dropdown-basic"
                                                >
                                                  {/* Horizontal three-dot icon */}
                                                  <FaEllipsisV size={20} />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      openviewProfileForm(
                                                        member.member_id,
                                                        member.user_id
                                                      )
                                                    }
                                                  >
                                                    {/* Eye icon */}
                                                    <FaEye
                                                      size={15}
                                                      style={{
                                                        color: "#007BFF",
                                                      }}
                                                    />
                                                    &nbsp; View
                                                  </Dropdown.Item>
                                                  {role !== "CHAPTER_ADMIN" && (
                                                    <>
                                                      {/* Show "Deactive" button only if member_status is "Pending" */}
                                                      {member.member_status ===
                                                        "Pending" && (
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            handleDeactive(
                                                              member.member_id,
                                                              member.deactive_date
                                                            )
                                                          }
                                                        >
                                                          <BsPersonSlash
                                                            className="deleteicon"
                                                            size={17}
                                                          />
                                                          &nbsp; Deactive
                                                        </Dropdown.Item>
                                                      )}

                                                      {/* Show "Active" button only if member_status is "ActivePending" */}
                                                      {member.member_status ===
                                                        "ActivePending" && (
                                                        <Dropdown.Item
                                                          onClick={() =>
                                                            handleActive(
                                                              member.member_id
                                                            )
                                                          }
                                                        >
                                                          <BsPersonSlash
                                                            className="deleteicon"
                                                            size={17}
                                                          />
                                                          &nbsp; Activate
                                                        </Dropdown.Item>
                                                      )}

                                                      <Dropdown.Item
                                                        onClick={() =>
                                                          handleShowdelete(
                                                            member.member_id
                                                          )
                                                        }
                                                      >
                                                        <FaTrash
                                                          className="deleteicon"
                                                          size={15}
                                                        />
                                                        &nbsp; Delete
                                                      </Dropdown.Item>
                                                    </>
                                                  )}
                                                </Dropdown.Menu>
                                              </Dropdown>
                                              {/* /modal for confirm Delete  */}
                                              <Modal
                                                centered
                                                show={showdelete}
                                                onHide={handleClosedelete}
                                              >
                                                <Modal.Header closeButton>
                                                  <Modal.Title>
                                                    Delete Row
                                                  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                  Are you sure you want to
                                                  delete this record?
                                                </Modal.Body>
                                                <Modal.Footer>
                                                  <Button
                                                    variant="secondary"
                                                    onClick={handleClosedelete}
                                                  >
                                                    Close
                                                  </Button>
                                                  <Button
                                                    variant="primary"
                                                    onClick={handleDelete}
                                                  >
                                                    yes delete
                                                  </Button>
                                                </Modal.Footer>
                                              </Modal>
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Tab.Pane>

                          <Tab.Pane eventKey="four">
                            {/* --------3 deleted members code---  */}
                            <Col lg={12}>
                              <Card>
                                <Card.Body
                                  className="p-0"
                                  style={{ overflowY: "auto", height: "80vh" }}
                                >
                                  <Table className="rounded">
                                    <thead className="bg-primary text-white">
                                      <tr>
                                        <th></th>
                                        <th>
                                          <b> Name</b>
                                        </th>
                                        <th>
                                          <b>
                                            {getOrgNames?.alt_chapter ||
                                              "Chapter"}
                                          </b>
                                        </th>
                                        <th>
                                          <b>
                                            {getOrgNames?.alt_membership_category ||
                                              "Category"}
                                          </b>
                                        </th>
                                        <th>
                                          <b> </b>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {deletedMembers.map((member, index) => (
                                        <React.Fragment key={index}>
                                          <tr>
                                            <td className="col-lg-2">
                                              {member.imageUrl ? (
                                                <img
                                                  src={member.imageUrl}
                                                  alt="story-img"
                                                  className="rounded-circle avatar-40"
                                                />
                                              ) : (
                                                <img
                                                  src={user5}
                                                  alt="story-img"
                                                  className="rounded-circle avatar-40"
                                                />
                                              )}
                                            </td>
                                            <td className="col-lg-3">
                                              <b>
                                                {" "}
                                                {member.first_name}{" "}
                                                {member.last_name}
                                              </b>
                                            </td>
                                            <td className="col-lg-2">
                                              <b>{member.chapter_name}</b>
                                            </td>

                                            <td className="col-lg-2">
                                              <b>{member.member_category}</b>
                                            </td>
                                            {/* <td className="col-lg-2">
                                              <FaEye
                                                size={15}
                                                style={{ color: "#007BFF" }}
                                                onClick={() =>
                                                  openviewProfileForm(
                                                    member.member_id,
                                                    member.user_id
                                                  )
                                                }
                                              />
                                            </td> */}
                                          </tr>
                                        </React.Fragment>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </div>
                  </Tab.Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* show the deactive and active form  */}
      {selectedRequest && (
        <Modal
          size="sm"
          scrollable={true}
          show={showdeactiveForm}
          onHide={closeDeactiveForm}
          style={{ marginTop: "15vh", height: "80vh", marginBottom: "5vh" }}
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: "#007BFF", color: "white" }}
          >
            <Modal.Title>Deactivat Members</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form encType="multipart/form-data">
              <Row className="align-items-center">
                <Form.Group className="form-group col-sm-6">
                  <Form.Label className="form-label d-block">
                    Memeber Status:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="age"
                    value={selectedRequest.member_status}
                    placeholder="Chapter Name"
                    readOnly
                  />
                </Form.Group>
                <Form.Group className="form-group col-sm-12">
                  <Form.Label htmlFor="dob" className="form-label">
                    Select Deactivation Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    className="form-control"
                    id="dob"
                    value={
                      selectedRequest.deactive_date
                        ? new Date(selectedRequest.deactive_date)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    onChange={(e) =>
                      handleMemberDataChange("deactive_date", e.target.value)
                    } // Add this onChange handler
                    placeholder="Enter Deactive Date..."
                  />
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={closeDeactiveForm}
              style={{ backgroundColor: "#6c757d", border: "none" }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                handleDeactive(
                  selectedRequest.member_id,
                  selectedRequest.deactive_date
                )
              }
              style={{ backgroundColor: "#007BFF", border: "none" }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* //show the profile of member  */}
      <Modal
        size="lg"
        scrollable={true}
        show={showviewProfileForm}
        onHide={closeviewProfileForm}
        style={{ marginTop: "13vh", height: "75vh" }}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#007BFF", color: "white" }}
        >
          <Modal.Title>Member Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container defaultActiveKey="first">
            <Row>
              <Col lg="12">
                <Card>
                  <Card.Body className="p-0">
                    <div>
                      <Nav
                        as="ul"
                        variant="pills"
                        className="iq-edit-profile row"
                      >
                        <Nav.Item as="li" className="col-md-3 p-0">
                          <Nav.Link eventKey="first" role="button">
                            Personal Information
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="col-md-3 p-0">
                          <Nav.Link eventKey="second" role="button">
                            Corporate Information
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="col-md-3 p-0">
                          <Nav.Link eventKey="third" role="button">
                            Membership
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                {/* <div className="iq-edit-list-data"> */}
                <Tab.Content>
                  <Tab.Pane eventKey="first" className="fade show">
                    <Card>
                      <Card.Header className="d-flex justify-content-between"></Card.Header>
                      <Card.Body>
                        {selectedRequest && (
                          <Form encType="multipart/form-data">
                            <Form.Group className="form-group align-items-center">
                              <Col md="12">
                                <div className="profile-img-edit">
                                  {displayImage || selectedRequest.imageUrl ? (
                                    <li>
                                      {/* Display the selected or database image */}
                                      <img
                                        className="profile-pic img-fluid"
                                        src={
                                          displayImage ||
                                          selectedRequest.imageUrl
                                        }
                                        alt="user-image"
                                      />
                                    </li>
                                  ) : (
                                    <li>
                                      {/* Display the default image when no image is available */}
                                      <img
                                        className="profile-pic img-fluid"
                                        src={img1}
                                        alt="default-user"
                                      />
                                    </li>
                                  )}

                                  <li>
                                    {/* <div className="p-image"> */}
                                    <label className="p-image  material-symbols-outlined">
                                      edit
                                      <input
                                        name="profileImage"
                                        className="file-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                      />
                                    </label>
                                    {/* </div> */}
                                  </li>
                                </div>
                              </Col>
                            </Form.Group>
                            <Row className="align-items-center">
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label
                                  htmlFor="fname"
                                  className="form-label"
                                >
                                  First Name:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.first_name}
                                  id="fname"
                                  placeholder="Enter your first name"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "first_name",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label
                                  htmlFor="lname"
                                  className="form-label"
                                >
                                  Last Name:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.last_name}
                                  id="lname"
                                  placeholder="Enter your last name"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "last_name",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label d-block">
                                  Gender:
                                </Form.Label>
                                <Form.Check className="form-check form-check-inline">
                                  <Form.Check.Input
                                    className="form-check-input"
                                    type="radio"
                                    name="genderOptions"
                                    id="maleOption"
                                    value="Male"
                                    checked={selectedRequest.gender === "Male"}
                                    onChange={(e) =>
                                      handleMemberDataChange(
                                        "gender",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Check.Label
                                    className="form-check-label"
                                    htmlFor="maleOption"
                                  >
                                    Male
                                  </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check form-check-inline">
                                  <Form.Check.Input
                                    className="form-check-input"
                                    type="radio"
                                    name="genderOptions"
                                    id="femaleOption"
                                    value="Female"
                                    checked={
                                      selectedRequest.gender === "Female"
                                    }
                                    onChange={(e) =>
                                      handleMemberDataChange(
                                        "gender",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Check.Label
                                    className="form-check-label"
                                    htmlFor="femaleOption"
                                  >
                                    Female
                                  </Form.Check.Label>
                                </Form.Check>
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label
                                  htmlFor="dob"
                                  className="form-label"
                                >
                                  Date Of Birth:
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  className="form-control"
                                  id="dob"
                                  value={
                                    selectedRequest.dob
                                      ? new Date(selectedRequest.dob)
                                          .toISOString()
                                          .split("T")[0]
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "dob",
                                      e.target.value
                                    )
                                  } // Add this onChange handler
                                  placeholder="Enter Date of Birth..."
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label
                                  htmlFor="dob"
                                  className="form-label"
                                >
                                  Email Id:
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  className="form-control"
                                  id="age"
                                  value={selectedRequest.email_id}
                                  placeholder="Enter email"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "email_id",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  Contact No.:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="age"
                                  value={selectedRequest.contact_no}
                                  placeholder="Enter Contact No"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "contact_no",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  Country:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="age"
                                  value={selectedRequest.country}
                                  placeholder="Enter Country"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "country",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  State:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="age"
                                  value={selectedRequest.state}
                                  placeholder="Enter State"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "state",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  City:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="age"
                                  value={selectedRequest.city}
                                  placeholder="Enter City"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "city",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  Zip Code:
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  className="form-control"
                                  id="age"
                                  value={selectedRequest.zip_code}
                                  placeholder="Enter Zip Code"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "zip_code",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-12">
                                <Form.Label className="form-label">
                                  Address:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="age"
                                  value={selectedRequest.street_address}
                                  placeholder="Enter Address"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "street_address",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                            </Row>
                          </Form>
                        )}
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second" className="fade show">
                    <Card>
                      <Card.Header className="d-flex justify-content-between"></Card.Header>
                      <Card.Body>
                        {selectedRequest && (
                          <Form encType="multipart/form-data">
                            <Form.Group className="form-group align-items-center">
                              <Col md="12">
                                <div className="profile-img-edit">
                                  {displayLogo ? (
                                    <li>
                                      {/* Display the selected or database logo */}
                                      <img
                                        className="profile-pic"
                                        src={displayLogo}
                                        alt="company-logo"
                                      />
                                    </li>
                                  ) : (
                                    <li>
                                      {/* Display the selected or database image selectedRequest.imageUrl */}
                                      <img
                                        className="profile-pic img-fluid"
                                        src={img1}
                                      />
                                    </li>
                                  )}{" "}
                                  <li>
                                    <div className="p-image material-symbols-outlined">
                                      <label className=" material-symbols-outlined">
                                        edit
                                        <input
                                          name="companyLogo"
                                          className="file-upload"
                                          type="file"
                                          accept="image/*"
                                          onChange={handleLogoChange}
                                        />
                                      </label>
                                    </div>
                                  </li>
                                </div>
                              </Col>
                            </Form.Group>
                            <Row className="align-items-center">
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label
                                  htmlFor="fname"
                                  className="form-label"
                                >
                                  Company Name:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.company_name}
                                  id="fname"
                                  placeholder="Enter Company Name"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "company_name",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label
                                  htmlFor="lname"
                                  className="form-label"
                                >
                                  Company Title:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.company_title}
                                  id="lname"
                                  placeholder="Enter Company Title"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "company_title",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label
                                  htmlFor="dob"
                                  className="form-label"
                                >
                                  Company LinkedIn URL:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.company_linkedIn_url}
                                  id="dob"
                                  placeholder="Enter LinkedIn URL"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "company_linkedIn_url",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label
                                  htmlFor="dob"
                                  className="form-label"
                                >
                                  Company Email:
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  className="form-control"
                                  value={selectedRequest.company_email}
                                  id="email"
                                  placeholder="Enter Email"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "company_email",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  Company Website:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.company_website}
                                  id="company_website"
                                  placeholder="Enter Website"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "company_website",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  Company Size:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.company_size}
                                  id="company_size"
                                  placeholder="Enter Company Size"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "company_size",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  Company Contact No.:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.company_contact_no}
                                  id="company_contact_no"
                                  placeholder="Enter Contact No"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "company_contact_no",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  Company Country:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.company_country}
                                  id="country"
                                  placeholder="Enter Country"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "company_country",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  Company State:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.company_state}
                                  id="state"
                                  placeholder="Enter State"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "company_state",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  Company City:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.company_city}
                                  id="city"
                                  placeholder="Enter City"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "company_city",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  Company Zip Code:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.company_zip_code}
                                  id="zip_code"
                                  placeholder="Enter Zip Code"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "company_zip_code",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-12">
                                <Form.Label className="form-label">
                                  Company Address:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.company_address}
                                  id="street_address"
                                  placeholder="Enter Address"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "company_address",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                            </Row>
                          </Form>
                        )}
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third" className="fade show">
                    <Card>
                      <Card.Header className="d-flex justify-content-between"></Card.Header>
                      <Card.Body>
                        {selectedRequest && (
                          <Form encType="multipart/form-data">
                            <Row className="align-items-center">
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  {getOrgNames?.alt_membership_plan ||
                                    "Membership Plan"}
                                  :
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="age"
                                  value={selectedRequest.membership_plan}
                                  placeholder="Enter membership plan"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "membership_plan",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  {getOrgNames?.alt_membership_category ||
                                    "Member Ctegory"}
                                  :
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="age"
                                  value={selectedRequest.member_category}
                                  placeholder="Enter member category"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "member_category",
                                      e.target.value
                                    )
                                  }
                                  readOnly
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  Member Type:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="age"
                                  value={selectedRequest.member_type}
                                  placeholder="Enter member type"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "member_type",
                                      e.target.value
                                    )
                                  }
                                  readOnly
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label">
                                  {getOrgNames?.alt_chapter || "Chapter"} Name:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  id="age"
                                  value={selectedRequest.chapter_name}
                                  placeholder="Chapter Name"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "chapter_name",
                                      e.target.value
                                    )
                                  }
                                  readOnly
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label
                                  htmlFor="dob"
                                  className="form-label"
                                >
                                  Membership Start Date:
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  className="form-control"
                                  value={
                                    selectedRequest.membership_start_date
                                      ? new Date(
                                          selectedRequest.membership_start_date
                                        )
                                          .toISOString()
                                          .split("T")[0]
                                      : ""
                                  }
                                  id="cno"
                                  placeholder="Enter membership Start date"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "membership_start_date",
                                      e.target.value
                                    )
                                  }
                                  readOnly
                                />
                              </Form.Group>

                              <Form.Group className="form-group col-sm-6">
                                <Form.Label
                                  htmlFor="email"
                                  className="form-label"
                                >
                                  Membership End Date:
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  className="form-control"
                                  value={
                                    selectedRequest.membership_end_date
                                      ? new Date(
                                          selectedRequest.membership_end_date
                                        )
                                          .toISOString()
                                          .split("T")[0]
                                      : ""
                                  }
                                  id="email"
                                  placeholder="Enter membership End date"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "membership_end_date",
                                      e.target.value
                                    )
                                  }
                                  readOnly
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label
                                  htmlFor="url"
                                  className="form-label"
                                >
                                  Membership Renewal Date:
                                </Form.Label>
                                <Form.Control
                                  type="date"
                                  className="form-control"
                                  value={
                                    selectedRequest.membership_renewal_date
                                      ? new Date(
                                          selectedRequest.membership_renewal_date
                                        )
                                          .toISOString()
                                          .split("T")[0]
                                      : ""
                                  }
                                  id="url"
                                  placeholder="Enter membership Renewal date"
                                  onChange={(e) =>
                                    handleMemberDataChange(
                                      "membership_renewal_date",
                                      e.target.value
                                    )
                                  }
                                  readOnly
                                />
                              </Form.Group>
                              <Form.Group className="form-group col-sm-6">
                                <Form.Label className="form-label d-block">
                                  Active Status:
                                </Form.Label>
                                <Form.Check className="form-check form-check-inline">
                                  <Form.Check.Input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio10"
                                    value="Active"
                                    checked={
                                      selectedRequest.is_active === true ||
                                      selectedRequest.is_active === 1
                                    } // Check if is_active is 1 for Active status
                                    onChange={(e) =>
                                      handleMemberDataChange(
                                        "is_active",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Check.Label
                                    className="form-check-label"
                                    htmlFor="inlineRadio10"
                                  >
                                    {" "}
                                    Active
                                  </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check form-check-inline">
                                  <Form.Check.Input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio11"
                                    value="Deactive"
                                    checked={
                                      selectedRequest.is_active === false ||
                                      selectedRequest.is_active === 0
                                    } // Check if is_active is 0 for Deactive status
                                    onChange={(e) =>
                                      handleMemberDataChange(
                                        "is_active",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Check.Label
                                    className="form-check-label"
                                    htmlFor="inlineRadio11"
                                  >
                                    Deactive
                                  </Form.Check.Label>
                                </Form.Check>
                              </Form.Group>
                            </Row>
                          </Form>
                        )}
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                </Tab.Content>
                {/* </div> */}
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeviewProfileForm}
            style={{ backgroundColor: "#6c757d", border: "none" }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={updateMemberData}
            style={{ backgroundColor: "#007BFF", border: "none" }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Success Alert */}
      <Alert
        show={successMessages.length > 0}
        variant="success"
        onClose={() => setSuccessMessages([])}
        dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "black",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            onClick={() => setSuccessMessages([])}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        {successMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </>
  );
};

export default Members;
