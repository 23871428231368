import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const TrainingSearch = () => {
  const token = sessionStorage.getItem("token");

  const [filters, setFilters] = useState({
    search_text: "",
  });
  const navigate = useNavigate();
  const roleId = sessionStorage.getItem("role_id");
  const org_id = sessionStorage.getItem("org_id");
  const [trainings, setTrainings] = useState([]);
  const chapterId = sessionStorage.getItem("chapter_id");
  const [filteredResults, setFilteredResults] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  useEffect(() => {
    // Apply filters when the filters state changes
    applyFilters();
  }, [filters]);

  const fetchDataFromApi = async () => {
    console.log("entered into function to fetch training list");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/training/gettrainings/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        // setTrainings(data.trainingwithUrl);
        if (roleId == 2) {
          const orgEvents = data.trainingwithUrl.filter(
            (event) =>
              event.org_id == org_id &&
              event.chapter_id != null &&
              event.chapter_id == chapterId
          );
          const sortedEvents = orgEvents.sort(
            (a, b) =>
              new Date(a.train_start_date) - new Date(b.train_start_date)
          );

          setTrainings(sortedEvents);
        } else if (roleId == 4) {
          const orgEvents = data.trainingwithUrl.filter(
            (event) => event.org_id == org_id
          );
          const sortedEvents = orgEvents.sort(
            (a, b) =>
              new Date(a.train_start_date) - new Date(b.train_start_date)
          );

          setTrainings(sortedEvents);
        } else if (roleId == 1) {
          const orgEvents = data.trainingwithUrl.filter(
            (event) => event.org_id === null
          );
          const sortedEvents = orgEvents.sort(
            (a, b) =>
              new Date(a.train_start_date) - new Date(b.train_start_date)
          );

          setTrainings(sortedEvents);
        }
      } else {
        // Handle errors from the backend
        const errorData = await response.json();
        // setError(`Error fetching events: ${JSON.stringify(errorData)}`);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Unexpected error:", error.message);
      // setError("Error fetching training. Please try again later.");
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const handleSearchError = (errorMessage) => {
    console.error(errorMessage);
    // Set the alert message for search error
    navigate("dashboard/app/organizations", {
      state: { alertMessage: errorMessage },
    });
  };

  const applyFilters = () => {
    const { search_text } = filters;
     

    const updatedResults = trainings.filter((result) => {
      const isSearchMatch =
        search_text == "" ||
        (result.training_topic &&
          result.training_topic
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.training_mode &&
          result.training_mode
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.training_details &&
          result.training_details
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.trainer_name &&
          result.trainer_name
            .toLowerCase()
            .includes(search_text.toLowerCase()));

      return isSearchMatch;
    });

    setFilteredResults(() => updatedResults); // Use callback function here
  };

  const handleSearch = () => {
    applyFilters();

    if (filteredResults.length == 0) {
      // No matching results, set the alert message
      navigate("/dashboard/createTreanning", {
        state: { alertMessage: "No matching results found!" },
      });
    } else {
      // Results found, navigate to the MembersList page with the filtered results
      navigate("/dashboard/createTreanning", {
        state: { searchResults: filteredResults },
      });
    }
  };

  const handleReset = () => {
    setFilters({
      search_text: "",
    });
    navigate("/dashboard/createTreanning", { state: { searchResults: null } });
  };

  return (
    <div>
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          name="search_text"
          value={filters.search_text}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-12 text-center pt-2">
        <button className="btn btn-primary" onClick={handleSearch}>
          <b>Search</b>
        </button>
      </div>
      <div className="col-12 text-center pt-2">
        <button className="btn btn-danger" onClick={handleReset}>
          <b>Reset</b>
        </button>
      </div>
    </div>
  );
};

export default TrainingSearch;
