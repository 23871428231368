import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Alert,
} from "react-bootstrap";
import { format } from "date-fns";
import { FaTimes } from "react-icons/fa";
import ChapterMeetingList from "./chapterMeetingList";
import useOrgNaming from "../../../hooks/getNamingHook";

const ChapterMeeting = () => {
  const memberId = sessionStorage.getItem("member_id");
  const chapterId = sessionStorage.getItem("chapter_id");
  const roleId = sessionStorage.getItem("role_id");
  const chapterName = sessionStorage.getItem("chapter_name");
  const userName = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const location = useLocation();
  const { searchResults, alertMessage } = location.state || {};
  const [memberid, setMemberid] = useState([]);
  const [memberNames, setMemberNames] = useState([]);
  const [newMeeting, setNewMeeting] = useState({
    meeting_mode: "",
    meeting_date: "",
    meeting_start_time: "",
    meeting_end_time: "",
    meeting_link: "",
    meeting_subject: "",
    meeting_type: "Chapter",
    member_id: "",
    chapter_id: chapterId,
    meeting_place: "",
    meeting_desc: "",
    meeting_status: "",
    payment_link: "",
    meeting_charges: "",
    meeting_cut_off_date: "",
    organized_by: `${memberId}`,
    created_by: `${memberId}`,
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [error, setError] = useState(null);
  const [showMeetingForm, setShowMeetingForm] = useState(true);
  const [headerText, setHeaderText] = useState("Chapter Meetings");
  const [chapterNames, setChapterNames] = useState([]);
  const org_id = sessionStorage.getItem("org_id");
  const { getOrgNames, loading } = useOrgNaming(org_id, token);

  const timeOptions = [];
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 15) {
      const hour12 = h % 12 || 12; // Convert 24h to 12h format
      const minute = m.toString().padStart(2, "0");
      const period = h < 12 ? "AM" : "PM";

      timeOptions.push({
        label: `${hour12}:${minute} ${period}`,  // Display format
        value: `${String(h).padStart(2, "0")}:${minute}`, // Store as 24-hour format
      });
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.get(
          process.env.REACT_APP_API_URL + `/members/members/all/${org_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data && response.data.imageUrls) {
          const memberNamess = response.data.imageUrls.map(
            (member) => member.first_name
          );
          const memberIDs = response.data.imageUrls.map(
            (member) => member.member_id
          );

          setMemberNames(memberNamess);
          setMemberid(memberIDs);
        }
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchData();
  }, []);

  const handlemembers = (event) => {
    const selectedName = event.target.value;
    const selectedIndex = memberNames.indexOf(selectedName);

    if (selectedIndex !== -1) {
      const selectedId = memberid[selectedIndex];

      setNewMeeting((newMeeting) => ({
        ...newMeeting,
        member_id: selectedId,
      }));
    } else {
      console.log("Name not found in memberNames array");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Set the value directly for chapter_id only when meeting_type is "Chapter"
    if (name === "meeting_type") {
      const newMeetingValues = {
        ...newMeeting,
        [name]: value,
      };

      if (value === "Chapter") {
        newMeetingValues.chapter_id = chapterId;
      }

      setNewMeeting(newMeetingValues);
    } else {
      setNewMeeting((prevMeeting) => ({
        ...prevMeeting,
        [name]: value,
      }));
    }
  };

  const showSuccess = () => {
    setShowSuccessModal(true);
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const clearError = () => {
    setError(null);
  };

  const isFormValid = () => {
    return (
      newMeeting.meeting_mode !== "" &&
      newMeeting.meeting_date !== "" &&
      newMeeting.meeting_cut_off_date !== "" &&
      newMeeting.meeting_start_time !== "" &&
      newMeeting.meeting_end_time !== "" &&
      (newMeeting.meeting_mode === "Virtual"
        ? newMeeting.meeting_link !== ""
        : true) &&
      (newMeeting.meeting_mode === "In-Person"
        ? newMeeting.meeting_place !== ""
        : true) &&
      newMeeting.meeting_subject !== "" &&
      (newMeeting.meeting_type === "One-on-One"
        ? newMeeting.member_id !== ""
        : true) &&
      (newMeeting.meeting_type === "Chapter"
        ? newMeeting.chapter_id !== ""
        : true) &&
      newMeeting.organized_by !== "" &&
      newMeeting.meeting_desc !== "" &&
      newMeeting.meeting_charges !== "" &&
      newMeeting.created_by !== ""
    );
  };

  const submitMeeting = () => {
    if (!isFormValid()) {
      setError("Please fill in all mandatory fields.");
      return;
    }

    const meetingSchedule = {
      ...newMeeting,
      organized_by: memberId,
      created_by: memberId,
    };

    const apiUrl =
      newMeeting.meeting_type === "One-on-One"
        ? `${process.env.REACT_APP_API_URL}/meetings/create-meeting`
        : `${process.env.REACT_APP_API_URL}/meetings/create-meeting-by`;

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(meetingSchedule),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(
            `Failed to submit meeting. Status: ${response.status}`
          );
        }
      })
      .then((data) => {
        console.log(data);

        setNewMeeting({
          meeting_mode: "",
          meeting_date: "",
          meeting_cut_off_date: "",
          meeting_start_time: "",
          meeting_end_time: "",
          meeting_link: "",
          meeting_subject: "",
          meeting_type: "Chapter",
          member_id: "",
          chapter_id: chapterId,
          meeting_place: "",
          meeting_desc: "",
          meeting_status: "",
          payment_link: "",
          meeting_charges: "",
          organized_by: `${memberId}`,
          created_by: `${memberId}`,
        });

        setShowMeetingForm(true);
        showSuccess();
      })
      .catch((err) => {
        console.error("Error scheduling meeting:", err);
        setError(
          "An error occurred while scheduling the meeting. Please try again later."
        );
      });
  };

  const toggleMeetingForm = () => {
    setShowMeetingForm(!showMeetingForm);
    if (showMeetingForm) {
      setHeaderText("Schedule a Chapter Meeting");
    } else {
      setHeaderText("Chapter meetings");
    }
  };

  const currentDate = new Date();
  const formattedCurrentDate = format(currentDate, "yyyy-MM-dd");

  return (
    <div id="content-page" className="content-page">
      <Container>
        <Row className="justify-content-center">
          <Col lg={12}>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">
                    <b>
                      {getOrgNames?.alt_chapter || "Chapter"}{" "}
                      {getOrgNames?.alt_meeting || "Meeting"}
                    </b>
                  </h4>
                </div>
                {roleId == 2 && (
                  <div className="d-flex flex-column flex-lg-row justify-content-end">
                    {showMeetingForm && (
                      <>
                        <Button
                          variant="primary"
                          onClick={toggleMeetingForm}
                          className="my-2"
                        >
                          Schedule {getOrgNames?.alt_chapter || "Chapter"}{" "}
                          {getOrgNames?.alt_meeting || "Meeting"}
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </Card.Header>
              <Card.Body className="p-0">
                {showMeetingForm ? (
                  <ChapterMeetingList
                    searchResults={searchResults}
                    alertMessage={alertMessage}
                    getOrgNames={getOrgNames}
                  />
                ) : (
                  <Form className="d-flex flex-column">
                    <div className="p-2">
                      <div className="row">
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meeting"} Mode
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="meeting_mode"
                            value={newMeeting.meeting_mode}
                            onChange={handleInputChange}
                          >
                            <option value="">Select a mode</option>
                            <option value="Virtual">Virtual</option>
                            <option value="In-Person">In-Person</option>
                          </Form.Control>
                        </Form.Group>

                        {newMeeting.meeting_mode === "Virtual" && (
                          <Form.Group className="col-lg-6">
                            <Form.Label>
                              {getOrgNames?.alt_meeting || "Meeting"} Link
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="url"
                              name="meeting_link"
                              value={newMeeting.meeting_link}
                              onChange={handleInputChange}
                              pattern="^(http|https)://\S+$"
                            />
                          </Form.Group>
                        )}
                        {newMeeting.meeting_mode === "In-Person" && (
                          <Form.Group className="col-lg-6">
                            <Form.Label>
                              {getOrgNames?.alt_meeting || "Meeting"} Place
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="meeting_place"
                              value={newMeeting.meeting_place}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        )}
                      </div>
                      <div className="row">
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meeting"} Date
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="meeting_date"
                            value={newMeeting.meeting_date}
                            onChange={handleInputChange}
                            min={formattedCurrentDate}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meeting"} Cut Off Date
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="meeting_cut_off_date"
                            value={newMeeting.meeting_cut_off_date}
                            onChange={handleInputChange}
                            // min={formattedCurrentDate}
                            min={new Date().toISOString().split("T")[0]}
                            max={newMeeting.meeting_date}
                          />
                        </Form.Group>
                      </div>

                      {roleId == 1 && (
                        <div className="row">
                          {newMeeting.meeting_type === "One-on-One" && (
                            <Form.Group className="col-lg-6">
                              <Form.Label>
                                Member<span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="member_id"
                                onChange={handlemembers}
                              >
                                <option value="">Select a member</option>
                                {memberNames.map((name, index) => (
                                  <option key={index} value={name}>
                                    {name}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          )}
                          {newMeeting.meeting_type === "Chapter" && (
                            <Form.Group className="col-lg-6">
                              <Form.Label>
                                {getOrgNames?.alt_chapter || "Chapter"}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                className="form-select"
                                placeholder="Select by Chapter Name"
                                name="chapter_id"
                                value={newMeeting.chapter_id}
                                onChange={handleInputChange}
                              >
                                <option value={chapterId}>{chapterName}</option>
                              </Form.Select>
                            </Form.Group>
                          )}
                        </div>
                      )}
                      <div className="row">
                        {/* Meeting Start Time */}
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meeting"} Start Time
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            value={newMeeting.meeting_start_time}
                            name="meeting_start_time"
                            onChange={handleInputChange}
                          >
                            <option value="">Select Time</option>
                            {timeOptions.map((time) => (
                              <option key={time.value} value={time.value}>
                                {time.label}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        {/* Meeting End Time */}
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meeting"} End Time
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            value={newMeeting.meeting_end_time}
                            name="meeting_end_time"
                            onChange={handleInputChange}
                          >
                            <option value="">Select Time</option>
                            {timeOptions.map((time) => (
                              <option key={time.value} value={time.value}>
                                {time.label}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meeting"} Payment Link
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="payment_link"
                            value={newMeeting.payment_link}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meeting"} Charges
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="meeting_charges"
                            value={newMeeting.meeting_charges}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </div>
                      <Form.Group>
                        <Form.Label>
                          {getOrgNames?.alt_meeting || "Meeting"} Subject
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="meeting_subject"
                          value={newMeeting.meeting_subject}
                          onChange={handleInputChange}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>
                          {getOrgNames?.alt_meeting || "Meeting"} Description
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="meeting_desc"
                          value={newMeeting.meeting_desc}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="danger"
                        onClick={toggleMeetingForm}
                        className="my-2 me-2"
                      >
                        <b>Cancel</b>
                      </Button>
                      <Button
                        variant="primary"
                        onClick={submitMeeting}
                        className="my-2"
                      >
                        <b>Schedule</b>
                      </Button>
                    </div>
                  </Form>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Success Alert */}
      <Alert
        show={showSuccessModal}
        variant="success"
        onClose={closeSuccessModal}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "green",
          width: "300px",
          zIndex: 1000, // Set zIndex to a higher value
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "green", fontSize: "15px" }}
            onClick={closeSuccessModal}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        <p>Your meeting has been scheduled successfully.</p>
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </div>
  );
};

export default ChapterMeeting;
