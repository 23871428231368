import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Alert,
  Modal,
} from "react-bootstrap";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FaTrash, FaEdit, FaTimes } from "react-icons/fa";
import "./customcss/admintabstyle.css";
import useOrgNaming from "../../../hooks/getNamingHook";

const MembershipPlan = () => {
  const [mPlans, setMPlans] = useState([]);
  const [plans, setPlans] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null);
  const token = sessionStorage.getItem("token");
  const member_id = sessionStorage.getItem("member_id");
  const userRole = sessionStorage.getItem("role");
  const [selectedRequest, setselectedRequest] = useState(null);
  const [selectedRequestId, setselectedRequestId] = useState(null);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const org_id = sessionStorage.getItem("org_id");
  const { getOrgNames, loading } = useOrgNaming(org_id, token);
  const [expandedRow, setExpandedRow] = useState(null);
  const [showdelete, setShowdelete] = useState(false);
  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = (mplan_id) => {
    setMemberToDelete(mplan_id);
    setShowdelete(true);
  };
  const [showedit, setShowedit] = useState(false);
  const handleCloseedit = () => setShowedit(false);
  const handleShowedit = (plan) => {
    setselectedRequest(plan);
    setselectedRequestId(plan.mplan_id);
    setShowedit(true);
  };
  const [showeadd, setShowadd] = useState(false);
  const handleCloseadd = () => {
    setShowadd(false);
    setCreateData(initialFormData);
  };
  const handleShoweadd = () => setShowadd(true);
  // Define initial form state
  const initialFormData = {
    membership_plan: "",
    plan_desc: "",
    fee: "",
    membership_benefits: "",
  };
  const [createData, setCreateData] = useState(initialFormData);
  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = () => {
    let endpoint = `${process.env.REACT_APP_API_URL}/api/membership_plans/membership_org_plans/${org_id}`;
    if (userRole === "SUPER_ADMIN") {
      endpoint = `${process.env.REACT_APP_API_URL}/api/membership_plans/membership_plans`;
    }
    fetch(
     endpoint,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => {
        // For SUPER_ADMIN: Filter data to only show org_id === null
        if (userRole === "SUPER_ADMIN") {
          // Filter plans where org_id is null
          const filteredPlans = data.filter((plan) => plan.org_id === null);
          setMPlans(filteredPlans);
        } else {
          // Directly set the plans for other roles
          setMPlans(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  //show the chapter list
  // const fetchOrgNaming = async () => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}/org/getOrgNaming/${org_id}`, {
  //       method: 'GET',
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Error fetching data: ${response.statusText}`);
  //     }

  //     const data = await response.json();
  //     setGetOrgNames(data.result);
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //   }
  // };

  const handleCreateChange = (e) => {
    const { name, value } = e.target;
    setCreateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    createData.created_by = member_id;
    createData.org_id = org_id;
    const { membership_plan, plan_desc, fee, membership_benefits } = createData;

    // Check if membership_plan is filled (required)
    if (!membership_plan) {
      setError("Membership plan is required.");
      return;
    }

    // Check if fee is filled (required)
    if (!fee) {
      setError("Membership fee is required.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/membership_plans/membership_plans`,
        createData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      fetchPlans();
      // Handle success or display a success message
      setShowadd(false);
      handleSuccess("Created Sucessfully");
      setCreateData(initialFormData);
    } catch (error) {
      setError("Error in Create. Please try again later.");
    }
  };

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/membership_plans/membership_plans/${memberToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Handle success or display a success message
      setShowdelete(false);
      handleSuccess("Record is deleted ");
      console.log("Delete Successful:", response.data);
      fetchPlans();
    } catch (error) {
      setError("Error in Delete. Please try again later.");
      console.error("Delete Failed:", error);
    }
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setselectedRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (selectedRequest) {
      selectedRequest.updated_by = member_id;
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/membership_plans/membership_plans/${selectedRequestId}`,
          selectedRequest,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        // Handle success or display a success message
        fetchPlans();
        setShowedit(false);
        handleSuccess("Record is Updated ");
      } catch (error) {
        setError("Error in Update. Please try again later.");
      }
    }
  };

  const toggleExpandRow = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      <b>
                        {getOrgNames?.alt_membership_plan || "Membership Plan"}
                      </b>
                    </h4>
                  </div>
                  <div className="d-flex flex-column flex-lg-row justify-content-end">
                    <Button
                      variant="primary"
                      onClick={handleShoweadd}
                      className="my-2"
                    >
                      Add {getOrgNames?.alt_membership_plan || "Plan"}
                    </Button>
                  </div>
                </Card.Header>
                <Modal centered show={showeadd} onHide={handleCloseadd}>
                  <Modal.Header
                    closeButton
                    style={{ backgroundColor: "#007BFF", color: "white" }}
                  >
                    <Modal.Title>
                      Add New
                      {getOrgNames?.alt_membership_plan || "Membership Plan"}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group className="form-group">
                        <Form.Label
                          htmlFor="membership_plan"
                          className="form-label"
                        >
                          {getOrgNames?.alt_membership_plan ||
                            "Membership Plan"}
                          :
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={createData.membership_plan}
                          id="membership_plan"
                          placeholder="Enter membership plan"
                          onChange={(e) => handleCreateChange(e)}
                          name="membership_plan"
                        />
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label htmlFor="plan_desc" className="form-label">
                          {getOrgNames?.alt_membership_plan || "Plan"}{" "}
                          Description:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={createData.plan_desc}
                          id="plan_desc"
                          placeholder="Enter plan description"
                          onChange={(e) => handleCreateChange(e)}
                          name="plan_desc"
                        />
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label htmlFor="fee" className="form-label">
                          Fees:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={createData.fee}
                          id="fee"
                          placeholder="Enter fees"
                          onChange={(e) => handleCreateChange(e)}
                          name="fee"
                        />
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label
                          htmlFor="membership_benefits"
                          className="form-label"
                        >
                          {getOrgNames?.alt_membership_plan || "Membership Plan"} Benefits:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={createData.membership_benefits}
                          id="membership_benefits"
                          placeholder="Enter membership benefits"
                          onChange={(e) => handleCreateChange(e)}
                          name="membership_benefits"
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseadd}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleCreateSubmit}>
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* </Card>
            </Col> */}

                {/* <Col sm={12}>
              <Card> */}
                <Card.Body style={{ width: "100%" }}>
                  <Table
                    className="mb-0 rounded"
                    style={{ display: "inline-table" }}
                  >
                    <thead className="bg-primary text-white">
                      <tr>
                        <th>
                          <b>{getOrgNames?.alt_membership_plan || "Plan"}</b>
                        </th>
                        {/* <th>
                            <b>Plan Details</b>
                          </th> */}
                        <th>
                          <b>Price</b>
                        </th>
                        <th>
                          <b>Benefits</b>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    {mPlans.length > 0 ? (
                      <tbody>
                        {mPlans.map((plan, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td className="col-lg-3">
                                <b>{plan.membership_plan}</b>
                              </td>

                              <td className="col-lg-3">
                                <b>₹ {plan.fee}</b>
                              </td>
                              <td className="col-lg-3">
                                <b>{plan.membership_benefits}</b>
                              </td>
                              <td className="col-lg-3 text-end">
                                <FaEdit
                                  className="editicon"
                                  onClick={() => handleShowedit(plan)}
                                />
                                {/* Edit Model */}
                                <Modal
                                  centered
                                  show={showedit}
                                  onHide={handleCloseedit}
                                >
                                  <Modal.Header
                                    closeButton
                                    style={{
                                      backgroundColor: "#007BFF",
                                      color: "white",
                                    }}
                                  >
                                    <Modal.Title>
                                      Edit{" "}
                                      {getOrgNames?.alt_membership_plan ||
                                        "Membership Plan"}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    {selectedRequest && (
                                      <Form>
                                        <Form.Group className="form-group col-sm-6">
                                          <Form.Label
                                            htmlFor="membership_plan"
                                            className="form-label"
                                          >
                                            {getOrgNames?.alt_membership_plan ||
                                              "Membership Plan"}
                                            :
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            className="form-control"
                                            value={
                                              selectedRequest.membership_plan
                                            }
                                            id="membership_plan"
                                            placeholder="Enter membership plan"
                                            onChange={(e) =>
                                              handleUpdateChange(e)
                                            }
                                            name="membership_plan"
                                          />
                                        </Form.Group>

                                        <Form.Group className="form-group col-sm-6">
                                          <Form.Label
                                            htmlFor="plan_desc"
                                            className="form-label"
                                          >
                                            {getOrgNames?.alt_membership_plan ||
                                              "Plan"}{" "}
                                            Description:
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            className="form-control"
                                            value={selectedRequest.plan_desc}
                                            id="plan_desc"
                                            placeholder="Enter plan description"
                                            onChange={(e) =>
                                              handleUpdateChange(e)
                                            }
                                            name="plan_desc"
                                          />
                                        </Form.Group>

                                        <Form.Group className="form-group col-sm-6">
                                          <Form.Label
                                            htmlFor="fee"
                                            className="form-label"
                                          >
                                            Fees:
                                          </Form.Label>
                                          <Form.Control
                                            type="number"
                                            className="form-control"
                                            value={selectedRequest.fee}
                                            id="fee"
                                            placeholder="Enter fees"
                                            onChange={(e) =>
                                              handleUpdateChange(e)
                                            }
                                            name="fee"
                                          />
                                        </Form.Group>

                                        <Form.Group className="form-group col-sm-6">
                                          <Form.Label
                                            htmlFor="membership_benefits"
                                            className="form-label"
                                          >
                                            {getOrgNames?.alt_membership_plan || "Membership Plan"} Benefits:
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            className="form-control"
                                            value={
                                              selectedRequest.membership_benefits
                                            }
                                            id="membership_benefits"
                                            placeholder="Enter membership benefits"
                                            onChange={(e) =>
                                              handleUpdateChange(e)
                                            }
                                            name="membership_benefits"
                                          />
                                        </Form.Group>
                                      </Form>
                                    )}
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleCloseedit}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={handleUpdateSubmit}
                                    >
                                      Save Changes
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                                &nbsp; &nbsp;
                                <FaTrash
                                  className="deleteicon"
                                  onClick={() =>
                                    handleShowdelete(plan.mplan_id)
                                  }
                                />
                                {/* Delete Model */}
                                <Modal
                                  centered
                                  show={showdelete}
                                  onHide={handleClosedelete}
                                >
                                  <Modal.Header
                                    closeButton
                                    style={{
                                      backgroundColor: "#007BFF",
                                      color: "white",
                                    }}
                                  >
                                    <Modal.Title>
                                      Delete{" "}
                                      {getOrgNames?.alt_membership_plan ||
                                        "Membership Plan"}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    Are you sure you want to delete this{" "}
                                    {getOrgNames?.alt_membership_plan ||
                                      "Membership Plan"}
                                    ?
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleClosedelete}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={handleDeleteSubmit}
                                    >
                                      Delete
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                                &nbsp;&nbsp;
                                {expandedRow === index ? (
                                  <FiChevronUp
                                    size={30}
                                    onClick={() => toggleExpandRow(index)}
                                  />
                                ) : (
                                  <FiChevronDown
                                    size={30}
                                    onClick={() => toggleExpandRow(index)}
                                  />
                                )}
                              </td>
                            </tr>
                            {expandedRow === index && (
                              <tr className="expanded-row">
                                <td colSpan="5">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <p>
                                        <b>
                                          {getOrgNames?.alt_membership_plan ||
                                            "Plan"}{" "}
                                          Description : &nbsp;
                                        </b>
                                        <b>{plan.plan_desc || "N/A"}</b>
                                      </p>
                                    </div>
                                    {/* <div className="col-lg-6">
                                      <p>
                                        <b>More data </b>
                                      </p>
                                    </div> */}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    ) : (
                      <p>
                        No{" "}
                        {getOrgNames?.alt_membership_plan || "Membership Plan"}{" "}
                        List.
                      </p>
                    )}
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Success Alert */}
      <Alert
        show={successMessages.length > 0}
        variant="success"
        onClose={() => setSuccessMessages([])}
        dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "black",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            onClick={() => setSuccessMessages([])}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        {successMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 9999,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </>
  );
};

export default MembershipPlan;
