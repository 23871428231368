import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useOrgNaming from "../../../../src/hooks/getNamingHook";
const ChapterMeetingSearch = () => {
  const token = sessionStorage.getItem("token");
  const org_id = sessionStorage.getItem("org_id");
  const chapterId = sessionStorage.getItem("chapter_id");
  const { getOrgNames, loading } = useOrgNaming(org_id,token);
  const [filters, setFilters] = useState({
    search_text: "",
    meeting_mode: "",
    organizer_name: "",
  });
  const navigate = useNavigate();
  const [userMeetings, setUserMeetings] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  // Fetch user meetings
  const fetchUserMeetings = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/meetings/oneonone/chapter/all/${org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Directly access data from response
      if (response.data && response.data.imageUrls) {
        setUserMeetings(response.data.imageUrls);
      }
    } catch (err) {
      console.error("Error fetching user meetings:", err);
    }
  };

  // Fetch meetings on component mount
  useEffect(() => {
    fetchUserMeetings();
  }, []);

  // Apply filters whenever filter state changes
  useEffect(() => {
    applyFilters();
  }, [filters]);

  // Apply filters
  const applyFilters = () => {
    const { search_text, meeting_mode, organizer_name } = filters;

    const updatedResults = userMeetings.filter((result) => {
      const isSearchMatch =
        !search_text ||
        (result.organizer_name &&
          result.organizer_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.participant_name &&
          result.participant_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_mode &&
          result.meeting_mode
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_subject &&
          result.meeting_subject
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_desc &&
          result.meeting_desc
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_place &&
          result.meeting_place
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_status &&
          result.meeting_status
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_link &&
          result.meeting_link
            .toLowerCase()
            .includes(search_text.toLowerCase()));

      const isModeMatch =
        !meeting_mode ||

        (result.meeting_mode && result.meeting_mode === meeting_mode);

      // const isOrganizerMatch =
      //   !organizer_name ||
      //   (result.organizer_name &&
      //     result.organizer_name
      //       .toLowerCase()
      //       .includes(organizer_name.toLowerCase()));
      //       const isMeetingTypeChapter = result.meeting_type === "Chapter" && result.chapter_id == chapterId;     

      // New condition: Only allow meetings with meeting_type === 'Chapter'
      //  return isSearchMatch && isModeMatch && isOrganizerMatch && isMeetingTypeChapter;
      return isSearchMatch && isModeMatch;
    });

    setFilteredResults(updatedResults); // Directly set filtered results
  };

  // Handle search and apply filters
  const handleSearch = () => {
    applyFilters();

    if (filteredResults.length === 0) {
      navigate("/dashboards/app/chapter-meeting", {
        state: { alertMessage: "No matching results found!" },
      });
    } else {
      navigate("/dashboards/app/chapter-meeting", {
        state: { searchResults: filteredResults },
      });
    }
  };

  // Handle reset filters
  const handleReset = () => {
    setFilters({
      search_text: "",
      meeting_mode: "",
      organizer_name: "",
    });
    setFilteredResults([]); // Clear filtered results
    navigate("/dashboards/app/chapter-meeting", {
      state: { searchResults: null },
    });
  };

  return (
    <div>
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search here..."
          name="search_text"
          value={filters.search_text}
          onChange={handleInputChange}
        />
      </div>
      <div className="input-group pb-2">
        <select
          className="form-select"
          placeholder="Filter by Mode"
          name="meeting_mode"
          value={filters.meeting_mode}
          onChange={handleInputChange}
        >
          <option value="">Select {getOrgNames?.alt_meeting || "Meeting"} Mode</option>
          <option value="Virtual">Virtual</option>
          <option value="In-Person">In-Person</option>
        </select>
      </div>
      <div className="col-12 text-center pt-2">
        <button className="btn btn-primary" onClick={handleSearch}>
          <b>Search and Apply Filters</b>
        </button>
      </div>
      <div className="col-12 text-center pt-2">
        <button className="btn btn-danger" onClick={handleReset}>
          <b>Reset</b>
        </button>
      </div>
    </div>
  );
};

export default ChapterMeetingSearch;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// const ChapterMeetingSearch = () => {
//   const token = sessionStorage.getItem("token");
//   const chapterId = sessionStorage.getItem("chapter_id");
//   const org_id = sessionStorage.getItem("org_id");

//   const [filters, setFilters] = useState({
//     search_text: "",
//     meeting_mode: "",
//     organizer_name: "",
//   });
//   const navigate = useNavigate();
//   const [userMeetings, setUserMeetings] = useState([]);
//   const [filteredResults, setFilteredResults] = useState([]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
//   };

//   useEffect(() => {
//     // Apply filters when the filters state changes
//     applyFilters();
//   }, [filters]);

//   const fetchUserMeetings = async () => {
//     try {
//       const response = await axios.get(
//         process.env.REACT_APP_API_URL +
//           `/meetings/oneonone/chapter/all/${org_id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       const data = await response.json();
//       if (data && data.imageUrls) {
//         setUserMeetings(data.imageUrls);
//       }
//     } catch (err) {
//       console.error("Error fetching user meetings:", err);
//       // handleSearchError(
//       //   "An error occurred while fetching user meetings. Please try again later."
//       // );
//     }
//   };

//   useEffect(() => {
//     fetchUserMeetings();
//   }, []);

//   const handleSearchError = (errorMessage) => {
//     console.error(errorMessage);
//     // Set the alert message for search error
//     navigate("/dashboards/app/chapter-meeting", {
//       state: { alertMessage: errorMessage },
//     });
//   };

//   const applyFilters = () => {
//     const { search_text, meeting_mode, organizer_name, meeting_type } = filters;
//      

//     const updatedResults = userMeetings.filter((result) => {
//       const isSearchMatch =
//         search_text == "" ||
//         (result.organizer_name &&
//           result.organizer_name.toLowerCase().includes(search_text.toLowerCase())) ||
//         (result.participant_name &&
//           result.participant_name.toLowerCase().includes(search_text.toLowerCase())) ||
//         (result.meeting_mode &&
//           result.meeting_mode
//             .toLowerCase()
//             .includes(search_text.toLowerCase())) ||
//         (result.meeting_subject &&
//           result.meeting_subject
//             .toLowerCase()
//             .includes(search_text.toLowerCase())) ||
//         (result.meeting_desc &&
//           result.meeting_desc
//             .toLowerCase()
//             .includes(search_text.toLowerCase())) ||
//         (result.meeting_place &&
//           result.meeting_place
//             .toLowerCase()
//             .includes(search_text.toLowerCase())) ||
//         (result.meeting_link &&
//           result.meeting_link
//             .toLowerCase()
//             .includes(search_text.toLowerCase()));

//       const isModeMatch =
//         meeting_mode === "" ||
//         (result.meeting_mode && result.meeting_mode === meeting_mode);

//       const isOrganizerMatch =
//         organizer_name === "" ||
//         (result.organizer_name &&
//           result.organizer_name
//             .toLowerCase()
//             .includes(organizer_name.toLowerCase()));

//       return isSearchMatch && isModeMatch && isOrganizerMatch;
//     });

//     setFilteredResults(() => updatedResults); // Use callback function here
//   };

//   const handleSearch = () => {
//     applyFilters();
//     console.log("chapter meeting search results: ", filteredResults);

//     if (filteredResults.length == 0) {
//       // No matching results, set the alert message
//       navigate("/dashboards/app/chapter-meeting", {
//         state: { alertMessage: "No matching results found!" },
//       });
//     } else {
//       // Results found, navigate to the MembersList page with the filtered results
//       navigate("/dashboards/app/chapter-meeting", {
//         state: { searchResults: filteredResults },
//       });
//     }
//   };

//   const handleReset = () => {
//     setFilters({
//       search_text: "",
//       meeting_mode: "",
//       organizer_name: "",
//     });
//     navigate("/dashboards/app/chapter-meeting", {
//       state: { searchResults: null },
//     });
//   };

//   return (
//     <div>
//       <div className="input-group pb-2">
//         <input
//           type="text"
//           className="form-control"
//           placeholder="Search here..."
//           name="search_text"
//           value={filters.search_text}
//           onChange={handleInputChange}
//         />
//       </div>
//       <div className="input-group pb-2">
//         <select
//           className="form-select"
//           placeholder="Filter by Meeting Mode"
//           name="meeting_mode"
//           value={filters.meeting_mode}
//           onChange={handleInputChange}
//         >
//           <option value="">Select Meeting Mode</option>
//           <option value="Virtual">Virtual</option>
//           <option value="In-Person">In-Person</option>
//         </select>
//       </div>

//       {/* <div className="input-group">
//         <input
//           type="text"
//           className="form-control"
//           placeholder="Filter by organised by"
//           name="organizer_name"
//           value={filters.organizer_name}
//           onChange={handleInputChange}
//         />
//       </div> */}

//       <div className="col-12 text-center pt-2">
//         <button className="btn btn-primary" onClick={handleSearch}>
//           <b>Search and Apply Filters</b>
//         </button>
//       </div>
//       <div className="col-12 text-center pt-2">
//         <button className="btn btn-danger" onClick={handleReset}>
//           <b>Reset</b>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ChapterMeetingSearch;
