import React, { useState, useEffect } from "react";
import "./customcss/admintabstyle.css";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Form,
  Alert,
  Tab,
} from "react-bootstrap";
import axios from "axios";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FaTrash, FaEdit, FaTimes } from "react-icons/fa";
import useOrgNaming from "../../../hooks/getNamingHook";

const MemberCategory = () => {
  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null); // State to manage errors
  const memberId = sessionStorage.getItem("member_id");
  const org_id = sessionStorage.getItem("org_id");
  const token = sessionStorage.getItem("token");
  const userRole = sessionStorage.getItem("role");
  const { getOrgNames, loading } = useOrgNaming(org_id, token);
  // Fetch the token from localStorage
  const [memberCategories, setMemberCategories] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [showdelete, setShowdelete] = useState(false);
  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = (mcategory_id) => {
    setMemberToDelete(mcategory_id);
    setShowdelete(true);
  };
  const [showedit, setShowedit] = useState(false);
  const handleCloseedit = () => setShowedit(false);
  const handleShowedit = (category) => {
    setselectedRequest(category);
    setselectedRequestId(category.mcategory_id);
    setShowedit(true);
  };
  const [showeadd, setShowadd] = useState(false);
  const handleCloseadd = () => {
    setShowadd(false);
    setCreateData(initialFormData);
  };
  const handleShoweadd = () => setShowadd(true);
  const [selectedRequest, setselectedRequest] = useState(null);
  const [selectedRequestId, setselectedRequestId] = useState(null);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const initialFormData = {
    member_category: "",
    category_desc: "",
  };
  const [createData, setCreateData] = useState(initialFormData);
  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    fetchMemberCategories();
  }, []);

  const fetchMemberCategories = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let endpoint = `${process.env.REACT_APP_API_URL}/memberCategory/get_org_memberCategory/${org_id}`;
      if (userRole === "SUPER_ADMIN") {
        endpoint = `${process.env.REACT_APP_API_URL}/memberCategory/memberCategory`;
      }

      const response = await axios.get(
        endpoint,
        config
      );
      let data = response.data;
      if (userRole === "SUPER_ADMIN") {
        data = data.filter((request) => request.org_id == null);
        setMemberCategories(data);
      }   
      // const categories = data.map((item) => item.member_category);
      setMemberCategories(data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleCreateChange = (e) => {
    const { name, value } = e.target;
    setCreateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    createData.created_by = memberId;
    createData.org_id = org_id;
    const { member_category, category_desc } = createData;
    if (!member_category) {
      setError("At least member_category  field must be filled.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/memberCategory/memberCategory`,
        createData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      fetchMemberCategories();
      // Handle success or display a success message
      setShowadd(false);
      handleSuccess("New membership category is added ");
      setCreateData(initialFormData);
    } catch (error) {
      setShowadd(false);
      setError(
        "Error in added New membership category. Please try again later."
      );
    }
  };

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/memberCategory/memberCategory/${memberToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Handle success or display a success message
      setShowdelete(false);
      handleSuccess("Record is deleted ");
      fetchMemberCategories();
    } catch (error) {
      setShowdelete(false);
      setError("Error in Delete. Please try again later.");
    }
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setselectedRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (selectedRequest) {
      selectedRequest.updated_by = memberId;
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/memberCategory/memberCategory/${selectedRequestId}`,
          selectedRequest,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        // Handle success or display a success message
        fetchMemberCategories();
        setShowedit(false);
        handleSuccess("Record is Updated ");
      } catch (error) {
        setShowedit(false);
        setError("Error in Update. Please try again later.");
      }
    }
  };

  const toggleExpandRow = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  return (
    <div id="content-page" className="content-page">
      <Container>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">
                    <b>
                      {getOrgNames?.alt_membership_category ||
                        "Member Categories"}
                    </b>
                  </h4>
                </div>
                <div className="d-flex flex-column flex-lg-row justify-content-end">
                  <Button
                    variant="primary"
                    onClick={handleShoweadd}
                    className="my-2"
                  >
                    Add {getOrgNames?.alt_membership_category || "Categories"}
                  </Button>
                </div>
              </Card.Header>
              <Modal centered show={showeadd} onHide={handleCloseadd}>
                <Modal.Header
                  closeButton
                  style={{ backgroundColor: "#007BFF", color: "white" }}
                >
                  <Modal.Title className="text-white">
                    Add{" "}
                    {getOrgNames?.alt_membership_category ||
                      "Membership Categories"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="form-group">
                      <Form.Label
                        htmlFor="member_category"
                        className="form-label"
                      >
                        {getOrgNames?.alt_membership_category ||
                          "Member Categories"}
                        :
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        value={createData.member_category}
                        id="member_category"
                        placeholder="Enter member category "
                        onChange={(e) => handleCreateChange(e)}
                        name="member_category"
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label
                        htmlFor="category_desc"
                        className="form-label"
                      >
                        {getOrgNames?.alt_membership_category || "Category"}{" "}
                        Description:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        value={createData.category_desc}
                        id="category_desc"
                        placeholder="Enter category description"
                        onChange={(e) => handleCreateChange(e)}
                        name="category_desc"
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseadd}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleCreateSubmit}>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* </Card>
          </Col>
        </Row> */}
              {/* <Row>
          <Col xs={12}>
            <Card> */}
              <Card.Body>
                <Table
                  className="mb-0 rounded"
                  style={{ display: "inline-table" }}
                >
                  <thead className="bg-primary text-white">
                    <tr>
                      <th>
                        <b>
                          {getOrgNames?.alt_membership_category || "Categories"}
                        </b>
                      </th>
                      <th></th>
                    </tr>
                  </thead>

                  {/* -----table body code */}
                  <tbody>
                    {memberCategories.map((category, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="col-xs-6">
                            <b>{category.member_category}</b>
                          </td>

                          <td className="col-xs-6 text-end">
                            <FaEdit
                              className="editicon"
                              onClick={() => handleShowedit(category)}
                            />
                            {/* -----edit model  */}
                            <Modal
                              centered
                              show={showedit}
                              onHide={handleCloseedit}
                            >
                              <Modal.Header
                                closeButton
                                style={{
                                  backgroundColor: "#007BFF",
                                  color: "white",
                                }}
                              >
                                <Modal.Title>
                                  Edit{" "}
                                  {getOrgNames?.alt_membership_category ||
                                    "Membership Category"}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {selectedRequest && (
                                  <Form>
                                    <Form.Group className="form-group col-sm-6">
                                      <Form.Label
                                        htmlFor="member_category"
                                        className="form-label"
                                      >
                                        {getOrgNames?.alt_membership_category ||
                                          "Member Categories"}
                                        :
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        value={selectedRequest.member_category}
                                        id="member_category"
                                        placeholder="Enter member category "
                                        onChange={(e) => handleUpdateChange(e)}
                                        name="member_category"
                                      />
                                    </Form.Group>

                                    <Form.Group className="form-group col-sm-6">
                                      <Form.Label
                                        htmlFor="category_desc"
                                        className="form-label"
                                      >
                                        {getOrgNames?.alt_membership_category ||
                                          "Category"}{" "}
                                        Description:
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        value={selectedRequest.category_desc}
                                        id="category_desc"
                                        placeholder="Enter category description"
                                        onChange={(e) => handleUpdateChange(e)}
                                        name="category_desc"
                                      />
                                    </Form.Group>
                                  </Form>
                                )}
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleCloseedit}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={handleUpdateSubmit}
                                >
                                  Save Changes
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            &nbsp;&nbsp;&nbsp;
                            <FaTrash
                              className="deleteicon"
                              onClick={() =>
                                handleShowdelete(category.mcategory_id)
                              }
                            />
                            {/* ----delete model  */}
                            <Modal
                              centered
                              show={showdelete}
                              onHide={handleClosedelete}
                            >
                              <Modal.Header
                                closeButton
                                style={{
                                  backgroundColor: "#007BFF",
                                  color: "white",
                                }}
                              >
                                <Modal.Title>
                                  Delete{" "}
                                  {getOrgNames?.alt_membership_category ||
                                    "Membership Category"}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                Are you sure, you want to delete this{" "}
                                {getOrgNames?.alt_membership_category ||
                                  "Membership Category"}
                                ?
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClosedelete}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={handleDeleteSubmit}
                                >
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            &nbsp;&nbsp;
                            {expandedRow === index ? (
                              <FiChevronUp
                                size={30}
                                onClick={() => toggleExpandRow(index)}
                              />
                            ) : (
                              <FiChevronDown
                                size={30}
                                onClick={() => toggleExpandRow(index)}
                              />
                            )}
                          </td>
                        </tr>

                        {expandedRow === index && (
                          <tr className="expanded-row">
                            <td colSpan="5">
                              <div className="row">
                                <div className="col-lg-12">
                                  <p>
                                    <b>
                                      {getOrgNames?.alt_membership_category ||
                                        "Category"}{" "}
                                      Description :
                                    </b>
                                    &nbsp;
                                    <b>{category.category_desc || "N/A"}</b>
                                  </p>
                                </div>
                                {/* <div className="col-lg-6">
                                  <p>
                                    <b>More data </b>
                                  </p>
                                </div> */}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* Success Alert */}
      <Alert
        show={successMessages.length > 0}
        variant="success"
        onClose={() => setSuccessMessages([])}
        dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "black",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            onClick={() => setSuccessMessages([])}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        {successMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 9999,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </div>
  );
};
export default MemberCategory;
