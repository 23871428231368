import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useOrgNaming from "../../../hooks/getNamingHook";
const MemberSearch = () => {
  const token = sessionStorage.getItem("token");
  const member_id = sessionStorage.getItem("member_id");
  const roleId = sessionStorage.getItem("role_id");
  const org_id = sessionStorage.getItem("org_id");
  const { getOrgNames } = useOrgNaming(org_id, token);
  const [members, setMembers] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search_text: "",
    member_category: "",
    chapter_name: "",
  });
  const [memberCategories, setMemberCategories] = useState([]);
  const [chapterNames, setChapterNames] = useState([]);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  useEffect(() => {
    const fetchMemberCategories = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL +
            `/memberCategory/get_org_memberCategory/${org_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          setMemberCategories(response.data);
          console.log("member category in member search: ", memberCategories);
        } else {
          console.error("Failed to fetch member categories");
        }
      } catch (error) {
        console.error("Error fetching member categories:", error);
      }
    };

    fetchMemberCategories();
  }, [org_id, token]);

  useEffect(() => {
    const fetchChapterNames = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/chapters/orgChapters/${org_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          setChapterNames(response.data);
        } else {
          console.error("Failed to fetch chapter names");
        }
      } catch (error) {
        console.error("Error fetching chapter names:", error);
      }
    };

    fetchChapterNames();
  }, [org_id, token]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/members/members/all/${org_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const filteredMembers = response.data.imageUrls;
        setMembers(filteredMembers);
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchMembers();
  }, [org_id, token, member_id]);

  useEffect(() => {
    applyFilters();
  }, [filters, members]);

  const applyFilters = () => {
    const updatedResults = members.filter((result) => {
      const { search_text, member_category, chapter_name } = filters;

      const isSearchMatch =
        search_text == "" ||
        (result.first_name &&
          result.first_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.last_name &&
          result.last_name.toLowerCase().includes(search_text.toLowerCase())) ||
        // (result.email_id &&
        //   result.email_id.toLowerCase().includes(search_text.toLowerCase())) ||
        (result.member_category &&
          result.member_category
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.chapter_name &&
          result.chapter_name
            .toLowerCase()
            .includes(search_text.toLowerCase()));

      // const isCompanyMatch =
      //   company_name === "" ||
      //   (result.company_name &&
      //     result.company_name
      //       .toLowerCase()
      //       .includes(company_name.toLowerCase()));
      const isMemberCategoryMatch =
        member_category === "" || result.member_category === member_category;
      const isChapterNameMatch =
        chapter_name === "" || result.chapter_name === chapter_name;

      return isSearchMatch && isMemberCategoryMatch && isChapterNameMatch;
    });

    setFilteredResults(updatedResults);
  };

  const handleSearch = () => {
    applyFilters();

    const path =
      roleId == 3 ? "/dashboard/app/MembersList" : "/dashboards/app/members";
    if (filteredResults.length === 0) {
      navigate(path, {
        state: { alertMessage: "No matching results found!" },
      });
    } else {
      navigate(path, {
        state: { searchResults: filteredResults },
      });
    }
  };

  const handleReset = () => {
    setFilters({
      search_text: "",
      company_name: "",
      member_category: "",
      chapter_name: "",
    });
    const path =
      roleId == 3 ? "/dashboard/app/MembersList" : "/dashboards/app/members";
    navigate(path, { state: { searchResults: null } });
  };

  return (
    <div>
      <div className="input-group pb-2">
        <input
          type="text"
          placeholder="Search Members..."
          name="search_text"
          value={filters.search_text}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>
      <div className="row g-2">
        {/* <div className="col-12">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Filter by Company Name"
              name="company_name"
              value={filters.company_name}
              onChange={handleInputChange}
            />
          </div>
        </div> */}
        <div className="col-12">
          <div className="input-group">
            <select
              className="form-select"
              placeholder="Filter by Member Category"
              name="member_category"
              value={filters.member_category}
              onChange={handleInputChange}
            >
              <option value="">
                Select {getOrgNames?.alt_membership_category || "Category"}
              </option>
              {memberCategories.map((category) => (
                <option
                  key={category.member_category}
                  value={category.member_category}
                >
                  {category.member_category}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12">
          <div className="input-group">
            <select
              className="form-select"
              placeholder="Filter by Chapter Name"
              name="chapter_name"
              value={filters.chapter_name}
              onChange={handleInputChange}
            >
              <option value="">
                Select {getOrgNames?.alt_chapter || "Chapter"}
              </option>
              {chapterNames.map((chapter) => (
                <option key={chapter.chapter_name} value={chapter.chapter_name}>
                  {chapter.chapter_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="col-12 text-center pt-2">
        <button
          className="btn btn-primary"
          onClick={handleSearch}
          disabled={loading}
          style={{ backgroundColor: loading ? "#ccc" : "#007bff" }}
        >
          <b>Search and Apply Filters</b>
        </button>
      </div>
      <div className="col-12 text-center pt-2">
        <button className="btn btn-danger" onClick={handleReset}>
          <b>Reset</b>
        </button>
      </div>
    </div>
  );
};

export default MemberSearch;
