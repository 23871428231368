import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Card,
  Dropdown,
} from "react-bootstrap";
import {
  FaTrash,
  FaEdit,
  FaEye,
  FaComment,
  FaEllipsisV,
  FaTimes,
} from "react-icons/fa";
import { MdEventBusy } from "react-icons/md";
import MeetingComment from "./meetingComment";
import EditMeeting from "./editMeeting";
import DeleteMeeting from "./deleteMeeting";
import MembersCard from "./memberCard";

const ProfileMeetings = ({ mId, state, getOrgNames }) => {
  const memberId = sessionStorage.getItem("member_id");
  const token = sessionStorage.getItem("token");
  const [showMembersCard, setShowMembersCard] = useState(false);
  const [userMeetings, setUserMeetings] = useState([]);
  const [memberMeetings, setMemberMeetings] = useState([]);
  const [error, setError] = useState(null);
  const [eyeClickedRow, setEyeClickedRow] = useState(null);
  const [commentRow, setCommentRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [showActionsDropdown, setShowActionsDropdown] = useState(null);
  const [showPreviousMeetings, setShowPreviousMeetings] = useState(false);

  const fetchUserMeetings = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/meetings/oneonone-meeting/user/${mId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 502) {
        // Don't throw error for 502 status code
        console.error(
          "Bad Gateway error occurred while fetching sent referrals"
        );
        return;
      }
      if (!response.ok) {
        throw Error("Failed to fetch user meetings");
      }
      const data = await response.json();
      if (data && data.results) {
                setUserMeetings(data.results);
      }
    } catch (err) {
      console.error("Error fetching user meetings:", err);
      // setError(
      //   "An error occurred while fetching user meetings. Please try again later."
      // );
    }
  };

  const fetchMemberMeetings = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/meetings/one-one-one/member/${mId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 502) {
        // Don't throw error for 502 status code
        console.error(
          "Bad Gateway error occurred while fetching sent referrals"
        );
        return;
      }
      if (!response.ok) {
        throw Error("Failed to fetch member meetings");
      }
      const data = await response.json();
      if (data && data.results) {
        setMemberMeetings(data.results);
      }
    } catch (err) {
      console.error("Error fetching member meetings:", err);
      // setError(
      //   "An error occurred while fetching member meetings. Please try again later."
      // );
    }
  };

  useEffect(() => {
    const fetchMeetingsData = async () => {
      fetchUserMeetings();
      fetchMemberMeetings();
    };

    // Fetch initial data
    fetchMeetingsData();

    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchMeetingsData();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [mId, refreshNeeded, token]);

  // useEffect(() => {
  //   fetchUserMeetings();
  //   fetchMemberMeetings();
  // }, [uId, mId, refreshNeeded, token]);

 // Combine meetings and remove duplicates using Map for unique meeting_ids
const combinedMeetings = [...userMeetings, ...memberMeetings];

// Create a map to ensure meetings are unique by their meeting_id
const meetingsMap = new Map();
combinedMeetings.forEach(meeting => {
  if (!meetingsMap.has(meeting.meeting_id)) {
    meetingsMap.set(meeting.meeting_id, meeting);
  }
});

// Convert map back to an array
const uniqueMeetings = Array.from(meetingsMap.values());

// Filter based on memberId and uId
const filteredMeetings = memberId == mId
  ? uniqueMeetings
  : uniqueMeetings.filter((meeting) => {
      const condition1 =
        meeting.created_by == parseInt(memberId, 10) &&
        meeting.member_id == parseInt(mId, 10);
      const condition2 =
        meeting.created_by == parseInt(mId, 10) &&
        meeting.member_id == parseInt(memberId, 10);

      return condition1 || condition2;
    });

// Ensure meetingsToDisplay uses uniqueMeetings (not combinedMeetings)
const meetingsToDisplay =
  state == memberId ? uniqueMeetings : filteredMeetings;

// Sort meetings to display
const sortedMeetingsToDisplay = meetingsToDisplay.slice().sort((a, b) => {
  const dateA = new Date(a.meeting_date);
  const dateB = new Date(b.meeting_date);

  return dateA - dateB;
});

// Filter previous and upcoming meetings
const previousMeetings = sortedMeetingsToDisplay.filter(
  (meeting) => meeting.meeting_type === "One-on-One"
);

const upcomingMeetings = sortedMeetingsToDisplay.filter(
  (meeting) => meeting.meeting_type === "Chapter"
);


  const toggleEyeDetails = (index) => {
    setEyeClickedRow((prev) => (prev === index ? null : index));
  };

  const toggleCommentSection = (index) => {
    setCommentRow((prev) => (prev === index ? null : index));
  };

  const toggleEditSection = (index) => {
    setEditRow((prev) => (prev === index ? null : index));
  };

  const toggleDeleteSection = (index) => {
    setDeleteRow((prev) => (prev === index ? null : index));
  };

  const toggleActionsDropdown = (index) => {
    setShowActionsDropdown((prev) => (prev === index ? null : index));
  };

  const handleDocumentClick = (event) => {
    // Check if the click occurred outside the expanded rows
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    // Check if the click occurred inside any expanded row
    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    // Close expanded rows if the click occurred outside
    if (!clickedInsideExpandedRow) {
      setEyeClickedRow(null);
      setCommentRow(null);
      setEditRow(null);
      setDeleteRow(null);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [eyeClickedRow, commentRow, editRow, deleteRow]);

  // Function to convert time from 24-hour to 12-hour format
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);
  
    if (formattedHour === 0) {
      formattedHour = 12;  // Midnight case (00:00 to 12:00 AM)
    } else if (formattedHour >= 12) {
      period = "PM";
      if (formattedHour > 12) {
        formattedHour -= 12;
      }
    }
  
    return `${formattedHour}:${minute} ${period}`;
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  {/* Display toggle for previous meetings */}
                  <div
                    onClick={() =>
                      setShowPreviousMeetings(!showPreviousMeetings)
                    }
                    style={{ cursor: "pointer", fontSize: "15px" }}
                    className="text-center text-primary"
                  >
                    <u>
                      {showPreviousMeetings
                        ? ` View chapter ${getOrgNames?.alt_meeting || "Meeting"}`
                        : `View member ${getOrgNames?.alt_meeting || "Meeting"}`}
                    </u>
                  </div>
                  <div>
                    <Table className="mb-0 rounded">
                      <thead className="bg-primary text-white">
                        <tr>
                          <th>
                            <b>Date</b>
                          </th>
                          <th>
                            <b>Status</b>
                          </th>
                          <th>
                            <b>Subject</b>
                          </th>
                          <th>
                            <b></b>
                          </th>
                        </tr>
                      </thead>
                      {meetingsToDisplay.length > 0 ? (
                        <tbody>
                          {showPreviousMeetings
                            ? previousMeetings.map((meeting, index) => (
                                <React.Fragment key={meeting.meeting_id}>
                                  <tr
                                    // style={{
                                    //   opacity:
                                    //     meeting.meeting_status === "cancelled"
                                    //       ? 0.5
                                    //       : 1,
                                    // }}
                                    style={{
                                      backgroundColor:
                                        new Date(meeting.meeting_date) <
                                        new Date().setHours(0, 0, 0, 0)
                                          ? "rgba(211, 211, 211, 0.5)"
                                          : "transparent",
                                      color:
                                        new Date(meeting.meeting_date) <
                                        new Date().setHours(0, 0, 0, 0)
                                          ? "darkgray"
                                          : "black",
                                    }}
                                  >
                                    <td>
                                      <b>
                                        {new Date(meeting.meeting_date)
                                          .getDate()
                                          .toString()
                                          .padStart(2, "0")}
                                        /
                                        {(
                                          new Date(
                                            meeting.meeting_date
                                          ).getMonth() + 1
                                        )
                                          .toString()
                                          .padStart(2, "0")}
                                        /
                                        {new Date(
                                          meeting.meeting_date
                                        ).getFullYear()}
                                      </b>
                                    </td>
                                    <td>
                                      <b>
                                        {meeting.meeting_status === "Schedule"
                                          ? "Scheduled"
                                          : meeting.meeting_status}
                                      </b>
                                    </td>
                                    <td>
                                      <b>{meeting.meeting_subject}</b>
                                    </td>
                                    <td>
                                      <div className="d-lg-none">
                                        <Dropdown
                                          show={showActionsDropdown === index}
                                          onSelect={() =>
                                            setShowActionsDropdown(null)
                                          }
                                        >
                                          <Dropdown.Toggle
                                            variant="link"
                                            id={`dropdown-basic-${index}`}
                                            style={{
                                              fontSize: "1.5rem",
                                              color: "skyblue",
                                            }}
                                          >
                                            <FaEllipsisV
                                              className="ellipsis-icon"
                                              onClick={() =>
                                                toggleActionsDropdown(index)
                                              }
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              onClick={() =>
                                                toggleEyeDetails(index)
                                              }
                                            >
                                              <FaEye
                                                className="viewicon"
                                                size={13}
                                              />{" "}
                                              View
                                            </Dropdown.Item>
                                            {meeting.meeting_status !==
                                              "cancelled" &&
                                              meeting.meeting_type ===
                                                "One-on-One" &&
                                              !(
                                                new Date(meeting.meeting_date) <
                                                new Date().setHours(0, 0, 0, 0)
                                              ) && (
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    toggleCommentSection(index)
                                                  }
                                                >
                                                  <FaComment
                                                    className="commenticon"
                                                    size={13}
                                                  />{" "}
                                                  Comment
                                                </Dropdown.Item>
                                              )}
                                            {memberId == meeting.created_by &&
                                              meeting.meeting_status !=
                                                "cancelled" &&
                                              meeting.meeting_type ==
                                                "One-on-One" &&
                                              !(
                                                new Date(meeting.meeting_date) <
                                                new Date().setHours(0, 0, 0, 0)
                                              ) && (
                                                <>
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      toggleEditSection(index)
                                                    }
                                                  >
                                                    <FaEdit
                                                      className="editicon"
                                                      size={13}
                                                    />{" "}
                                                    Edit
                                                  </Dropdown.Item>
                                                  {/* <Dropdown.Item
                                                    onClick={() =>
                                                      toggleDeleteSection(index)
                                                    }
                                                  >
                                                    <MdEventBusy
                                                      className="deleteicon"
                                                      size={15}
                                                    />{" "}
                                                    Cancel
                                                  </Dropdown.Item> */}
                                                </>
                                              )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                      <div className="d-none d-lg-block">
                                        <FaEye
                                          className="viewicon"
                                          size={15}
                                          color="#007BFF"
                                          onClick={() =>
                                            toggleEyeDetails(index)
                                          }
                                        />
                                        &nbsp;&nbsp;
                                        {meeting.meeting_status !=
                                          "cancelled" &&
                                          !(
                                            new Date(meeting.meeting_date) <
                                            new Date().setHours(0, 0, 0, 0)
                                          ) && (
                                            <FaComment
                                              className="commenticon"
                                              size={15}
                                              color="#007BFF"
                                              onClick={() =>
                                                toggleCommentSection(index)
                                              }
                                            />
                                          )}
                                        &nbsp;&nbsp;
                                        {memberId == meeting.created_by &&
                                          meeting.meeting_status !=
                                            "cancelled" &&
                                          !(
                                            new Date(meeting.meeting_date) <
                                            new Date().setHours(0, 0, 0, 0)
                                          ) && (
                                            <>
                                              <FaEdit
                                                className="editicon"
                                                size={15}
                                                color="#007BFF"
                                                onClick={() =>
                                                  toggleEditSection(index)
                                                }
                                              />
                                              &nbsp;&nbsp;
                                              {/* <MdEventBusy
                                                className="deleteicon"
                                                size={17}
                                                color="red"
                                                onClick={() =>
                                                  toggleDeleteSection(index)
                                                }
                                              /> */}
                                            </>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                  {commentRow === index && (
                                    <tr className="expanded-row">
                                      <td
                                        colSpan="4"
                                        style={{ position: "relative" }}
                                      >
                                        <div
                                          className="close-icon"
                                          onClick={() => setCommentRow(null)}
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "20px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          &#10006;
                                        </div>
                                        <MeetingComment
                                          meetingId={meeting.meeting_id}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  {eyeClickedRow === index && (
                                    <tr className="expanded-row">
                                      <td
                                        colSpan="4"
                                        style={{ position: "relative" }}
                                      >
                                        <div
                                          className="close-icon"
                                          onClick={() => setEyeClickedRow(null)}
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "20px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          &#10006;
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <p>
                                              <b>
                                                Member:{" "}
                                                {meeting.attendee_first_name}{" "}
                                                {meeting.attendee_last_name}
                                              </b>
                                            </p>
                                            <p>
                                              <b>
                                                Created By:{" "}
                                                {meeting.creator_first_name}{" "}
                                                {meeting.creator_last_name}
                                              </b>
                                            </p>
                                            <p>
                                              <b>
                                                Mode: {meeting.meeting_mode}
                                              </b>
                                            </p>
                                            <p>
                                              <b>
                                                {meeting.meeting_mode ===
                                                "Virtual" ? (
                                                  <div>
                                                    <p>
                                                      Meeting Link:{" "}
                                                      {meeting.meeting_link ||
                                                        "N/A"}
                                                    </p>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <p>
                                                      Meeting Place:{" "}
                                                      {meeting.meeting_place ||
                                                        "N/A"}
                                                    </p>
                                                  </div>
                                                )}
                                              </b>
                                            </p>
                                          </div>
                                          <div className="col-lg-6">
                                            <p>
                                              <b>
                                                Start Time:{" "}
                                                {formatTime(
                                                  meeting.meeting_start_time
                                                )}
                                              </b>
                                            </p>
                                            <p>
                                              <b>
                                                End Time:{" "}
                                                {formatTime(
                                                  meeting.meeting_end_time
                                                )}
                                              </b>
                                            </p>
                                            <p>
                                              <b>
                                                Description:{" "}
                                                {meeting.meeting_desc}
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}

                                  {editRow === index && (
                                    <tr className="expanded-row">
                                      <td
                                        colSpan="4"
                                        style={{ position: "relative" }}
                                      >
                                        <div
                                          className="close-icon"
                                          onClick={() => setEditRow(null)}
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "20px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          &#10006;
                                        </div>
                                        <EditMeeting
                                          meetingId={meeting.meeting_id}
                                          onClose={() => setEditRow(null)}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  {deleteRow === index && (
                                    <tr className="expanded-row">
                                      <td
                                        colSpan="4"
                                        style={{ position: "relative" }}
                                      >
                                        <div
                                          className="close-icon"
                                          onClick={() => setDeleteRow(null)}
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "20px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          &#10006;
                                        </div>
                                        <DeleteMeeting
                                          meetingId={meeting.meeting_id}
                                          onClose={() => setDeleteRow(null)}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))
                            : upcomingMeetings.map((meeting, index) => (
                                <React.Fragment key={index}>
                                  <tr
                                    // style={{
                                    //   opacity:
                                    //     meeting.meeting_status === "cancelled"
                                    //       ? 0.5
                                    //       : 1,
                                    // }}
                                    style={{
                                      backgroundColor:
                                        new Date(meeting.meeting_date) <
                                        new Date().setHours(0, 0, 0, 0)
                                          ? "rgba(211, 211, 211, 0.5)"
                                          : "transparent",
                                      color:
                                        new Date(meeting.meeting_date) <
                                        new Date().setHours(0, 0, 0, 0)
                                          ? "darkgray"
                                          : "black",
                                    }}
                                  >
                                    <td className="col-lg-3">
                                      <b>
                                        {new Date(meeting.meeting_date)
                                          .getDate()
                                          .toString()
                                          .padStart(2, "0")}
                                        /
                                        {(
                                          new Date(
                                            meeting.meeting_date
                                          ).getMonth() + 1
                                        )
                                          .toString()
                                          .padStart(2, "0")}
                                        /
                                        {new Date(
                                          meeting.meeting_date
                                        ).getFullYear()}
                                      </b>
                                    </td>
                                    <td className="col-lg-3">
                                      <b>{meeting.meeting_status}</b>
                                    </td>
                                    <td className="col-lg-3">
                                      <b>{meeting.meeting_subject}</b>
                                    </td>
                                    <td className="col-lg-3">
                                      <div className="d-lg-none">
                                        <Dropdown
                                          show={showActionsDropdown === index}
                                          onSelect={() =>
                                            setShowActionsDropdown(null)
                                          }
                                        >
                                          <Dropdown.Toggle
                                            variant="link"
                                            id={`dropdown-basic-${index}`}
                                            style={{
                                              fontSize: "1.5rem",
                                              color: "skyblue",
                                            }}
                                          >
                                            <FaEllipsisV
                                              className="ellipsis-icon"
                                              onClick={() =>
                                                toggleActionsDropdown(index)
                                              }
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              onClick={() =>
                                                toggleEyeDetails(index)
                                              }
                                            >
                                              <FaEye
                                                className="viewicon"
                                                size={13}
                                              />{" "}
                                              View
                                            </Dropdown.Item>
                                            {meeting.meeting_status !=
                                              "cancelled" &&
                                              !(
                                                new Date(meeting.meeting_date) <
                                                new Date().setHours(0, 0, 0, 0)
                                              ) && (
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    toggleCommentSection(index)
                                                  }
                                                >
                                                  <FaComment
                                                    className="commenticon"
                                                    size={13}
                                                  />{" "}
                                                  Comment
                                                </Dropdown.Item>
                                              )}
                                            {/* {memberId == meeting.created_by &&
                                              meeting.meeting_status !=
                                                "cancelled" && (
                                                <>
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      toggleEditSection(index)
                                                    }
                                                  >
                                                    <FaEdit
                                                      className="editicon"
                                                      size={13}
                                                    />{" "}
                                                    Edit
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      toggleDeleteSection(index)
                                                    }
                                                  >
                                                    <MdEventBusy
                                                      className="deleteicon"
                                                      size={15}
                                                    />{" "}
                                                    Cancel
                                                  </Dropdown.Item>
                                                </>
                                              )} */}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                      <div className="d-none d-lg-block">
                                        <FaEye
                                          className="viewicon"
                                          size={15}
                                          color="#007BFF"
                                          onClick={() =>
                                            toggleEyeDetails(index)
                                          }
                                        />
                                        &nbsp;&nbsp;
                                        {meeting.meeting_status !=
                                          "cancelled" &&
                                          !(
                                            new Date(meeting.meeting_date) <
                                            new Date().setHours(0, 0, 0, 0)
                                          ) && (
                                            <FaComment
                                              className="commenticon"
                                              size={15}
                                              color="#007BFF"
                                              onClick={() =>
                                                toggleCommentSection(index)
                                              }
                                            />
                                          )}
                                        &nbsp;&nbsp;
                                        {/* {memberId == meeting.created_by &&
                                          meeting.meeting_status !=
                                            "cancelled" && (
                                            <>
                                              <FaEdit
                                                className="editicon"
                                                size={15}
                                                color="#007BFF"
                                                onClick={() =>
                                                  toggleEditSection(index)
                                                }
                                              />
                                              &nbsp;&nbsp;
                                              <MdEventBusy
                                                className="deleteicon"
                                                size={17}
                                                color="red"
                                                onClick={() =>
                                                  toggleDeleteSection(index)
                                                }
                                              />
                                            </>
                                          )} */}
                                      </div>
                                    </td>
                                  </tr>
                                  {eyeClickedRow === index && (
                                    <tr className="expanded-row">
                                      <td
                                        colSpan="4"
                                        style={{ position: "relative" }}
                                      >
                                        <div
                                          className="close-icon"
                                          onClick={() => setEyeClickedRow(null)}
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "20px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          &#10006;
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <p>
                                              <b>
                                                <b>
                                                  Members:{" "}
                                                  <button
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                      setShowMembersCard(
                                                        meeting.chapter_id
                                                      )
                                                    }
                                                  >
                                                    View Members
                                                  </button>
                                                  {showMembersCard && (
                                                    <MembersCard
                                                      chapterId={
                                                        showMembersCard
                                                      }
                                                      onClose={() =>
                                                        setShowMembersCard(
                                                          false
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </b>
                                              </b>
                                            </p>
                                            <p>
                                              <b>
                                                Created By:{" "}
                                                {meeting.creator_first_name}{" "}
                                                {meeting.creator_last_name}
                                              </b>
                                            </p>
                                            <p>
                                              <b>
                                                Mode: {meeting.meeting_mode}
                                              </b>
                                            </p>
                                            <p>
                                              <b>
                                                {meeting.meeting_mode ===
                                                "Virtual" ? (
                                                  <div>
                                                    <p>
                                                      online Link:{" "}
                                                      <a
                                                        href={
                                                          meeting.meeting_link
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        Click here
                                                        {/* {meeting.meeting_link} */}
                                                      </a>
                                                    </p>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <p>
                                                      Place:{" "}
                                                      {meeting.meeting_place ||
                                                        "N/A"}
                                                    </p>
                                                  </div>
                                                )}
                                              </b>
                                            </p>
                                            {meeting.meeting_type ===
                                              "Chapter" && (
                                              <p>
                                                <b>
                                                  Payment Link:{" "}
                                                  <a
                                                    href={
                                                      meeting.meeting_payment_link
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    click here
                                                    {/* {meeting.meeting_payment_link} */}
                                                  </a>
                                                </b>
                                              </p>
                                            )}
                                          </div>
                                          <div className="col-lg-6">
                                            <p>
                                              <b>
                                                Start Time:{" "}
                                                {formatTime(
                                                  meeting.meeting_start_time
                                                )}
                                              </b>
                                            </p>
                                            <p>
                                              <b>
                                                End Time:{" "}
                                                {formatTime(
                                                  meeting.meeting_end_time
                                                )}
                                              </b>
                                            </p>
                                            <p>
                                              <b>
                                                Description:{" "}
                                                {meeting.meeting_desc}
                                              </b>
                                            </p>
                                            <p>
                                              <b>
                                                Status: {meeting.meeting_status}
                                              </b>
                                            </p>
                                            {meeting.meeting_type ===
                                              "Chapter" && (
                                              <p>
                                                <b>
                                                  Meeting Charges:{" "}
                                                  {meeting.meeting_charges}
                                                </b>
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {commentRow === index && (
                                    <tr className="expanded-row">
                                      <td
                                        colSpan="4"
                                        style={{ position: "relative" }}
                                      >
                                        <div
                                          className="close-icon"
                                          onClick={() => setCommentRow(null)}
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "20px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          &#10006;
                                        </div>
                                        <MeetingComment
                                          meetingId={meeting.meeting_id}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  {editRow === index && (
                                    <tr className="expanded-row">
                                      <td
                                        colSpan="4"
                                        style={{ position: "relative" }}
                                      >
                                        <div
                                          className="close-icon"
                                          onClick={() => setEditRow(null)}
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "20px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          &#10006;
                                        </div>
                                        <EditMeeting
                                          meetingId={meeting.meeting_id}
                                          onClose={() => setEditRow(null)}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  {deleteRow === index && (
                                    <tr className="expanded-row">
                                      <td
                                        colSpan="4"
                                        style={{ position: "relative" }}
                                      >
                                        <div
                                          className="close-icon"
                                          onClick={() => setDeleteRow(null)}
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "20px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          &#10006;
                                        </div>
                                        <DeleteMeeting
                                          meetingId={meeting.meeting_id}
                                          onClose={() => setDeleteRow(null)}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                        </tbody>
                      ) : (
                        <p>No meetings available.</p>
                      )}
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={() => setError(null)}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={() => setError(null)}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </>
  );
};

export default ProfileMeetings;
