import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Alert,
} from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { format } from "date-fns";
import { FaTimes } from "react-icons/fa";

const MemberMeeting = ({ onClose, memberId, state, getOrgNames }) => {
  const mId = sessionStorage.getItem("member_id");
  const userName = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const chapterId = sessionStorage.getItem("chapter_id");
  const chapterName = sessionStorage.getItem("chapter_name");

  const [members, setMembers] = useState([]);
  const [chapterNames, setChapterNames] = useState([]);
  const [memberNames, setMemberNames] = useState([]);
  const [newMeeting, setNewMeeting] = useState({
    meeting_mode: "",
    meeting_date: "",
    meeting_start_time: "",
    meeting_end_time: "",
    meeting_link: "",
    meeting_subject: "",
    meeting_type: "One-on-One",
    member_id: memberId == mId ? "" : memberId,
    chapter_id: `${chapterId}`,
    meeting_place: "",
    meeting_desc: "",
    organized_by: `${mId}`,
    created_by: `${mId}`,
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [error, setError] = useState(null);
  const [showMeetingForm, setShowMeetingForm] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const org_id = sessionStorage.getItem("org_id");
  const resetForm = () => {
    setNewMeeting({
      meeting_mode: "",
      meeting_date: "",
      meeting_start_time: "",
      meeting_end_time: "",
      meeting_link: "",
      meeting_subject: "",
      meeting_type: "One-on-One",
      member_id: memberId == mId ? "" : memberId,
      chapter_id: `${chapterId}`,
      meeting_place: "",
      meeting_desc: "",
      organized_by: `${mId}`,
      created_by: `${mId}`,
    });
  };

  const fetchMembers = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/members/members/org/meeting/${chapterId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw Error("Failed to fetch members");
      }
      const data = await response.json();
      console.log("filteredMembers-->",data);

      // Filter out records where member_id equals mId
      const filteredMembers = data.imageUrls.filter(
        (member) => member.member_id != mId
      );

      setMembers(filteredMembers);
    } catch (err) {
      console.error("Error fetching members:", err);
      // setError(
      //   "An error occurred while fetching members. Please try again later."
      // );
    }
  };

  // useEffect(() => {
  //   // Fetch initial data
  //   fetchMembers();

  //   // Poll for updates every 5 seconds (adjust as needed)
  //   const intervalId = setInterval(() => {
  //     fetchMembers();
  //   }, 5000);

  //   // Cleanup interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    // Fetch initial data
    fetchMembers();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setNewMeeting((prevMeeting) => ({
      ...prevMeeting,
      [name]: value,
    }));
  };

  const showSuccess = () => {
    setShowSuccessModal(true);
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    handleClose();
  };

  const clearError = () => {
    setError(null);
  };

  const isFormValid = () => {
    return (
      newMeeting.meeting_mode !== "" &&
      newMeeting.meeting_date !== "" &&
      newMeeting.meeting_start_time !== "" &&
      newMeeting.meeting_end_time !== "" &&
      (newMeeting.meeting_mode === "Virtual"
        ? newMeeting.meeting_link !== ""
        : true) &&
      (newMeeting.meeting_mode === "In-Person"
        ? newMeeting.meeting_place !== ""
        : true) &&
      newMeeting.meeting_subject !== "" &&
      (newMeeting.meeting_type === "One-on-One"
        ? newMeeting.member_id !== ""
        : true) &&
      newMeeting.chapter_id !== "" &&
      newMeeting.organized_by !== "" &&
      newMeeting.meeting_desc !== "" &&
      newMeeting.created_by !== ""
    );
  };

  const submitMeeting = () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    if (!isFormValid()) {
      setError("Please fill in all mandatory fields.");
      setIsSubmitting(false);
      return;
    }

    const meetingSchedule = {
      ...newMeeting,
      organized_by: mId,
      created_by: mId,
    };

    const apiUrl =
      newMeeting.meeting_type === "One-on-One"
        ? `${process.env.REACT_APP_API_URL}/meetings/create-meeting`
        : `${process.env.REACT_APP_API_URL}/meetings/create-meeting-by`;

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(meetingSchedule),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to submit meeting. Status: ${response.status}`
          );
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);

        // Check if the response is successful before updating state
        if (data) {
          setNewMeeting({
            meeting_mode: "",
            meeting_date: "",
            meeting_start_time: "",
            meeting_end_time: "",
            meeting_link: "",
            meeting_subject: "",
            meeting_type: "One-on-One",
            member_id: memberId == mId ? "" : memberId,
            organized_by: `${mId}`,
            chapter_id: `${chapterId}`,
            meeting_place: "",
            meeting_desc: "",
            created_by: `${mId}`,
          });

          setShowMeetingForm(true);
          showSuccess();
        } else {
          // Handle unsuccessful response, if needed
          console.error("Error in response:", data.error);
          setError(
            "An error occurred while scheduling the meeting. Please try again later."
          );
        }
      })
      .catch((err) => {
        console.error("Error scheduling meeting:", err);
        console.log("meeting data: ", newMeeting);
        setError(
          "An error occurred while scheduling the meeting. Please try again later."
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleClose = () => {
    onClose(); // Call the onClose callback passed from the parent
  };

  const currentDate = new Date();
  const formattedCurrentDate = format(currentDate, "yyyy-MM-dd");

  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col lg={12}>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <h3>Schedule a meeting</h3>
                <div className="d-flex flex-column flex-lg-row justify-content-end">
                  <AiOutlineClose
                    onClick={handleClose}
                    style={{ cursor: "pointer", fontSize: "20px" }}
                  />
                </div>
              </Card.Header>
              <Card.Body>
                <Form className="d-flex flex-column px-3">
                  <div className="p-3">
                    <div className="row">
                      <Form.Group className="col-lg-6">
                        <Form.Label>
                          {getOrgNames?.alt_meeting || "Meeting"} Mode<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="meeting_mode"
                          value={newMeeting.meeting_mode}
                          onChange={handleInputChange}
                        >
                          <option value="">Select a mode</option>
                          <option value="Virtual">Virtual</option>
                          <option value="In-Person">In-Person</option>
                        </Form.Control>
                      </Form.Group>
                      {newMeeting.meeting_mode === "Virtual" && (
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meeting"} Link<span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="url"
                            name="meeting_link"
                            value={newMeeting.meeting_link}
                            onChange={handleInputChange}
                            pattern="^(http|https)://\S+$"
                          />
                        </Form.Group>
                      )}
                      {newMeeting.meeting_mode === "In-Person" && (
                        <Form.Group className="col-lg-6">
                          <Form.Label>
                            {getOrgNames?.alt_meeting || "Meeting"} Place<span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="meeting_place"
                            value={newMeeting.meeting_place}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      )}
                    </div>
                    <div className="row">
                      <Form.Group className="col-lg-6">
                        <Form.Label>
                          {getOrgNames?.alt_meeting || "Meeting"} Date<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          name="meeting_date"
                          value={newMeeting.meeting_date}
                          onChange={handleInputChange}
                          min={formattedCurrentDate}
                        />
                      </Form.Group>
                      <Form.Group className="col-lg-6">
                        <Form.Label>
                          {getOrgNames?.alt_meeting || "Meeting"} Subject<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="meeting_subject"
                          value={newMeeting.meeting_subject}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>

                    <div className="row">
                      <Form.Group className="col-lg-6">
                        <Form.Label>
                          {getOrgNames?.alt_meeting || "Meeting"} Start Time
                          <span className="text-danger">*</span>
                        </Form.Label>

                        <Form.Control
                          type="time"
                          name="meeting_start_time"
                          value={newMeeting.meeting_start_time}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                      <Form.Group className="col-lg-6">
                        <Form.Label>
                          {getOrgNames?.alt_meeting || "Meeting"} End Time<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="time"
                          name="meeting_end_time"
                          value={newMeeting.meeting_end_time}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    {state == mId && (
                      <div className="row">
                        {newMeeting.meeting_type === "One-on-One" &&
                          state == mId && (
                            <Form.Group className="col-lg-12">
                              <Form.Label>
                                Member<span className="text-danger">*</span>
                              </Form.Label>
                              <Select
                                name="referred_to"
                                value={
                                  members
                                    .map((member) => ({
                                      value: member.member_id,
                                      label: `${member.first_name} ${member.last_name}`,
                                    }))
                                    .find((option) => option.value === newMeeting.member_id) || null
                                }
                                onChange={(selectedOption) =>
                                  setNewMeeting({
                                    ...newMeeting,
                                    member_id: selectedOption ? selectedOption.value : "",
                                  })
                                }
                                options={members.map((member) => ({
                                  value: member.member_id,
                                  label: `${member.first_name} ${member.last_name}`,
                                }))}
                                isSearchable
                                placeholder="Select a member"
                                isClearable
                              />
                              {/* <Form.Control
                                as="select"
                                name="member_id"
                                value={newMeeting.member_id}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="">Select a Member</option>
                                {members.map((member) => (
                                  <option
                                    key={member.member_id}
                                    value={member.member_id}
                                  >
                                    {member.first_name} {member.last_name}
                                  </option>
                                ))}
                              </Form.Control> */}
                            </Form.Group>
                          )}
                        {newMeeting.meeting_type === "Chapter" && (
                          <Form.Group className="col-lg-6">
                            <Form.Label>
                              Chapter<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              className="form-select"
                              placeholder="Select by Chapter Name"
                              name="chapter_id"
                              value={newMeeting.chapter_id}
                              onChange={handleInputChange}
                            >
                              <option value={chapterId}>{chapterName}</option>
                            </Form.Select>
                          </Form.Group>
                        )}
                      </div>
                    )}

                    <Form.Group>
                      <Form.Label>
                        {getOrgNames?.alt_meeting || "Meeting"} Description
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="meeting_desc"
                        value={newMeeting.meeting_desc}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="danger"
                      onClick={resetForm}
                      className="my-2 me-2"
                    >
                      <b>Reset</b>
                    </Button>
                    <Button
                      variant="primary"
                      onClick={submitMeeting}
                      className="my-2"
                      disabled={isSubmitting}
                    >
                      <b>Schedule</b>
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Success Alert */}
      <Alert
        show={showSuccessModal}
        variant="success"
        onClose={closeSuccessModal}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "green",
          width: "300px",
          zIndex: 1000, // Set zIndex to a higher value
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "green", fontSize: "15px" }}
            onClick={closeSuccessModal}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        <p>Your meeting has been scheduled successfully.</p>
      </Alert>

      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "red",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </div>
  );
};

export default MemberMeeting;
