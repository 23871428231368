import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Carousel,
  Alert,
} from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import "font-awesome/css/font-awesome.min.css";
import { FaTimes } from "react-icons/fa";

const CreateOrganization = ({ onCancel }) => {
  const memberId = sessionStorage.getItem("member_id");
  const token = sessionStorage.getItem("token");

  const [organizationData, setOrganizationData] = useState({
    org_name: "",
    owner_name: "",
    contact: "",
    email: "",
    created_by: memberId,
    url: "",
  });

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [error, setError] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [orgSettings, setOrgSettings] = useState({
    trainings_across_chapter: 0,
    referrals_across_chapter: 0,
    events_across_chapter: 0,
    posts_across_chapter: 0,
    meetings_across_chapter: 0,
    member_onboard_authority: 0,
    chapter_referral_thirdparty_data: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganizationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleToggle = (key, value) => {
    // Create a copy of orgSettings object

    // Update the specific setting
    orgSettings.updated_by = memberId;

    setOrgSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
    console.log(orgSettings);
    // Update the state with the modified orgSettings object
  };

  // const handleSave = async (e) => {
  //   e.preventDefault();
  //   console.log("upated settings", orgSettings);

  //   try {
  //     const response = await fetch(
  //       process.env.REACT_APP_API_URL + "/org/createOrgSetting",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify(orgSettings),
  //       }
  //     );

  //     if (response.ok) {
  //       console.log("Organization setting updated successfully");
  //       alert("Organization setting updated successfully"); // Show alert
  //     } else {
  //       const errorData = await response.json();
  //       console.error("Error creating Organization setting:", errorData);
  //       setError("Error creating Organization. Please try again later.");
  //     }
  //   } catch (error) {
  //     console.error("Unexpected error:", error.message);
  //     setError("Error creating Organization setting. Please try again later.");
  //   }
  // };

  const isFormValid = () => {
    // Check your form validation conditions here
    // Return true if the form is valid, false otherwise
    return (
      organizationData.org_name !== "" &&
      organizationData.owner_name !== "" &&
      organizationData.contact !== "" &&
      organizationData.email !== ""
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      setValidated(true); // Trigger Bootstrap validation styles
      return;
    }

    try {
      setLoadingSubmit(true); // Start loading state
      // console.log("==organizationData org==>",organizationData);

      const protocol = window.location.protocol;

      const hostname = window.location.hostname;

      const port = window.location.port;

      // const url = protocol + "//" + hostname + ":" + port;

      // const sanitizedOrgName = organizationData.org_name.toLowerCase().replace(/\s+/g, "");
      // const updatedHostname = hostname.replace(/^[^.]+/, sanitizedOrgName);

      const orgName = organizationData.org_name.toLowerCase().replace(/\s+/g, "");
      let updatedHostname;
      
      if (hostname.split('.').length > 2) {
        // Subdomain exists → replace
        updatedHostname = hostname.replace(/^[^.]+/, orgName);
      } else {
        // No subdomain → add
        updatedHostname = `${orgName}.${hostname}`;
      }
      
      // Construct the new URL dynamically
      const url = `${protocol}//${updatedHostname}${port ? `:${port}` : ""}`;

      console.log(url); // Output: https://cb.nellinfotech.com (or with port if applicable)


      organizationData.url = url;
      const combinedData = {
        ...orgSettings,
        ...organizationData,
      };
      combinedData.url = url;
      setOrganizationData(combinedData);
      console.log(organizationData);

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/org/createOrg",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(combinedData),
        }
      );

      if (response.ok) {
        // console.log("Organization created successfully");
        showSuccess();
      } else {
        const errorData = await response.json();
        console.error("Error creating Organization:", errorData);
        setError(
          errorData.error ||
          "Error creating Organization. Please try again later."
        );
      }
    } catch (error) {
      console.error("Unexpected error:", error.message);
      setError("Error creating Organization. Please try again later.");
    } finally {
      setLoadingSubmit(false); // Stop loading state
    }
  };

  const resetForm = () => {
    setOrganizationData({
      org_name: "",
      owner_name: "",
      contact: "",
      email: "",
    });

    setValidated(false);
  };

  const showSuccess = () => {
    setShowSuccessAlert(true);
    resetForm();
  };

  const closeSuccess = () => {
    setShowSuccessAlert(false);
    onCancel();
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <Card className="shadow-sm">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">Create an Organization</h3>
                  <AiOutlineClose
                    onClick={onCancel}
                    style={{ cursor: "pointer", fontSize: "20px" }}
                  />
                </Card.Header>
                <Card.Body>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="org_name">
                          <Form.Label>
                            Organization <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter organization name"
                            name="org_name"
                            value={organizationData.org_name}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter the organization name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="owner_name">
                          <Form.Label>
                            Owner Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter owner name"
                            name="owner_name"
                            value={organizationData.owner_name}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter the owner name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="contact">
                          <Form.Label>
                            Contact <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            name="contact"
                            placeholder="Enter the contact number"
                            value={organizationData.contact}
                            onChange={handleChange}
                            maxLength="10"
                            pattern="[0-9]{10}"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter the contact number.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="email">
                          <Form.Label>
                            Email <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            name="email"
                            value={organizationData.email}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter email.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="12">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Settings (Optional)</h3>
                {/* <AiOutlineClose
                    onClick={onCancel}
                    style={{ cursor: 'pointer', fontSize: '20px' }}
                  /> */}
              </Card.Header>
              <Card className="shadow-sm">
                <Card.Body>
                  <Row>
                    <Col lg="6" className="px-3">
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>Member Onboarding Authority For Chapter Admin (Y/N) </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="post-switch"
                            label=""
                            checked={null}
                            onChange={(e) =>
                              handleToggle(
                                "member_onboard_authority",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>Posts Display Within Chapter Only (Y/N)</p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="post-switch"
                            label=""
                            checked={null}
                            onChange={(e) =>
                              handleToggle(
                                "posts_across_chapter",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>Trainings Post Display Within Chapter Only (Y/N)</p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="training-switch"
                            label=""
                            checked={orgSettings.trainings_across_chapter === 1}
                            onChange={(e) =>
                              handleToggle(
                                "trainings_across_chapter",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>Referral Data Display To The Chapter Admin (Y/N)</p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="training-switch"
                            label=""
                            checked={
                              orgSettings.chapter_referral_thirdparty_data === 1
                            }
                            onChange={(e) =>
                              handleToggle(
                                "chapter_referral_thirdparty_data",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>
                    </Col>
                    <Col lg="6" className="px-3">
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>Events Post Display Within Chapter Only (Y/N)</p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="events-switch"
                            label=""
                            checked={null}
                            onChange={(e) =>
                              handleToggle(
                                "events_across_chapter",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>Create Meetings Within Chapter Members Only (Y/N)</p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="meeting-switch"
                            label=""
                            checked={null}
                            onChange={(e) =>
                              handleToggle(
                                "meetings_across_chapter",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>
                      <div className="d-flex flex-row justify-content-between px-2">
                        <p>Give Reference Within Chapter Members Only (Y/N)</p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="referral-switch"
                            label=""
                            checked={orgSettings.referrals_across_chapter === 1}
                            onChange={(e) =>
                              handleToggle(
                                "referrals_across_chapter",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card.Footer className="d-flex justify-content-end">
                <Button
                  className="mr-2 mx-2"
                  variant="danger"
                  onClick={resetForm}
                >
                  Reset
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={loadingSubmit}
                >
                  {loadingSubmit ? "Creating..." : "Create"}
                </Button>
              </Card.Footer>
            </Col>
          </Row>
        </Container>

        {/* <button
                        style={{
                          padding: "2px 12px",
                          fontSize: "13px",
                          fontWeight: "bold",
                          backgroundColor: "#007BFF",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={handleSave}
                      >
                        Save
                      </button> */}

        {/* Success Alert */}
        <Alert
          show={showSuccessAlert}
          variant="success"
          onClose={closeSuccess}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid green",
            color: "green",
            width: "300px",
            zIndex: 1000, // Set zIndex to a higher value
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "green", fontSize: "15px" }}
              onClick={closeSuccess}
            />
          </div>
          <Alert.Heading>Success!</Alert.Heading>
          <p>Organization created successfully.</p>
        </Alert>

        {/* Error Alert */}
        {error && (
          <Alert
            variant="danger"
            onClose={() => setError(null)}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              border: "1px solid red",
              color: "red",
              width: "300px",
              zIndex: 1000, // Set zIndex to a higher value
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <FaTimes
                style={{ cursor: "pointer", color: "red", fontSize: "15px" }}
                onClick={() => setError(null)}
              />
            </div>
            <Alert.Heading>Error!</Alert.Heading>
            <p>{error}</p>
          </Alert>
        )}
      </div>
    </>
  );
};

export default CreateOrganization;
