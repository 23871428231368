// import React, { useState, useContext } from "react";

// //router
// import { Link, useLocation, useNavigate } from "react-router-dom";

// //react-bootstrap
// import {
//   Accordion,
//   useAccordionButton,
//   AccordionContext,
//   Nav,
//   Tooltip,
//   OverlayTrigger,
// } from "react-bootstrap";

// import { FaCamera, FaFilm } from "react-icons/fa";
// import { IoMdPeople, IoMdPerson } from "react-icons/io";

// function CustomToggle({ children, eventKey, onClick, onMenuItemClick }) {
//   const { activeEventKey } = useContext(AccordionContext);

//   const decoratedOnClick = useAccordionButton(eventKey, (active) =>
//     onClick({ state: !active, eventKey: eventKey })
//   );

//   const isCurrentEventKey = activeEventKey === eventKey;

//   return (
//     <Link
//       to="#"
//       aria-expanded={isCurrentEventKey ? "true" : "false"}
//       className="nav-link"
//       role="button"
//       onClick={(e) => {
//         decoratedOnClick(isCurrentEventKey);
//       }}
//     >
//       {children}
//     </Link>
//   );
// }

// const VerticalNav = React.memo(({ onMenuItemClick }) => {
//   const navigate = useNavigate();
//   const [activeMenu, setActiveMenu] = useState(false);
//   const [active, setActive] = useState("");

//   //location
//   let location = useLocation();
//   // console.log(document);
//   // Replace 'userRole' with the key you've used to save the user role in localStorage
//   const userRole = sessionStorage.getItem("role");
//   const token = sessionStorage.getItem("token");
//   const role_id = sessionStorage.getItem("role_id");
//   const minisidebar = () => {
//     document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
//   };

//   //Logout fuctinallity
//   const handleLogout = async () => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/auth/logout`,
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//           credentials: "include",
//         }
//       );

//       if (response.ok) {
//         // Clear user data from localStorage upon successful logout
//         // localStorage.clear(); // Clears all localStorage items
//         sessionStorage.clear(); // Clears all sessionStorage items
//         navigate("/auth/sign-in"); // Redirect to the login page after logout
//       } else {
//         console.error("Logout request failed");
//       }
//     } catch (error) {
//       console.error("Logout error:", error);
//     }
//   };

//   //menu list for Admin
//   const renderSuperAdminMenu = (
//     // JSX for Admin menu
//     <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
//       <li className="nav-item static-item">
//         <Link
//           onClick={minisidebar}
//           className="nav-link static-item disabled"
//           to="#"
//           tabIndex="-1"
//         >
//           <span className="default-icon">Social</span>
//           <span
//             className="mini-icon"
//             data-bs-toggle="tooltip"
//             title="Social"
//             data-bs-placement="right"
//           >
//             -
//           </span>
//         </Link>
//       </li>

//       {/* <li
//         className={`nav-item ${
//           location.pathname === "/userposts" ? "active" : ""
//         }`}
//       >
//         <Link
//           onClick={minisidebar}
//           className={`nav-link ${
//             location.pathname === "/userposts" ? "active" : ""
//           }`}
//           aria-current="page"
//           to="/userposts"
//         >
//           <OverlayTrigger placement="right" overlay={<Tooltip>Home</Tooltip>}>
//             <i className="icon material-symbols-outlined">newspaper</i>
//           </OverlayTrigger>
//           <span className="item-name">Home</span>
//         </Link>
//       </li> */}
//       <li
//         className={`${
//           location.pathname === "/superAdminDashboard" ? "active" : ""
//         } nav-item `}
//       >
//         <Link
//           onClick={minisidebar}
//           className={`${
//             location.pathname === "/superAdminDashboard" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//           to="/superAdminDashboard"
//         >
//           <OverlayTrigger
//             placement="right"
//             overlay={<Tooltip>Dashboard</Tooltip>}
//           >
//             <i className="icon material-symbols-outlined">Dashboard</i>
//           </OverlayTrigger>
//           <span className="item-name">Dashboard</span>
//         </Link>
//       </li>

//       <li
//         className={`${
//           location.pathname === "dashboard/app/organizations" ? "active" : ""
//         } nav-item `}
//       >
//         <Link
//           onClick={minisidebar}
//           className={`${
//             location.pathname === "dashboard/app/organizations" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//           to="dashboard/app/organizations"
//         >
//           <OverlayTrigger
//             placement="right"
//             overlay={<Tooltip>Organizations</Tooltip>}
//           >
//             <i className="icon material-symbols-outlined">corporate_fare</i>
//           </OverlayTrigger>
//           <span className="item-name">Organizations</span>
//         </Link>
//       </li>

//       <Nav.Item as="li">
//         <Link
//           onClick={handleLogout}
//           className={`${
//             location.pathname === "/auth/sign-in" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//         >
//           <OverlayTrigger placement="right" overlay={<Tooltip>logout</Tooltip>}>
//             <i className="icon material-symbols-outlined">logout</i>
//           </OverlayTrigger>
//           <span className="item-name">Logout</span>
//         </Link>
//       </Nav.Item>
//     </Accordion>
//   );

//   //menu list for Admin
//   const renderOrgAdminMenu = (
//     // JSX for Admin menu
//     <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
//       <li className="nav-item static-item">
//         <Link
//           onClick={minisidebar}
//           className="nav-link static-item disabled"
//           to="#"
//           tabIndex="-1"
//         >
//           <span className="default-icon">Social</span>
//           <span
//             className="mini-icon"
//             data-bs-toggle="tooltip"
//             title="Social"
//             data-bs-placement="right"
//           >
//             -
//           </span>
//         </Link>
//       </li>

//       <li
//         className={`nav-item ${
//           location.pathname === "/userposts" ? "active" : ""
//         }`}
//       >
//         <Link
//           onClick={minisidebar}
//           className={`nav-link ${
//             location.pathname === "/userposts" ? "active" : ""
//           }`}
//           aria-current="page"
//           to="/userposts"
//         >
//           <OverlayTrigger placement="right" overlay={<Tooltip>Home</Tooltip>}>
//             <i className="icon material-symbols-outlined">newspaper</i>
//           </OverlayTrigger>
//           <span className="item-name">Home</span>
//         </Link>
//       </li>
//       <li
//         className={`${
//           location.pathname === "/adminDashboard" ? "active" : ""
//         } nav-item `}
//       >
//         <Link
//           onClick={minisidebar}
//           className={`${
//             location.pathname === "/adminDashboard" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//           to="/adminDashboard"
//         >
//           <OverlayTrigger
//             placement="right"
//             overlay={<Tooltip>Dashboard</Tooltip>}
//           >
//             <i className="icon material-symbols-outlined">Dashboard</i>
//           </OverlayTrigger>
//           <span className="item-name">Dashboard</span>
//         </Link>
//       </li>

//       {userRole !== "CHAPTER_ADMIN" && (
//         <React.Fragment>
//           <li
//             className={`${
//               location.pathname === "/OrgSetting" ? "active" : ""
//             } nav-item `}
//           >
//             <Link
//               onClick={minisidebar}
//               className={`${
//                 location.pathname === "/OrgSetting" ? "active" : ""
//               } nav-link `}
//               aria-current="page"
//               to="/OrgSetting"
//             >
//               <OverlayTrigger
//                 placement="right"
//                 overlay={<Tooltip>Organisation Setting</Tooltip>}
//               >
//                 <i className="icon material-symbols-outlined">
//                   settings
//                 </i>
//               </OverlayTrigger>
//               <span className="item-name">Organisation Setting</span>
//             </Link>
//           </li>
//           <li
//             className={`${
//               location.pathname === "dashboard/createAdmin" ? "active" : ""
//             } nav-item `}
//           >
//             <Link
//               onClick={minisidebar}
//               className={`${
//                 location.pathname === "dashboard/createAdmin" ? "active" : ""
//               } nav-link `}
//               aria-current="page"
//               to="dashboard/createAdmin"
//             >
//               <OverlayTrigger
//                 placement="right"
//                 overlay={<Tooltip>Create Admin</Tooltip>}
//               >
//                 <i className="icon material-symbols-outlined">
//                   admin_panel_settings
//                 </i>
//               </OverlayTrigger>
//               <span className="item-name">Create Admin</span>
//             </Link>
//           </li>
//         </React.Fragment>
//       )}

//       <li
//         className={`${
//           location.pathname === "dashboard/createEvent" ? "active" : ""
//         } nav-item `}
//       >
//         <Link
//           onClick={minisidebar}
//           className={`${
//             location.pathname === "dashboard/createEvent" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//           to="dashboard/createEvent"
//         >
//           <OverlayTrigger placement="right" overlay={<Tooltip>Event</Tooltip>}>
//             <i className="icon material-symbols-outlined">event</i>
//           </OverlayTrigger>
//           <span className="item-name">Event</span>
//         </Link>
//       </li>
//       <li
//         className={`${
//           location.pathname === "dashboard/createTreanning" ? "active" : ""
//         } nav-item `}
//       >
//         <Link
//           onClick={minisidebar}
//           className={`${
//             location.pathname === "dashboard/createTreanning" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//           to="dashboard/createTreanning"
//         >
//           <OverlayTrigger
//             placement="right"
//             overlay={<Tooltip>Training</Tooltip>}
//           >
//             <i className="icon material-symbols-outlined">school</i>
//           </OverlayTrigger>
//           <span className="item-name">Training</span>
//         </Link>
//       </li>
//       <li
//         className={`${
//           location.pathname === "/dashboards/app/renew-mship" ? "active" : ""
//         } nav-item `}
//       >
//         <Link
//           onClick={minisidebar}
//           className={`${
//             location.pathname === "/dashboards/app/renew-mship" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//           to="/dashboards/app/renew-mship"
//         >
//           <OverlayTrigger
//             placement="right"
//             overlay={<Tooltip>Membership Requests</Tooltip>}
//           >
//             <i className="icon material-symbols-outlined">group_add</i>
//           </OverlayTrigger>
//           <span className="item-name">Membership Requests</span>
//         </Link>
//       </li>
//       <li
//         className={`${
//           location.pathname === "/dashboards/app/members" ? "active" : ""
//         } nav-item `}
//       >
//         <Link
//           onClick={minisidebar}
//           className={`${
//             location.pathname === "/dashboards/app/members" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//           to="/dashboards/app/members"
//         >
//           <OverlayTrigger
//             placement="right"
//             overlay={<Tooltip>Members</Tooltip>}
//           >
//             <i className="icon material-symbols-outlined">groups_2</i>
//           </OverlayTrigger>
//           <span className="item-name">Members</span>
//         </Link>
//       </li>
//       {userRole !== "CHAPTER_ADMIN" && (
//       <Accordion.Item
//         as="li"
//         eventKey="members-menu"
//         bsPrefix={`nav-item ${active === "profile" ? "active" : ""} `}
//         onClick={() => setActive("profile")}
//       >
//         <CustomToggle
//           eventKey="members-menu"
//           onClick={(activeKey) => setActiveMenu(activeKey)}
//           onMenuItemClick={onMenuItemClick}
//         >
//           <OverlayTrigger
//             placement="right"
//             overlay={<Tooltip>Master Data </Tooltip>}
//           >
//             <i className="icon material-symbols-outlined">table_view</i>
//           </OverlayTrigger>
//           <span className="item-name">Master Data</span>
//           <i className="right-icon material-symbols-outlined">chevron_right</i>
//         </CustomToggle>
//         <Accordion.Collapse eventKey="members-menu">
//           <ul className="sub-nav">
//             <Nav.Item as="li">
//               <Link
//                 onClick={minisidebar}
//                 className={`${
//                   location.pathname === "dashboards/app/mship-plans"
//                     ? "active"
//                     : ""
//                 } nav-link`}
//                 to="dashboards/app/mship-plans"
//               >
//                 <i className="icon">
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="10"
//                     viewBox="0 0 24 24"
//                     fill="currentColor"
//                   >
//                     <g>
//                       <circle
//                         cx="12"
//                         cy="12"
//                         r="8"
//                         fill="currentColor"
//                       ></circle>
//                     </g>
//                   </svg>
//                 </i>
//                 <OverlayTrigger
//                   placement="right"
//                   overlay={<Tooltip>Membership Plan</Tooltip>}
//                 >
//                   <i className="sidenav-mini-icon"> M </i>
//                 </OverlayTrigger>
//                 <span className="item-name"> Membership Plan </span>
//               </Link>
//             </Nav.Item>

//             <Nav.Item as="li">
//               <Link
//                 onClick={minisidebar}
//                 className={`${
//                   location.pathname === "dashboards/app/mcategory"
//                     ? "active"
//                     : ""
//                 } nav-link`}
//                 to="dashboards/app/mcategory"
//               >
//                 <i className="icon">
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="10"
//                     viewBox="0 0 24 24"
//                     fill="currentColor"
//                   >
//                     <g>
//                       <circle
//                         cx="12"
//                         cy="12"
//                         r="8"
//                         fill="currentColor"
//                       ></circle>
//                     </g>
//                   </svg>
//                 </i>
//                 <OverlayTrigger
//                   placement="right"
//                   overlay={<Tooltip>Member Categories</Tooltip>}
//                 >
//                   <i className="sidenav-mini-icon"> P1 </i>
//                 </OverlayTrigger>
//                 <span className="item-name"> Member Categories</span>
//               </Link>
//             </Nav.Item>

//             <Nav.Item as="li">
//               <Link
//                 onClick={minisidebar}
//                 className={`${
//                   location.pathname === "dashboards/app/chapters"
//                     ? "active"
//                     : ""
//                 } nav-link`}
//                 to="dashboards/app/chapters"
//               >
//                 <i className="icon">
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="10"
//                     viewBox="0 0 24 24"
//                     fill="currentColor"
//                   >
//                     <g>
//                       <circle
//                         cx="12"
//                         cy="12"
//                         r="8"
//                         fill="currentColor"
//                       ></circle>
//                     </g>
//                   </svg>
//                 </i>
//                 <OverlayTrigger
//                   placement="right"
//                   overlay={<Tooltip>Chapters</Tooltip>}
//                 >
//                   <i className="sidenav-mini-icon"> P2 </i>
//                 </OverlayTrigger>
//                 <span className="item-name"> Chapters</span>
//               </Link>
//             </Nav.Item>
//           </ul>
//         </Accordion.Collapse>
//       </Accordion.Item>
//       )}
//       {userRole == "CHAPTER_ADMIN" ? (
//         <Accordion.Item as="li" eventKey="referrals-menu" bsPrefix="nav-item">
//           <CustomToggle
//             eventKey="referrals-menu"
//             onClick={(activeKey) => setActiveMenu(activeKey)}
//             onMenuItemClick={onMenuItemClick}
//           >
//             <OverlayTrigger
//               placement="right"
//               overlay={<Tooltip>Referrals</Tooltip>}
//             >
//               <i className="icon material-symbols-outlined">task_alt</i>
//             </OverlayTrigger>
//             <span className="item-name">Referrals</span>
//             <i className="right-icon material-symbols-outlined">
//               chevron_right
//             </i>
//           </CustomToggle>
//           <Accordion.Collapse eventKey="referrals-menu">
//             <ul className="sub-nav">
//               <Nav.Item as="li">
//                 <Link
//                   onClick={minisidebar}
//                   className={`${
//                     location.pathname ===
//                     "dashboards/app/chapter-received-referrals"
//                       ? "active"
//                       : ""
//                   } nav-link`}
//                   to="dashboards/app/chapter-received-referrals"
//                 >
//                   <i className="icon">
//                     <svg
//                       className="icon-10"
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="10"
//                       viewBox="0 0 24 24"
//                       fill="currentColor"
//                     >
//                       <g>
//                         <circle
//                           cx="12"
//                           cy="12"
//                           r="8"
//                           fill="currentColor"
//                         ></circle>
//                       </g>
//                     </svg>
//                   </i>
//                   <OverlayTrigger
//                     placement="right"
//                     overlay={<Tooltip>Referrals Received</Tooltip>}
//                   >
//                     <i className="sidenav-mini-icon"> FM </i>
//                   </OverlayTrigger>
//                   <span className="item-name">Referrals Received</span>
//                 </Link>
//               </Nav.Item>

//               <Nav.Item as="li">
//                 <Link
//                   onClick={minisidebar}
//                   className={`${
//                     location.pathname ===
//                     "dashboards/app/chapter-sent-referrals"
//                       ? "active"
//                       : ""
//                   } nav-link`}
//                   to="dashboards/app/chapter-sent-referrals"
//                 >
//                   <i className="icon">
//                     <svg
//                       className="icon-10"
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="10"
//                       viewBox="0 0 24 24"
//                       fill="currentColor"
//                     >
//                       <g>
//                         <circle
//                           cx="12"
//                           cy="12"
//                           r="8"
//                           fill="currentColor"
//                         ></circle>
//                       </g>
//                     </svg>
//                   </i>
//                   <OverlayTrigger
//                     placement="right"
//                     overlay={<Tooltip>Referrals Sent</Tooltip>}
//                   >
//                     <i className="sidenav-mini-icon"> BM </i>
//                   </OverlayTrigger>
//                   <span className="item-name">Referrals Sent</span>
//                 </Link>
//               </Nav.Item>
//             </ul>
//           </Accordion.Collapse>
//         </Accordion.Item>
//       ) : (
//         <Nav.Item as="li">
//           <Link
//             onClick={minisidebar}
//             className={`${
//               location.pathname === "dashboard/app/referralsList"
//                 ? "active"
//                 : ""
//             } nav-link `}
//             aria-current="page"
//             to="dashboard/app/referralsList"
//           >
//             <OverlayTrigger
//               placement="right"
//               overlay={<Tooltip>Referrals</Tooltip>}
//             >
//               <i className="icon material-symbols-outlined">task_alt</i>
//             </OverlayTrigger>
//             <span className="item-name">Referrals</span>
//           </Link>
//         </Nav.Item>
//       )}

//       {userRole == "CHAPTER_ADMIN" ? (
//         <Accordion.Item as="li" eventKey="meeting-menu" bsPrefix="nav-item">
//           <CustomToggle
//             eventKey="meeting-menu"
//             onClick={(activeKey) => setActiveMenu(activeKey)}
//             onMenuItemClick={onMenuItemClick}
//           >
//             <OverlayTrigger
//               placement="right"
//               overlay={<Tooltip>Meetings</Tooltip>}
//             >
//               <i className="icon material-symbols-outlined">
//                 <IoMdPeople />
//               </i>
//             </OverlayTrigger>
//             <span className="item-name">Meetings</span>
//             <i className="right-icon material-symbols-outlined">
//               chevron_right
//             </i>
//           </CustomToggle>
//           <Accordion.Collapse eventKey="meeting-menu">
//             <ul className="sub-nav">
//               <Nav.Item as="li">
//                 <Link
//                   onClick={minisidebar}
//                   className={`${
//                     location.pathname === "dashboard/app/memberMeetingAdmin"
//                       ? "active"
//                       : ""
//                   } nav-link`}
//                   to="dashboard/app/memberMeetingAdmin"
//                 >
//                   <i className="icon">
//                     <IoMdPerson />
//                   </i>
//                   <OverlayTrigger
//                     placement="right"
//                     overlay={<Tooltip>Member Meetings</Tooltip>}
//                   >
//                     <i className="sidenav-mini-icon">
//                       <IoMdPerson />
//                     </i>
//                   </OverlayTrigger>
//                   <span className="item-name">Member Meetings</span>
//                 </Link>
//               </Nav.Item>

//               <Nav.Item as="li">
//                 <Link
//                   onClick={minisidebar}
//                   className={`${
//                     location.pathname === "dashboards/app/chapter-meeting"
//                       ? "active"
//                       : ""
//                   } nav-link`}
//                   to="dashboards/app/chapter-meeting"
//                 >
//                   <i className="icon">
//                     <IoMdPeople />
//                   </i>
//                   <OverlayTrigger
//                     placement="right"
//                     overlay={<Tooltip>Chapter Meetings</Tooltip>}
//                   >
//                     <i className="sidenav-mini-icon">
//                       <IoMdPeople />
//                     </i>
//                   </OverlayTrigger>
//                   <span className="item-name">Chapter Meetings</span>
//                 </Link>
//               </Nav.Item>
//             </ul>
//           </Accordion.Collapse>
//         </Accordion.Item>
//       ) : (
//         <Nav.Item as="li">
//           <Link
//             onClick={minisidebar}
//             className={`${
//               location.pathname === "dashboard/app/AllMeetingList"
//                 ? "active"
//                 : ""
//             } nav-link `}
//             aria-current="page"
//             to="dashboard/app/AllMeetingList"
//           >
//             <OverlayTrigger
//               placement="right"
//               overlay={<Tooltip>Meetings</Tooltip>}
//             >
//               <i className="icon material-symbols-outlined">notifications</i>
//             </OverlayTrigger>
//             <span className="item-name">All Meetings</span>
//           </Link>
//         </Nav.Item>
//       )}

//       <Nav.Item as="li">
//         <Link
//           onClick={handleLogout}
//           className={`${
//             location.pathname === "/auth/sign-in" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//         >
//           <OverlayTrigger placement="right" overlay={<Tooltip>logout</Tooltip>}>
//             <i className="icon material-symbols-outlined">logout</i>
//           </OverlayTrigger>
//           <span className="item-name">Logout</span>
//         </Link>
//       </Nav.Item>
//     </Accordion>
//   );

//   //Menu list for Member
//   const renderMemberMenu = (
//     // JSX for Member menu
//     <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
//       <li className="nav-item static-item">
//         <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
//           <span className="default-icon">Social</span>
//           <span
//             className="mini-icon"
//             data-bs-toggle="tooltip"
//             title="Social"
//             data-bs-placement="right"
//           >
//             -
//           </span>
//         </Link>
//       </li>

//       <li
//         className={`${
//           location.pathname === "/userposts" ? "active" : ""
//         } nav-item `}
//       >
//         <Link
//           onClick={minisidebar}
//           className={`${
//             location.pathname === "/userposts" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//           to="/userposts"
//         >
//           <OverlayTrigger placement="right" overlay={<Tooltip>Home</Tooltip>}>
//             <i className="icon material-symbols-outlined">newspaper</i>
//           </OverlayTrigger>
//           <span className="item-name">Home</span>
//         </Link>
//       </li>
//       <li
//         onClick={minisidebar}
//         className={`${
//           location.pathname === "/userDashboard" ? "active" : ""
//         } nav-item `}
//       >
//         <Link
//           className={`${
//             location.pathname === "/userDashboard" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//           to="/userDashboard"
//         >
//           <OverlayTrigger
//             placement="right"
//             overlay={<Tooltip>Dashboard</Tooltip>}
//           >
//             <i className="icon material-symbols-outlined">Dashboard</i>
//           </OverlayTrigger>
//           <span className="item-name">Dashboard</span>
//         </Link>
//       </li>

//       <Nav.Item as="li">
//         <Link
//           onClick={minisidebar}
//           className={`${
//             location.pathname === "dashboard/app/MembersList" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//           to="dashboard/app/MembersList"
//         >
//           <OverlayTrigger
//             placement="right"
//             overlay={<Tooltip>Members</Tooltip>}
//           >
//             <i className="icon material-symbols-outlined">person</i>
//           </OverlayTrigger>
//           <span className="item-name">Members</span>
//         </Link>
//       </Nav.Item>

//       <Accordion.Item as="li" eventKey="referrals-menu" bsPrefix="nav-item">
//         <CustomToggle
//           eventKey="referrals-menu"
//           onClick={(activeKey) => setActiveMenu(activeKey)}
//           onMenuItemClick={onMenuItemClick}
//         >
//           <OverlayTrigger
//             placement="right"
//             overlay={<Tooltip>Referrals</Tooltip>}
//           >
//             <i className="icon material-symbols-outlined">task_alt</i>
//           </OverlayTrigger>
//           <span className="item-name">Referrals</span>
//           <i className="right-icon material-symbols-outlined">chevron_right</i>
//         </CustomToggle>
//         <Accordion.Collapse eventKey="referrals-menu">
//           <ul className="sub-nav">
//             <Nav.Item as="li">
//               <Link
//                 onClick={minisidebar}
//                 className={`${
//                   location.pathname === "dashboards/app/for-me" ? "active" : ""
//                 } nav-link`}
//                 to="dashboards/app/for-me"
//               >
//                 <i className="icon">
//                   <svg
//                     className="icon-10"
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="10"
//                     viewBox="0 0 24 24"
//                     fill="currentColor"
//                   >
//                     <g>
//                       <circle
//                         cx="12"
//                         cy="12"
//                         r="8"
//                         fill="currentColor"
//                       ></circle>
//                     </g>
//                   </svg>
//                 </i>
//                 <OverlayTrigger
//                   placement="right"
//                   overlay={<Tooltip>Referrals Received</Tooltip>}
//                 >
//                   <i className="sidenav-mini-icon"> FM </i>
//                 </OverlayTrigger>
//                 <span className="item-name">Referrals Received</span>
//               </Link>
//             </Nav.Item>

//             <Nav.Item as="li">
//               <Link
//                 onClick={minisidebar}
//                 className={`${
//                   location.pathname === "dashboards/app/by-me" ? "active" : ""
//                 } nav-link`}
//                 to="dashboards/app/by-me"
//               >
//                 <i className="icon">
//                   <svg
//                     className="icon-10"
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="10"
//                     viewBox="0 0 24 24"
//                     fill="currentColor"
//                   >
//                     <g>
//                       <circle
//                         cx="12"
//                         cy="12"
//                         r="8"
//                         fill="currentColor"
//                       ></circle>
//                     </g>
//                   </svg>
//                 </i>
//                 <OverlayTrigger
//                   placement="right"
//                   overlay={<Tooltip>Referrals Sent</Tooltip>}
//                 >
//                   <i className="sidenav-mini-icon"> BM </i>
//                 </OverlayTrigger>
//                 <span className="item-name">Referrals Sent</span>
//               </Link>
//             </Nav.Item>
//           </ul>
//         </Accordion.Collapse>
//       </Accordion.Item>

//       <Accordion.Item as="li" eventKey="meeting-menu" bsPrefix="nav-item">
//         <CustomToggle
//           eventKey="meeting-menu"
//           onClick={(activeKey) => setActiveMenu(activeKey)}
//           onMenuItemClick={onMenuItemClick}
//         >
//           <OverlayTrigger
//             placement="right"
//             overlay={<Tooltip>Meetings</Tooltip>}
//           >
//             <i className="icon material-symbols-outlined">
//               <IoMdPeople />
//             </i>
//           </OverlayTrigger>
//           <span className="item-name">Meetings</span>
//           <i className="right-icon material-symbols-outlined">chevron_right</i>
//         </CustomToggle>
//         <Accordion.Collapse eventKey="meeting-menu">
//           <ul className="sub-nav">
//             <Nav.Item as="li">
//               <Link
//                 onClick={minisidebar}
//                 className={`${
//                   location.pathname === "dashboards/app/meeting" ? "active" : ""
//                 } nav-link`}
//                 to="dashboards/app/meeting"
//               >
//                 <i className="icon">
//                   <IoMdPerson />
//                 </i>
//                 <OverlayTrigger
//                   placement="right"
//                   overlay={<Tooltip>Member Meetings</Tooltip>}
//                 >
//                   <i className="sidenav-mini-icon">
//                     <IoMdPerson />
//                   </i>
//                 </OverlayTrigger>
//                 <span className="item-name">Member Meetings</span>
//               </Link>
//             </Nav.Item>

//             <Nav.Item as="li">
//               <Link
//                 onClick={minisidebar}
//                 className={`${
//                   location.pathname === "dashboards/app/chapter-meeting"
//                     ? "active"
//                     : ""
//                 } nav-link`}
//                 to="dashboards/app/chapter-meeting"
//               >
//                 <i className="icon">
//                   <IoMdPeople />
//                 </i>
//                 <OverlayTrigger
//                   placement="right"
//                   overlay={<Tooltip>Chapter Meetings</Tooltip>}
//                 >
//                   <i className="sidenav-mini-icon">
//                     <IoMdPeople />
//                   </i>
//                 </OverlayTrigger>
//                 <span className="item-name">Chapter Meetings</span>
//               </Link>
//             </Nav.Item>
//           </ul>
//         </Accordion.Collapse>
//       </Accordion.Item>

//       {/* <Nav.Item as="li">
//                 <Link onClick={minisidebar} className={`${location.pathname === 'dashboards/app/meeting' ? 'active' : ''} nav-link`} to="dashboards/app/meeting">
//                     <i className="icon">
//                         <IoMdPerson />
//                     </i>
//                     <OverlayTrigger placement="right" overlay={<Tooltip>Member Meetings</Tooltip>}>
//                         <i className="sidenav-mini-icon"><IoMdPerson /></i>
//                     </OverlayTrigger>
//                     <span className="item-name">Member Meetings</span>
//                 </Link>
//             </Nav.Item> */}

//       <Nav.Item as="li">
//         <Link
//           onClick={minisidebar}
//           className={`${
//             location.pathname === "/dashboards/app/profile-videos"
//               ? "active"
//               : ""
//           } nav-link`}
//           to="/dashboards/app/profile-videos"
//         >
//           <OverlayTrigger placement="right" overlay={<Tooltip>Files</Tooltip>}>
//             <i className="icon material-symbols-outlined">insert_drive_file</i>
//           </OverlayTrigger>
//           <span className="item-name">Files</span>
//         </Link>
//       </Nav.Item>

//       <Nav.Item as="li">
//         <Link
//           onClick={handleLogout}
//           className={`${
//             location.pathname === "/auth/sign-in" ? "active" : ""
//           } nav-link `}
//           aria-current="page"
//         >
//           <OverlayTrigger placement="right" overlay={<Tooltip>logout</Tooltip>}>
//             <i className="icon material-symbols-outlined">logout</i>
//           </OverlayTrigger>
//           <span className="item-name">Logout</span>
//         </Link>
//       </Nav.Item>
//     </Accordion>
//   );

//   const renderMenu =
//     userRole === "CHAPTER_ADMIN"
//       ? renderOrgAdminMenu
//       : userRole === "ORG_ADMIN"
//       ? renderOrgAdminMenu
//       : userRole === "SUPER_ADMIN"
//       ? renderSuperAdminMenu
//       : renderMemberMenu;

//   return <React.Fragment>{renderMenu}</React.Fragment>;
// });

// export default VerticalNav;





import React, { useState, useContext } from "react";

//router
import { Link, useLocation, useNavigate } from "react-router-dom";

//react-bootstrap
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Nav,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import { FaCamera, FaFilm } from "react-icons/fa";
import { IoMdPeople, IoMdPerson } from "react-icons/io";

function CustomToggle({ children, eventKey, onClick, onMenuItemClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = React.memo(
  ({ onMenuItemClick, getOrgNames, getSettingNames }) => {
    const navigate = useNavigate();
    const [activeMenu, setActiveMenu] = useState(false);
    const [active, setActive] = useState("");

    //location
    let location = useLocation();
    // Replace 'userRole' with the key you've used to save the user role in localStorage
    const userRole = sessionStorage.getItem("role");
    const token = sessionStorage.getItem("token");
    const role_id = sessionStorage.getItem("role_id");
    const minisidebar = () => {
      document
        .getElementsByTagName("ASIDE")[0]
        .classList.toggle("sidebar-mini");
    };

    //Logout fuctinallity
    const handleLogout = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/auth/logout`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            credentials: "include",
          }
        );

        if (response.ok) {
          // Clear user data from localStorage upon successful logout
          // localStorage.clear(); // Clears all localStorage items
          const token = sessionStorage.getItem("login_token");

          if (!token) {
            navigate("/auth/sign-in");
          } else {
            navigate(
              `/auth/sign-in?token=${sessionStorage.getItem("login_token")}`
            );
          }
          sessionStorage.clear(); // Clears all sessionStorage items
        } else {
          console.error("Logout request failed");
        }
      } catch (error) {
        console.error("Logout error:", error);
      }
    };

    //menu list for Admin
    // const renderSuperAdminMenu = (
    //   // JSX for Admin menu
    //   <Accordion
    //     as="ul"
    //     className="navbar-nav iq-main-menu pt-3"
    //     id="sidebar-menu"
    //   >
    //     <li
    //       className={`nav-item ${
    //         location.pathname === "/userposts" ? "active" : ""
    //       }`}
    //     >
    //       <Link
    //         onClick={minisidebar}
    //         className={`nav-link ${
    //           location.pathname === "/userposts" ? "active" : ""
    //         }`}
    //         aria-current="page"
    //         to="/userposts"
    //       >
    //         <OverlayTrigger placement="right" overlay={<Tooltip>Home</Tooltip>}>
    //           <i className="icon material-symbols-outlined">newspaper</i>
    //         </OverlayTrigger>
    //         <span className="item-name">Home</span>
    //       </Link>
    //     </li>
    //     <li
    //       className={`${
    //         location.pathname === "/superAdminDashboard" ? "active" : ""
    //       } nav-item `}
    //     >
    //       <Link
    //         onClick={minisidebar}
    //         className={`${
    //           location.pathname === "/superAdminDashboard" ? "active" : ""
    //         } nav-link `}
    //         aria-current="page"
    //         to="/superAdminDashboard"
    //       >
    //         <OverlayTrigger
    //           placement="right"
    //           overlay={<Tooltip>Dashboard</Tooltip>}
    //         >
    //           <i className="icon material-symbols-outlined">Dashboard</i>
    //         </OverlayTrigger>
    //         <span className="item-name">Dashboard</span>
    //       </Link>
    //     </li>

    //     <li
    //       className={`${
    //         location.pathname === "dashboard/app/organizations" ? "active" : ""
    //       } nav-item `}
    //     >
    //       <Link
    //         onClick={minisidebar}
    //         className={`${
    //           location.pathname === "dashboard/app/organizations"
    //             ? "active"
    //             : ""
    //         } nav-link `}
    //         aria-current="page"
    //         to="dashboard/app/organizations"
    //       >
    //         <OverlayTrigger
    //           placement="right"
    //           overlay={<Tooltip>Organizations</Tooltip>}
    //         >
    //           <i className="icon material-symbols-outlined">corporate_fare</i>
    //         </OverlayTrigger>
    //         <span className="item-name">Organizations</span>
    //       </Link>
    //     </li>

    //     {userRole !== "CHAPTER_ADMIN" && (
    //       <React.Fragment>
    //         <li
    //           className={`nav-item ${
    //             location.pathname === "/OrgSetting" ? "active" : ""
    //           }`}
    //         >
    //           <Link
    //             onClick={minisidebar}
    //             className={`nav-link ${
    //               location.pathname === "/OrgSetting" ? "active" : ""
    //             }`}
    //             aria-current="page"
    //             to="/OrgSetting"
    //           >
    //             <OverlayTrigger
    //               placement="right"
    //               overlay={<Tooltip>Organisation Setting</Tooltip>}
    //             >
    //               <i className="icon material-symbols-outlined">settings</i>
    //             </OverlayTrigger>
    //             <span className="item-name">Organisation Setting</span>
    //           </Link>
    //         </li>
    //         <li
    //           className={`nav-item ${
    //             location.pathname === "/orgGallery" ? "active" : ""
    //           }`}
    //         >
    //           <Link
    //             onClick={minisidebar}
    //             className={`nav-link ${
    //               location.pathname === "/orgGallery" ? "active" : ""
    //             }`}
    //             aria-current="page"
    //             to="/orgGallery"
    //           >
    //             <OverlayTrigger
    //               placement="right"
    //               overlay={<Tooltip>Organisation Gallery</Tooltip>}
    //             >
    //               <i className="icon material-symbols-outlined">image</i>
    //             </OverlayTrigger>
    //             <span className="item-name">Organisation Gallery</span>
    //           </Link>
    //         </li>
    //         <li
    //           className={`nav-item ${
    //             location.pathname === "/OrgSetting" ? "active" : ""
    //           }`}
    //         >
    //           <Link
    //             onClick={minisidebar}
    //             className={`nav-link ${
    //               location.pathname === "/visitors" ? "active" : ""
    //             }`}
    //             aria-current="page"
    //             to="/visitors"
    //           >
    //             <OverlayTrigger
    //               placement="right"
    //               overlay={<Tooltip>Visitor</Tooltip>}
    //             >
    //               <i className="icon material-symbols-outlined">
    //                 nest_doorbell_visitor
    //               </i>
    //             </OverlayTrigger>
    //             <span className="item-name">Visitor</span>
    //           </Link>
    //         </li>
    //         <li
    //           className={`nav-item ${
    //             location.pathname === "/dashboard/createAdmin" ? "active" : ""
    //           }`}
    //         >
    //           <Link
    //             onClick={minisidebar}
    //             className={`nav-link ${
    //               location.pathname === "/dashboard/createAdmin" ? "active" : ""
    //             }`}
    //             aria-current="page"
    //             to="/dashboard/createAdmin"
    //           >
    //             <OverlayTrigger
    //               placement="right"
    //               overlay={<Tooltip>Create Admin</Tooltip>}
    //             >
    //               <i className="icon material-symbols-outlined">
    //                 admin_panel_settings
    //               </i>
    //             </OverlayTrigger>
    //             <span className="item-name">Create Admin</span>
    //           </Link>
    //         </li>

    //         <li
    //           className={`nav-item ${
    //             location.pathname === "/NamingConvention" ? "active" : ""
    //           }`}
    //         >
    //           <Link
    //             onClick={minisidebar}
    //             className={`nav-link ${
    //               location.pathname === "/NamingConvention" ? "active" : ""
    //             }`}
    //             aria-current="page"
    //             to="/NamingConvention"
    //           >
    //             <OverlayTrigger
    //               placement="right"
    //               overlay={<Tooltip>Naming Convention</Tooltip>}
    //             >
    //               <i className="icon material-symbols-outlined">Description</i>
    //             </OverlayTrigger>
    //             <span className="item-name">Naming Convention</span>
    //           </Link>
    //         </li>
    //         <Accordion.Item
    //           as="li"
    //           eventKey="members-menu"
    //           bsPrefix={`nav-item ${active === "profile" ? "active" : ""}`}
    //           onClick={() => setActive("profile")}
    //         >
    //           <CustomToggle
    //             eventKey="members-menu"
    //             onClick={(activeKey) => setActiveMenu(activeKey)}
    //             onMenuItemClick={onMenuItemClick}
    //           >
    //             <OverlayTrigger
    //               placement="right"
    //               overlay={<Tooltip>Master Data</Tooltip>}
    //             >
    //               <i className="icon material-symbols-outlined">table_view</i>
    //             </OverlayTrigger>
    //             <span className="item-name">Master Data</span>
    //             <i className="right-icon material-symbols-outlined">
    //               chevron_right
    //             </i>
    //           </CustomToggle>
    //           <Accordion.Collapse eventKey="members-menu">
    //             <ul className="sub-nav">
    //               <Nav.Item as="li">
    //                 <Link
    //                   onClick={minisidebar}
    //                   className={`nav-link ${
    //                     location.pathname === "/dashboards/app/mship-plans"
    //                       ? "active"
    //                       : ""
    //                   }`}
    //                   to="/dashboards/app/mship-plans"
    //                 >
    //                   <i className="icon">
    //                     <svg
    //                       xmlns="http://www.w3.org/2000/svg"
    //                       width="10"
    //                       viewBox="0 0 24 24"
    //                       fill="currentColor"
    //                     >
    //                       <g>
    //                         <circle
    //                           cx="12"
    //                           cy="12"
    //                           r="8"
    //                           fill="currentColor"
    //                         ></circle>
    //                       </g>
    //                     </svg>
    //                   </i>
    //                   <OverlayTrigger
    //                     placement="right"
    //                     overlay={
    //                       <Tooltip>
    //                         {getOrgNames?.alt_membership_plan ||
    //                           "Membership Plan"}
    //                       </Tooltip>
    //                     }
    //                   >
    //                     <i className="sidenav-mini-icon">M</i>
    //                   </OverlayTrigger>
    //                   <span className="item-name">
    //                     {getOrgNames?.alt_membership_plan || "Membership Plan"}
    //                   </span>
    //                 </Link>
    //               </Nav.Item>
    //               <Nav.Item as="li">
    //                 <Link
    //                   onClick={minisidebar}
    //                   className={`nav-link ${
    //                     location.pathname === "/dashboards/app/mcategory"
    //                       ? "active"
    //                       : ""
    //                   }`}
    //                   to="/dashboards/app/mcategory"
    //                 >
    //                   <i className="icon">
    //                     <svg
    //                       xmlns="http://www.w3.org/2000/svg"
    //                       width="10"
    //                       viewBox="0 0 24 24"
    //                       fill="currentColor"
    //                     >
    //                       <g>
    //                         <circle
    //                           cx="12"
    //                           cy="12"
    //                           r="8"
    //                           fill="currentColor"
    //                         ></circle>
    //                       </g>
    //                     </svg>
    //                   </i>
    //                   <OverlayTrigger
    //                     placement="right"
    //                     overlay={
    //                       <Tooltip>
    //                         {getOrgNames?.alt_membership_category ||
    //                           "Member Categories"}
    //                       </Tooltip>
    //                     }
    //                   >
    //                     <i className="sidenav-mini-icon">P1</i>
    //                   </OverlayTrigger>
    //                   <span className="item-name">
    //                     {getOrgNames?.alt_membership_category ||
    //                       "Member Categories"}
    //                   </span>
    //                 </Link>
    //               </Nav.Item>
    //               <Nav.Item as="li">
    //                 <Link
    //                   onClick={minisidebar}
    //                   className={`nav-link ${
    //                     location.pathname === "/dashboards/app/chapters"
    //                       ? "active"
    //                       : ""
    //                   }`}
    //                   to="/dashboards/app/chapters"
    //                 >
    //                   <i className="icon">
    //                     <svg
    //                       xmlns="http://www.w3.org/2000/svg"
    //                       width="10"
    //                       viewBox="0 0 24 24"
    //                       fill="currentColor"
    //                     >
    //                       <g>
    //                         <circle
    //                           cx="12"
    //                           cy="12"
    //                           r="8"
    //                           fill="currentColor"
    //                         ></circle>
    //                       </g>
    //                     </svg>
    //                   </i>
    //                   <OverlayTrigger
    //                     placement="right"
    //                     overlay={
    //                       <Tooltip>
    //                         {getOrgNames?.alt_chapter || "Chapters"}
    //                       </Tooltip>
    //                     }
    //                   >
    //                     <i className="sidenav-mini-icon">P2</i>
    //                   </OverlayTrigger>
    //                   <span className="item-name">
    //                     {getOrgNames?.alt_chapter || "Chapters"}
    //                   </span>
    //                 </Link>
    //               </Nav.Item>
    //             </ul>
    //           </Accordion.Collapse>
    //         </Accordion.Item>
    //       </React.Fragment>
    //     )}
    //     {userRole === "ORG_ADMIN" ||
    //     userRole === "SUPER_ADMIN" ||
    //     (userRole === "CHAPTER_ADMIN" &&
    //       getSettingNames.member_onboard_authority === 1) ? (
    //       <>
    //         <li
    //           className={`${
    //             location.pathname === "/dashboards/app/renew-mship"
    //               ? "active"
    //               : ""
    //           } nav-item `}
    //         >
    //           <Link
    //             onClick={minisidebar}
    //             className={`${
    //               location.pathname === "/dashboards/app/renew-mship"
    //                 ? "active"
    //                 : ""
    //             } nav-link `}
    //             aria-current="page"
    //             to="/dashboards/app/renew-mship"
    //           >
    //             <OverlayTrigger
    //               placement="right"
    //               overlay={<Tooltip>Membership Requests</Tooltip>}
    //             >
    //               <i className="icon material-symbols-outlined">group_add</i>
    //             </OverlayTrigger>
    //             <span className="item-name">Membership Requests</span>
    //           </Link>
    //         </li>
    //       </>
    //     ) : null}

    //     <li
    //       className={`${
    //         location.pathname === "dashboards/app/RenuewMemberList"
    //           ? "active"
    //           : ""
    //       } nav-item `}
    //     >
    //       <Link
    //         onClick={minisidebar}
    //         className={`${
    //           location.pathname === "/dashboards/app/RenuewMemberList"
    //             ? "active"
    //             : ""
    //         } nav-link `}
    //         aria-current="page"
    //         to="/dashboards/app/RenuewMemberList"
    //       >
    //         <OverlayTrigger
    //           placement="right"
    //           overlay={<Tooltip>Renew Member List</Tooltip>}
    //         >
    //           <i className="icon material-symbols-outlined"> person </i>
    //         </OverlayTrigger>
    //         <span className="item-name">Renew Member List</span>
    //       </Link>
    //     </li>

    //     <li
    //       className={`${
    //         location.pathname === "/dashboards/app/members" ? "active" : ""
    //       } nav-item `}
    //     >
    //       <Link
    //         onClick={minisidebar}
    //         className={`${
    //           location.pathname === "/dashboards/app/members" ? "active" : ""
    //         } nav-link `}
    //         aria-current="page"
    //         to="/dashboards/app/members"
    //       >
    //         <OverlayTrigger
    //           placement="right"
    //           overlay={<Tooltip>Members</Tooltip>}
    //         >
    //           <i className="icon material-symbols-outlined">groups_2</i>
    //         </OverlayTrigger>
    //         <span className="item-name">Members</span>
    //       </Link>
    //     </li>

    //     <li
    //       className={`${
    //         location.pathname === "dashboard/createEvent" ? "active" : ""
    //       } nav-item `}
    //     >
    //       <Link
    //         onClick={minisidebar}
    //         className={`${
    //           location.pathname === "dashboard/createEvent" ? "active" : ""
    //         } nav-link `}
    //         aria-current="page"
    //         to="dashboard/createEvent"
    //       >
    //         <OverlayTrigger
    //           placement="right"
    //           overlay={<Tooltip>Event</Tooltip>}
    //         >
    //           {/* <Tooltip>{getOrgNames.alt_event ? `Event: ${getOrgNames.alt_event}` : "Event"}</Tooltip> */}
    //           <i className="icon material-symbols-outlined">event</i>
    //         </OverlayTrigger>
    //         <span className="item-name">Event</span>
    //         {/* <span className="item-name">{getOrgNames.alt_event || "Event"}</span> */}
    //       </Link>
    //     </li>
    //     <li
    //       className={`${
    //         location.pathname === "dashboard/createTreanning" ? "active" : ""
    //       } nav-item `}
    //     >
    //       <Link
    //         onClick={minisidebar}
    //         className={`${
    //           location.pathname === "dashboard/createTreanning" ? "active" : ""
    //         } nav-link `}
    //         aria-current="page"
    //         to="dashboard/createTreanning"
    //       >
    //         <OverlayTrigger
    //           placement="right"
    //           overlay={<Tooltip>Training</Tooltip>}
    //         >
    //           <i className="icon material-symbols-outlined">school</i>
    //         </OverlayTrigger>
    //         <span className="item-name">Training</span>
    //       </Link>
    //     </li>

    //     {userRole == "CHAPTER_ADMIN" ? (
    //       <>
    //         <Accordion.Item
    //           as="li"
    //           eventKey="referrals-menu"
    //           bsPrefix="nav-item"
    //         >
    //           <CustomToggle
    //             eventKey="referrals-menu"
    //             onClick={(activeKey) => setActiveMenu(activeKey)}
    //             onMenuItemClick={onMenuItemClick}
    //           >
    //             <OverlayTrigger
    //               placement="right"
    //               overlay={<Tooltip>Referrals</Tooltip>}
    //             >
    //               <i className="icon material-symbols-outlined">task_alt</i>
    //             </OverlayTrigger>
    //             <span className="item-name">Referrals</span>
    //             <i className="right-icon material-symbols-outlined">
    //               chevron_right
    //             </i>
    //           </CustomToggle>
    //           <Accordion.Collapse eventKey="referrals-menu">
    //             <ul className="sub-nav">
    //               <Nav.Item as="li">
    //                 <Link
    //                   onClick={minisidebar}
    //                   className={`${
    //                     location.pathname ===
    //                     "dashboards/app/chapter-received-referrals"
    //                       ? "active"
    //                       : ""
    //                   } nav-link`}
    //                   to="dashboards/app/chapter-received-referrals"
    //                 >
    //                   <i className="icon">
    //                     <svg
    //                       className="icon-10"
    //                       xmlns="http://www.w3.org/2000/svg"
    //                       width="10"
    //                       viewBox="0 0 24 24"
    //                       fill="currentColor"
    //                     >
    //                       <g>
    //                         <circle
    //                           cx="12"
    //                           cy="12"
    //                           r="8"
    //                           fill="currentColor"
    //                         ></circle>
    //                       </g>
    //                     </svg>
    //                   </i>
    //                   <OverlayTrigger
    //                     placement="right"
    //                     overlay={<Tooltip>Referrals Received</Tooltip>}
    //                   >
    //                     <i className="sidenav-mini-icon"> FM </i>
    //                   </OverlayTrigger>
    //                   <span className="item-name">Referrals Received</span>
    //                 </Link>
    //               </Nav.Item>

    //               <Nav.Item as="li">
    //                 <Link
    //                   onClick={minisidebar}
    //                   className={`${
    //                     location.pathname ===
    //                     "dashboards/app/chapter-sent-referrals"
    //                       ? "active"
    //                       : ""
    //                   } nav-link`}
    //                   to="dashboards/app/chapter-sent-referrals"
    //                 >
    //                   <i className="icon">
    //                     <svg
    //                       className="icon-10"
    //                       xmlns="http://www.w3.org/2000/svg"
    //                       width="10"
    //                       viewBox="0 0 24 24"
    //                       fill="currentColor"
    //                     >
    //                       <g>
    //                         <circle
    //                           cx="12"
    //                           cy="12"
    //                           r="8"
    //                           fill="currentColor"
    //                         ></circle>
    //                       </g>
    //                     </svg>
    //                   </i>
    //                   <OverlayTrigger
    //                     placement="right"
    //                     overlay={<Tooltip>Referrals Sent</Tooltip>}
    //                   >
    //                     <i className="sidenav-mini-icon"> BM </i>
    //                   </OverlayTrigger>
    //                   <span className="item-name">Referrals Sent</span>
    //                 </Link>
    //               </Nav.Item>
    //             </ul>
    //           </Accordion.Collapse>
    //         </Accordion.Item>
    //         <li
    //           className={`nav-item ${
    //             location.pathname === "/OrgSetting" ? "active" : ""
    //           }`}
    //         >
    //           <Link
    //             onClick={minisidebar}
    //             className={`nav-link ${
    //               location.pathname === "/visitors" ? "active" : ""
    //             }`}
    //             aria-current="page"
    //             to="/visitors"
    //           >
    //             <OverlayTrigger
    //               placement="right"
    //               overlay={<Tooltip>Visitor</Tooltip>}
    //             >
    //               <i className="icon material-symbols-outlined">
    //                 nest_doorbell_visitor
    //               </i>
    //             </OverlayTrigger>
    //             <span className="item-name">Visitor</span>
    //           </Link>
    //         </li>
    //       </>
    //     ) : (
    //       <Nav.Item as="li">
    //         <Link
    //           onClick={minisidebar}
    //           className={`${
    //             location.pathname === "dashboard/app/referralsList"
    //               ? "active"
    //               : ""
    //           } nav-link `}
    //           aria-current="page"
    //           to="dashboard/app/referralsList"
    //         >
    //           <OverlayTrigger
    //             placement="right"
    //             overlay={<Tooltip>Referrals</Tooltip>}
    //           >
    //             <i className="icon material-symbols-outlined">task_alt</i>
    //           </OverlayTrigger>
    //           <span className="item-name">Referrals</span>
    //         </Link>
    //       </Nav.Item>
    //     )}

    //     <Nav.Item as="li">
    //       <Link
    //         onClick={minisidebar}
    //         className={`${
    //           location.pathname === "/org-thank-you-notes-recieved"
    //             ? "active"
    //             : ""
    //         } nav-link `}
    //         aria-current="page"
    //         to="/org-thank-you-notes-recieved"
    //       >
    //         <OverlayTrigger
    //           placement="right"
    //           overlay={<Tooltip>Thank You Note</Tooltip>}
    //         >
    //           <i className="icon material-symbols-outlined">folded_hands</i>
    //         </OverlayTrigger>
    //         <span className="item-name">Thank You Note</span>
    //       </Link>
    //     </Nav.Item>

    //     {/* {userRole == "CHAPTER_ADMIN" ? ( */}
    //     <Accordion.Item as="li" eventKey="meeting-menu" bsPrefix="nav-item">
    //       <CustomToggle
    //         eventKey="meeting-menu"
    //         onClick={(activeKey) => setActiveMenu(activeKey)}
    //         onMenuItemClick={onMenuItemClick}
    //       >
    //         <OverlayTrigger
    //           placement="right"
    //           overlay={
    //             <Tooltip>{getOrgNames?.alt_meeting || "Meetings"}</Tooltip>
    //           }
    //         >
    //           <i className="icon material-symbols-outlined">
    //             <IoMdPeople />
    //           </i>
    //         </OverlayTrigger>
    //         <span className="item-name">
    //           {getOrgNames?.alt_meeting || "Meetings"}
    //         </span>
    //         <i className="right-icon material-symbols-outlined">
    //           chevron_right
    //         </i>
    //       </CustomToggle>
    //       <Accordion.Collapse eventKey="meeting-menu">
    //         <ul className="sub-nav">
    //           <Nav.Item as="li">
    //             <Link
    //               onClick={minisidebar}
    //               className={`${
    //                 location.pathname === "dashboard/app/memberMeetingAdmin"
    //                   ? "active"
    //                   : ""
    //               } nav-link`}
    //               to="dashboard/app/memberMeetingAdmin"
    //             >
    //               <i className="icon">
    //                 <IoMdPerson />
    //               </i>
    //               <OverlayTrigger
    //                 placement="right"
    //                 overlay={
    //                   <Tooltip>
    //                     Member {getOrgNames?.alt_meeting || "Meetings"}
    //                   </Tooltip>
    //                 }
    //               >
    //                 <i className="sidenav-mini-icon">
    //                   <IoMdPerson />
    //                 </i>
    //               </OverlayTrigger>
    //               <span className="item-name">
    //                 Member {getOrgNames?.alt_meeting || "Meetings"}
    //               </span>
    //             </Link>
    //           </Nav.Item>

    //           <Nav.Item as="li">
    //             <Link
    //               onClick={minisidebar}
    //               className={`${
    //                 location.pathname === "dashboards/app/chapter-meeting"
    //                   ? "active"
    //                   : ""
    //               } nav-link`}
    //               to="dashboards/app/chapter-meeting"
    //             >
    //               <i className="icon">
    //                 <IoMdPeople />
    //               </i>
    //               <OverlayTrigger
    //                 placement="right"
    //                 overlay={
    //                   <Tooltip>
    //                     {" "}
    //                     {getOrgNames?.alt_chapter || "Chapter"}{" "}
    //                     {getOrgNames?.alt_meeting || "Meetings"}
    //                   </Tooltip>
    //                 }
    //               >
    //                 <i className="sidenav-mini-icon">
    //                   <IoMdPeople />
    //                 </i>
    //               </OverlayTrigger>
    //               <span className="item-name">
    //                 {" "}
    //                 {getOrgNames?.alt_chapter || "Chapter"}{" "}
    //                 {getOrgNames?.alt_meeting || "Meetings"}
    //               </span>
    //             </Link>
    //           </Nav.Item>
    //         </ul>
    //       </Accordion.Collapse>
    //     </Accordion.Item>

    //     <Nav.Item as="li">
    //       <Link
    //         onClick={handleLogout}
    //         className={`${
    //           location.pathname === "/auth/sign-in" ? "active" : ""
    //         } nav-link `}
    //         aria-current="page"
    //       >
    //         <OverlayTrigger
    //           placement="right"
    //           overlay={<Tooltip>logout</Tooltip>}
    //         >
    //           <i className="icon material-symbols-outlined">logout</i>
    //         </OverlayTrigger>
    //         <span className="item-name">Logout</span>
    //       </Link>
    //     </Nav.Item>
    //   </Accordion>
    // );

    //menu list for Admin
    const renderOrgAdminMenu = (
      // JSX for Admin menu
      <Accordion
        as="ul"
        className="navbar-nav iq-main-menu pt-3"
        id="sidebar-menu"
      >
        <li
          className={`nav-item ${
            location.pathname === "/userposts" ? "active" : ""
          }`}
        >
          <Link
            onClick={minisidebar}
            className={`nav-link ${
              location.pathname === "/userposts" ? "active" : ""
            }`}
            aria-current="page"
            to="/userposts"
          >
            <OverlayTrigger placement="right" overlay={<Tooltip>Home</Tooltip>}>
              <i className="icon material-symbols-outlined">newspaper</i>
            </OverlayTrigger>
            <span className="item-name">Home</span>
          </Link>
        </li>
        {userRole === "SUPER_ADMIN" ? (
          <React.Fragment>
            <li
              className={`${
                location.pathname === "/superAdminDashboard" ? "active" : ""
              } nav-item `}
            >
              <Link
                onClick={minisidebar}
                className={`${
                  location.pathname === "/superAdminDashboard" ? "active" : ""
                } nav-link `}
                aria-current="page"
                to="/superAdminDashboard"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Dashboard</Tooltip>}
                >
                  <i className="icon material-symbols-outlined">Dashboard</i>
                </OverlayTrigger>
                <span className="item-name">Dashboard</span>
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "dashboard/app/organizations"
                  ? "active"
                  : ""
              } nav-item `}
            >
              <Link
                onClick={minisidebar}
                className={`${
                  location.pathname === "dashboard/app/organizations"
                    ? "active"
                    : ""
                } nav-link `}
                aria-current="page"
                to="dashboard/app/organizations"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Organizations</Tooltip>}
                >
                  <i className="icon material-symbols-outlined">
                    corporate_fare
                  </i>
                </OverlayTrigger>
                <span className="item-name">Organizations</span>
              </Link>
            </li>
          </React.Fragment>
        ) : (
          <li
            className={`${
              location.pathname === "/adminDashboard" ? "active" : ""
            } nav-item `}
          >
            <Link
              onClick={minisidebar}
              className={`${
                location.pathname === "/adminDashboard" ? "active" : ""
              } nav-link `}
              aria-current="page"
              to="/adminDashboard"
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Dashboard</Tooltip>}
              >
                <i className="icon material-symbols-outlined">Dashboard</i>
              </OverlayTrigger>
              <span className="item-name">Dashboard</span>
            </Link>
          </li>
        )}

        {userRole !== "CHAPTER_ADMIN" && (
          <React.Fragment>
            <li
              className={`nav-item ${
                location.pathname === "/OrgSetting" ? "active" : ""
              }`}
            >
              <Link
                onClick={minisidebar}
                className={`nav-link ${
                  location.pathname === "/OrgSetting" ? "active" : ""
                }`}
                aria-current="page"
                to="/OrgSetting"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Organisation Setting</Tooltip>}
                >
                  <i className="icon material-symbols-outlined">settings</i>
                </OverlayTrigger>
                <span className="item-name">Organisation Setting</span>
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/orgGallery" ? "active" : ""
              }`}
            >
              <Link
                onClick={minisidebar}
                className={`nav-link ${
                  location.pathname === "/orgGallery" ? "active" : ""
                }`}
                aria-current="page"
                to="/orgGallery"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Organisation Gallery</Tooltip>}
                >
                  <i className="icon material-symbols-outlined">image</i>
                </OverlayTrigger>
                <span className="item-name">Organisation Gallery</span>
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/OrgSetting" ? "active" : ""
              }`}
            >
              <Link
                onClick={minisidebar}
                className={`nav-link ${
                  location.pathname === "/visitors" ? "active" : ""
                }`}
                aria-current="page"
                to="/visitors"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Visitor</Tooltip>}
                >
                  <i className="icon material-symbols-outlined">
                    nest_doorbell_visitor
                  </i>
                </OverlayTrigger>
                <span className="item-name">Visitor</span>
              </Link>
            </li>
            {/* <li
              className={`nav-item ${
                location.pathname === "/dashboard/createOrgAdmin"
                  ? "active"
                  : ""
              }`}
            >
              <Link
                onClick={minisidebar}
                className={`nav-link ${
                  location.pathname === "/dashboard/createOrgAdmin"
                    ? "active"
                    : ""
                }`}
                aria-current="page"
                to="/dashboard/createOrgAdmin"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Create Org Admin</Tooltip>}
                >
                  <i className="icon material-symbols-outlined">person_add</i>
                </OverlayTrigger>
                <span className="item-name">Create Org Admin</span>
              </Link>
            </li> */}
            <li
              className={`nav-item ${
                location.pathname === "/dashboard/createAdmin" ? "active" : ""
              }`}
            >
              <Link
                onClick={minisidebar}
                className={`nav-link ${
                  location.pathname === "/dashboard/createAdmin" ? "active" : ""
                }`}
                aria-current="page"
                to="/dashboard/createAdmin"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Create Chapter Admin</Tooltip>}
                >
                  <i className="icon material-symbols-outlined">
                    admin_panel_settings
                  </i>
                </OverlayTrigger>
                <span className="item-name">Create Chapter Admin</span>
              </Link>
            </li>

            <li
              className={`nav-item ${
                location.pathname === "/NamingConvention" ? "active" : ""
              }`}
            >
              <Link
                onClick={minisidebar}
                className={`nav-link ${
                  location.pathname === "/NamingConvention" ? "active" : ""
                }`}
                aria-current="page"
                to="/NamingConvention"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Naming Convention</Tooltip>}
                >
                  <i className="icon material-symbols-outlined">Description</i>
                </OverlayTrigger>
                <span className="item-name">Naming Convention</span>
              </Link>
            </li>
            <Accordion.Item
              as="li"
              eventKey="members-menu"
              bsPrefix={`nav-item ${active === "profile" ? "active" : ""}`}
              onClick={() => setActive("profile")}
            >
              <CustomToggle
                eventKey="members-menu"
                onClick={(activeKey) => setActiveMenu(activeKey)}
                onMenuItemClick={onMenuItemClick}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Master Data</Tooltip>}
                >
                  <i className="icon material-symbols-outlined">table_view</i>
                </OverlayTrigger>
                <span className="item-name">Master Data</span>
                <i className="right-icon material-symbols-outlined">
                  chevron_right
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="members-menu">
                <ul className="sub-nav">
                  <Nav.Item as="li">
                    <Link
                      onClick={minisidebar}
                      className={`nav-link ${
                        location.pathname === "/dashboards/app/mship-plans"
                          ? "active"
                          : ""
                      }`}
                      to="/dashboards/app/mship-plans"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip>
                            {getOrgNames?.alt_membership_plan ||
                              "Membership Plan"}
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon">M</i>
                      </OverlayTrigger>
                      <span className="item-name">
                        {getOrgNames?.alt_membership_plan || "Membership Plan"}
                      </span>
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link
                      onClick={minisidebar}
                      className={`nav-link ${
                        location.pathname === "/dashboards/app/mcategory"
                          ? "active"
                          : ""
                      }`}
                      to="/dashboards/app/mcategory"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip>
                            {getOrgNames?.alt_membership_category ||
                              "Member Categories"}
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon">P1</i>
                      </OverlayTrigger>
                      <span className="item-name">
                        {getOrgNames?.alt_membership_category ||
                          "Member Categories"}
                      </span>
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link
                      onClick={minisidebar}
                      className={`nav-link ${
                        location.pathname === "/dashboards/app/chapters"
                          ? "active"
                          : ""
                      }`}
                      to="/dashboards/app/chapters"
                    >
                      <i className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip>
                            {getOrgNames?.alt_chapter || "Chapters"}
                          </Tooltip>
                        }
                      >
                        <i className="sidenav-mini-icon">P2</i>
                      </OverlayTrigger>
                      <span className="item-name">
                        {getOrgNames?.alt_chapter || "Chapters"}
                      </span>
                    </Link>
                  </Nav.Item>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
          </React.Fragment>
        )}
        {userRole === "ORG_ADMIN" ||
        userRole === "SUPER_ADMIN" ||
        (userRole === "CHAPTER_ADMIN" &&
          getSettingNames.member_onboard_authority === 1) ? (
                  <>
                    <li
                      className={`${
                        location.pathname === "/dashboards/app/renew-mship"
                          ? "active"
                          : ""
                      } nav-item `}
                    >
                      <Link
                        onClick={minisidebar}
                        className={`${
                          location.pathname === "/dashboards/app/renew-mship"
                            ? "active"
                            : ""
                        } nav-link `}
                        aria-current="page"
                        to="/dashboards/app/renew-mship"
                      >
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip>Membership Requests</Tooltip>}
                        >
                          <i className="icon material-symbols-outlined">group_add</i>
                        </OverlayTrigger>
                        <span className="item-name">Membership Requests</span>
                      </Link>
                    </li>
                  </>
                ) : null}

        <li
          className={`${
            location.pathname === "dashboards/app/RenuewMemberList"
              ? "active"
              : ""
          } nav-item `}
        >
          <Link
            onClick={minisidebar}
            className={`${
              location.pathname === "/dashboards/app/RenuewMemberList"
                ? "active"
                : ""
            } nav-link `}
            aria-current="page"
            to="/dashboards/app/RenuewMemberList"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Renew Member List</Tooltip>}
            >
              <i className="icon material-symbols-outlined"> person </i>
            </OverlayTrigger>
            <span className="item-name">Renew Member List</span>
          </Link>
        </li>

        <li
          className={`${
            location.pathname === "/dashboards/app/members" ? "active" : ""
          } nav-item `}
        >
          <Link
            onClick={minisidebar}
            className={`${
              location.pathname === "/dashboards/app/members" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/dashboards/app/members"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Members</Tooltip>}
            >
              <i className="icon material-symbols-outlined">groups_2</i>
            </OverlayTrigger>
            <span className="item-name">Members</span>
          </Link>
        </li>

        <li
          className={`${
            location.pathname === "dashboard/createEvent" ? "active" : ""
          } nav-item `}
        >
          <Link
            onClick={minisidebar}
            className={`${
              location.pathname === "dashboard/createEvent" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="dashboard/createEvent"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Event</Tooltip>}
            >
              {/* <Tooltip>{getOrgNames.alt_event ? `Event: ${getOrgNames.alt_event}` : "Event"}</Tooltip> */}
              <i className="icon material-symbols-outlined">event</i>
            </OverlayTrigger>
            <span className="item-name">Event</span>
            {/* <span className="item-name">{getOrgNames.alt_event || "Event"}</span> */}
          </Link>
        </li>
        <li
          className={`${
            location.pathname === "dashboard/createTreanning" ? "active" : ""
          } nav-item `}
        >
          <Link
            onClick={minisidebar}
            className={`${
              location.pathname === "dashboard/createTreanning" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="dashboard/createTreanning"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Training</Tooltip>}
            >
              <i className="icon material-symbols-outlined">school</i>
            </OverlayTrigger>
            <span className="item-name">Training</span>
          </Link>
        </li>

        {userRole == "CHAPTER_ADMIN" ? (
          <>
            <Accordion.Item
              as="li"
              eventKey="referrals-menu"
              bsPrefix="nav-item"
            >
              <CustomToggle
                eventKey="referrals-menu"
                onClick={(activeKey) => setActiveMenu(activeKey)}
                onMenuItemClick={onMenuItemClick}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Referrals</Tooltip>}
                >
                  <i className="icon material-symbols-outlined">task_alt</i>
                </OverlayTrigger>
                <span className="item-name">Referrals</span>
                <i className="right-icon material-symbols-outlined">
                  chevron_right
                </i>
              </CustomToggle>
              <Accordion.Collapse eventKey="referrals-menu">
                <ul className="sub-nav">
                  <Nav.Item as="li">
                    <Link
                      onClick={minisidebar}
                      className={`${
                        location.pathname ===
                        "dashboards/app/chapter-received-referrals"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="dashboards/app/chapter-received-referrals"
                    >
                      <i className="icon">
                        <svg
                          className="icon-10"
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>Referrals Received</Tooltip>}
                      >
                        <i className="sidenav-mini-icon"> FM </i>
                      </OverlayTrigger>
                      <span className="item-name">Referrals Received</span>
                    </Link>
                  </Nav.Item>

                  <Nav.Item as="li">
                    <Link
                      onClick={minisidebar}
                      className={`${
                        location.pathname ===
                        "dashboards/app/chapter-sent-referrals"
                          ? "active"
                          : ""
                      } nav-link`}
                      to="dashboards/app/chapter-sent-referrals"
                    >
                      <i className="icon">
                        <svg
                          className="icon-10"
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <g>
                            <circle
                              cx="12"
                              cy="12"
                              r="8"
                              fill="currentColor"
                            ></circle>
                          </g>
                        </svg>
                      </i>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>Referrals Sent</Tooltip>}
                      >
                        <i className="sidenav-mini-icon"> BM </i>
                      </OverlayTrigger>
                      <span className="item-name">Referrals Sent</span>
                    </Link>
                  </Nav.Item>
                </ul>
              </Accordion.Collapse>
            </Accordion.Item>
            <li
              className={`nav-item ${
                location.pathname === "/OrgSetting" ? "active" : ""
              }`}
            >
              <Link
                onClick={minisidebar}
                className={`nav-link ${
                  location.pathname === "/visitors" ? "active" : ""
                }`}
                aria-current="page"
                to="/visitors"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Visitor</Tooltip>}
                >
                  <i className="icon material-symbols-outlined">
                    nest_doorbell_visitor
                  </i>
                </OverlayTrigger>
                <span className="item-name">Visitor</span>
              </Link>
            </li>
          </>
        ) : (
          <Nav.Item as="li">
            <Link
              onClick={minisidebar}
              className={`${
                location.pathname === "dashboard/app/referralsList"
                  ? "active"
                  : ""
              } nav-link `}
              aria-current="page"
              to="dashboard/app/referralsList"
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Referrals</Tooltip>}
              >
                <i className="icon material-symbols-outlined">task_alt</i>
              </OverlayTrigger>
              <span className="item-name">Referrals</span>
            </Link>
          </Nav.Item>
        )}

        <Nav.Item as="li">
          <Link
            onClick={minisidebar}
            className={`${
              location.pathname === "/org-thank-you-notes-recieved"
                ? "active"
                : ""
            } nav-link `}
            aria-current="page"
            to="/org-thank-you-notes-recieved"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Thank You Note</Tooltip>}
            >
              <i className="icon material-symbols-outlined">folded_hands</i>
            </OverlayTrigger>
            <span className="item-name">Thank You Note</span>
          </Link>
        </Nav.Item>

        {/* {userRole == "CHAPTER_ADMIN" ? ( */}
        <Accordion.Item as="li" eventKey="meeting-menu" bsPrefix="nav-item">
          <CustomToggle
            eventKey="meeting-menu"
            onClick={(activeKey) => setActiveMenu(activeKey)}
            onMenuItemClick={onMenuItemClick}
          >
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip>{getOrgNames?.alt_meeting || "Meetings"}</Tooltip>
              }
            >
              <i className="icon material-symbols-outlined">
                <IoMdPeople />
              </i>
            </OverlayTrigger>
            <span className="item-name">
              {getOrgNames?.alt_meeting || "Meetings"}
            </span>
            <i className="right-icon material-symbols-outlined">
              chevron_right
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="meeting-menu">
            <ul className="sub-nav">
              <Nav.Item as="li">
                <Link
                  onClick={minisidebar}
                  className={`${
                    location.pathname === "dashboard/app/memberMeetingAdmin"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="dashboard/app/memberMeetingAdmin"
                >
                  <i className="icon">
                    <IoMdPerson />
                  </i>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Member {getOrgNames?.alt_meeting || "Meetings"}
                      </Tooltip>
                    }
                  >
                    <i className="sidenav-mini-icon">
                      <IoMdPerson />
                    </i>
                  </OverlayTrigger>
                  <span className="item-name">
                    Member {getOrgNames?.alt_meeting || "Meetings"}
                  </span>
                </Link>
              </Nav.Item>

              <Nav.Item as="li">
                <Link
                  onClick={minisidebar}
                  className={`${
                    location.pathname === "dashboards/app/chapter-meeting"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="dashboards/app/chapter-meeting"
                >
                  <i className="icon">
                    <IoMdPeople />
                  </i>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        {" "}
                        {getOrgNames?.alt_chapter || "Chapter"}{" "}
                        {getOrgNames?.alt_meeting || "Meetings"}
                      </Tooltip>
                    }
                  >
                    <i className="sidenav-mini-icon">
                      <IoMdPeople />
                    </i>
                  </OverlayTrigger>
                  <span className="item-name">
                    {" "}
                    {getOrgNames?.alt_chapter || "Chapter"}{" "}
                    {getOrgNames?.alt_meeting || "Meetings"}
                  </span>
                </Link>
              </Nav.Item>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>
        {/* ) : (
        <Nav.Item as="li">
          <Link
            onClick={minisidebar}
            className={`${
              location.pathname === "dashboard/app/AllMeetingList"
                ? "active"
                : ""
            } nav-link `}
            aria-current="page"
            to="dashboard/app/AllMeetingList"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>{getOrgNames?.alt_meeting || "Meetings"}</Tooltip>}
            >
              <i className="icon material-symbols-outlined">notifications</i>
            </OverlayTrigger>
            <span className="item-name">All {getOrgNames?.alt_meeting || "Meetings"}</span>
          </Link>
        </Nav.Item>
      )} */}

        <Nav.Item as="li">
          <Link
            onClick={handleLogout}
            className={`${
              location.pathname === "/auth/sign-in" ? "active" : ""
            } nav-link `}
            aria-current="page"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>logout</Tooltip>}
            >
              <i className="icon material-symbols-outlined">logout</i>
            </OverlayTrigger>
            <span className="item-name">Logout</span>
          </Link>
        </Nav.Item>
      </Accordion>
    );

    //Menu list for Member
    const renderMemberMenu = (
      // JSX for Member menu
      <Accordion
        as="ul"
        className="navbar-nav iq-main-menu pt-3"
        id="sidebar-menu"
      >
        <li
          className={`${
            location.pathname === "/userposts" ? "active" : ""
          } nav-item `}
        >
          <Link
            onClick={minisidebar}
            className={`${
              location.pathname === "/userposts" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/userposts"
          >
            <OverlayTrigger placement="right" overlay={<Tooltip>Home</Tooltip>}>
              <i className="icon material-symbols-outlined">newspaper</i>
            </OverlayTrigger>
            <span className="item-name">Home</span>
          </Link>
        </li>
        <li
          onClick={minisidebar}
          className={`${
            location.pathname === "/userDashboard" ? "active" : ""
          } nav-item `}
        >
          <Link
            className={`${
              location.pathname === "/userDashboard" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/userDashboard"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Dashboard</Tooltip>}
            >
              <i className="icon material-symbols-outlined">Dashboard</i>
            </OverlayTrigger>
            <span className="item-name">Dashboard</span>
          </Link>
        </li>

        <Nav.Item as="li">
          <Link
            onClick={minisidebar}
            className={`${
              location.pathname === "dashboard/app/MembersList" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="dashboard/app/MembersList"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Members</Tooltip>}
            >
              <i className="icon material-symbols-outlined">person</i>
            </OverlayTrigger>
            <span className="item-name">Members</span>
          </Link>
        </Nav.Item>

        <Accordion.Item as="li" eventKey="referrals-menu" bsPrefix="nav-item">
          <CustomToggle
            eventKey="referrals-menu"
            onClick={(activeKey) => setActiveMenu(activeKey)}
            onMenuItemClick={onMenuItemClick}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Referrals</Tooltip>}
            >
              <i className="icon material-symbols-outlined">task_alt</i>
            </OverlayTrigger>
            <span className="item-name">Referrals</span>
            <i className="right-icon material-symbols-outlined">
              chevron_right
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="referrals-menu">
            <ul className="sub-nav">
              <Nav.Item as="li">
                <Link
                  onClick={minisidebar}
                  className={`${
                    location.pathname === "dashboards/app/for-me"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="dashboards/app/for-me"
                >
                  <i className="icon">
                    <svg
                      className="icon-10"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Referrals Received</Tooltip>}
                  >
                    <i className="sidenav-mini-icon"> FM </i>
                  </OverlayTrigger>
                  <span className="item-name">Referrals Received</span>
                </Link>
              </Nav.Item>

              <Nav.Item as="li">
                <Link
                  onClick={minisidebar}
                  className={`${
                    location.pathname === "dashboards/app/by-me" ? "active" : ""
                  } nav-link`}
                  to="dashboards/app/by-me"
                >
                  <i className="icon">
                    <svg
                      className="icon-10"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Referrals Sent</Tooltip>}
                  >
                    <i className="sidenav-mini-icon"> BM </i>
                  </OverlayTrigger>
                  <span className="item-name">Referrals Sent</span>
                </Link>
              </Nav.Item>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>

        <Accordion.Item as="li" eventKey="thanknote-menu" bsPrefix="nav-item">
          <CustomToggle
            eventKey="thanknote-menu"
            onClick={(activeKey) => setActiveMenu(activeKey)}
            onMenuItemClick={onMenuItemClick}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Thank You Note</Tooltip>}
            >
              <i className="icon material-symbols-outlined">folded_hands</i>
            </OverlayTrigger>
            <span className="item-name">Thank You Note</span>
            <i className="right-icon material-symbols-outlined">
              chevron_right
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="thanknote-menu">
            <ul className="sub-nav">
              <Nav.Item as="li">
                <Link
                  onClick={minisidebar}
                  className={`${
                    location.pathname === "/org-thank-you-notes-recieved"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="/org-thank-you-notes-recieved"
                >
                  <i className="icon">
                    <svg
                      className="icon-10"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>TY Notes Received</Tooltip>}
                  >
                    <i className="sidenav-mini-icon"> NR </i>
                  </OverlayTrigger>
                  <span className="item-name">TY Notes Received</span>
                </Link>
              </Nav.Item>

              <Nav.Item as="li">
                <Link
                  onClick={minisidebar}
                  className={`${
                    location.pathname === "/org-thank-you-notes-sent"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="/org-thank-you-notes-sent"
                >
                  <i className="icon">
                    <svg
                      className="icon-10"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                  </i>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>TY Notes Sent</Tooltip>}
                  >
                    <i className="sidenav-mini-icon"> NS </i>
                  </OverlayTrigger>
                  <span className="item-name">TY Notes Sent</span>
                </Link>
              </Nav.Item>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>

        <Accordion.Item as="li" eventKey="meeting-menu" bsPrefix="nav-item">
          <CustomToggle
            eventKey="meeting-menu"
            onClick={(activeKey) => setActiveMenu(activeKey)}
            onMenuItemClick={onMenuItemClick}
          >
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip>{getOrgNames?.alt_meeting || "Meetings"}</Tooltip>
              }
            >
              <i className="icon material-symbols-outlined">
                <IoMdPeople />
              </i>
            </OverlayTrigger>
            <span className="item-name">
              {getOrgNames?.alt_meeting || "Meetings"}
            </span>
            <i className="right-icon material-symbols-outlined">
              chevron_right
            </i>
          </CustomToggle>
          <Accordion.Collapse eventKey="meeting-menu">
            <ul className="sub-nav">
              <Nav.Item as="li">
                <Link
                  onClick={minisidebar}
                  className={`${
                    location.pathname === "dashboards/app/meeting"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="dashboards/app/meeting"
                >
                  <i className="icon">
                    <IoMdPerson />
                  </i>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Member {getOrgNames?.alt_meeting || "Meetings"}
                      </Tooltip>
                    }
                  >
                    <i className="sidenav-mini-icon">
                      <IoMdPerson />
                    </i>
                  </OverlayTrigger>
                  <span className="item-name">
                    Member {getOrgNames?.alt_meeting || "Meetings"}
                  </span>
                </Link>
              </Nav.Item>

              <Nav.Item as="li">
                <Link
                  onClick={minisidebar}
                  className={`${
                    location.pathname === "dashboards/app/chapter-meeting"
                      ? "active"
                      : ""
                  } nav-link`}
                  to="dashboards/app/chapter-meeting"
                >
                  <i className="icon">
                    <IoMdPeople />
                  </i>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        {getOrgNames?.alt_chapter || "Chapter"}{" "}
                        {getOrgNames?.alt_meeting || "Meetings"}
                      </Tooltip>
                    }
                  >
                    <i className="sidenav-mini-icon">
                      <IoMdPeople />
                    </i>
                  </OverlayTrigger>
                  <span className="item-name">
                    {getOrgNames?.alt_chapter || "Chapter"}{" "}
                    {getOrgNames?.alt_meeting || "Meetings"}
                  </span>
                </Link>
              </Nav.Item>
            </ul>
          </Accordion.Collapse>
        </Accordion.Item>

        {/* <Nav.Item as="li">
                <Link onClick={minisidebar} className={`${location.pathname === 'dashboards/app/meeting' ? 'active' : ''} nav-link`} to="dashboards/app/meeting">
                    <i className="icon">
                        <IoMdPerson />
                    </i>
                    <OverlayTrigger placement="right" overlay={<Tooltip>Member Meetings</Tooltip>}>
                        <i className="sidenav-mini-icon"><IoMdPerson /></i>
                    </OverlayTrigger>
                    <span className="item-name">Member Meetings</span>
                </Link>
            </Nav.Item> */}

        <Nav.Item as="li">
          <Link
            onClick={minisidebar}
            className={`${
              location.pathname === "/dashboards/app/profile-videos"
                ? "active"
                : ""
            } nav-link`}
            to="/dashboards/app/profile-videos"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Files</Tooltip>}
            >
              <i className="icon material-symbols-outlined">
                insert_drive_file
              </i>
            </OverlayTrigger>
            <span className="item-name">Files</span>
          </Link>
        </Nav.Item>

        <Nav.Item as="li">
          <Link
            onClick={handleLogout}
            className={`${
              location.pathname === "/auth/sign-in" ? "active" : ""
            } nav-link `}
            aria-current="page"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>logout</Tooltip>}
            >
              <i className="icon material-symbols-outlined">logout</i>
            </OverlayTrigger>
            <span className="item-name">Logout</span>
          </Link>
        </Nav.Item>
      </Accordion>
    );

      const renderSuperAdminMenu = (
    // JSX for Admin menu
    <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
      <li className="nav-item static-item">
        <Link
          onClick={minisidebar}
          className="nav-link static-item disabled"
          to="#"
          tabIndex="-1"
        >
          <span className="default-icon">Social</span>
          <span
            className="mini-icon"
            data-bs-toggle="tooltip"
            title="Social"
            data-bs-placement="right"
          >
            -
          </span>
        </Link>
      </li>

      {/* <li
        className={`nav-item ${
          location.pathname === "/userposts" ? "active" : ""
        }`}
      >
        <Link
          onClick={minisidebar}
          className={`nav-link ${
            location.pathname === "/userposts" ? "active" : ""
          }`}
          aria-current="page"
          to="/userposts"
        >
          <OverlayTrigger placement="right" overlay={<Tooltip>Home</Tooltip>}>
            <i className="icon material-symbols-outlined">newspaper</i>
          </OverlayTrigger>
          <span className="item-name">Home</span>
        </Link>
      </li> */}
      <li
        className={`${
          location.pathname === "/superAdminDashboard" ? "active" : ""
        } nav-item `}
      >
        <Link
          onClick={minisidebar}
          className={`${
            location.pathname === "/superAdminDashboard" ? "active" : ""
          } nav-link `}
          aria-current="page"
          to="/superAdminDashboard"
        >
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Dashboard</Tooltip>}
          >
            <i className="icon material-symbols-outlined">Dashboard</i>
          </OverlayTrigger>
          <span className="item-name">Dashboard</span>
        </Link>
      </li>

      <li
        className={`${
          location.pathname === "dashboard/app/organizations" ? "active" : ""
        } nav-item `}
      >
        <Link
          onClick={minisidebar}
          className={`${
            location.pathname === "dashboard/app/organizations" ? "active" : ""
          } nav-link `}
          aria-current="page"
          to="dashboard/app/organizations"
        >
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Organizations</Tooltip>}
          >
            <i className="icon material-symbols-outlined">corporate_fare</i>
          </OverlayTrigger>
          <span className="item-name">Organizations</span>
        </Link>
      </li>

      <Nav.Item as="li">
        <Link
          onClick={handleLogout}
          className={`${
            location.pathname === "/auth/sign-in" ? "active" : ""
          } nav-link `}
          aria-current="page"
        >
          <OverlayTrigger placement="right" overlay={<Tooltip>logout</Tooltip>}>
            <i className="icon material-symbols-outlined">logout</i>
          </OverlayTrigger>
          <span className="item-name">Logout</span>
        </Link>
      </Nav.Item>
    </Accordion>
  );

    const renderMenu =
      userRole === "CHAPTER_ADMIN"
        ? renderOrgAdminMenu
        : userRole === "ORG_ADMIN"
        ? renderOrgAdminMenu
        : userRole === "SUPER_ADMIN"
        ? renderSuperAdminMenu
        : renderMemberMenu;

    return <React.Fragment>{renderMenu}</React.Fragment>;
  }
);

export default VerticalNav;
