import React from "react";
import AdminDashboard from "../views/dashboard/app/admin";
import UserDashboard from "../views/dashboard/app/user";
import Index from "../views/dashboard/index";
import MembersList from "../views/dashboard/app/memberList";
import CreateAdmin from "../views/dashboard/app/createAdmin";
import Training from "../views/dashboard/app/training";
import TrainingList from "../views/dashboard/app/trainingList";
import CreateTreanning from "../views/dashboard/app/createTreanning";
import Event from "../views/dashboard/app/event";
import EventList from "../views/dashboard/app/eventList";
import CreateEventForm from "../views/dashboard/app/createEventForm";
import FriendProfile from "../views/dashboard/app/friend-profile";
import UserProfileEdit from "../views/dashboard/app/user-profile-edit";
import AllMeetingList from "../views/dashboard/app/allMeeteingList";
import MemberMeetingAdmin from "../views/dashboard/app/memberMeetingAdmin";
import ReferralsList from "../views/dashboard/app/referralsList";
import MembershipPlan from "../views/dashboard/app/mship-plans";
import UserProfileEditPassword from "../views/dashboard/app/user-profile-editpassword";
import UserEditCreditials from "../views/dashboard/app/user_edit_creditials";
import Admin from "../views/dashboard/app/admin";
import AdminPosts from "../views/dashboard/adminPosts";
import Organizations from "../views/dashboard/app/organizations";
import OrganizationList from "../views/dashboard/app/orgList";
import CreateOrganization from "../views/dashboard/app/createOrg";
import SuperAdminDashboard from "../views/dashboard/app/superAdmin";
import OrgSetting from "../views/dashboard/app/orgSetting";
import OrgGallery from "../views/dashboard/app/orgGallery";
import NamingConvention from "../views/dashboard/app/namingConvention";
import MembershipRequest from "../views/dashboard/app/membershipRequest";
import Visitor from "../views/dashboard/app/visitor";
import OrgThankYouNoteSent from "../views/dashboard/app/OrgThankYouNote/thankYouNotesSent";
import OrgThankYouNoteRecieved from "../views/dashboard/app/OrgThankYouNote/thankYouNotesRecieved";
import CreateOrgAdmin from "../views/dashboard/app/createOrgAdmin";

export const DefaultRouter = [
  {
    path: "/userposts",
    element: <Index />,
  },
  {
    path: "/org-thank-you-notes-sent",
    element: <OrgThankYouNoteSent />,
  },
  {
    path: "/org-thank-you-notes-recieved",
    element: <OrgThankYouNoteRecieved />,
  },
  {
    path: "/OrgSetting",
    element: <OrgSetting />,
  },
  {
    path: "/NamingConvention",
    element: <NamingConvention />,
  },
  {
    path: "/orgGallery",
    element: <OrgGallery />,
  },
  {
    path: "/dashboards/app/renew-mship",
    element: <MembershipRequest />,
  },
  {
    path: "/superAdminDashboard",
    element: <SuperAdminDashboard />,
  },
  {
    path: "dashboard/app/organizations",
    element: <Organizations />,
  },
  {
    path: "dashboard/app/org-list",
    element: <OrganizationList />,
  },
  {
    path: "dashboard/app/create-org",
    element: <CreateOrganization />,
  },
  {
    path: "/adminDashboard",
    element: <AdminDashboard />,
  },
  {
    path: "/user-resetPassword",
    element: <UserProfileEditPassword />,
  },
  {
    path: "/userDashboard",
    element: <UserDashboard />,
  },
  {
    path: "/userEditCreditials",
    element: <UserEditCreditials />,
  },
  {
    path: "dashboard/app/MembersList",
    element: <MembersList />,
  },
  {
    path: "/dashboard/app/fprofile",
    element: <FriendProfile />,
  },
  {
    path: "dashboard/app/profile-forum",
    element: <userEditCreditials />,
  },
  {
    path: "dashboards/app/mship-plans",
    element: <MembershipPlan />,
  },
  {
    path: "dashboard/app/user-profile-edit",
    element: <UserProfileEdit />,
  },
  {
    path: "dashboard/app/AllMeetingList",
    element: <AllMeetingList />,
  },
  {
    path: "dashboard/app/memberMeetingAdmin",
    element: <MemberMeetingAdmin />,
  },
  {
    path: "/visitors",
    element: <Visitor />,
  },
  {
    path: "dashboard/app/referralsList",
    element: <ReferralsList />,
  },
  {
    path: "dashboard/createAdmin",
    element: <CreateAdmin />,
  },
  {
    path: "dashboard/createOrgAdmin",
    element: <CreateOrgAdmin />,
  },
  {
    path: "dashboard/createEvent",
    element: <Event />,
  },
  {
    path: "dashboard/createEventForm",
    element: <CreateEventForm />,
  },
  {
    path: "dashboard/eventList",
    element: <EventList />,
  },
  {
    path: "dashboard/createTreanning",
    element: <Training />,
  },
  {
    path: "dashboard/createTreanning/List",
    element: <TrainingList />,
  },
  {
    path: "dashboard/createTreanning/Form",
    element: <CreateTreanning />,
  },
  {
    path: "dashboard/createTreanning/adminPost",
    element: <AdminPosts />,
  },
  {
    path: "dashboard/app/admin",
    element: <Admin />,
  },
];
