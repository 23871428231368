import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  Table,
  Button,
  Modal,
  Dropdown,
} from "react-bootstrap";
import {
  FaTrash,
  FaEye,
  FaComment,
  FaEllipsisV,
  FaTimes,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import MyReferralComment from "./myReferralComment";
import DeleteReferral from "./deleteReferral";
import axios from "axios";
// Component to manage and display referrals for the user
const ForMe = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Destructure state from location if available
  const { searchResults, alertMessage } = location.state || {};

  // State variables to manage data and UI
  const [referrals, setReferrals] = useState([]); // Stores referrals
  const [eyeClickedRow, setEyeClickedRow] = useState(null); // Manages row state for viewing details
  const [commentRow, setCommentRow] = useState(null); // Manages row state for commenting
  const [deleteRow, setDeleteRow] = useState(null); // Manages row state for deleting
  const [referralschange, setReferralschange] = useState([]); // Stores changed referrals
  const token = sessionStorage.getItem("token"); // Retrieve token from session storage
  const memberId = sessionStorage.getItem("member_id"); // Retrieve member ID from session storage
  const [successMessages, setSuccessMessages] = useState([]); // Stores success messages
  const [error, setError] = useState(null); // Manages error state
  const [tempAmounts, setTempAmounts] = useState({}); // Temporarily stores amounts for editing
  const [showdelete, setShowdelete] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = (index) => {
    setMemberToDelete(index);
    setShowdelete(true);
  };
  // Function to update referral status and amount
  const updateReferralBDA = async (
    referralId,
    business_done_amount,
    memberId
  ) => {
    try {
      const data = {
        ref_amount: business_done_amount,
        created_by: memberId,
      };

      // Make API request to update the referral status
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/referrals/update-referral/status/${referralId}`,
        {
          method: "PUT", // Use PUT method to update
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      handleSuccess("Business Done successfully"); // Handle success message
    } catch (error) {
      setError("Failed to update referral status"); // Handle error state
      console.error("Error updating referral status:", error); // Log error
    }
  };

  // Another function to update referral status (duplicated for possible different handling)
  const updateReferralStatus = async (
    referralId,
    newStatus,
    memberId
  ) => {
    try {
      const data = {
        ref_status: newStatus,
        created_by: memberId,
      };

      // Make API request to update the referral status
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/referrals/update-referral/onlystatus/${referralId}`,
        {
          method: "PUT", // Use PUT method to update
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      // Commented out handleSuccess call to avoid duplicate messages
      // handleSuccess("Business Done successfully");
    } catch (error) {
      setError("Failed to update referral status"); // Handle error state
      console.error("Error updating referral status:", error); // Log error
    }
  };

  // Handle status change for a referral
  const handleStatusChange = async (e, index) => {
    try {
      const newStatus = e.target.value; // Get new status from event
      const updatedReferrals = [...referrals];
      updatedReferrals[index].ref_status = newStatus;

      setReferrals(updatedReferrals); // Update referrals state

      // Call function to update referral status
      await updateReferralStatus(
        updatedReferrals[index].referral_id,
        newStatus,
        memberId
      );
    } catch (error) {
      console.error("Error handling status change:", error); // Log error
    }
  };

  // Handle amount change in the form
  const handleAmountChange = (e, index) => {
    try {
      const { value } = e.target;
      setTempAmounts((prev) => ({
        ...prev,
        [index]: value,
      }));
    } catch (error) {
      console.error("Error handling amount change:", error); // Log error
    }
  };

  // Handle amount submission for a referral
  const handleAmountSubmit = async () => {
    try {
      const updatedReferrals = [...referrals];
      const newAmount = tempAmounts[memberToDelete];
      updatedReferrals[memberToDelete].business_done_amount = newAmount;
      updatedReferrals[memberToDelete].submitted = true;
      setReferrals(updatedReferrals); // Update referrals state

      // Call function to update referral status
      await updateReferralBDA(
        updatedReferrals[memberToDelete].referral_id,
        updatedReferrals[memberToDelete].business_done_amount,
        memberId
      );
      handleClosedelete();
    } catch (error) {
      console.error("Error handling amount submit:", error); // Log error
    }
  };

  // Fetch referrals for the user
  const fetchReferrals = () => {
    fetch(
      process.env.REACT_APP_API_URL +
      `/referrals/get-referralForMe/${memberId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 502) {
          console.error(
            "Bad Gateway error occurred while fetching sent referrals"
          );
          return;
        }
        if (!response.ok) {
          throw Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Initialize the `submitted` state for each referral
        const initializedReferrals = data.results.reverse().map((referral) => ({
          ...referral,
          submitted:
            referral.ref_status === "Business Done" &&
            referral.ref_amount !== null,
        }));
        setReferrals(initializedReferrals); // Set fetched referrals state
      })
      .catch((error) => {
        console.error("Error fetching referrals:", error); // Log error
      });
  };

  // Effect to fetch referrals and set up polling
  useEffect(() => {
    fetchReferrals(); // Fetch initial data

    // Poll for updates every 20 seconds
    const intervalId = setInterval(() => {
      fetchReferrals();
    }, 20000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once

  // Toggle visibility of eye details
  const toggleEyeDetails = (index) => {
    setEyeClickedRow((prev) => (prev === index ? null : index));
  };

  // Toggle visibility of comment section
  const toggleCommentSection = (index) => {
    setCommentRow((prev) => (prev === index ? null : index));
  };

  // Toggle visibility of delete section
  const toggleDeleteSection = (index) => {
    setDeleteRow((prev) => (prev === index ? null : index));
  };

  // Show success message
  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  // Clear error message
  const clearError = () => {
    setError(null);
  };

  // Handle document click to close expanded rows if clicked outside
  const handleDocumentClick = (event) => {
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    if (!clickedInsideExpandedRow) {
      setEyeClickedRow(null);
      setCommentRow(null);
      setDeleteRow(null);
    }
  };

  // Add event listener for document clicks
  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [eyeClickedRow, commentRow, deleteRow]);

  // Render the component UI
  return (
    <div id="content-page" className="content-page">
      <Container>
        <Row>
          <Col lg={12}>
            <Card className="mt-3 p-1">
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">
                    <b>Referrals Received</b>
                  </h4>
                </div>
              </Card.Header>

              <Col lg={12}>
                <Card.Body>
                  <Table className="rounded">
                    <thead className="bg-primary text-white">
                      <tr>
                        <th>
                          <b>Referred By</b>
                        </th>
                        <th>
                          <b> Referral Name</b>
                        </th>
                        <th>
                          <b>Status</b>
                        </th>
                        <th>
                          <b></b>
                        </th>
                      </tr>
                    </thead>
                    {referrals.length > 0 ? (
                      <tbody>
                        {(searchResults && searchResults.length > 0
                          ? searchResults
                          : referrals
                        ).map((referral, index) => (
                          <React.Fragment key={referral.referral_id}>
                            <tr>
                              <td>
                                <b>{referral.referred_by_first_name}</b>
                              </td>
                              <td>
                                <b>{referral.referral_name}</b>
                              </td>
                              <td>
                                <b>{referral.ref_status}</b>
                              </td>
                              <td>
                                {/* Actions */}
                                <div className="d-lg-none">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="link"
                                      id="dropdown-basic"
                                      style={{
                                        fontSize: "1.5rem",
                                        color: "skyblue",
                                      }}
                                    >
                                      <FaEllipsisV className="ellipsis-icon" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => toggleEyeDetails(index)}
                                      >
                                        <FaEye className="viewicon" /> View
                                      </Dropdown.Item>
                                      {referral.ref_status !=
                                        "Business Done" && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              toggleCommentSection(index)
                                            }
                                          >
                                            <FaComment className="commenticon" />{" "}
                                            Comment &nbsp;
                                            <span class="badge text-bg-danger px-2 py-0">
                                              {/* here display new referral count */}
                                            </span>
                                          </Dropdown.Item>
                                        )}
                                      {/* <Dropdown.Item
                                        onClick={() =>
                                          toggleDeleteSection(index)
                                        }
                                      >
                                        <FaTrash className="deleteicon" />{" "}
                                        Delete
                                      </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                <div className="d-none d-lg-block">
                                  <FaEye
                                    className="viewicon"
                                    size={15}
                                    color="#007BFF"
                                    onClick={() => toggleEyeDetails(index)}
                                  />
                                  &nbsp;&nbsp;
                                  {referral.ref_status !=
                                    "Business Done" && (
                                      <FaComment
                                        className="commenticon"
                                        size={15}
                                        color="#007BFF"
                                        onClick={() => toggleCommentSection(index)}
                                      />)}
                                  &nbsp;&nbsp;
                                  {/* <FaTrash
                                    className="deleteicon"
                                    size={15}
                                    color="red"
                                    onClick={() => toggleDeleteSection(index)}
                                  /> */}
                                </div>
                              </td>
                            </tr>
                            {/* Expanded rows for detailed information */}
                            {eyeClickedRow === index && (
                              <tr className="expanded-row">
                                <td
                                  colSpan="4"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    className="close-icon"
                                    onClick={() => setEyeClickedRow(null)}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "10px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    &#10006;
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      {/* <p>
                                          <b>
                                            Referral Name:{" "}
                                            {referral.referral_name}
                                          </b>
                                        </p> */}
                                      <p>
                                        <b>
                                          Company Name: {referral.company_name}
                                        </b>
                                      </p>
                                      <p>
                                        <b>
                                          Referral Status:
                                          <select
                                            value={referral.ref_status}
                                            onChange={(e) =>
                                              handleStatusChange(e, index)
                                            }
                                            disabled={referral.submitted == 1}
                                          >
                                            <option value="Open">Open</option>
                                            <option value="Contacted">
                                              Contacted
                                            </option>
                                            <option value="Business Done">
                                              Business Done
                                            </option>
                                            <option value="Business Not Done">
                                              Business Not Done
                                            </option>
                                          </select>
                                        </b>
                                      </p>
                                      {!referral.business_done_amount ? (
                                        referral.ref_status ===
                                        "Business Done" && (
                                          <p>
                                            <b>
                                              Referral Amount:
                                              <input
                                                type="number"
                                                value={referral.business_done_amount}
                                                onChange={(e) =>
                                                  handleAmountChange(e, index)
                                                }
                                              />
                                            </b>
                                          </p>
                                        )
                                      ) : (
                                        <p>
                                          <b>
                                            Business Done Amount:
                                            {referral.business_done_amount}
                                          </b>
                                        </p>
                                      )}
                                      {referral.refSubmitted == 1 && (
                                        <p>
                                          <b>
                                            Business Done Amount:
                                            <input
                                              type="number"
                                              value={referral.business_done_amount}
                                              disabled
                                            />
                                          </b>
                                        </p>
                                      )}
                                      <p>
                                        <b>
                                        Referral Flag: {referral.ref_flag}
                                        </b>
                                      </p>
                                    </div>
                                    <div className="col-lg-6">
                                      <p>
                                        <b>Email: {referral.email_id}</b>
                                      </p>
                                      <p>
                                        <b>Contact No: {referral.contact_no}</b>
                                      </p>
                                      <p>
                                        <b>
                                          Referral Description:{" "}
                                          {referral.referral_desc}
                                        </b>
                                      </p>
                                      {!referral.business_done_amount
                                        ? referral.ref_status ===
                                        "Business Done" && (
                                          <p>
                                            <b>
                                              <button
                                                onClick={() =>
                                                  handleShowdelete(index)
                                                }
                                              >
                                                Submit
                                              </button>
                                            </b>

                                          </p>
                                        )
                                        : ""}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}

                            {commentRow === index && (
                              <tr className="expanded-row">
                                <td
                                  colSpan="4"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    className="close-icon"
                                    onClick={() => setCommentRow(null)}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "10px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    &#10006;
                                  </div>
                                  <MyReferralComment
                                    referralId={referral.referral_id}
                                  />
                                </td>
                              </tr>
                            )}
                            {deleteRow === index && (
                              <tr className="expanded-row">
                                <td
                                  colSpan="4"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    className="close-icon"
                                    onClick={() => setDeleteRow(null)}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "20px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    &#10006;
                                  </div>
                                  <DeleteReferral
                                    referralId={referral.referral_id}
                                    onClose={() => setDeleteRow(null)}
                                  />
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    ) : (
                      <p>No referrals available.</p>
                    )}
                  </Table>
                  {/* Delete Model */}
                  <Modal
                    centered
                    show={showdelete}
                    onHide={handleClosedelete}
                  >
                    <Modal.Header
                      closeButton
                      style={{
                        backgroundColor: "#007BFF",
                        color: "white",
                      }}
                    >
                      <Modal.Title>
                        Delete
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure you want submit the ammount
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleClosedelete}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        onClick={handleAmountSubmit}
                      // onClick={handleDeleteSubmit}
                      >
                        Yes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Card.Body>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Success Alert */}
      <Alert
        show={successMessages.length > 0}
        variant="success"
        onClose={() => setSuccessMessages([])}
        dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "black",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            onClick={() => setSuccessMessages([])}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        {successMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </div>
  );
};

export default ForMe;
