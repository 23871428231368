import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  Table,
  Dropdown,
} from "react-bootstrap";
import {
  FaTrash,
  FaEye,
  FaEllipsisV,
  FaEdit,
  FaTimes,
  FaUserCheck,
} from "react-icons/fa";
import TrainingRegMembers from "../trainingRegMembers";

function TrainingList({ searchResults, alertMessage }) {
  const token = sessionStorage.getItem("token");
  const chapterId = sessionStorage.getItem("chapter_id");
  const org_id = sessionStorage.getItem("org_id");
  const roleId = sessionStorage.getItem("role_id");
  const [trainings, setTrainings] = useState([]);
  const [error, setError] = useState(null);
  const [eyeClickedRow, setEyeClickedRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [deleteRow, setDeleteRow] = useState(null);
  const [showActionsDropdown, setShowActionsDropdown] = useState(null);
  const [membersRow, setMembersRow] = useState(null);

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      setTrainings(searchResults); // Update members with search results
    } else if (!alertMessage) {
      // Fetch the default member list only if alertMessage is not present
      fetchDataFromApi();
    }
  }, [searchResults, alertMessage]);

  const fetchDataFromApi = async () => {
    console.log("entered into function to fetch training list");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/training/gettrainings/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        // setTrainings(data.trainingwithUrl);
        if (roleId == 2) {
          const orgEvents = data.trainingwithUrl.filter(
            (event) => event.chapter_id == chapterId
          );
          const sortedEvents = orgEvents.sort(
            (a, b) =>
              new Date(a.train_start_date) - new Date(b.train_start_date)
          );

          setTrainings(sortedEvents);
        } else if (roleId == 4) {
          const orgEvents = data.trainingwithUrl.filter(
            (event) => event.org_id == org_id
          );
          const sortedEvents = orgEvents.sort(
            (a, b) =>
              new Date(a.train_start_date) - new Date(b.train_start_date)
          );

          setTrainings(sortedEvents);
        } else if (roleId == 1) {
          const orgEvents = data.trainingwithUrl.filter(
            (event) => event.org_id === null
          );
          const sortedEvents = orgEvents.sort(
            (a, b) =>
              new Date(a.train_start_date) - new Date(b.train_start_date)
          );

          setTrainings(sortedEvents);
        }
      } else {
        // Handle errors from the backend
        const errorData = await response.json();
        // setError(`Error fetching events: ${JSON.stringify(errorData)}`);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Unexpected error:", error.message);
      // setError("Error fetching training. Please try again later.");
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  useEffect(() => {
    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchDataFromApi();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const toggleEyeDetails = (index) => {
    setEyeClickedRow((prev) => (prev === index ? null : index));
  };

  const toggleMembersSection = (index) => {
    setMembersRow((prev) => (prev === index ? null : index));
  };

  const toggleEditSection = (index) => {
    setEditRow((prev) => (prev === index ? null : index));
  };

  const toggleDeleteSection = (index) => {
    setDeleteRow((prev) => (prev === index ? null : index));
  };

  const toggleActionsDropdown = (index) => {
    setShowActionsDropdown((prev) => (prev === index ? null : index));
  };

  const handleDocumentClick = (event) => {
    // Check if the click occurred outside the expanded rows
    const expandedRows = document.querySelectorAll(".expanded-row");
    let clickedInsideExpandedRow = false;

    // Check if the click occurred inside any expanded row
    expandedRows.forEach((row) => {
      if (row.contains(event.target)) {
        clickedInsideExpandedRow = true;
      }
    });

    // Close expanded rows if the click occurred outside
    if (!clickedInsideExpandedRow) {
      setEyeClickedRow(null);
      setEditRow(null);
      setDeleteRow(null);
      setMembersRow(null);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [eyeClickedRow, editRow, deleteRow]);
  // Function to convert time from 24-hour to 12-hour format
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);

    if (formattedHour === 0) {
      formattedHour = 12; // Midnight case (00:00 to 12:00 AM)
    } else if (formattedHour >= 12) {
      period = "PM";
      if (formattedHour > 12) {
        formattedHour -= 12;
      }
    }

    return `${formattedHour}:${minute} ${period}`;
  };

  return (
    <div>
      {" "}
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg="12">
              <Card>
                {/* <Card.Header className="d-flex justify-content-between align-items-center">
                  <h4>Upcoming Scheduled Trainings</h4>
                </Card.Header> */}
                <Card.Body className="p-0">
                  {alertMessage ? (
                    <div>
                      {" "}
                      <p>{alertMessage}</p>
                    </div>
                  ) : (
                    <Table className="rounded">
                      <thead className="bg-primary text-white">
                        <tr>
                          <th>
                            <b>Start Date</b>
                          </th>
                          <th>
                            <b>Topic</b>
                          </th>
                          <th>
                            <b>Mode</b>
                          </th>
                          <th>
                            <b></b>
                          </th>
                        </tr>
                      </thead>
                      {trainings.length > 0 ? (
                        <tbody>
                          {trainings &&
                            trainings.map((event, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>
                                    <b>
                                      {new Date(event.train_start_date)
                                        .getDate()
                                        .toString()
                                        .padStart(2, "0")}
                                      /
                                      {(
                                        new Date(
                                          event.train_start_date
                                        ).getMonth() + 1
                                      )
                                        .toString()
                                        .padStart(2, "0")}
                                      /
                                      {new Date(
                                        event.train_start_date
                                      ).getFullYear()}
                                    </b>
                                  </td>
                                  <td>
                                    <b>{event.training_topic}</b>
                                  </td>
                                  <td>
                                    <b>{event.training_mode}</b>
                                  </td>
                                  <td>
                                    <div className="d-lg-none">
                                      <Dropdown
                                        show={showActionsDropdown === index}
                                        onSelect={() =>
                                          setShowActionsDropdown(null)
                                        }
                                      >
                                        <Dropdown.Toggle
                                          variant="link"
                                          id={`dropdown-basic-${index}`}
                                          style={{
                                            fontSize: "1.5rem",
                                            color: "skyblue",
                                          }}
                                        >
                                          <FaEllipsisV
                                            className="ellipsis-icon"
                                            onClick={() =>
                                              toggleActionsDropdown(index)
                                            }
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              toggleEyeDetails(index)
                                            }
                                          >
                                            <FaEye className="viewicon" /> View
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              toggleMembersSection(index)
                                            }
                                          >
                                            <FaUserCheck className="editicon" />{" "}
                                            View Members
                                          </Dropdown.Item>
                                          {/* <Dropdown.Item onClick={() => toggleEditSection(index)}>
                                                                            <FaEdit className="editicon" /> Edit
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => toggleDeleteSection(index)}>
                                                                            <FaTrash className="deleteicon" /> Delete
                                                                        </Dropdown.Item> */}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <div className="d-none d-lg-block">
                                      <FaEye
                                        className="viewicon"
                                        size={15}
                                        color="#007BFF"
                                        onClick={() => toggleEyeDetails(index)}
                                      />
                                      &nbsp;&nbsp;
                                      <FaUserCheck
                                        // className="editicon"
                                        size={15}
                                        color="#007BFF"
                                        onClick={() =>
                                          toggleMembersSection(index)
                                        }
                                      />
                                      &nbsp;&nbsp;
                                      {/* <FaTrash
                                                                        className="deleteicon"
                                                                        size={15}
                                                                        color="red"
                                                                        onClick={() => toggleDeleteSection(index)}
                                                                    /> */}
                                    </div>
                                  </td>
                                </tr>
                                {eyeClickedRow === index && (
                                  <tr className="expanded-row">
                                    <td
                                      colSpan="4"
                                      style={{ position: "relative" }}
                                    >
                                      <div
                                        className="close-icon"
                                        onClick={() => setEyeClickedRow(null)}
                                        style={{
                                          position: "absolute",
                                          top: "10px",
                                          right: "20px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        &#10006;
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <p>
                                            <b>
                                              Trainer Name: {event.trainer_name}
                                            </b>
                                          </p>

                                          <p>
                                            <b>
                                              Start Date:{" "}
                                              {new Date(event.train_start_date)
                                                .getDate()
                                                .toString()
                                                .padStart(2, "0")}
                                              /
                                              {(
                                                new Date(
                                                  event.train_start_date
                                                ).getMonth() + 1
                                              )
                                                .toString()
                                                .padStart(2, "0")}
                                              /
                                              {new Date(
                                                event.train_start_date
                                              ).getFullYear()}
                                            </b>
                                          </p>
                                          <p>
                                            <b>
                                              Start Time:{" "}
                                              {formatTime(
                                                event.train_start_time
                                              )}
                                            </b>
                                          </p>
                                          <p>
                                            <b>
                                              {event?.training_mode ===
                                              "virtual" ? (
                                                <>
                                                  Traning Link:
                                                  <a
                                                    href={event?.training_link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                      marginLeft: "5px",
                                                    }}
                                                  >
                                                    Join Training
                                                  </a>
                                                </>
                                              ) : (
                                                <>
                                                  Place: {event?.training_place}
                                                </>
                                              )}
                                            </b>
                                          </p>

                                          <p>
                                            <b>
                                              Charge: {event.training_charges}
                                            </b>
                                          </p>
                                        </div>
                                        <div className="col-lg-6">
                                          <p>
                                            <b>
                                              Cut-Off Reg. Date:{" "}
                                              {new Date(
                                                event.train_cutoff_reg_date
                                              )
                                                .getDate()
                                                .toString()
                                                .padStart(2, "0")}
                                              /
                                              {(
                                                new Date(
                                                  event.train_cutoff_reg_date
                                                ).getMonth() + 1
                                              )
                                                .toString()
                                                .padStart(2, "0")}
                                              /
                                              {new Date(
                                                event.train_cutoff_reg_date
                                              ).getFullYear()}
                                            </b>
                                          </p>
                                          <p>
                                            <b>
                                              End Date:{" "}
                                              {new Date(event.train_end_date)
                                                .getDate()
                                                .toString()
                                                .padStart(2, "0")}
                                              /
                                              {(
                                                new Date(
                                                  event.train_end_date
                                                ).getMonth() + 1
                                              )
                                                .toString()
                                                .padStart(2, "0")}
                                              /
                                              {new Date(
                                                event.train_end_date
                                              ).getFullYear()}
                                            </b>
                                          </p>

                                          <p>
                                            <b>
                                              End Time:{" "}
                                              {formatTime(event.train_end_time)}
                                            </b>
                                          </p>
                                          <p>
                                            <b>
                                              Description:{" "}
                                              {event.training_details}
                                            </b>
                                          </p>
                                          {event?.training_payment_link && (
                                            <p>
                                              <b>
                                                Traning Payment link:{" "}
                                                <a
                                                  href={
                                                    event?.training_payment_link
                                                  }
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Click to pay
                                                </a>
                                              </b>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}

                                {membersRow === index && (
                                  <tr className="expanded-row">
                                    <td
                                      colSpan="4"
                                      style={{ position: "relative" }}
                                    >
                                      <div
                                        className="close-icon"
                                        onClick={() => setMembersRow(null)}
                                        style={{
                                          position: "absolute",
                                          top: "10px",
                                          right: "20px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        &#10006;
                                      </div>
                                      <TrainingRegMembers
                                        trainingID={event.training_id}
                                        onClose={() => setMembersRow(null)}
                                      />
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}
                        </tbody>
                      ) : (
                        <p>No trainings available!</p>
                      )}
                    </Table>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default TrainingList;
