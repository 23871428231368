import React, { useState, useEffect } from "react";
import {
  Dropdown,
  Nav,
  Form,
  Card,
  Container,
  Image,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

//image
import admin from "../../../../assets/images/user/admin.jpg";
import user1 from "../../../../assets/images/user/1.png";
import logo from "../../../../assets/images/logo.png";

//const userID = sessionStorage.getItem('userID');

//Componets
import CustomToggle from "../../../dropdowns";
// import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { generateToken, messaging } from "../../../../src/fireBase";
import { getMessaging, onMessage } from "firebase/messaging";
import axios from "axios";
const Header = () => {
  const navigate = useNavigate();
  const [databaseImage, setDatabaseImage] = useState("");
  const [org, setOrg] = useState(logo);
  const username = sessionStorage.getItem("userName");
  const userRole = sessionStorage.getItem("role");
  const token = sessionStorage.getItem("token");
  const member_id = sessionStorage.getItem("member_id");
  const org_id = sessionStorage.getItem("org_id");
  let org_logo = sessionStorage.getItem("org_logo");
  const org_name = sessionStorage.getItem("org_name");
  // State to store notification data
  const [notifications, setNotifications] = useState([]);

  // useEffect(() => {
  //   fetchMemberData();
  // });

  useEffect(() => {
    // Fetch initial data
    org_logo = sessionStorage.getItem("org_logo");
    if (org_id) {
      try {
        axios
          .post(process.env.REACT_APP_API_URL + "/org/getOrg/", { org_id })
          .then((response) => {
            let logo1 = response.data.result[0];
            setOrg(logo1);
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("No org found:", error);
        } else {
          console.error("Error fetching org:", error);
        }
      }
    }
    fetchMemberData();

    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchMemberData();
    }, 5000); // Polling every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [sessionStorage.getItem("org_logo")]); // Empty dependency array ensures this effect runs only once

  const fetchMemberData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/members/members/${member_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // Setting the Cache-Control header as a configuration option
          "Cache-Control": "no-cache",
        }
      );
      sessionStorage.setItem("userName",response.data.result.first_name);
      setDatabaseImage(response.data.imageUrl);
    } catch (error) {
      console.error("Error fetching member data:", error);
    }
  };
  const displayImage = databaseImage || user1;
  const displayAdminImage = databaseImage || admin;

  // const displayImage = (userRole === "CHAPTER_ADMIN" || userRole === "ORG_ADMIN")
  // ? (admin || databaseImage)  // Show adminImage if available, otherwise databaseImage or user1
  // : (databaseImage || user1);  // For non-admins, show databaseImage or user1

  // try {
  //   axios
  //     .post(process.env.REACT_APP_API_URL + '/org/verify-token/', { token })
  //     .then((response) => {
  //       let logo1 = response.data.result[0];
  //       console.log("orgid==>", logo1);
  //       setOrg(logo1);
  //     })
  // }
  // catch (error) {
  //   if (error.response && error.response.status === 404) {
  //     console.error("No org found:", error);
  //   } else {
  //     console.error("Error fetching org:", error);
  //   }
  // }
  // Function to handle receiving notifications
  // Function to handle receiving notifications
  const handleNotification = (payload) => {
    // Extract notification data from the payload
    const { title, body, image } = payload.notification;

    // Create a new notification object
    const newNotification = {
      id: payload.messageId, // Use messageId as unique identifier
      title,
      body,
      image,
      timestamp: Date.now(), // Add current timestamp
      read: false, // Add a property to track if notification is read
    };
    // Call generateToken() function
    // Update notification state by appending the new notification
    setNotifications((prevNotifications) => [
      newNotification,
      ...prevNotifications,
    ]);
    // Retrieve existing notifications from local storage
    const storedNotifications =
      JSON.parse(localStorage.getItem("notifications")) || [];
    // Save notifications to localStorage
    localStorage.setItem(
      "notifications",
      JSON.stringify([newNotification, ...storedNotifications])
    );
  };

  // Effect to load notifications from storage on component mount
  useEffect(() => {
    const storedNotifications =
      JSON.parse(localStorage.getItem("notifications")) || [];
    setNotifications(storedNotifications);

    // Generate FCM token
    generateToken();

    // Listen for incoming messages
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Payload:", payload);
      handleNotification(payload);
    });

    return () => unsubscribe(); // Unsubscribe from message listener on component unmount
  }, []);

  // Function to mark a notification as read
  const markAsRead = (id) => {
    const updatedNotifications = notifications.map((notification) =>
      notification.id === id ? { ...notification, read: true } : notification
    );
    setNotifications(updatedNotifications);
    localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
  };

  const sendDataAndNavigate = () => {
    //   const dataToSend = 'YourDataHere';
    navigate("/dashboard/app/fprofile", { state: member_id });
  };
  //logout function
  const handleLogout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/logout`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        // Clear user data from localStorage upon successful logout
        // localStorage.clear(); // Clears all localStorage items
        const token = sessionStorage.getItem("login_token");

        if (!token) {
          navigate("/auth/sign-in");
        } else {
          navigate(
            // `/auth/sign-in?token=${sessionStorage.getItem("login_token")}`
            `/auth/sign-in`
          );
        }
        sessionStorage.clear(); // Clears all sessionStorage items
      } else {
        console.error("Logout request failed");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="iq-top-navbar">
        <Nav
          expand="lg"
          variant="light"
          className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
        >
          <Container fluid className="navbar-inner">
            <div className="d-flex align-items-center gap-3  pb-2 pb-lg-0">
              <Link
                to="#"
                className="sidebar-toggle"
                data-toggle="sidebar"
                data-active="true"
                onClick={minisidebar}
              >
                <div className="icon material-symbols-outlined iq-burger-menu">
                  menu
                </div>
              </Link>
              <Link
                to="/userposts"
                className="d-flex align-items-center gap-2 iq-header-logo"
              >
                <div className="d-flex align-items-center">
                  {org_logo === "undefined" ||
                  org_logo == "null" ||
                  !org_logo ? (
                    <img
                      src={logo}
                      className="img-fluid"
                      alt="logo"
                      style={{ width: "2rem", marginRight: ".5rem" }}
                    />
                  ) : (
                    // <svg
                    //   width="40"
                    //   viewBox="0 0 30 30"
                    //   fill="none"
                    //   xmlns="http://www.w3.org/2000/svg"
                    // >
                    //   <path
                    //     fillRule="evenodd"
                    //     clipRule="evenodd"
                    //     d="M1.67733 9.50001L7.88976 20.2602C9.81426 23.5936 14.6255 23.5936 16.55 20.2602L22.7624 9.5C24.6869 6.16666 22.2813 2 18.4323 2H6.00746C2.15845 2 -0.247164 6.16668 1.67733 9.50001ZM14.818 19.2602C13.6633 21.2602 10.7765 21.2602 9.62181 19.2602L9.46165 18.9828L9.46597 18.7275C9.48329 17.7026 9.76288 16.6993 10.2781 15.8131L12.0767 12.7195L14.1092 16.2155C14.4957 16.8803 14.7508 17.6132 14.8607 18.3743L14.9544 19.0239L14.818 19.2602ZM16.4299 16.4683L19.3673 11.3806C18.7773 11.5172 18.172 11.5868 17.5629 11.5868H13.7316L15.8382 15.2102C16.0721 15.6125 16.2699 16.0335 16.4299 16.4683ZM20.9542 8.63193L21.0304 8.5C22.1851 6.5 20.7417 4 18.4323 4H17.8353L17.1846 4.56727C16.6902 4.99824 16.2698 5.50736 15.9402 6.07437L13.8981 9.58676H17.5629C18.4271 9.58676 19.281 9.40011 20.0663 9.03957L20.9542 8.63193ZM14.9554 4C14.6791 4.33499 14.4301 4.69248 14.2111 5.06912L12.0767 8.74038L10.0324 5.22419C9.77912 4.78855 9.48582 4.37881 9.15689 4H14.9554ZM6.15405 4H6.00746C3.69806 4 2.25468 6.50001 3.40938 8.50001L3.4915 8.64223L4.37838 9.04644C5.15962 9.40251 6.00817 9.58676 6.86672 9.58676H10.2553L8.30338 6.22943C7.9234 5.57587 7.42333 5.00001 6.8295 4.53215L6.15405 4ZM5.07407 11.3833L7.88909 16.2591C8.05955 15.7565 8.28025 15.2702 8.54905 14.8079L10.4218 11.5868H6.86672C6.26169 11.5868 5.66037 11.5181 5.07407 11.3833Z"
                    //     fill="currentColor"
                    //   />
                    // </svg>
                    <img
                      src={org_logo}
                      className="img-fluid"
                      alt="logo"
                      style={{ width: "2rem", marginRight: ".5rem" }}
                    />
                  )}
                  {!org_name ? (
                    <h2 className="logo-title" data-setting="app_name">
                      CB
                    </h2>
                  ) : (
                    <h2 className="logo-title" data-setting="app_name">
                      {org.organization_name}
                    </h2>
                  )}
                </div>
              </Link>
            </div>

            <ul className="navbar-nav navbar-list">
              {userRole !== "SUPER_ADMIN" && (
                <Nav.Item as="li">
                  <Link to="/userposts" className="d-flex align-items-center">
                    <i className="material-symbols-outlined">home</i>
                    <span className="mobile-text d-none ms-3">Home</span>
                  </Link>
                </Nav.Item>
              )}

              <Dropdown as="li" className="nav-item">
                {/* <Dropdown.Toggle
                  href="#"
                  variant="search-toggle d-flex align-items-center"
                >
                  <div className="position-relative mt-md-2">
                    <i className="material-symbols-outlined">notifications </i>
                    <span
                      className="badge text-danger position-absolute top-10 start-100 translate-middle mt-md-3 px-1"
                      style={{ fontSize: "1em" }}
                    >
                      {"+"}
                      {
                        notifications.filter(
                          (notification) => !notification.read
                        ).length
                      }
                    </span>
                  </div>
                </Dropdown.Toggle> */}

                <Dropdown.Menu className="sub-drop dropdown-menu-end">
                  <Card className="shadow-none m-0">
                    <Card.Header className="d-flex justify-content-between bg-primary">
                      <div className="header-title bg-primary">
                        <h5 className="mb-0 text-white ">All Notifications</h5>
                      </div>
                      <small className="badge  bg-light text-dark">
                        {notifications.length}
                      </small>
                    </Card.Header>
                    <Card.Body className="p-0">
                      {/* Display notifications */}
                      {notifications.map((notification) => (
                        <Link
                          to="#"
                          key={notification.id}
                          className={`iq-sub-card ${
                            notification.read ? "read" : ""
                          }`}
                          onClick={() => markAsRead(notification.id)}
                        >
                          <div className="d-flex align-items-center">
                            <div className="">
                              <Image
                                className="avatar-40 rounded"
                                // src={notification.image}
                                alt=""
                                loading="lazy"
                              />
                            </div>
                            <div className="ms-3 w-100">
                              <h6 className="mb-0">{notification.title}</h6>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="mb-0">{notification.body}</p>
                                <small className="float-right font-size-12">
                                  {new Date(
                                    notification.timestamp
                                  ).toLocaleString()}
                                </small>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                as="li"
                className="nav-item user-dropdown"
                show={dropdownOpen}
                onToggle={toggleDropdown}
              >
                <Dropdown.Toggle
                  href="#"
                  as={CustomToggle}
                  variant="d-flex align-items-center"
                  onClick={toggleDropdown}
                >
                  {/* {renderUserImage()} */}
                  {(userRole == "CHAPTER_ADMIN" || userRole == "ORG_ADMIN" || userRole == "SUPER_ADMIN")?
                  (<Image
                    src={displayAdminImage}
                    className="img-fluid rounded-circle me-3"
                    alt="user"
                    loading="lazy"
                  />):(<Image
                    src={displayImage}
                    className="img-fluid rounded-circle me-3"
                    alt="user"
                    loading="lazy"
                  />)}
                  <div className="caption d-none d-lg-block">
                    <h6 className="mb-0 line-height">
                      {username}
                      {/* {(userRole === "CHAPTER_ADMIN" ||
                        userRole === "ORG_ADMIN" ||
                        userRole === "SUPER_ADMIN") && (
                        <span className="badge rounded-pill text-bg-light">
                          ADMIN
                        </span>
                      )} */}
                    </h6>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className={`sub-drop caption-menu ${
                    dropdownOpen ? "show" : ""
                  }`}
                  onClick={closeDropdown}
                >
                  <Card className="shadow-none m-0">
                    <Card.Header>
                      <div className="header-title">
                        <h5 className="mb-0 ">Hello, {username}</h5>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-0 ">
                      {userRole === "CHAPTER_ADMIN" ? (
                        <div className="d-flex align-items-center iq-sub-card border-0">
                          <span className="material-symbols-outlined">
                            passkey
                          </span>
                          <div className="ms-3">
                            <Link to="user-resetPassword" className="mb-0 h6">
                              Edit Password
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <>
                          {userRole !== "SUPER_ADMIN" &&
                          userRole !== "ORG_ADMIN" ? (
                            <>
                              <div className="d-flex align-items-center iq-sub-card border-0">
                                <span className="material-symbols-outlined">
                                  line_style
                                </span>
                                <div className="ms-3">
                                  <button
                                    onClick={sendDataAndNavigate}
                                    className="mb-0 h6"
                                  >
                                    My Profile
                                  </button>
                                </div>
                              </div>
                              <div className="d-flex align-items-center iq-sub-card border-0">
                                <span className="material-symbols-outlined">
                                  edit_note
                                </span>
                                <div className="ms-3">
                                  <Link
                                    to="/dashboard/app/user-profile-edit"
                                    className="mb-0 h6"
                                  >
                                    Edit Profile
                                  </Link>
                                </div>
                              </div>
                              <div className="d-flex align-items-center iq-sub-card border-0">
                                <span className="material-symbols-outlined">
                                  passkey
                                </span>
                                <div className="ms-3">
                                  <Link
                                    to="/user-resetPassword"
                                    className="mb-0 h6"
                                  >
                                    Edit Password
                                  </Link>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="d-flex align-items-center iq-sub-card border-0">
                              <span className="material-symbols-outlined">
                                passkey
                              </span>
                              <div className="ms-3">
                                <Link
                                  to="/user-resetPassword"
                                  className="mb-0 h6"
                                >
                                  Edit Password
                                </Link>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      <div className="d-flex align-items-center iq-sub-card">
                        <span className="material-symbols-outlined">login</span>
                        <div className="ms-3">
                          <Link onClick={handleLogout} className="mb-0 h6">
                            Logout
                          </Link>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </Container>
        </Nav>
      </div>
    </>
  );
};

export default Header;
