import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useOrgNaming from "../../../../src/hooks/getNamingHook";
const MeetingSearch = () => {
  const token = sessionStorage.getItem("token");
  const memberId = sessionStorage.getItem("member_id");
  const org_id = sessionStorage.getItem("org_id");

  const [filters, setFilters] = useState({
    search_text: "",
    meeting_mode: "",
    organizer_name: "",
  });
  const navigate = useNavigate();
  const [userMeetings, setUserMeetings] = useState([]);
  const [memberMeetings, setMemberMeetings] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const { getOrgNames, loading } = useOrgNaming(org_id,token);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  useEffect(() => {
    applyFilters();
  }, [filters, userMeetings, memberMeetings]);

  const fetchUserMeetings = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/meetings/oneonone-meeting/user/${memberId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 502) {
        // Don't throw error for 502 status code
        console.error(
          "Bad Gateway error occurred while fetching sent referrals"
        );
        return;
      }
      if (!response.ok) {
        throw Error("Failed to fetch user meetings");
      }
      const data = await response.json();
      if (data && data.results) {
        setUserMeetings(data.results);
      }
    } catch (err) {
      console.error("Error fetching user meetings:", err);
      // setError(
      //   "An error occurred while fetching user meetings. Please try again later."
      // );
    }
  };

  const fetchMemberMeetings = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/meetings/one-one-one/member/${memberId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 502) {
        // Don't throw error for 502 status code
        console.error(
          "Bad Gateway error occurred while fetching sent referrals"
        );
        return;
      }
      if (!response.ok) {
        throw Error("Failed to fetch member meetings");
      }
      const data = await response.json();
      if (data && data.results) {
        setMemberMeetings(data.results);
      }
    } catch (err) {
      console.error("Error fetching member meetings:", err);
      // setError(
      //   "An error occurred while fetching member meetings. Please try again later."
      // );
    }
  };

  useEffect(() => {
    const fetchMeetingsData = async () => {
      fetchUserMeetings();
      fetchMemberMeetings();
    };

    // Fetch initial data
    fetchMeetingsData();

    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchMeetingsData();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const combinedMeetings = [...userMeetings, ...memberMeetings];

  const filteredMeetings = combinedMeetings.filter(
    (meeting) => meeting.meeting_type !== "Chapter"
  );

  // console.log(
  //   "filteres meetings for search in meeting search: ",
  //   filteredMeetings
  // );

  const applyFilters = () => {
    const { search_text, meeting_mode, organizer_name } = filters;
     

    const updatedResults = filteredMeetings.filter((result) => {
      const isSearchMatch =
        search_text === "" ||
        (result.creator_first_name &&
          result.creator_first_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.creator_last_name &&
          result.creator_last_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.attendee_first_name &&
          result.attendee_first_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.attendee_last_name &&
          result.attendee_last_name
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_mode &&
          result.meeting_mode
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_subject &&
          result.meeting_subject
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_desc &&
          result.meeting_desc
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_place &&
          result.meeting_place
            .toLowerCase()
            .includes(search_text.toLowerCase())) ||
        (result.meeting_link &&
          result.meeting_link
            .toLowerCase()
            .includes(search_text.toLowerCase()));

      const isModeMatch =
        meeting_mode === "" ||
        (result.meeting_mode && result.meeting_mode === meeting_mode);

      const isOrganizerMatch =
        organizer_name === "" ||
        (result.creator_first_name &&
          result.creator_first_name
            .toLowerCase()
            .includes(organizer_name.toLowerCase())) ||
        (result.creator_last_name &&
          result.creator_last_name
            .toLowerCase()
            .includes(organizer_name.toLowerCase()));

      return isSearchMatch && isModeMatch && isOrganizerMatch;
    });

    setFilteredResults(updatedResults); // Removed the callback function
  };

  const handleSearch = () => {
    applyFilters();
    console.log("all meeting search results: ", filteredResults);

    if (filteredResults.length === 0) {
      navigate("/dashboards/app/meeting", {
        state: { alertMessage: "No matching results found!" },
      });
    } else {
      navigate("/dashboards/app/meeting", {
        state: { searchResults: filteredResults },
      });
    }
  };

  const handleReset = () => {
    setFilters({
      search_text: "",
      meeting_mode: "",
      organizer_name: "",
    });
    navigate("/dashboards/app/meeting", {
      state: { searchResults: null },
    });
  };

  return (
    <div>
      <div className="input-group pb-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search here..."
          name="search_text"
          value={filters.search_text}
          onChange={handleInputChange}
        />
      </div>
      <div className="input-group pb-2">
        <select
          className="form-select"
          placeholder="Filter by Meeting Mode"
          name="meeting_mode"
          value={filters.meeting_mode}
          onChange={handleInputChange}
        >
          <option value="">Select {getOrgNames?.alt_meeting || "Meeting"} Mode</option>
          <option value="Virtual">Virtual</option>
          <option value="In-Person">In-Person</option>
        </select>
      </div>

      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="Filter by organised by"
          name="organizer_name"
          value={filters.organizer_name}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-12 text-center pt-2">
        <button className="btn btn-primary" onClick={handleSearch}>
          <b>Search and Apply Filters</b>
        </button>
      </div>
      <div className="col-12 text-center pt-2">
        <button className="btn btn-danger" onClick={handleReset}>
          <b>Reset</b>
        </button>
      </div>
    </div>
  );
};

export default MeetingSearch;
