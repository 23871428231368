import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import img1 from "../../../assets/images/user/11.png";
import axios from "axios";
import "./customcss/admintabstyle.css";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  Form,
  Button,
  Nav,
  Modal,
  Alert,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FaTrash, FaEdit, FaEye, FaTimes } from "react-icons/fa";

//image
import user5 from "../../../assets/images/user/1.png";

import useOrgNaming from "../../../hooks/getNamingHook";
const MembershipRequest = () => {
  const [memberCategories, setMemberCategories] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [membershipPlans, setMembershipPlans] = useState([]);
  const [membershipRequests, setMembershipRequests] = useState([]);
  const [deactivatedMembers, setDeactivatedMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [customMember, setCustomMember] = useState("");
  const location = useLocation();
  const { searchResults, alertMessage } = location.state || [];

  // State to manage errors message and success Messages
  const [successMessages, setSuccessMessages] = useState([]);
  const [error, setError] = useState(null);

  // State for controlling the visibility of the modal ViewProfileForm
  const [showViewProfileForm, setShowViewProfileForm] = useState(false);
  const closeViewProfileForm = () => setShowViewProfileForm(false);
  const [selectedRequest, setselectedRequest] = useState(null); // State for controlling the visibility of the modal
  const [expandedRow, setExpandedRow] = useState(null);

  // to show the rejected Tab
  const [activeTab, setActiveTab] = useState("first");
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [isRejectedTab, setIsRejectedTab] = useState(true);
  const [isShowButtons, setIsShowButtons] = useState(true);
  const [comment, setComment] = useState("");
  const org_id = sessionStorage.getItem("org_id");
  const token = sessionStorage.getItem("token");
  const member_id = sessionStorage.getItem("member_id");
  const userRole = sessionStorage.getItem("role");
  const chapter_id = sessionStorage.getItem("chapter_id");
  const { getOrgNames, loading } = useOrgNaming(org_id, token);
  // const [chapter_to_join1, setChapterToJoin] = useState(0); // Define and initialize chapter_to_join state
  const [selectedMemberCategory, setSelectedMemberCategory] = useState("");
  const [selectedMembershipPlan, setSelectedMembershipPlan] = useState("");
  const [selectedChapter, setSelectedChapter] = useState(""); // fetch the chapter plan and catogory on the form of accept form
  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      setMembershipRequests(searchResults); // Update members with search results
    } else if (!alertMessage) {
      // Fetch the default member list only if alertMessage is not present
      fetchMembershipRequests();
    }
  }, [searchResults, alertMessage]);

  const fetchMembers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/members/members/all/${org_id}`
      );

      if (response.status === 200) {
        const allMembers = response.data.imageUrls || [];
        setMembers(allMembers);
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("No Members found:", error);
      } else {
        console.error("Error fetching members:", error);
      }
    }
  };

  const fetchMemberCategories = async () => {
    try {
      let endpoint = `${process.env.REACT_APP_API_URL}/memberCategory/get_org_memberCategory/${org_id}`;
      if (userRole === "SUPER_ADMIN") {
        endpoint = `${process.env.REACT_APP_API_URL}/memberCategory/memberCategory`;
      }
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response.data;

      if (userRole === "SUPER_ADMIN") {
        data = response.data.filter((request) => request.org_id == null);
        setMemberCategories(data);
      }
      // const categories = data.map((item) => item.member_category);
      setMemberCategories(data);
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  const fetchChapters = async () => {
    try {
      let endpoint = `${process.env.REACT_APP_API_URL}/chapters/orgChapters/${org_id}`;
      if (userRole === "SUPER_ADMIN") {
        endpoint = `${process.env.REACT_APP_API_URL}/chapters/chapters`;
      }
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let data = response.data;
      if (userRole === "SUPER_ADMIN") {
        // Filter plans where org_id is null
        data = data.filter((plan) => plan.org_id === null);
        setChapters(data);
      }
      // const categories = data.map((item) => item.member_category);
      setChapters(data);
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  const fetchMembershipPlans = async () => {
    try {
      let endpoint = `${process.env.REACT_APP_API_URL}/api/membership_plans/membership_org_plans/${org_id}`;
      if (userRole === "SUPER_ADMIN") {
        endpoint = `${process.env.REACT_APP_API_URL}/api/membership_plans/membership_plans`;
      }
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (userRole === "SUPER_ADMIN") {
        // Filter plans where org_id is null
        const filteredPlans = response.data.filter(
          (plan) => plan.org_id === null
        );
        setMembershipPlans(filteredPlans);
      } else {
        // Directly set the plans for other roles
        setMembershipPlans(response.data);
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  // Set initial values if selectedRequest exists
  useEffect(() => {
    if (selectedRequest) {
      setSelectedMemberCategory(selectedRequest.member_category || "");
      setSelectedChapter(selectedRequest.chapter_id || "");
      setSelectedMembershipPlan(selectedRequest.membership_plan || "");
    }
  }, [selectedRequest]);
  //for show the shift chapter request
  const [showShiftChapter, setShowShiftChapter] = useState(false);
  const [newChapter, setNewChapter] = useState("");

  //to show the modal form delete membership request
  const [currentMemberId, setCurrentMemberId] = useState(null);
  const [showdelete, setShowdelete] = useState(false);
  const handleClosedelete = () => setShowdelete(false);
  const handleShowDelete = (member_req_id) => {
    setCurrentMemberId(member_req_id);
    setShowdelete(true);
  };

  //to show the modal form comment for rejection of membership request
  const openCommentForm = () => {
    setShowCommentForm(true);
    setShowViewProfileForm(false);
  };
  const closeCommentForm = () => {
    setShowCommentForm(false);
  };

  //to show the modal profile view of membership request
  const openViewProfileForm = (request) => {
    setselectedRequest(request);
    setShowViewProfileForm(true);
    setIsRejectedTab(true);
    if (request.membership_status === "Accepted") {
      setIsShowButtons(false); // Set to false to hide "Reject" and "Accept" buttons
    } else {
      setIsShowButtons(true); // Set to true to show "Reject" and "Accept" buttons
    }
  };
  const openRejectViewProfileForm = (request) => {
    setselectedRequest(request);
    setShowViewProfileForm(true);
    setIsRejectedTab(false);
  };

  //fectch the all membership request on page
  const fetchMembershipRequests = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let endpoint = `${process.env.REACT_APP_API_URL}/membershipRequest/membership-requests/org/${org_id}`;
      if (userRole === "SUPER_ADMIN") {
        endpoint = `${process.env.REACT_APP_API_URL}/membershipRequest/membership-requests/list/all`;
      }
      const response = await axios.get(endpoint, config);

      let allRequests = response.data.requests;

      // For SUPER_ADMIN: Filter data to only show org_id === null
      if (userRole === "SUPER_ADMIN") {
        allRequests = allRequests.filter((request) => request.org_id == null);
      }
      let pendingAndAcceptedRequests = [];
      let deactivatedMembersList = [];

      // if (userRole === "ORG_ADMIN") {
      // Filter for org_admin: show all pending and accepted requests
      pendingAndAcceptedRequests = allRequests.filter(
        (request) => request.membership_status !== "Rejected"
      );

      // Filter out the rejected requests
      deactivatedMembersList = allRequests.filter(
        (request) => request.membership_status === "Rejected"
      );

      // Update the states accordingly
      setMembershipRequests(pendingAndAcceptedRequests);
      setDeactivatedMembers(deactivatedMembersList);
      fetchMembers();
    } catch (error) {
      console.error("Error fetching membership requests: ", error);
    }
  };

  // Function to handle comment submission
  const submitComment = (member_req_id, comment) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    // Construct the payload object with the review_comment property
    const payload = {
      comment: comment,
      updated_by: member_id,
    };

    axios
      .put(
        process.env.REACT_APP_API_URL +
          `/membershipRequest/membership-requests/${member_req_id}`,
        payload,
        config
      )
      .then((response) => {
        console.log("Comment submitted successfully:", response.data);
        setComment("");
        // Close the comment modal after successful submission
        handleSuccess("Comment submitted successfully And Request is rejected");
        closeCommentForm();
        fetchMembershipRequests();

        // Remove the rejected record from membershipRequests and add it to deactivatedMembers
        const updatedMembershipRequests = membershipRequests.filter(
          (request) => request.member_req_id !== member_req_id
        );
        const rejectedRecord = membershipRequests.find(
          (request) => request.member_req_id === member_req_id
        );
        setMembershipRequests(updatedMembershipRequests);
        setDeactivatedMembers([...deactivatedMembers, rejectedRecord]);
      })
      .catch((error) => {
        console.error("An error occurred while submitting the comment:", error);
        setError(
          "An error occurred while submitting the comment. Please try again later."
        );
        // Handle the error as needed
      });
  };

  // Function to accetp the membership request
  const handleAccept = () => {
    const protocol = window.location.protocol;

    const hostname = window.location.hostname;

    const port = window.location.port;

    const purl = protocol + "//" + hostname + ":" + port;
    // Validate that both chapter and category are selected
    if (!selectedChapter) {
      setError("Please select a chapter to join.");
      return;
    }

    if (!selectedMemberCategory) {
      setError("Please select a member category.");
      return;
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const data = {
      // Include the data you want to send to the server
      // Assuming selectedRequest contains the necessary data
      // Replace the properties according to your data structure
      member_id: member_id,
      member_req_id: selectedRequest.member_req_id,
      first_name: selectedRequest.first_name,
      last_name: selectedRequest.last_name,
      dob: selectedRequest.dob,
      gender: selectedRequest.gender,
      email_id: selectedRequest.email_id,
      contact_no: selectedRequest.contact_no,
      street_address: selectedRequest.street_address,
      city: selectedRequest.city,
      state: selectedRequest.state,
      country: selectedRequest.country,
      zip_code: selectedRequest.zip_code,
      member_type: selectedRequest.member_type,
      company_name: selectedRequest.company_name,
      company_title: selectedRequest.company_title,
      company_email: selectedRequest.company_email,
      company_contact_no: selectedRequest.company_contact_no,
      company_website: selectedRequest.company_website,
      company_linkedIn_url: selectedRequest.company_linkedIn_url,
      company_address: selectedRequest.company_address,
      company_city: selectedRequest.company_city,
      company_state: selectedRequest.company_state,
      company_country: selectedRequest.company_country,
      company_zip_code: selectedRequest.company_zip_code,
      member_category: selectedMemberCategory,
      no_of_years_in_business: selectedRequest.no_of_years_in_business,
      // Conditionall set chapter_to_join based on the value of chapter_to_join
      chapter_to_join: selectedChapter,
      reference_id: selectedMember,
      otherReferenc: customMember,
      membership_status: "Accepted",
      role_id: 3,
      review_comment: selectedRequest.review_comment,
      is_delete: 0,
      org_id: org_id,
      membership_plan: selectedMembershipPlan,
      url: purl,
    };

    axios
      .post(process.env.REACT_APP_API_URL + "/members/members", data, config)
      .then((response) => {
        console.log("Data saved successfully:", response.data);
        setShowViewProfileForm(false);
        handleSuccess("Data saved successfully And Request is accepted");
        fetchMembershipRequests();
        // Add any necessary actions on successful save
      })
      .catch((error) => {
        console.error("An error occurred while saving the data:", error);
        setError("An error occurred while saving the data");
        // Handle the error as needed
      });
  };
  // Function to show Success and error messages
  const handleSuccess = (message) => {
    setSuccessMessages([...successMessages, message]);
  };

  const clearError = () => {
    setError(null);
  };

  //fectch the all requerd data on page
  useEffect(() => {
    // Load the initial list of membership requests
    fetchMembershipRequests();
    fetchMemberCategories();
    fetchChapters();
    fetchMembershipPlans();
    fetchMembers();
    // fetchChapters();
  }, []);

  const toggleExpandRow = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  //to check the which tab is selected
  const handleSelect = (key) => {
    setActiveTab(key);
  };

  // delete the membership request from the rejected list
  const handleDelete = () => {
    if (currentMemberId) {
      fetch(
        process.env.REACT_APP_API_URL +
          `/membershipRequest/membership-requests/${currentMemberId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
            //
          }
          setDeactivatedMembers((prevRequests) =>
            prevRequests.filter((request) => request.id !== currentMemberId)
          );
          setShowdelete(false);
          fetchMembershipRequests();
          handleSuccess("Record is deleted "); // Handle success or display a success message
        })
        .catch((error) =>
          setError("An error occurred while deleteing the data")
        );
    }
  };

  // const handleShiftButtonClick = async (member_req_id) => {
  //   try {
  //     const response = await fetch(
  //       process.env.REACT_APP_API_URL +
  //         `/membershipRequest/membership-requests/changeChapter/${member_req_id}`,
  //       {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`, // Include the token here
  //         },
  //         body: JSON.stringify({ newChapterId: newChapter }),
  //       }
  //     );

  //     if (response.ok) {
  //       const data = await response.json();
  //       setShowViewProfileForm(false);
  //       handleSuccess(
  //         "Membership Request is shiffed to another chapter successfully"
  //       );
  //       fetchMembershipRequests();
  //       // Handle success, e.g., show a success message, update state, etc.
  //       console.log("Chapter shifted successfully:", data);
  //       // Optionally call handleShiftChapter to update the parent component's state
  //     } else {
  //       // Handle errors
  //       setError("An error occurred while Chapter shifted the data");
  //     }
  //   } catch (error) {
  //     console.error("Network error:", error);
  //   }
  // };

  // const handleOptionChange = (e) => {
  //   setShowShiftChapter(e.target.value === "yes");
  // };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            {/* ----multitab code---  */}
            <Col lg="12">
              <Card>
                <Card.Header>
                  <h4>
                    <b>Membership Request List</b>
                  </h4>
                </Card.Header>
                <Card.Body className="p-2">
                  <Tab.Container activeKey={activeTab} onSelect={handleSelect}>
                    <div className="user-tabing p-3">
                      <div className=" flex-wrap align-items-center justify-content-between">
                        <div className=" align-items-center text-center profile-forum-items p-0 m-0">
                          <Nav variant="pills">
                            <Col lg={6} className=" p-3">
                              <Nav.Item>
                                <Nav.Link eventKey="first" role="button">
                                  Membership Requests
                                </Nav.Link>
                              </Nav.Item>
                            </Col>

                            <Col lg={6} className=" p-3">
                              <Nav.Item>
                                <Nav.Link eventKey="second" role="button">
                                  Rejected Request
                                </Nav.Link>
                              </Nav.Item>
                            </Col>
                          </Nav>
                        </div>

                        <Tab.Content>
                          <Tab.Pane eventKey="first" className="fade show">
                            <Col lg={12}>
                              <Card>
                                <Card.Body
                                  className="p-0"
                                  style={{ overflowY: "auto", height: "80vh" }}
                                >
                                  {alertMessage ? (
                                    <div>
                                      {" "}
                                      <p>{alertMessage}</p>
                                    </div>
                                  ) : (
                                    <Table className="rounded">
                                      <thead className="bg-primary text-white">
                                        <tr>
                                          <th></th>
                                          <th>
                                            <b> Name</b>
                                          </th>
                                          <th>
                                            <b>Email</b>
                                          </th>
                                          <th>
                                            <b> Status</b>
                                          </th>
                                          <th></th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {membershipRequests.map(
                                          (request, index) => (
                                            <React.Fragment key={index}>
                                              <tr>
                                                <td>
                                                  <img
                                                    src={user5}
                                                    alt="story-img"
                                                    className="rounded-circle avatar-40"
                                                  />
                                                </td>
                                                <td>
                                                  <b>
                                                    {" "}
                                                    {request.first_name}{" "}
                                                    {request.last_name}{" "}
                                                  </b>
                                                </td>
                                                <td>
                                                  <b> {request.email_id} </b>
                                                </td>
                                                <td>
                                                  <b>
                                                    {" "}
                                                    {request.membership_status ===
                                                    "Accepted"
                                                      ? "Accepted"
                                                      : request.membership_status ===
                                                        "Rejected"
                                                      ? "Rejected"
                                                      : "Pending"}
                                                  </b>
                                                </td>
                                                <td>
                                                  <FaEye
                                                    size={15}
                                                    onClick={() =>
                                                      openViewProfileForm(
                                                        request
                                                      )
                                                    }
                                                  />
                                                  &nbsp;&nbsp;
                                                </td>
                                              </tr>

                                              {expandedRow === index && (
                                                <tr className="expanded-row">
                                                  <td colSpan="6">
                                                    <div className="row">
                                                      <div className="col-lg-6">
                                                        <p>
                                                          <b>
                                                            Contact No:{" "}
                                                            {request.contact_no}
                                                          </b>{" "}
                                                        </p>
                                                        <p>
                                                          <b>
                                                            {" "}
                                                            City: {request.city}
                                                          </b>
                                                        </p>
                                                      </div>
                                                      <div className="col-lg-6">
                                                        <p>
                                                          <b>
                                                            Category:{" "}
                                                            {
                                                              request.member_category
                                                            }
                                                          </b>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              )}
                                            </React.Fragment>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  )}
                                </Card.Body>
                              </Card>
                            </Col>
                          </Tab.Pane>

                          <Tab.Pane eventKey="second" className="fade show">
                            <Col lg={12}>
                              <Card>
                                <Card.Body
                                  className="p-0"
                                  style={{ overflowY: "auto", height: "80vh" }}
                                >
                                  <Table className="rounded">
                                    <thead className="bg-primary text-white">
                                      <tr>
                                        <th></th>
                                        <th>
                                          <b> Name</b>
                                        </th>
                                        <th>
                                          <b>Email</b>
                                        </th>
                                        <th>
                                          <b> Status</b>
                                        </th>
                                        <th></th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {deactivatedMembers.map(
                                        (request, index) => (
                                          <React.Fragment key={index}>
                                            <tr>
                                              <td>
                                                <img
                                                  src={user5}
                                                  alt="story-img"
                                                  className="rounded-circle avatar-40"
                                                />
                                              </td>
                                              <td>
                                                <b>
                                                  {request.first_name}{" "}
                                                  {request.last_name}
                                                </b>
                                              </td>
                                              <td>
                                                <b> {request.email_id}</b>
                                              </td>
                                              <td>
                                                <b>
                                                  {" "}
                                                  {request.membership_status ===
                                                  "Accepted"
                                                    ? "Accepted"
                                                    : request.membership_status ===
                                                      "Rejected"
                                                    ? "Rejected"
                                                    : "Pending"}
                                                </b>
                                              </td>
                                              <td className="col-lg-2">
                                                <FaEye
                                                  size={15}
                                                  onClick={() =>
                                                    openRejectViewProfileForm(
                                                      request
                                                    )
                                                  }
                                                />
                                                &nbsp;&nbsp;
                                                {/* <FaTrash
                                                  className="deleteicon"
                                                  onClick={() =>
                                                    handleShowDelete(
                                                      request.member_req_id
                                                    )
                                                  }
                                                /> */}
                                                {/* modal for delete */}
                                                <Modal
                                                  show={showdelete}
                                                  onHide={handleClosedelete}
                                                  style={{ marginTop: "60px" }}
                                                >
                                                  <Modal.Header
                                                    closeButton
                                                    style={{
                                                      backgroundColor:
                                                        "#007BFF",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Modal.Title>
                                                      Delete Member{" "}
                                                    </Modal.Title>
                                                  </Modal.Header>
                                                  <Modal.Body>
                                                    Are you sure you want to
                                                    delete this row?
                                                  </Modal.Body>
                                                  <Modal.Footer>
                                                    <Button
                                                      variant="secondary"
                                                      onClick={
                                                        handleClosedelete
                                                      }
                                                      style={{
                                                        backgroundColor:
                                                          "#6c757d",
                                                        border: "none",
                                                      }}
                                                    >
                                                      Close
                                                    </Button>
                                                    <Button
                                                      variant="secondary"
                                                      onClick={handleDelete}
                                                      style={{
                                                        backgroundColor:
                                                          "#007BFF",
                                                        border: "none",
                                                      }}
                                                    >
                                                      Yes, Delete it
                                                    </Button>
                                                  </Modal.Footer>
                                                </Modal>
                                                {/* &nbsp;&nbsp;
                                                {expandedRow === index ? (
                                                  <FiChevronUp
                                                    size={30}
                                                    onClick={() =>
                                                      toggleExpandRow(index)
                                                    }
                                                  />
                                                ) : (
                                                  <FiChevronDown
                                                    size={30}
                                                    onClick={() =>
                                                      toggleExpandRow(index)
                                                    }
                                                  />
                                                )} */}
                                              </td>
                                            </tr>

                                            {expandedRow === index && (
                                              <tr className="expanded-row">
                                                <td colSpan="6">
                                                  <div className="row">
                                                    <div className="col-lg-12">
                                                      <p>
                                                        <b>
                                                          Reason for Rejection:{" "}
                                                          {
                                                            request.review_comment
                                                          }
                                                        </b>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </React.Fragment>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </div>
                  </Tab.Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Modal form for show the profie for reject list  */}

      {/* Modal form for show the profie */}
      <Modal
        size="lg"
        scrollable={true}
        show={showViewProfileForm}
        onHide={closeViewProfileForm}
        style={{ marginTop: "15vh", marginBottom: "5vh", height: "80vh" }}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#007BFF", color: "white" }}
        >
          <Modal.Title>Membership Request Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              {selectedRequest && (
                <Form>
                  <Form.Group className="form-group align-items-center">
                    <Col md="12"></Col>
                  </Form.Group>
                  <Row className="align-items-center">
                    <Form.Group className="form-group col-sm-6">
                      <Form.Label htmlFor="fname" className="form-label">
                        First Name:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        value={selectedRequest.first_name}
                        id="fname"
                        placeholder="Enter your first name"
                      />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                      <Form.Label htmlFor="lname" className="form-label">
                        Last Name:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        value={selectedRequest.last_name}
                        id="lname"
                        placeholder="Enter your last name"
                      />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                      <Form.Label htmlFor="email" className="form-label">
                        Email Id:
                      </Form.Label>
                      <Form.Control
                        type="email"
                        className="form-control"
                        id="email"
                        value={selectedRequest.email_id}
                        placeholder="Enter email"
                      />
                    </Form.Group>
                    <Form.Group className="form-group col-sm-6">
                      <Form.Label className="form-label">
                        Contact No.:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="age"
                        value={selectedRequest.contact_no}
                        placeholder="Enter Contact No"
                      />
                    </Form.Group>
                    <Row>
                      <Col sm="6">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="chapter">
                            No. Of Years In Business{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="NoOfYear"
                            value={selectedRequest.no_of_years_in_business}
                            placeholder="Enter No. Of Years In Business"
                          />
                        </Form.Group>
                      </Col>
                      {isRejectedTab && (
                        <Col sm="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="chapter">
                              {getOrgNames?.alt_chapter || "Chapter"}
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </Form.Label>
                            {selectedRequest.chapter_to_join == null ? (
                              <Form.Control
                                as="select"
                                id="chapter"
                                className="form-select"
                                placeholder="select a chapter"
                                value={selectedChapter}
                                onChange={(e) =>
                                  setSelectedChapter(e.target.value)
                                }
                              >
                                <option value="" disabled selected>
                                  Select {getOrgNames?.alt_chapter || "Chapter"}
                                </option>
                                {chapters.map((chapter) => (
                                  <option
                                    key={chapter.chapter_id}
                                    value={chapter.chapter_id}
                                  >
                                    {chapter.chapter_name}
                                  </option>
                                ))}
                              </Form.Control>
                            ) : (
                              <Form.Control
                                type="text"
                                className="form-control"
                                value={selectedRequest.chapter_name1}
                                id="chapter"
                                placeholder="Chapter"
                                readOnly
                              />
                            )}
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                    {isRejectedTab && (
                      <>
                        <Row>
                          <Col sm="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="memberCategory">
                                {getOrgNames?.alt_membership_category ||
                                  "  Member Category"}
                                <span style={{ color: "red" }}>*</span> :{" "}
                              </Form.Label>
                              {selectedRequest.member_category == null ? (
                                <Form.Control
                                  as="select"
                                  id="memberCategory"
                                  className="form-select"
                                  placeholder="select a category"
                                  value={selectedMemberCategory}
                                  onChange={(e) =>
                                    setSelectedMemberCategory(e.target.value)
                                  }
                                >
                                  <option value="" disabled selected>
                                    Select{" "}
                                    {getOrgNames?.alt_membership_category ||
                                      "  Member Category"}
                                  </option>
                                  {memberCategories.map((category) => (
                                    <option
                                      key={category.id}
                                      value={category.mcategory_id}
                                    >
                                      {category.member_category}
                                    </option>
                                  ))}
                                </Form.Control>
                              ) : (
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.member_category1}
                                  id="memberCategory"
                                  placeholder="Member Category"
                                  readOnly
                                />
                              )}
                            </Form.Group>
                          </Col>

                          <Col sm="6">
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="membershipPlan">
                                {getOrgNames?.alt_membership_plan ||
                                  "Membership Plan "}
                                <span style={{ color: "red" }}>*</span> :{" "}
                              </Form.Label>
                              {selectedRequest.membership_plan == null ? (
                                <Form.Control
                                  as="select"
                                  id="membershipPlan"
                                  className="form-select"
                                  placeholder="select a plan"
                                  value={selectedMembershipPlan}
                                  onChange={(e) =>
                                    setSelectedMembershipPlan(e.target.value)
                                  }
                                >
                                  <option value="" disabled selected>
                                    Select{" "}
                                    {getOrgNames?.alt_membership_plan ||
                                      "Membership Plan "}
                                  </option>
                                  {membershipPlans.map((plan) => (
                                    <option key={plan.id} value={plan.mplan_id}>
                                      {plan.membership_plan}
                                    </option>
                                  ))}
                                </Form.Control>
                              ) : (
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.membership_plan1}
                                  id="membershipPlan"
                                  placeholder="Membership Plan"
                                  readOnly
                                />
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <Form.Group className="form-group">
                              <Form.Label>
                                Refered By
                                <spna style={{ color: "red" }}>*</spna> :
                              </Form.Label>
                              {selectedRequest.member_id == null ? (
                                <Form.Control
                                  as="select"
                                  id="membership"
                                  value={selectedMember}
                                  onChange={(e) =>
                                    setSelectedMember(e.target.value)
                                  }
                                >
                                  <option value="">Select a member</option>
                                  {members.map((member) => (
                                    <option
                                      key={member.member_id}
                                      value={member.member_id}
                                    >
                                      {member.first_name} {""}
                                      {member.last_name}
                                    </option>
                                  ))}
                                  <option value="Other">Other</option>
                                </Form.Control>
                              ) : (
                                <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={selectedRequest.member_name}
                                  id="chapter"
                                  placeholder="Chapter"
                                  readOnly
                                />
                              )}
                            </Form.Group>
                          </Col>

                          <Col sm="6">
                            {selectedMember === "Other" && (
                              <Form.Group>
                                <Form.Label>Custom Member</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={customMember}
                                  onChange={(e) =>
                                    setCustomMember(e.target.value)
                                  }
                                  placeholder="Enter custom Refrence"
                                />
                              </Form.Group>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                    {selectedRequest.review_comment != null &&
                      selectedRequest.review_comment !== undefined && (
                        <Row>
                          <Col sm="12">
                            <Form.Group>
                              <Form.Label>Resone for Rejection </Form.Label>
                              <Form.Control
                                type="text"
                                value={selectedRequest.review_comment}
                                onChange={(e) =>
                                  setCustomMember(e.target.value)
                                }
                                placeholder="Enter custom Refrence"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                  </Row>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeViewProfileForm}
            style={{ backgroundColor: "#6c757d", border: "none" }}
          >
            Close
          </Button>
          {isShowButtons && isRejectedTab && (
            <Button
              variant="primary"
              onClick={openCommentForm}
              style={{ backgroundColor: "#007BFF", border: "none" }}
            >
              Reject
            </Button>
          )}
          {isRejectedTab && isShowButtons && (
            <Button
              variant="primary"
              onClick={handleAccept}
              style={{ backgroundColor: "#007BFF", border: "none" }}
            >
              Accept
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* ---------comment model code */}
      <Modal
        show={showCommentForm}
        onHide={closeCommentForm}
        style={{ marginTop: "60px" }}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#007BFF", color: "white" }}
        >
          <Modal.Title>Request Rejection Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="row">
              <div className="col-md-12">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeCommentForm}
            style={{ backgroundColor: "#6c757d", border: "none" }}
          >
            Close
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              submitComment(selectedRequest.member_req_id, comment)
            }
            style={{ backgroundColor: "#007BFF", border: "none" }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Success Alert */}
      <Alert
        show={successMessages.length > 0}
        variant="success"
        onClose={() => setSuccessMessages([])}
        dismissible
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          border: "1px solid green",
          color: "black",
          width: "300px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FaTimes
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            onClick={() => setSuccessMessages([])}
          />
        </div>
        <Alert.Heading>Success!</Alert.Heading>
        {successMessages.map((message, index) => (
          <p key={index}>{message}</p>
        ))}
      </Alert>

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={clearError}
          dismissible
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "1px solid red",
            color: "black",
            width: "300px",
            zIndex: 9999,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
              onClick={clearError}
            />
          </div>
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      )}
    </>
  );
};

export default MembershipRequest;
