import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import { FaEye } from "react-icons/fa";

const RegisteredMembers = ({ eventId}) => {
  const token = sessionStorage.getItem('token');

  const [members, setMembers] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();


  const fetchDataFromApi = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/event/getallregi/${eventId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setMembers(data.events);
      } else {
        const errorData = await response.json();
        setError(`Error fetching registered members: ${JSON.stringify(errorData)}`);
      }
    } catch (error) {
      console.error('Unexpected error:', error.message);
      setError('Error fetching registered members. Please try again later.');
    }
  };

  useEffect(() => {
    fetchDataFromApi();
    const intervalId = setInterval(() => {
      fetchDataFromApi();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const navigateToFriendProfile = (memberId, user_id) => {
    // Navigate to the friend profile page with memberId
    navigate("/dashboard/app/fprofile", {
      state: { state: memberId, userid: user_id },
    });
  };

  return (
    <>
      <Container>
        <Row>
          {members.length > 0 ? (
            <Col lg="12">
              <Card>
                <Card.Header>
                  <h4>Registered Members</h4>
                </Card.Header>
                <Card.Body>
                  <Table className="rounded">
                    <thead className="bg-primary text-white">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {members.map((member, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{member.first_name} {member.last_name}</td>
                            <td>{member.email_id}</td>
                            <td>
                                <FaEye
                                    size={15}
                                    color="#007BFF"
                                    onClick={() => navigateToFriendProfile(member.member_id, member.user_id)}
                                  />
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          ) : (
            <p>No registered members available!</p>
          )}
        </Row>
      </Container>

       
    </>
  );
};

export default RegisteredMembers;
