import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import Card from "../../../components/Card";
import MeetingRegister from "./meetingRegister";
import profil1 from "../../../assets/images/user/1.png";
import { useLocation } from "react-router-dom";
import useOrgNaming from "../../../hooks/getNamingHook";
import ChapterMeetingRegi from "../app/chapterMeetingRegi"
import {
  FaUserCheck,
} from "react-icons/fa";
const ChapterMeetingPost = () => {
  // State variables for managing training data and UI states
  const [adminPost, setAdminPost] = useState([]);
  const location = useLocation();
  let filteredState = (location.state ?? {}).filteredResult ?? [];
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [error, setError] = useState(null);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [message1, setMessage1] = useState("");
  const org_id = sessionStorage.getItem("org_id");
  const memberID = sessionStorage.getItem("member_id");
  const token = sessionStorage.getItem("token");
  const { getOrgNames } = useOrgNaming(org_id, token);
  const chapterId = sessionStorage.getItem("chapter_id");
  const userRole = sessionStorage.getItem("role");
  const [showRegistrationDiv, setShowRegistrationDiv] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const fetchRegisterDataFromApi = async (eventId) => {
    setSelectedEventId(eventId);
    setShowMembersModal(true);
  };

  // Function to fetch training data from the API
  const fetchDataFromApi = async () => {
    try {
      // If chapterId is not null, call the existing API with chapterId
      const response = await fetch(
        process.env.REACT_APP_API_URL +
        `/meetings/oneonone/chapter/all/${org_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        let filteredEvents = data.imageUrls;
        // Filter based on role
        if (userRole == "CHAPTER_ADMIN" || userRole == "MEMBER") {
          filteredEvents = filteredEvents.filter(
            (event) => event.chapter_id == chapterId
          );
        }
        // Filter events where cutoff registration date has not passed
        // const filteredEvents = data.results.filter(
        //   (event) => new Date(event.meeting_cut_off_date) > new Date()
        // );
        // Sort events by start date
        const sortedEvents = filteredEvents.sort(
          (a, b) => new Date(a.meeting_date) - new Date(b.meeting_date)
        );

        setAdminPost(sortedEvents);
      }
    } catch (error) {
      console.error("Unexpected error:", error.message);
      // setError("Error fetching tranning. Please try again later.");
    }
  };
  useEffect(() => {
    // Fetch initial data
    fetchDataFromApi();

    // Poll for updates every 5 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      fetchDataFromApi();
    }, 5000);
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once

  // Handler to open registration modal for a selected training
  const handleRegister = (index) => {
    setShowRegistrationDiv(true);
    console.log("cliked", showRegistrationDiv);

    setSelectedEventIndex(index);
  };

  // Handler to close registration modal
  const handleRegistrationClose = () => {
    setShowRegistrationDiv(false);
    setSelectedEventIndex(null);
  };

  // Function to format the date as dd/mm/yyyy
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // to sort data based on posid
  const sortedDataa = useMemo(() => {
    if (Array.isArray(adminPost)) {
      return [...adminPost]
        .sort((a, b) => new Date(b.meeting_id) - new Date(a.meeting_id))
        .map((post) => ({
          ...post,
          startDate: formatDate(post.meeting_date),
          // CutOffDate: formatDate(post.train_cutoff_reg_date),
        }));
    } else {
      console.error("adminPost is not an array");
      return [];
    }
  }, [adminPost]); // Ensure that postData is the only dependency, not sortedData

  // functions to display success or error message
  const showSuccess = () => {
    setShowSuccessAlert(true);
  };

  const closeSuccess = () => {
    setShowSuccessAlert(false);
  };

  const clearError = () => {
    setError(null);
  };
  const today = new Date().toISOString().split("T")[0]; // Get the current date in YYYY-MM-DD format

  // Function to format time from 24-hour to 12-hour format
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);

    if (formattedHour === 0) {
      formattedHour = 12; // Midnight case (00:00 to 12:00 AM)
    } else if (formattedHour >= 12) {
      period = "PM";
      if (formattedHour > 12) {
        formattedHour -= 12;
      }
    }

    return `${formattedHour}:${minute} ${period}`;
  };
  return (
    <Container>
      {(filteredState && filteredState.length > 0
        ? filteredState
        : sortedDataa
      ).map(
        (item, index) =>
          item?.meeting_cut_off_date >= today && (
            <Card key={item.meeting_id}>
              <Card.Header className="p-0">
                {/* <Col sm={12}>
              <div className="card card-block card-stretch card-height"> */}
                <div className="card-body">
                  <div className="user-post-data">
                    <div className="d-flex justify-content-between">
                      <div className="me-3">
                        <img
                          loading="lazy"
                          className="rounded-circle avatar-60"
                          src={item.imageUrl || profil1}
                          alt=""
                        />
                      </div>
                      <div className="w-100">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5 className="mb-0 d-inline-block">
                              {item.organizer_name}
                            </h5>
                            &nbsp;
                            <span className="badge rounded-pill text-bg-light mx-1">
                              CHAPTER ADMIN
                            </span>
                            <p className="mt-1 mb-0 text-primary">
                              {item.meeting_subject}
                              <span
                                className="badge rounded-pill mx-1"
                                style={{
                                  backgroundColor: "gray",
                                  color: "white",
                                }}
                              >
                                M
                              </span>
                              <FaUserCheck
                                // className="editicon"
                                size={15}
                                color="#007BFF"
                                onClick={() =>
                                  fetchRegisterDataFromApi(item.meeting_id)
                                }
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div>
            </Col> */}
              </Card.Header>
              <Card.Body className="p-3">
                <Row>
                  <Col lg="6">
                    <p>
                      <b>
                        {getOrgNames?.alt_meeting || "Meeting"} Subject:{" "}
                        {item?.meeting_subject}
                      </b>
                    </p>
                  </Col>
                  <Col lg="6">
                    <p>
                      <b>
                        Cut Off Reg Date:{" "}
                        {new Date(item?.meeting_cut_off_date)
                          .getDate()
                          .toString()
                          .padStart(2, "0")}
                        /
                        {(new Date(item?.meeting_cut_off_date).getMonth() + 1)
                          .toString()
                          .padStart(2, "0")}
                        /{new Date(item?.meeting_cut_off_date).getFullYear()}
                      </b>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <p>
                      <b>Mode: {item?.meeting_mode}</b>
                    </p>
                  </Col>
                  <Col lg="6">
                    <p>
                      <b>
                        {item.meeting_mode === "Virtual" ? (
                          <>
                            {getOrgNames?.alt_meeting || "Meeting"} Link:
                            <a
                              href={item.meeting_link}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ marginLeft: "5px" }} // Optional: Adds space between the label and the link
                            >
                              Join Meeting
                            </a>
                          </>
                        ) : (
                          <>
                            {getOrgNames?.alt_meeting || "Meeting"} Place:{" "}
                            {item?.meeting_place}
                          </>
                        )}
                      </b>
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <p>
                      <b>
                        Date:{" "}
                        {new Date(item?.meeting_date)
                          .getDate()
                          .toString()
                          .padStart(2, "0")}
                        /
                        {(new Date(item?.meeting_date).getMonth() + 1)
                          .toString()
                          .padStart(2, "0")}
                        /{new Date(item?.meeting_date).getFullYear()}
                      </b>
                    </p>
                  </Col>
                  <Col lg="6">
                    <p>
                      <b>
                        Time: {formatTime(item?.meeting_start_time)} -{" "}
                        {formatTime(item?.meeting_end_time)}
                      </b>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <p>
                      <b>Description: {item?.meeting_desc}</b>
                    </p>
                  </Col>
                  <Col lg="6">
                    <p>
                      <b>Charges: ₹{item?.meeting_charges}</b>
                    </p>
                  </Col>
                </Row>
                {item?.meeting_payment_link && (
                  <Row>
                    <p>
                      <b>
                        {getOrgNames?.alt_meeting || "Meeting"} Payment link:{" "}
                        <a
                          href={item?.meeting_payment_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click to pay
                        </a>
                      </b>
                    </p>
                  </Row>
                )}
              </Card.Body>
              <Card.Footer>
                {!showRegistrationDiv && selectedEventIndex !== index && (
                  <>
                    <Row>
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="primary"
                          onClick={() => handleRegister(index)}
                        >
                          <b>Register</b>
                        </Button>
                      </div>
                    </Row>
                  </>
                )}
                {showRegistrationDiv && selectedEventIndex === index && (
                  <Row>
                    <Col lg="12">
                      <div className="registration-div">
                        <MeetingRegister
                          onClose={handleRegistrationClose}
                          meeting_id={item.meeting_id}
                          meeting_payment_link={item.meeting_payment_link}
                          memberId={memberID}
                          createdBy={memberID}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </Card.Footer>
            </Card>
          )
      )}
      {/* Modal to show members */}
      <Modal
        // size="sm"
        scrollable={true}
        style={{ marginTop: "15vh", height: "80vh", marginBottom: "5vh" }}
        show={showMembersModal} onHide={() => setShowMembersModal(false)}>
        <Modal.Body>
          <ChapterMeetingRegi
            meetingID={selectedEventId}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowMembersModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>

  );
};

export default ChapterMeetingPost;
